<script>
  export let socket_query;
  export let user_level;
  import {
    menu,
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    geos_class_list,
    route_list,
    route_list_d,
    travel_crtl,
    geos_joined,
    geos_class_join,
    internal_com,
    map_ready,
    monitor_extra_map,
    recognition,
    unit_groups,
    unit_config,
    unit_types,
    reports_cron,
    users_list,
    protocol,
    alerts_list,
    reports,
    driver_reports,
  } from "./stores.js";
  import { onDestroy, onMount } from "svelte";
  import { protocol_templates } from "./protocol_templates.js";
  import { alert_templates } from "./alert_templates.js";

  let map, m_inicio;
  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center:
        last_post_big != null
          ? last_post_big
          : { lat: 24.458489, lng: -102.217231 },
      zoom: 16,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });

    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (last_post_big != null) {
      m_inicio = new google.maps.Marker({
        position: last_post_big,
        map: map,
        animation: google.maps.Animation.DROP,
        zIndex: 0,
      });
    } else {
      map.fitBounds(bounds);
    }
  }

  let units_list_p;
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  let selected = !localStorage.getItem("selected_config")
    ? 1
    : parseInt(localStorage.getItem("selected_config"));
  $: {
    localStorage.setItem("selected_config", selected);
  }
  let configuraciones = [
    "Grupos",
    "Unidades",
    "Reportes programados",
    "Formatos de reporte",
  ];
  function maping(i) {
    selected = i;
  }
  function reporte_csv() {
    let csv = "";
    var regex = /( |<([^>]+)>)/gi;
    regex = /<[^>]*>?/gm;
    regex = /(<([^>]+)>)/gi;
    let file_name = "Reporte.csv";

    if (selected == 8) {
      var table = document.getElementById("big_query_table");
      var rowLength = table.rows.length;
      for (let i = 0; i < rowLength; i++) {
        var oCells = table.rows.item(i).cells;
        var cellLength = oCells.length;
        for (var j = 0; j < cellLength; j++) {
          var cellVal = oCells.item(j).innerHTML;
          cellVal = cellVal.replace(regex, "");
          cellVal = cellVal.replace(/,/g, "|");
          cellVal = cellVal.replace(/\n/g, "|");
          csv = csv + cellVal + ",";
        }
        csv = csv.slice(0, -1);
        csv = csv + "\n";
      }
      file_name = "Reporte_bigq.csv";
    }

    if (selected == 4) {
      file_name = "Alertas-Complejas.csv";
      let titles = [];
      for (let x in $alerts_list) {
        let parcial = [];
        for (let y in $alerts_list[x]) {
          if (titles.indexOf(y) == -1) {
            titles.push(y);
          }
          parcial[titles.indexOf(y)] = JSON.stringify(
            $alerts_list[x][y]
          ).replace(/,/g, "|");
        }
        csv = csv + parcial.join(",") + "\n";
      }
      csv = titles.join(",") + "\n" + csv;
    }

    if (selected == 5) {
      file_name = "Usuarios.csv";
      let titles = [];
      for (let x in $users_list) {
        let parcial = [];
        for (let y in $users_list[x]) {
          if (titles.indexOf(y) == -1) {
            titles.push(y);
          }
          parcial[titles.indexOf(y)] = $users_list[x][y]
            .toString()
            .replace(/,/g, "|");
        }
        csv = csv + parcial.join(",") + "\n";
      }
      csv = titles.join(",") + "\n" + csv;
    }

    if (selected == 6) {
      file_name = "Protocolos.csv";
      let titles = ["id", "name", "init"];
      for (let x in $protocol) {
        let parcial = [x];
        for (let y in $protocol[x]) {
          if (titles.indexOf(y) == -1) {
            titles.push(y);
          }
          parcial[titles.indexOf(y)] = JSON.stringify($protocol[x][y]).replace(
            /,/g,
            "|"
          );
        }
        csv = csv + parcial.join(",") + "\n";
      }
      csv = titles.join(",") + "\n" + csv;
    }

    if (selected == 1) {
      file_name = "Unidades.csv";
      let titles = ["Nick name", "Serial", "Type"];
      for (let x in $units_list) {
        let parcial = [
          $nicks_list[$units_list[x]],
          $units_list[x],
          $unit_config[$units_list[x]]
            ? $unit_config[$units_list[x]].type
            : "FS",
        ];
        csv = csv + parcial.join(",") + "\n";
      }
      csv = titles.join(",") + "\n" + csv;
    }

    if (selected == 0) {
      file_name = "Grupos.csv";
      let titles = ["Equipor Maestro", "Equipos Secundarios"];
      for (let x in $unit_groups) {
        let parcial = [$nicks_list[$unit_groups[x][0]]];
        for (let y in $unit_groups[x][1]) {
          parcial.push($nicks_list[$unit_groups[x][1][y]]);
        }
        csv = csv + parcial.join(",") + "\n";
      }
      csv = titles.join(",") + "\n" + csv;
    }

    let bom = "\uFEFF";
    csv = bom + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = file_name;
    hiddenElement.click();
  }
  let mostrar_todo_ = false;
  function mostrar_todo() {
    if (!mostrar_todo_) {
      p_size = 10000;
      mostrar_todo_ = true;
    } else {
      p_size = 200;
      mostrar_todo_ = false;
    }
  }
  function data_table_on() {}
  let selected_edit = -1;
  let selected_borrar = -1;
  let eco_selected = [];
  let eco_master = "";
  function menu_grupos(i) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Editar",
          onClick: () => {
            eco_selected = [...$unit_groups[i][1]];
            selected_edit = i;
          },
        },
        {
          label: "Borrar",
          onClick: () => {
            delete_group(i);
          },
        },
      ],
    });
  }
  function save_group(i, master) {
    console.log(eco_selected, i, master);
    socket_query.emit("edit_unit_group", master, i, eco_selected, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Grupo guardado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        extra_add = [];
        selected_edit = -1;
      }
    });
  }
  let new_group = [];
  let new_group_mode = false;
  function add_group() {
    if (new_group.length == 0) {
      eco_selected = [];
      new_group = [[[], [[]]]];
      new_group_mode = true;
    }
  }
  function add_secundario() {
    if (new_group.length != 0) {
      new_group[0][1].push([]);
      new_group = [...new_group];
    }
  }
  let saved_ok = false,
    saved_type,
    saved_text;
  function save_new_group() {
    socket_query.emit("new_unit_group", eco_master, eco_selected, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Grupo guardado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        new_group = [];
        new_group_mode = false;
      }
    });
  }
  let extra_add = [];
  function delete_group(i) {
    socket_query.emit("delete_unit_group", i, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Grupo borrado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }

  let new_unit = [];
  let new_unit_mode = false;
  let new_eco = "",
    new_serial = "",
    new_type,
    edit_type;
  function menu_unidades(i, unit) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Editar",
          onClick: () => {
            select_actual(i);
            selected_edit = i;
          },
        },
        {
          label: "Borrar",
          onClick: () => {
            delete_unidad(i, unit);
          },
        },
      ],
    });
  }
  function delete_unidad(i, unit) {
    socket_query.emit("delete_unit", unit, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Unidad borrada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  function add_unidad() {
    if (new_unit.length == 0) {
      new_type = $unit_types[0];
      new_unit = [[]];
      new_unit_mode = true;
    }
  }
  function save_new_unit(i) {
    console.log(new_eco, new_serial);
    socket_query.emit(
      "new_unit",
      new_eco.replace(/\s/g, ""),
      new_serial,
      new_type,
      (data) => {
        if (data == "ok") {
          saved_type = "alert-success";
          saved_text = "Unidad guardada";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
          new_unit = [];
          new_unit_mode = false;
        }
      }
    );
  }
  function save_unit(unit) {
    socket_query.emit("edit_unit", unit, new_serial, edit_type, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Unidad guardada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        selected_edit = -1;
      }
    });
  }
  function select_actual(i) {
    let item = $units_list[i];
    new_serial = item;
    edit_type =
      typeof $unit_config[item] != "undefined" ? $unit_config[item].type : "FS";
  }

  let new_report_cron = [];
  let new_report_cron_mode = false;
  function add_report_cron() {
    if (new_report_cron.length == 0) {
      new_report_cron = [
        {
          name: "Reporte programado",
          time: [12],
          day: [1],
          vars: ["travel_loop", "units_loop"],
          format: "travel",
          emails: [""],
          last: 0,
        },
      ];
      new_report_cron_mode = true;
    }
  }
  function add_time_report() {
    new_report_cron[0].time.push(12);
    new_report_cron = [...new_report_cron];
  }
  function add_day_report() {
    new_report_cron[0].day.push(0);
    new_report_cron = [...new_report_cron];
  }
  function add_email_report() {
    new_report_cron[0].emails.push("");
    new_report_cron = [...new_report_cron];
  }
  function save_new_report_cron() {
    new_report_cron.map((x) => {
      x.time = x.time.map((y) => parseInt(y));
      x.day = x.day.map((y) => parseInt(y));
    });
    new_report_cron[0].emails = new_report_cron[0].emails.filter(
      (x) => x != ""
    );
    new_report_cron[0].emails = new_report_cron[0].emails.map((x) => x.trim());
    socket_query.emit("new_report", new_report_cron[0], (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Reporte programado guardado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        new_report_cron = [];
        new_report_cron_mode = false;
      }
    });
  }
  let report_to_delete = -1;
  let m_delete_report = false;
  function menu_reportes(i) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Borrar",
          onClick: () => {
            report_to_delete = i;
            m_delete_report = true;
          },
        },
      ],
    });
  }
  function delete_report() {
    socket_query.emit("delete_report", report_to_delete, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Reporte programado borrado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        m_delete_report = false;
      }
    });
  }
  function day_es(i) {
    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    return dias[i];
  }

  let eco_f_,
    eco_f = "";
  $: {
    configuraciones = [
      "Grupos",
      "Unidades",
      "Reportes programados",
      "Formatos de reporte",
    ];
    if (user_level >= 4) {
      configuraciones.push("Alertas Complejas");
      configuraciones.push("Usuarios");
      configuraciones.push("Protocolos");
      configuraciones.push("Formularios para viajes");
      configuraciones = [...configuraciones];
    } else {
      configuraciones.push("");
      configuraciones.push("");
      configuraciones.push("");
      configuraciones.push("");
      configuraciones = [...configuraciones];
    }
    if (user_level > 4) {
      configuraciones.push("Diagnóstico (Big Query)");
      configuraciones.push("Sesiones");
      configuraciones = [...configuraciones];
      socket_query.emit("units_big_query");
      socket_query.emit("sessions");
    } else {
      configuraciones.push("");
      configuraciones.push("");
      configuraciones = [...configuraciones];
    }
    if (user_level >= 4) {
      configuraciones.push("Configuraciones generales");
      configuraciones = [...configuraciones];
    } else {
      configuraciones.push("");
      configuraciones = [...configuraciones];
    }
  }
  let units_big_query = [];
  let big_seaching = false;
  socket_query.on("units_big_query", async function (data) {
    units_big_query = data;
  });
  $: {
    p_size;
    if (eco_f.length == 10) {
      if (units_big_query.includes(eco_f)) {
        big_seaching = true;
        paginas_l = [];
        secciones_l = [];
        cas_info = [];
        puls_info = {
          iccid: "",
          lastIdtime: "",
          scriptVersion: "",
          configVersion: "",
          firmware: "",
          firmware2: "",
          imsi: "",
          partInfo: "",
        };
        socket_query.emit("alert_reload", eco_f, 3, (data) => {});
        socket_query.emit("read_big_query", eco_f, fecha_v, fecha_v2);
        socket_query.emit("puls", eco_f);
        //socket_query.emit('cas',eco_f);
        cas_info_runing = true;
        socket_query.emit("cas2", eco_f, (data) => {
          cas_info_runing = false;
          data = JSON.parse(data);
          if (data.length > 0) {
            let cas_info_p = data;
            cas_info = [];
            for (let x in cas_info_p) {
              for (let y in cas_info_p[x]) {
                if (parseInt(y) == 3) {
                  // cambia fecha
                  let dat1 = cas_info_p[x][y]
                    .split(",")[0]
                    .replace(/\//g, "")
                    .replace("Ene", "01")
                    .replace("Feb", "02")
                    .replace("Mar", "03")
                    .replace("Abr", "04")
                    .replace("May", "05")
                    .replace("Jun", "06")
                    .replace("Jul", "07")
                    .replace("Ago", "08")
                    .replace("Sep", "09")
                    .replace("Oct", "10")
                    .replace("Nov", "11")
                    .replace("Dic", "12")
                    .replace(/ /g, "");
                  cas_info_p[x][y] =
                    moment(
                      dat1.substring(4, 8) +
                        dat1.substring(2, 4) +
                        dat1.substring(0, 2)
                    )
                      .utc()
                      .startOf("day")
                      .valueOf() / 1000;
                }
              }
            }
            for (let w in cas_info_p) {
              for (let x = 0; x < cas_info_p.length - 1; x++) {
                if (cas_info_p[x][3] < cas_info_p[x + 1][3]) {
                  let par = cas_info_p[x];
                  cas_info_p[x] = [...cas_info_p[x + 1]];
                  cas_info_p[x + 1] = [...par];
                }
              }
            }
            cas_info = [...cas_info_p];
          } else {
            cas_info = [];
          }
        });
      }
    }
  }
  let unit_data = [];
  let total_data = [];
  let total_data_ex = [];
  let run_cron = false;
  let run_cron_m = !localStorage.getItem("run_cron_m")
    ? true
    : localStorage.getItem("run_cron_m") == "true"
      ? true
      : false;
  $: {
    run_cron_m;
    localStorage.setItem("run_cron_m", run_cron_m);
  }
  let last_post_big;
  socket_query.on("read_big_query", async function (data) {
    total_data = data;
    if (data.length > 0) {
      total_data_ex = [data[0]];
    }
    let max_size = data.length;
    paginas_l = [];
    f_pag = 0;
    secciones_l = [];
    f_secc = 0;
    for (let x = 0; x < Math.round(max_size / p_size) + 1; x++) {
      paginas_l.push(x);
    }
    for (let x = 0; x < Math.floor(paginas_l.length / 15) + 1; x++) {
      secciones_l.push(x);
    }
    filtro_secc(0);
    big_seaching = false;
    //eco_f_="";
    unit_data = [];
    for (let x = 0; x < p_size && x < data.length; x++) {
      unit_data.push(data[x]);
    }
    let now = new Date(moment().utc().startOf("day").valueOf())
      .toISOString()
      .split("T")[0];
    let hasta = new Date(fecha_v2 * 1000).toISOString().split("T")[0];
    if (eco_f != "" && now == hasta && total_data_ex.length > 0) {
      run_cron = true;
    }
    //console.log("rub",now,hasta,total_data_ex.length>0,eco_f)

    //Buscar ultima posición
    last_post_big = null;
    for (let x in data) {
      if (data[x][1].substring(20, 22) == "02") {
        let lat_total =
          parseInt(
            (parseInt(data[x][1].substring(42, 50), 16) > 0x80000000
              ? ((0xffffffff - parseInt(data[x][1].substring(42, 50), 16) + 1) *
                  -1) /
                10000000
              : parseInt(data[x][1].substring(42, 50), 16) / 10000000) * 10000
          ) / 10000;
        let lng_total =
          parseInt(
            (parseInt(data[x][1].substring(50, 58), 16) > 0x80000000
              ? ((0xffffffff - parseInt(data[x][1].substring(50, 58), 16) + 1) *
                  -1) /
                10000000
              : parseInt(data[x][1].substring(50, 58), 16) / 10000000) * 10000
          ) / 10000;
        last_post_big = { lat: lat_total, lng: lng_total };
        break;
      }
    }
  });

  function big_cron_() {
    if (run_cron) {
      socket_query.emit(
        "read_big_query",
        eco_f,
        fecha_v,
        fecha_v2,
        "now",
        total_data_ex[0][0]
      );
    }
    if (eco_f != "" && run_cron_m) {
      socket_query.emit("alert_reload", eco_f, 3, (data) => {});
    }
  }

  let big_cron;
  big_cron = setInterval(big_cron_, 30000);
  onDestroy(() => {
    run_cron = false;
    clearInterval(big_cron);
    big_cron = null;
    window.removeEventListener("message", handleMessage);
    socket_query.off("units_big_query");
    socket_query.off("read_big_query");
    socket_query.off("read_big_query_ex");
    socket_query.off("sessions");
    socket_query.off("puls");
  });

  socket_query.on("read_big_query_ex", async function (data) {
    if (data.length > 0) {
      total_data_ex = data.concat(total_data_ex);
      if (f_pag == 0) {
        filtro(0);
      }
    }
  });

  let fechas = [];
  let fecha_v_ = moment().startOf("day").subtract(8, "days").format("DD-MM-YY");
  let fecha_v =
    moment().utc().startOf("day").subtract(8, "days").valueOf() / 1000;
  let fecha_v_2 = moment().startOf("day").format("DD-MM-YY");
  let fecha_v2 = moment().utc().startOf("day").valueOf() / 1000;
  for (let x = 0; x < 90; x++) {
    fechas.push([
      moment().utc().startOf("day").subtract(x, "days").valueOf() / 1000,
      moment().startOf("day").subtract(x, "days").format("DD-MM-YY"),
    ]);
  }
  function hex2bin(hex) {
    return ("00000000" + parseInt(hex, 16).toString(2)).substr(-8);
  }
  let paginas_l = [];
  let secciones_l = [];
  let f_pag = 0;
  let f_secc = 0;
  let p_size = 200;
  function filtro(pagina) {
    if (true) {
      //if(f_pag!=pagina)
      unit_data = [];
      let total_data_ex_par = [...total_data_ex];
      total_data_ex_par.splice(total_data_ex_par.length - 1, 1);
      if (pagina == 0) {
        unit_data = [...total_data_ex_par];
      }
      for (
        let x = pagina * p_size;
        x < (pagina + 1) * p_size && x < total_data.length;
        x++
      ) {
        unit_data.push(total_data[x]);
      }
    }
    f_pag = pagina;
  }
  let pag_min = 0;
  let pag_max = 0;
  function filtro_secc(secc) {
    f_secc = secc;
    pag_min = secc * 15;
    pag_max = pag_min + 15;
  }
  function copy_full(text) {
    var el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    saved_type = "alert-success";
    saved_text = "Mensaje original copiado";
    saved_ok = true;
    setTimeout(function () {
      saved_ok = false;
    }, 3000);
  }
  let sessions = [];
  socket_query.on("sessions", async function (data) {
    let s = JSON.parse(data);
    let sessions_p = [];
    let sessions_p2 = [];
    for (let x in s) {
      sessions_p.push(s[x]);
    }
    for (let x in sessions_p) {
      if (sessions_p[x].status == 1) {
        sessions_p2.push(sessions_p[x]);
      }
    }
    for (let x in sessions_p) {
      if (sessions_p[x].status != 1) {
        sessions_p2.push(sessions_p[x]);
      }
    }
    sessions = [...sessions_p2];
  });

  let big_filtro = 0;
  function change_filtro(filtro) {
    big_filtro = filtro;
  }

  function hex2a(hexx) {
    var hex = hexx.toString(); //force conversion
    var str = "";
    for (var i = 0; i < hex.length; i += 2) {
      let x = parseInt(hex.substr(i, 2), 16);
      if (x > 31 && x < 127) {
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
      }
    }
    return str;
  }
  let event_map = {
    "01": "PowerUp",
    "03": "OTADownload",
    "07": "WakeUPSMS",
    "08": "WakeUP",
    "0D": "BeginMove",
    "0E": "NotMoving",
    "0F": "Idling",
    "10": "Stop",
    "12": "GPSJamming",
    "13": "RadioJam",
    "14": "IgnOff",
    "15": "IgnOn",
    "1E": "NormalON",
    "1F": "NormalOFF",
    "20": "WakeUPSleep",
    "21": "GPSAcquired",
    "22": "GPSLost",
    "23": "COMAcquired",
    "24": "COMLost",
    "25": "COMOff",
    "28": "PowerDisconn",
    "29": "PowerConn",
    "2A": "BattLow",
    "2B": "BattOK",
    "2C": "DriverID",
    "2D": "TempAboveS1",
    "2E": "TempOKS1",
    "2F": "TempBelowS1",
    "30": "TempAboveS2",
    "31": "TempOKS2",
    "32": "TempBelowS2",
    "33": "TempAboveS3",
    "34": "TempOKS3",
    "35": "TempBelowS3",
    "36": "MDTdetected",
    "37": "MDTNOdetected",
    "38": "MDTtest",
    "3C": "In1LtoH",
    "3D": "In1HtoL",
    "3E": "In2LtoH",
    "3F": "In2HtoL",
    "40": "In3LtoH",
    "41": "In3HtoL",
    "42": "In4LtoH",
    "43": "In4HtoL",
    "44": "In5LtoH",
    "45": "In5HtoL",
    "46": "In6LtoH",
    "47": "In6HtoL",
    "48": "In7LtoH",
    "49": "In7HtoL",
    "50": "DriverLogIn",
    "51": "DriverLogOut",
    "5A": "SpeedAboveTh0",
    "5B": "SpeedBelowTh0",
    "6E": "HarshAcceleration",
    "6F": "HarshBrake",
    "70": "HarshTLeft",
    "71": "HarshTRight",
    "72": "SMSReceivedWrite",
    "73": "SMSReceivedPEGAction",
    "74": "RadioJammingEnd",
    "75": "GPSJammingEnd",
    "76": "WakeUPMov",
    "81": "HibernateMode",
    FF: "VectorQ",
  };

  function get_5(app_msg_jp2, app_len) {
    var options_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(0, 2);
    var report_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(2, 4);
    var state_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(4, 8);
    var group_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(8, 10);
    var list_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(10);
    if (options_jp2 == "05" && group_jp2 == "00") {
      //Group 0 Option 5
      let jp2_c = 0,
        data_jp2 = [],
        jp2_par_c = 0,
        jp2_data_c = 0,
        jp2_param = 0,
        data_jp2_s = [];
      for (var x = 0; x < app_len * 2 - 10; x = x + 2) {
        if (jp2_c == 0) {
          jp2_c = parseInt(list_jp2.substring(x, x + 2), 16);
          jp2_param = jp2_par_c;
          data_jp2.push("");
          jp2_par_c++;
          jp2_data_c = 0;
        } else {
          if (jp2_data_c == 0) {
            data_jp2_s.push(list_jp2.substring(x, x + 2));
            jp2_data_c++;
          } else {
            if (jp2_param != 34 && jp2_param != 16) {
              data_jp2[jp2_param] =
                list_jp2.substring(x, x + 2) + data_jp2[jp2_param];
            } else {
              data_jp2[jp2_param] =
                data_jp2[jp2_param] + list_jp2.substring(x, x + 2);
            }
          }
          jp2_c--;
        }
      }

      let odometer_1708 = parseInt(parseInt(data_jp2[0], 16) * 0.161);
      data_jp2[0] = odometer_1708;
      let batt_voltage_1708 = parseInt(parseInt(data_jp2[1], 16) * 5);
      data_jp2[1] = batt_voltage_1708;
      let batt_voltage_switched_1708 = parseInt(parseInt(data_jp2[2], 16) * 5);
      data_jp2[2] = batt_voltage_switched_1708;
      let rpm_1708 = parseInt(parseInt(data_jp2[3], 16) * 0.25);
      data_jp2[3] = rpm_1708;
      let road_speed_1708 = parseInt(parseInt(data_jp2[4], 16) * 0.805);
      data_jp2[4] = road_speed_1708;
      let total_fuel_1708 = parseInt(parseInt(data_jp2[5], 16) * 0.473);
      data_jp2[5] = total_fuel_1708;
      let total_idle_fuel_1708 = parseInt(parseInt(data_jp2[6], 16) * 0.473);
      data_jp2[6] = total_idle_fuel_1708;
      let total_idle_hours_1708 = parseInt(parseInt(data_jp2[7], 16) * 0.05);
      data_jp2[7] = total_idle_hours_1708;
      let fuel_level_1708 = parseInt(parseInt(data_jp2[8], 16) * 0.5);
      data_jp2[8] = fuel_level_1708;
      let total_engine_hours_1708 = parseInt(parseInt(data_jp2[9], 16) * 0.05);
      data_jp2[9] = total_engine_hours_1708;
      let engine_coolant_temp_1708 = parseInt(
        parseInt(data_jp2[10], 16) * (5 / 9)
      );
      data_jp2[10] = engine_coolant_temp_1708;
      let eng_oil_temp_1708 = parseInt(
        (parseInt(data_jp2[11], 16) - 32) * 0.25 * (5 / 9)
      );
      data_jp2[11] = eng_oil_temp_1708;
      let eng_coolant_level_1708 = parseInt(parseInt(data_jp2[12], 16) * 0.5);
      data_jp2[12] = eng_coolant_level_1708;
      let eng_oil_level_1708 = parseInt(parseInt(data_jp2[13], 16) * 0.5);
      data_jp2[13] = eng_oil_level_1708;
      let eng_coolant_pressure_1708 = parseInt(
        parseInt(data_jp2[14], 16) * 0.862
      );
      data_jp2[14] = eng_coolant_pressure_1708;
      let eng_oil_pressure_1708 = parseInt(parseInt(data_jp2[15], 16) * 3.45);
      data_jp2[15] = eng_oil_pressure_1708;
      let vin_1708 = hex2asc(data_jp2[16]) != "" ? hex2asc(data_jp2[16]) : null;
      data_jp2[16] = vin_1708;

      let odometer_1939 = parseInt(parseInt(data_jp2[17], 16) * 0.125);
      data_jp2[17] = odometer_1939;
      let odometer_hi_1939 = parseInt(parseInt(data_jp2[18], 16) * (5 / 1000));
      data_jp2[18] = odometer_hi_1939;
      let batt_voltage_1939 = parseInt(parseInt(data_jp2[19], 16) * 5);
      data_jp2[19] = batt_voltage_1939;
      let batt_voltage_switched_1939 = parseInt(parseInt(data_jp2[20], 16) * 5);
      data_jp2[20] = batt_voltage_switched_1939;
      let rpm_1939 = parseInt(parseInt(data_jp2[21], 16) * 0.125);
      data_jp2[21] = rpm_1939;
      let road_speed_1939 = parseInt(parseInt(data_jp2[22], 16) / 256);
      data_jp2[22] = road_speed_1939;
      let total_fuel_1939 = parseInt(parseInt(data_jp2[23], 16) * 0.5);
      data_jp2[23] = total_fuel_1939;
      let total_idle_fuel_1939 = parseInt(parseInt(data_jp2[24], 16) * 0.5);
      data_jp2[24] = total_idle_fuel_1939;
      let total_idle_hours_1939 = parseInt(parseInt(data_jp2[25], 16) * 0.05);
      data_jp2[25] = total_idle_hours_1939;
      let fuel_level_1939 = parseInt(parseInt(data_jp2[26], 16) * 0.4);
      data_jp2[26] = fuel_level_1939;
      let total_engine_hours_1939 = parseInt(parseInt(data_jp2[27], 16) * 0.05);
      data_jp2[27] = total_engine_hours_1939;
      let engine_coolant_temp_1939 = parseInt(parseInt(data_jp2[28], 16) - 40);
      data_jp2[28] = engine_coolant_temp_1939;
      let eng_oil_temp_1939 = parseInt(parseInt(data_jp2[29], 16) / 32 - 273);
      data_jp2[29] = eng_oil_temp_1939;
      let eng_coolant_level_1939 = parseInt(parseInt(data_jp2[30], 16) * 0.4);
      data_jp2[30] = eng_coolant_level_1939;
      let eng_oil_level_1939 = parseInt(parseInt(data_jp2[31], 16) * 0.4);
      data_jp2[31] = eng_oil_level_1939;
      let eng_coolant_pressure_1939 = parseInt(parseInt(data_jp2[32], 16) * 2);
      data_jp2[32] = eng_coolant_pressure_1939;
      let eng_oil_pressure_1939 = parseInt(parseInt(data_jp2[33], 16) * 4);
      data_jp2[33] = eng_oil_pressure_1939;
      let vin_1939 = hex2asc(data_jp2[34]) != "" ? hex2asc(data_jp2[34]) : null;
      data_jp2[34] = vin_1939;

      return data_jp2;
    }
    return null;
  }
  function get_5_JP1(app_msg, type) {
    let odometer_1708 = "",
      odometer_1939 = "",
      odometer_hi_1939 = "",
      batt_voltage_1708 = "",
      batt_voltage_1939 = "",
      batt_voltage_switched_1708 = "",
      batt_voltage_switched_1939 = "",
      rpm_1708 = "",
      rpm_1939 = "",
      road_speed_1708 = "",
      road_speed_1939 = "",
      total_fuel_1708 = "",
      total_fuel_1939 = "",
      total_idle_fuel_1708 = "",
      total_idle_fuel_1939 = "",
      total_idle_hours_1708 = "",
      total_idle_hours_1939 = "",
      fuel_level_1708 = "",
      fuel_level_1939 = "",
      total_engine_hours_1708 = "",
      total_engine_hours_1939 = "",
      engine_coolant_temp_1708 = "",
      engine_coolant_temp_1939 = "",
      eng_oil_temp_1708 = "",
      eng_oil_temp_1939 = "",
      eng_coolant_level_1708 = "",
      eng_coolant_level_1939 = "",
      eng_oil_level_1708 = "",
      eng_oil_level_1939 = "",
      eng_coolant_pressure_1708 = "",
      eng_coolant_pressure_1939 = "",
      eng_oil_pressure_1708 = "",
      eng_oil_pressure_1939 = "",
      vin_1708 = "",
      vin_1939 = "";
    let map_id = app_msg.substring(0, 2);

    if (map_id == "2c") {
      odometer_1708 = parseInt(
        parseInt(
          app_msg.substring(8, 10) +
            app_msg.substring(6, 8) +
            app_msg.substring(4, 6) +
            app_msg.substring(2, 4),
          16
        ) * 0.161
      );
      odometer_1939 = parseInt(
        parseInt(
          app_msg.substring(16, 18) +
            app_msg.substring(14, 16) +
            app_msg.substring(12, 14) +
            app_msg.substring(10, 12),
          16
        ) * 0.125
      );
      odometer_hi_1939 = parseInt(
        parseInt(
          app_msg.substring(24, 26) +
            app_msg.substring(22, 24) +
            app_msg.substring(20, 22) +
            app_msg.substring(18, 20),
          16
        ) *
          (5 / 1000)
      );
      batt_voltage_1708 = parseInt(
        parseInt(app_msg.substring(28, 30) + app_msg.substring(26, 28), 16) * 5
      );
      batt_voltage_1939 = parseInt(
        parseInt(app_msg.substring(32, 34) + app_msg.substring(30, 32), 16) * 5
      );
      batt_voltage_switched_1708 = parseInt(
        parseInt(app_msg.substring(36, 38) + app_msg.substring(34, 36), 16) * 5
      );
      batt_voltage_switched_1939 = parseInt(
        parseInt(app_msg.substring(40, 42) + app_msg.substring(38, 40), 16) * 5
      );
      rpm_1708 = parseInt(
        parseInt(app_msg.substring(44, 46) + app_msg.substring(42, 44), 16) *
          0.25
      );
      rpm_1939 = parseInt(
        parseInt(app_msg.substring(48, 50) + app_msg.substring(46, 48), 16) *
          0.125
      );
      road_speed_1708 = parseInt(
        parseInt(app_msg.substring(50, 52), 16) * 0.805
      );
      road_speed_1939 = parseInt(
        parseInt(app_msg.substring(54, 56) + app_msg.substring(52, 54), 16) /
          256
      );
    }

    if (map_id == "2d") {
      total_fuel_1708 = parseInt(
        parseInt(
          app_msg.substring(8, 10) +
            app_msg.substring(6, 8) +
            app_msg.substring(4, 6) +
            app_msg.substring(2, 4),
          16
        ) * 0.473
      );
      total_fuel_1939 = parseInt(
        parseInt(
          app_msg.substring(16, 18) +
            app_msg.substring(14, 16) +
            app_msg.substring(12, 14) +
            app_msg.substring(10, 12),
          16
        ) * 0.5
      );
      total_idle_fuel_1708 = parseInt(
        parseInt(
          app_msg.substring(24, 26) +
            app_msg.substring(22, 24) +
            app_msg.substring(20, 22) +
            app_msg.substring(18, 20),
          16
        ) * 0.473
      );
      total_idle_fuel_1939 = parseInt(
        parseInt(
          app_msg.substring(32, 34) +
            app_msg.substring(30, 32) +
            app_msg.substring(28, 30) +
            app_msg.substring(26, 28),
          16
        ) * 0.5
      );
      total_idle_hours_1708 = parseInt(
        parseInt(
          app_msg.substring(40, 42) +
            app_msg.substring(38, 40) +
            app_msg.substring(36, 38) +
            app_msg.substring(34, 36),
          16
        ) * 0.05
      );
      total_idle_hours_1939 = parseInt(
        parseInt(
          app_msg.substring(48, 50) +
            app_msg.substring(46, 48) +
            app_msg.substring(44, 46) +
            app_msg.substring(42, 44),
          16
        ) * 0.05
      );
      fuel_level_1708 = parseInt(parseInt(app_msg.substring(50, 52), 16) * 0.5);
      fuel_level_1939 = parseInt(parseInt(app_msg.substring(52, 54), 16) * 0.4);
    }

    if (map_id == "2e") {
      total_engine_hours_1708 = parseInt(
        parseInt(
          app_msg.substring(8, 10) +
            app_msg.substring(6, 8) +
            app_msg.substring(4, 6) +
            app_msg.substring(2, 4),
          16
        ) * 0.05
      );
      total_engine_hours_1939 = parseInt(
        parseInt(
          app_msg.substring(16, 18) +
            app_msg.substring(14, 16) +
            app_msg.substring(12, 14) +
            app_msg.substring(10, 12),
          16
        ) * 0.05
      );
      engine_coolant_temp_1708 = parseInt(
        parseInt(app_msg.substring(18, 20), 16) * (5 / 9)
      );
      engine_coolant_temp_1939 = parseInt(
        parseInt(app_msg.substring(20, 22), 16) - 40
      );
      eng_oil_temp_1708 = parseInt(
        (parseInt(app_msg.substring(24, 26) + app_msg.substring(22, 24), 16) -
          32) *
          0.25 *
          (5 / 9)
      );
      eng_oil_temp_1939 = parseInt(
        parseInt(app_msg.substring(28, 30) + app_msg.substring(26, 28), 16) /
          32 -
          273
      );
      eng_coolant_level_1708 = parseInt(
        parseInt(app_msg.substring(30, 32), 16) * 0.5
      );
      eng_coolant_level_1939 = parseInt(
        parseInt(app_msg.substring(32, 34), 16) * 0.4
      );
      eng_oil_level_1708 = parseInt(
        parseInt(app_msg.substring(34, 36), 16) * 0.5
      );
      eng_oil_level_1939 = parseInt(
        parseInt(app_msg.substring(36, 38), 16) * 0.4
      );
      eng_coolant_pressure_1708 = parseInt(
        parseInt(app_msg.substring(38, 40), 16) * 0.862
      );
      eng_coolant_pressure_1939 = parseInt(
        parseInt(app_msg.substring(40, 42), 16) * 2
      );
      eng_oil_pressure_1708 = parseInt(
        parseInt(app_msg.substring(42, 44), 16) * 3.45
      );
      eng_oil_pressure_1939 = parseInt(
        parseInt(app_msg.substring(44, 46), 16) * 4
      );
    }

    if (map_id == "30") {
      vin_1708 = hex2asc(app_msg.substring(2), 16);
    }
    if (map_id == "31") {
      vin_1939 = hex2asc(app_msg.substring(2), 16);
    }

    if (type == "1708") {
      return [
        odometer_1708,
        rpm_1708,
        road_speed_1708,
        total_fuel_1708,
        total_idle_fuel_1708,
        total_idle_hours_1708,
        total_engine_hours_1708,
        vin_1708,
        batt_voltage_1708,
        batt_voltage_switched_1708,
        engine_coolant_temp_1708,
        eng_oil_temp_1708,
        eng_coolant_level_1708,
        eng_oil_level_1708,
        eng_coolant_pressure_1708,
        eng_oil_pressure_1708,
      ];
    } else if (type == "1939") {
      return [
        odometer_1939,
        odometer_hi_1939,
        rpm_1939,
        road_speed_1939,
        total_fuel_1939,
        total_idle_fuel_1939,
        total_idle_hours_1939,
        total_engine_hours_1939,
        vin_1939,
        batt_voltage_1939,
        batt_voltage_switched_1939,
        engine_coolant_temp_1939,
        eng_oil_temp_1939,
        eng_coolant_level_1939,
        eng_oil_level_1939,
        eng_coolant_pressure_1939,
        eng_oil_pressure_1939,
      ];
    }
  }
  let close_matrix_modal = false;
  let matrix_table = [[], [], [], [], [], []];
  let matrix_label = ["0-20", "21-40", "41-60", "61-80", "81-100", ">100"];

  function get_matrix_JP1(app_msg, type) {
    let map_id = app_msg.substring(0, 2);
    let matrix = [[], [], [], [], [], []];
    if (map_id == "5a") {
      let col = 0;
      let fila = 0;
      for (let x = 4; x < 144 + 4; x = x + 4) {
        if (col == 0) {
          matrix[fila].push([matrix_label[fila]]);
        }
        matrix[fila].push([
          parseInt(
            app_msg[x + 2] + app_msg[x + 3] + app_msg[x] + app_msg[x + 1],
            16
          ),
        ]);
        col++;
        if (col == 6) {
          fila++;
          col = 0;
        }
      }
    }
    matrix_table = [...matrix];
    close_matrix_modal = true;
  }
  function hex2asc(hex) {
    try {
      hex = hex.toString();
    } catch (e) {
      hex = "00";
      console.log(e);
    }
    var str = "";
    for (
      var i = 0;
      i < hex.length &&
      hex.substr(i, 2) !== "00" &&
      parseInt(hex.substr(i, 2), 16) > 47;
      i += 2
    )
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
  }
  let cas_info = [],
    puls_info = {
      iccid: "",
      lastIdtime: "",
      scriptVersion: "",
      configVersion: "",
      firmware: "",
      firmware2: "",
      imsi: "",
      partInfo: "",
    };
  socket_query.on("puls", async function (data) {
    puls_info = JSON.parse(data);
  });
  let cas_info_runing = false;
  let f_sesion = 1;

  let file_name;
  let carga_file = false;
  function carga_unidades() {
    if (!carga_file) {
      carga_file = true;
    } else {
      for (let x of file_name.files) {
        let reader = new FileReader();
        reader.addEventListener("load", (event) => {
          let units_to_save = [];
          let units = event.target.result.split("\n");
          for (let x in units) {
            if (units[x].split(",").length == 3) {
              let u_ = units[x].split(",");
              if (u_[1].length == 10) {
                u_[0] = u_[0].replace(/\s/g, "").replace(/(\r\n|\n|\r)/g, "");
                u_[1] = u_[1].replace(/\s/g, "").replace(/(\r\n|\n|\r)/g, "");
                u_[2] = u_[2].replace(/\s/g, "").replace(/(\r\n|\n|\r)/g, "");
                units_to_save.push(u_);
                console.log(u_);
              }
            }
          }
          if (units_to_save.length > 0) {
            socket_query.emit("new_unit_m", units_to_save, (data) => {
              if (data == "ok") {
                saved_type = "alert-success";
                saved_text = "Unidad guardada";
                saved_ok = true;
                setTimeout(function () {
                  saved_ok = false;
                }, 3000);
                new_unit = [];
                new_unit_mode = false;
              }
            });
          }
        });
        reader.fileName = x.name;
        reader.readAsText(x);
      }
      file_name.value = "";
      carga_file = false;
    }
  }

  let new_user = [];
  let new_user_mode = false;
  let unit_selected = -1;
  function add_user() {
    if (new_user.length == 0) {
      new_user_mode = true;
      new_user = [
        {
          user: "",
          pass: "",
          level: 4,
          type: "normal",
          alerts: true,
        },
      ];
    }
  }
  let updated_user = {
    user: "",
    password: "",
    level: 4,
    type: "normal",
    alerts: true,
  };
  function menu_user(i, user) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Editar",
          onClick: () => {
            updated_user = {
              user: user.user,
              password: "********",
              level: user.level,
              type: user.type,
              alerts: user.alerts,
            };
            unit_selected = i;
          },
        },
        {
          label: "Borrar",
          onClick: () => {
            m_delete_user = true;
            user_to_delete = user;
          },
        },
      ],
    });
  }
  function save_new_user() {
    new_user[0].alerts = new_user[0].alerts == "true" ? true : false;
    socket_query.emit("new_user", new_user, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Usuario guardado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        new_user = [];
        new_user_mode = false;
        socket_query.emit("user_list");
      }
    });
  }
  let m_delete_user = false;
  let user_to_delete = -1;
  function delete_user() {
    socket_query.emit("delete_user", user_to_delete.user, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Usuario eliminado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        socket_query.emit("user_list");
        m_delete_user = false;
        user_to_delete = -1;
      }
    });
  }
  function save_user() {
    if (updated_user.password == "********") {
      updated_user.password = "";
    }
    updated_user.alerts =
      updated_user.alerts == "true" || updated_user.alerts == true
        ? true
        : false;
    updated_user.level = parseInt(updated_user.level);
    socket_query.emit("save_user", updated_user, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Usuario guardado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        new_user = [];
        new_user_mode = false;
        unit_selected = -1;
        socket_query.emit("user_list");
      }
    });
  }

  let protocol_selected = -1;
  let new_protocol = false;
  function add_protocol() {
    if (protocol_selected == -1) {
      protocol_selected = $protocol.length;
      new_protocol = true;
      protocol_new = {
        name: "Protocolo de prueba",
        init: "0",
      };
    }
  }
  function loadGoJS(node, callback) {
    const script = document.createElement("script");
    script.src = "js/diagrams.js";
    script.onload = callback;
    document.head.appendChild(script);
  }
  function init_gojs() {
    console.log("running gojs");
    const $ = go.GraphObject.make;

    const myDiagram = $(go.Diagram, "protocol_diag", {
      layout: $(go.LayeredDigraphLayout, {
        direction: 90,
        layerSpacing: 100,
        columnSpacing: 200,
        setsPortSpots: true,
        initializeOption: go.LayeredDigraphLayout.InitDepthFirstIn, // Usar un orden predefinido
        layeringOption: go.LayeredDigraphLayout.LayerOptimalLinkLength, // Ordenar por el camino más largo
      }),
      "undoManager.isEnabled": true,
    });

    myDiagram.nodeTemplate = $(
      go.Node,
      "Auto",
      $(
        go.Shape,
        "RoundedRectangle",
        { strokeWidth: 0 },
        new go.Binding("fill", "color")
      ),
      $(
        go.TextBlock,
        { margin: 8, editable: true },
        new go.Binding("text", "mensaje")
      )
    );

    myDiagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        curve: go.Link.JumpOver,
        corner: 10,
        relinkableFrom: true,
        relinkableTo: true,
      },
      $(go.Shape, { strokeWidth: 4 }, new go.Binding("stroke", "color")),
      $(go.Shape, {
        toArrow: "Standard",
        stroke: "blue",
        fill: "blue",
        scale: 1.5,
      }),
      $(
        go.Panel,
        "Auto",
        $(go.Shape, {
          fill: "lightblue",
        }),
        $(go.TextBlock, { margin: 10 }, new go.Binding("text", "text"))
      )
    );

    let data = Object.fromEntries(
      Object.entries($protocol[protocol_selected]).filter(
        ([key]) => key !== "name" && key !== "init"
      )
    );

    data["inicio"] = {
      mensaje: "inicio",
      actions: [$protocol[protocol_selected].init, "", ""],
      options: ["", "", ""],
      function: ["", "", ""],
      btn_color: ["success", "danger", "warning"],
      btn_size: ["lg", "lg", "lg"],
      run: 0,
      init: "",
    };
    console.log(data);
    const nodeDataArray = [];
    const linkDataArray = [];

    for (let key in data) {
      const node = {
        key: key,
        mensaje:
          data[key].mensaje != ""
            ? data[key].mensaje
            : data[key].actions.every((item) => item === "")
              ? "Fin"
              : "",
        color: "lightgreen",
      };
      nodeDataArray.push(node);

      data[key].actions.forEach((action, index) => {
        if (action && (data[key].options[index] || index == data[key].run)) {
          linkDataArray.push({
            from: key,
            to: action,
            text:
              data[key].options[index] != ""
                ? data[key].options[index]
                : "Continua",
            color:
              data[key].btn_color[index] === "success"
                ? "lightgreen"
                : data[key].btn_color[index] === "danger"
                  ? "lightcoral"
                  : "yellow",
          });
        }
      });
    }
    myDiagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray);

    myDiagram.layout.makeNetwork = function (diagram) {
      var net = go.LayeredDigraphLayout.prototype.makeNetwork.call(
        this,
        diagram
      );
      var rootNode = net.findVertex(
        myDiagram.findNodeForKey($protocol[protocol_selected].init)
      );
      if (rootNode !== null) {
        rootNode.layer = 0; // Fijar el nivel del nodo raíz
      }
      return net;
    };
  }

  // Protocolo creator
  let protocol_new = {
    name: "Protocolo de prueba",
    init: "0",
  };
  const paso_base = {
    mensaje: "Mensaje para el paso",
    actions: ["", "", ""],
    options: ["Seccion 1", "", ""],
    function: ["", "", ""],
    btn_color: ["success", "success", "success"],
    btn_size: ["lg", "lg", "lg"],
    run: "",
    init: "",
  };
  let protocol_t;
  let protocol_text = "";
  let functions = [
    "close_alert",
    "close_alert_notif",
    "call_oper",
    "call_matriz",
    "msg_oper",
    "security_command",
    "security_off",
    "pause_alert",
  ];
  let protocol_list = [];
  function generate() {
    protocol_text = JSON.stringify(protocol_new, null, 2);
    if (protocol_t) autoExpand(protocol_t);
  }
  function get_protocol() {
    let list = [];
    for (let x in protocol_new) {
      if (x != "name" && x != "init") {
        list.push(x);
      }
    }
    protocol_list = list;
  }
  function add_step() {
    let paso_base_ = JSON.parse(JSON.stringify(paso_base));
    if (protocol_list.length == 0) {
      protocol_new["0"] = paso_base_;
    } else {
      let transformed_list = protocol_list.map((item) => {
        if (typeof item === "string") {
          let numStr = item.replace(/\D/g, "");
          return numStr ? Number(numStr) : NaN;
        } else {
          return Number(item);
        }
      });
      let filtered_list = transformed_list.filter((item) => !isNaN(item));
      let obj = Math.max(...filtered_list) + 1;
      paso_base_.mensaje = paso_base_.mensaje + obj;
      protocol_new[obj] = paso_base_;
    }
    setTimeout(() => {
      autoExpand(document.querySelector("textarea"));
    }, 100);
  }
  function reverse() {
    try {
      //protocol_new = JSON.parse(protocol_text);
    } catch (e) {
      console.log(e);
    }
  }
  function autoExpand(textarea) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }
  $: {
    protocol_new;
    generate();
    get_protocol();
  }

  function save_protocol() {
    socket_query.emit(
      "new_protocol",
      protocol_selected,
      protocol_new,
      (data) => {
        if (data == "ok") {
          saved_type = "alert-success";
          saved_text = "Protocolo guardado";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
          protocol_new = {
            name: "Protocolo de prueba",
            init: "0",
          };
          protocol_text = "";
          protocol_list = [];
          new_protocol = false;
          protocol_selected = -1;
          socket_query.emit("protocol");
        }
      }
    );
  }
  function delete_protocol() {
    socket_query.emit("delete_protocol", protocol_to_delete, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Protocolo eliminado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        protocol_new = {
          name: "Protocolo de prueba",
          init: "0",
        };
        protocol_text = "";
        protocol_list = [];
        new_protocol = false;
        protocol_selected = -1;
        socket_query.emit("protocol");
        m_delete_protocol = false;
        protocol_to_delete = -1;
      }
    });
  }
  function menu_protocolo(i) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Editar",
          onClick: () => {
            protocol_selected = i;
            new_protocol = true;
            protocol_new = $protocol[i];
          },
        },
        {
          label: "Borrar",
          onClick: () => {
            protocol_to_delete = i;
            m_delete_protocol = true;
          },
        },
      ],
    });
  }
  let m_delete_protocol = false;
  let protocol_to_delete = -1;

  function renderTable(dataArray) {
    let table = `<table class="table table-bordered"><thead><tr><th>Key</th><th>Type</th><th>Value</th></tr></thead><tbody>`;
    dataArray.forEach((item) => {
      for (const key in item) {
        const type = item[key].type;
        const val = item[key].val;
        if (typeof val === "object" && val !== null) {
          let subTable =
            '<table class="table table-bordered mt-2"><thead><tr><th colspan="2">Value Details</th></tr></thead><tbody>';
          for (const subKey in val) {
            subTable += `<tr><td>${subKey}</td><td>${val[subKey]}</td></tr>`;
          }
          subTable += "</tbody></table>";
          table += `<tr><td>${key}</td><td>${type}</td><td>${subTable}</td></tr>`;
        } else {
          table += `<tr><td>${key}</td><td>${type}</td><td>${val}</td></tr>`;
        }
      }
    });
    table += "</tbody></table>";
    return table;
  }
  let edit_alert_mode = false;
  let add_alert_mode = false;
  let key_alert = "";
  let alerts_url = "https://vector-alert-creator-prod.omnitracs.online"; //"https://vector-alert-creator.omnitracs.online";
  let formats_driver_url =
    "https://vector-trip-report-creator-prod.omnitracs.online";
  let url_api =
    "https://" +
    document.getElementById("ws_server").getAttribute("value") +
    "/cook";
  function edit_alerts() {
    if (!edit_alert_mode) {
      socket_query.emit("set_cook", $alerts_list, (data) => {
        key_alert = data;
        edit_alert_mode = true;
      });
    } else {
      edit_alert_mode = false;
    }
  }
  let iframe_alerts;
  let iframe_reports;
  let iframe_reports_ready = false;
  function iframe_alerts_on() {
    iframe_alerts = document.getElementById("iframe_alerts");
  }
  function iframe_reports_on() {
    iframe_reports = document.getElementById("iframe_reports");
  }
  $: {
    if (Object.keys($driver_reports).length != 0 && iframe_reports_ready) {
      iframe_reports.contentWindow.postMessage(
        {
          type: "reports",
          body:
            Object.keys($driver_reports).length != 0
              ? $driver_reports
              : {
                  afterTrip: [],
                  afterTripOptional: [],
                  beforeTrip: [],
                  beforeTripOptional: [],
                  id: "",
                },
        },
        formats_driver_url
      );
      console.log("comando enviado", {
        type: "reports",
        body:
          Object.keys($driver_reports).length != 0
            ? $driver_reports
            : {
                afterTrip: [],
                afterTripOptional: [],
                beforeTrip: [],
                beforeTripOptional: [],
                id: "",
              },
      });
      iframe_reports_ready = false;
    }
  }
  function handleMessage(event) {
    console.log(
      "Mensaje recibido del iframe:",
      event.data,
      event.origin,
      event.data.type
    );
    switch (event.origin) {
      case alerts_url:
        switch (event.data.type) {
          case "command":
            iframe_alerts = document.getElementById("iframe_alerts");
            iframe_alerts.contentWindow.postMessage(
              { type: "alerts", body: $alerts_list },
              alerts_url
            );
            console.log("comando enviado", { type: "alerts" });
            break;
          case "alerts":
            socket_query.emit(
              "save_alerts",
              event.data.body ? event.data.body : event.data.data,
              (fn) => {
                if (fn == "ok") {
                  saved_type = "alert-success";
                  saved_text = "Alertas guardadas";
                  saved_ok = true;
                  setTimeout(function () {
                    saved_ok = false;
                  }, 3000);
                  edit_alert_mode = false;
                  socket_query.emit("alerts_list");
                }
              }
            );
            break;
          case "alerts_update":
            socket_query.emit(
              "save_alerts",
              event.data.body ? event.data.body : event.data.data,
              (fn) => {
                if (fn == "ok") {
                  saved_type = "alert-success";
                  saved_text = "Alertas guardadas";
                  saved_ok = true;
                  setTimeout(function () {
                    saved_ok = false;
                  }, 3000);
                  socket_query.emit("alerts_list");
                }
              }
            );
            break;
        }
        break;
      case formats_driver_url:
        switch (event.data.type) {
          case "command":
            iframe_reports = document.getElementById("iframe_reports");
            if (Object.keys($driver_reports).length == 0) {
              iframe_reports_ready = true;
              break;
            }
            iframe_reports.contentWindow.postMessage(
              {
                type: "reports",
                body:
                  Object.keys($driver_reports).length != 0
                    ? $driver_reports
                    : {
                        afterTrip: [],
                        afterTripOptional: [],
                        beforeTrip: [],
                        beforeTripOptional: [],
                        id: "",
                      },
              },
              formats_driver_url
            );
            console.log("comando enviado", {
              type: "reports",
              body:
                Object.keys($driver_reports).length != 0
                  ? $driver_reports
                  : {
                      afterTrip: [],
                      afterTripOptional: [],
                      beforeTrip: [],
                      beforeTripOptional: [],
                      id: "",
                    },
            });
            break;
          case "reports":
            socket_query.emit("save_driver_reports", event.data.body, (fn) => {
              if (fn == "ok") {
                saved_type = "alert-success";
                saved_text = "Formatos guardados";
                saved_ok = true;
                setTimeout(function () {
                  saved_ok = false;
                }, 3000);
                reports_changed = false;
                socket_query.emit("driver_reports");
              }
            });
            break;
        }
        break;
    }
  }
  window.addEventListener("message", handleMessage);

  let reports_n = { alerts: "", estado: "", travel: "" };
  function read_reports() {
    if (!$reports.alerts || !$reports.estado || !$reports.travel) return;
    reports_n = {
      alerts: decodeURIComponent(atob($reports.alerts)),
      estado: decodeURIComponent(atob($reports.estado)),
      travel: decodeURIComponent(atob($reports.travel)),
    };
    if (editor) editor.setValue(reports_n[format_selected]);
  }
  $: {
    $reports;
    read_reports();
  }

  function loadCSS(url) {
    return new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;
      link.onload = () => resolve();
      link.onerror = () => reject(new Error(`Error loading CSS: ${url}`));
      document.head.appendChild(link);
    });
  }
  function loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Error loading script: ${url}`));
      document.body.appendChild(script);
    });
  }
  function handleEditorChange(instance, changeObj) {
    if (changeObj.origin === "setValue") {
      return;
    }
    const value = instance.getValue();
    reports_n[format_selected] = value;
    reports_changed = true;
  }
  let format_selected = "alerts";
  let reports_changed = false;
  let editor;
  async function loadCodeMirror() {
    try {
      // Cargar estilos y scripts de CodeMirror 5.65.12
      await loadCSS("codemirror/codemirror.min.css");
      await loadCSS("codemirror/dracula.min.css");
      await loadScript("codemirror/codemirror.min.js");
      await loadScript("codemirror/xml.min.js");
      await loadScript("codemirror/javascript.min.js");
      await loadScript("codemirror/css.min.js");
      await loadScript("codemirror/htmlmixed.min.js");

      const style = document.createElement("style");
      style.textContent = `
          .CodeMirror {
              height: 100%;
          }
      `;
      document.head.appendChild(style);

      // Inicializar el editor después de que todos los recursos se han cargado
      editor = CodeMirror.fromTextArea(document.getElementById("code-editor"), {
        lineNumbers: true, // Mostrar números de línea
        mode: "htmlmixed", // Modo de resaltado de sintaxis
        theme: "dracula", // Tema visual (opcional)
        tabSize: 2, // Tamaño del tabulador
      });
      editor.setValue(reports_n[format_selected]);
      editor.on("change", handleEditorChange);
    } catch (error) {
      console.error("Error al cargar CodeMirror:", error);
    }
  }
  function save_report() {
    reports_n[format_selected] = editor.getValue();
    let reports_s = {
      alerts: btoa(encodeURIComponent(reports_n.alerts)),
      estado: btoa(encodeURIComponent(reports_n.estado)),
      travel: btoa(encodeURIComponent(reports_n.travel)),
    };
    socket_query.emit("save_reports", reports_s, (fn) => {
      if (fn == "ok") {
        saved_type = "alert-success";
        saved_text = "Reportes guardados";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        reports_changed = false;
        socket_query.emit("reports");
      }
    });
  }
  function page_view(html) {
    html = html.replace(/{{unit}}/g, "TR-2893");
    html = html.replace(/{{alert_name}}/g, "Fuera de ruta");
    html = html.replace(/{{oper}}/g, "Operador Demo");
    html = html.replace(/{{group}}/g, "Cuenta Demo");
    html = html.replace(/{{org}}/g, "Patio origen");
    html = html.replace(/{{dest}}/g, "Patio destino");
    html = html.replace(/{{street_name}}/g, "Calle 1, municipio 1, estado 1");
    html = html.replace(/{{reason}}/g, "Alerta de prueba");
    html = html.replace(/{{comment}}/g, "Comentario de prueba");
    html = html.replace(/{{serial}}/g, "1234567890");
    html = html.replace(/{{mail}}/g, "ejemplo@ejemplo.com");
    html = html.replace(/cid:street_photo/g, "img/street_photo.jpg");
    html = html.replace(/cid:map_photo/g, "img/map_photo.png");
    html = html.replace(/{{pos}}/g, "20.7825,-101.34894");
    html = html.replace(/{{date}}/g, "08/19/24, 19:00");
    let travel_res = "";
    let travel_loop =
      html
        .split("<!--{{travel_loop}}-->")[1]
        ?.split("<!--{{end_loop}}-->")[0] || "";
    for (let x = 0; x < 4; x++) {
      let patron = travel_loop;
      patron = patron.replace(/{{travel_loop.id}}/g, "TR-2893");
      patron = patron.replace(/{{travel_loop.oper}}/g, "Operador Demo");
      patron = patron.replace(/{{travel_loop.org}}/g, "Patio origen");
      patron = patron.replace(/{{travel_loop.dest}}/g, "Patio destino");
      patron = patron.replace(/{{travel_loop.route}}/g, "Ruta alterna");
      patron = patron.replace(/{{travel_loop.date_ini}}/g, "08/19/24, 19:00");
      patron = patron.replace(/{{travel_loop.pos}}/g, "20.7825,-101.34894");
      patron = patron.replace(/{{travel_loop.link}}/g, "mapa_travel.html");
      travel_res = travel_res + patron;
    }
    html =
      html.indexOf("<!--{{travel_loop}}-->") !== -1 &&
      html.indexOf("<!--{{end_loop}}-->") !== -1
        ? html.slice(0, html.indexOf("<!--{{travel_loop}}-->")) +
          travel_res +
          html.slice(
            html.indexOf("<!--{{end_loop}}-->") + "<!--{{end_loop}}-->".length
          )
        : html;
    let units_res = "";
    let units_loop =
      html.split("<!--{{units_loop}}-->")[1]?.split("<!--{{end_loop}}-->")[0] ||
      "";
    for (let x = 0; x < 4; x++) {
      let patron = units_loop;
      patron = patron.replace(/{{units_loop.id}}/g, "TR-2893");
      patron = patron.replace(/{{units_loop.oper}}/g, "Operador Demo");
      patron = patron.replace(/{{units_loop.org}}/g, "Patio origen");
      patron = patron.replace(/{{units_loop.dest}}/g, "Patio destino");
      patron = patron.replace(/{{units_loop.route}}/g, "Ruta alterna");
      patron = patron.replace(/{{units_loop.date}}/g, "08/19/24, 19:00");
      patron = patron.replace(/{{units_loop.serial}}/g, "1234567890");
      patron = patron.replace(
        /{{units_loop.street_name}}/g,
        "Calle 1, municipio 1, estado 1"
      );
      patron = patron.replace(/{{units_loop.pos}}/g, "20.7825,-101.34894");
      units_res = units_res + patron;
    }
    html =
      html.indexOf("<!--{{units_loop}}-->") !== -1 &&
      html.indexOf("<!--{{end_loop}}-->") !== -1
        ? html.slice(0, html.indexOf("<!--{{units_loop}}-->")) +
          units_res +
          html.slice(
            html.indexOf("<!--{{end_loop}}-->") + "<!--{{end_loop}}-->".length
          )
        : html;
    return html;
  }

  let unit_to_alert = -1;
  let alert_selected = -1;
  function simulate_alert() {
    // find in alerts_list, the alert with the same protocol as the selected
    let alert = $alerts_list.find((x) => x.protocol == protocol_selected);
    if (alert) {
      socket_query.emit("run_alert", unit_to_alert, alert_selected, (data) => {
        if (data == "ok") {
          saved_type = "alert-success";
          saved_text = "Alerta simulada, espere 5 segundos";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
          menu.set(6);
        }
      });
    } else {
      saved_type = "alert-danger";
      saved_text = "No hay alerta con el protocolo seleccionado";
      saved_ok = true;
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    }
  }

  let alert_template_selected = 0;
  let emails_template = "";
  async function select_alert(alert) {
    alert_template_selected = alert;
  }
  async function add_new_alert(alert) {
    alert.def.alertid = uuidv4();
    alert.def.emails = emails_template.split(",");
    //emails_template = "";
    let new_alerts = [alert.def, ...$alerts_list];
    socket_query.emit("save_alerts", new_alerts, (fn) => {
      if (fn == "ok") {
        saved_type = "alert-success";
        saved_text = "Alerta guardada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        socket_query.emit("alerts_list");
      }
    });
  }
  async function delete_alert(alert) {
    let new_alerts = $alerts_list.filter((x) => x.alertid != alert.alertid);
    socket_query.emit("save_alerts", new_alerts, (fn) => {
      if (fn == "ok") {
        saved_type = "alert-success";
        saved_text = "Alerta eliminada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        socket_query.emit("alerts_list");
      }
    });
  }
  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (char) {
        const random = (Math.random() * 16) | 0; // Genera un número aleatorio entre 0 y 15
        const value = char === "x" ? random : (random & 0x3) | 0x8; // Maneja 'y' para cumplir con el estándar UUIDv4
        return value.toString(16); // Convierte a hexadecimal
      }
    );
  }
  let add_protocol_mode = false;
  let protocol_template_selected = 0;
  async function select_protocol(protocol) {
    protocol_template_selected = protocol;
  }
  async function add_new_protocol(new_protocol) {
    let new_protocol_number = $protocol.length;
    socket_query.emit(
      "new_protocol",
      new_protocol_number,
      new_protocol,
      (data) => {
        if (data == "ok") {
          saved_type = "alert-success";
          saved_text = "Protocolo guardado";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
          socket_query.emit("protocol");
        }
      }
    );
  }
  let monitor_outputs;
  monitor_outputs = localStorage.getItem("monitor_outputs")
    ? JSON.parse(localStorage.getItem("monitor_outputs"))
    : [
        { name: "S0", enabled: false },
        { name: "S1", enabled: false },
        { name: "S2", enabled: false },
        { name: "S3", enabled: false },
        { name: "S4", enabled: false },
        { name: "S5", enabled: false },
        { name: "S6", enabled: false },
        { name: "S7", enabled: false },
      ];
  $: {
    if (monitor_outputs) {
      localStorage.setItem("monitor_outputs", JSON.stringify(monitor_outputs));
    }
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

{#if close_matrix_modal}
  <div
    class="modal fade {close_matrix_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {close_matrix_modal
      ? 'block'
      : 'none'}; z-index:1052;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 40%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Matriz RPM<strong
            />
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="mdc-data-table" style="display:flex" use:data_table_on>
            <table class="mdc-data-table__table" id="travels_table">
              <thead>
                <tr class="mdc-data-table__header-row">
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">Road Speed Interval (KPH)</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">0-500</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">501-1000</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">1001-1500</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">1501-2000</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">2001-2500</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">>2500</th
                  >
                </tr>
              </thead>
              <tbody class="mdc-data-table__content">
                {#each matrix_table as fila, i}
                  <tr class="mdc-data-table__row">
                    {#each fila as item, j}
                      <td class="mdc-data-table__cell">
                        {item}
                      </td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-end">
            <div>
              <button
                on:click={() => {
                  close_matrix_modal = false;
                }}
                class="btn btn-success big"
                type="button"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1051" />
{/if}

<!-- Modal para aceptar borrar protocolo -->
{#if m_delete_protocol}
  <div
    class="modal fade {m_delete_protocol ? 'show' : ''}"
    style="display: {m_delete_protocol ? 'block' : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      style="min-width: 20%"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Confirmar Borrado</h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="d-flex flex-column">
            ¿Estás seguro de que quieres borrar el protocolo?
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary big"
            on:click={() => {
              m_delete_protocol = false;
              protocol_to_delete = -1;
            }}>Cancelar</button
          >
          <button
            type="button"
            class="btn btn-danger big"
            on:click={delete_protocol}>Borrar</button
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

<!-- Modal para aceptar borrar usuario -->
{#if m_delete_user}
  <div
    class="modal fade {m_delete_user ? 'show' : ''}"
    style="display: {m_delete_user ? 'block' : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      style="min-width: 20%"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Confirmar Borrado</h5>
        </div>
        <div
          class="modal-body
          h-100
          p-3
          w-100"
        >
          <div class="d-flex flex-column">
            ¿Estás seguro de que quieres borrar el usuario?
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary big"
            on:click={() => {
              m_delete_user = false;
              user_to_delete = -1;
            }}>Cancelar</button
          >
          <button
            type="button"
            class="btn btn-danger big"
            on:click={delete_user}>Borrar</button
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

<!-- Modal para aceptar borrar reporte -->
{#if m_delete_report}
  <div
    class="modal fade {m_delete_report ? 'show' : ''}"
    style="display: {m_delete_report ? 'block' : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      style="min-width: 20%"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Confirmar Borrado</h5>
        </div>
        <div
          class="modal-body
          h-100
          p-3
          w-100"
        >
          <div class="d-flex flex-column">
            ¿Estás seguro de que quieres borrar el reporte?
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary big"
            on:click={() => {
              m_delete_report = false;
              report_to_delete = -1;
            }}>Cancelar</button
          >
          <button
            type="button"
            class="btn btn-danger big"
            on:click={delete_report}>Borrar</button
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div class="card-header bg-dark text-white b-0">
            <i tooltip="Configuracion" class="material-icons md-90">widgets</i> Configuraciones
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each configuraciones as item, i}
                {#if item != ""}
                  <a
                    href="/"
                    class:list-group-item-warning={selected == i}
                    class:active_={selected == i}
                    on:click|preventDefault={() => {
                      selected_edit = -1;
                      maping(i);
                    }}
                    class="list-group-item list-group-item-action">{item}</a
                  >
                {/if}
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          {#if selected != -1}
            <div class="card-header bg-dark text-white b-0">
              <div class="d-flex justify-content-between">
                <div>
                  <i
                    tooltip={configuraciones[selected]}
                    class="material-icons md-90">notes</i
                  >
                  {configuraciones[selected]}
                  {#if selected == 9}
                    <div class="btn-group mx-2" role="group">
                      <button
                        on:click|preventDefault={() => {
                          f_sesion = 1;
                        }}
                        type="button"
                        class="btn {f_sesion == 1
                          ? 'btn-info'
                          : 'btn-outline-info'}"
                        style={f_sesion == 1 ? "" : "background-color:white"}
                        >En linea</button
                      >
                      <button
                        on:click|preventDefault={() => {
                          f_sesion = 0;
                        }}
                        type="button"
                        class="btn {f_sesion == 0
                          ? 'btn-info'
                          : 'btn-outline-info'}"
                        style={f_sesion == 0 ? "" : "background-color:white"}
                        >Todos
                      </button>
                    </div>
                  {/if}
                  {#if selected == 1}
                    {#if carga_file}
                      <input
                        type="file"
                        id="files"
                        accept=".csv"
                        bind:this={file_name}
                        class="mx-2"
                      />
                    {/if}
                    <button
                      on:click={carga_unidades}
                      id="carga_unidades"
                      type="button"
                      class="btn btn-{!carga_file
                        ? 'secondary'
                        : 'success'} mini mx-2">Cargar por archivo</button
                    >
                  {/if}
                </div>
                <div>
                  <button
                    on:click={mostrar_todo}
                    id="csv_2"
                    type="button"
                    class="btn btn-{!mostrar_todo_
                      ? 'secondary'
                      : 'success'} mini">Mostar todo</button
                  >
                  <button
                    on:click={reporte_csv}
                    id="csv"
                    type="button"
                    class="btn btn-success mini">Descargar CSV</button
                  >
                </div>
              </div>
            </div>
            <div class="card-body flex-grow-1 overflow-auto">
              {#if selected == 0}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Equipo maestro</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Equipos secundarios</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                          style="text-align: right;"
                        >
                          {#if selected_edit == -1}
                            <button
                              id="add_group"
                              on:click={add_group}
                              type="button"
                              class="btn btn-info mini"
                            >
                              <i class="material-icons md-90"
                                >add_circle_outline</i
                              >
                              Agregar
                            </button>
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each new_group as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            <input
                              list="lista_unidades"
                              placeholder="Unidad"
                              size="14"
                              on:change={(event) => {
                                eco_master = "";
                                for (let w in $nicks_list) {
                                  if ($nicks_list[w] == event.target.value) {
                                    eco_master = w;
                                  }
                                }
                              }}
                            />
                            <datalist id="lista_unidades">
                              {#each units_list_p as item, i}
                                <option
                                  value={typeof $nicks_list[item] != "undefined"
                                    ? $nicks_list[item]
                                    : item}
                                />{/each}
                            </datalist>
                          </td>
                          <td class="mdc-data-table__cell">
                            {#each item[1] as item_e, j}
                              <input
                                list="lista_unidades"
                                placeholder="Unidad"
                                size="14"
                                on:change={(event) => {
                                  eco_selected[j] = "";
                                  for (let w in $nicks_list) {
                                    if ($nicks_list[w] == event.target.value) {
                                      eco_selected[j] = w;
                                    }
                                  }
                                }}
                              />
                              <datalist id="lista_unidades">
                                {#each units_list_p as item, i}
                                  <option
                                    value={typeof $nicks_list[item] !=
                                    "undefined"
                                      ? $nicks_list[item]
                                      : item}
                                  />{/each}
                              </datalist>
                            {/each}
                            <button
                              id="add_{i}"
                              type="button"
                              class="btn btn-link"
                              on:click={add_secundario}
                              ><i class="material-icons md-90 green"
                                >add_circle_outline</i
                              ></button
                            >
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              id="save_new_group"
                              on:click={() => {
                                save_new_group(i);
                              }}
                              type="button"
                              class="btn btn-success mini">Guardar</button
                            >
                            <button
                              id="cancel_save_group"
                              on:click={() => {
                                new_group = [];
                                new_group_mode = false;
                              }}
                              type="button"
                              class="btn btn-danger mini">Cancelar</button
                            >
                          </td>
                        </tr>
                      {/each}
                      {#each $unit_groups as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            {typeof $nicks_list[item[0]] != "undefined"
                              ? $nicks_list[item[0]]
                              : item[0]}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#if selected_edit != i}
                              {#each item[1] as item_e, j}
                                {typeof $nicks_list[item_e] != "undefined"
                                  ? $nicks_list[item_e]
                                  : item_e}{j != item[1].length - 1 ? "," : ""}
                              {/each}
                            {:else}
                              {#each item[1] as item_e, j}
                                <input
                                  value={typeof $nicks_list[item_e] !=
                                  "undefined"
                                    ? $nicks_list[item_e]
                                    : item_e}
                                  list="lista_unidades"
                                  placeholder="Unidad"
                                  size="14"
                                  on:change={(event) => {
                                    eco_selected[j] = "";
                                    for (let w in $nicks_list) {
                                      if (
                                        $nicks_list[w] == event.target.value
                                      ) {
                                        eco_selected[j] = w;
                                      }
                                    }
                                  }}
                                />
                                <datalist id="lista_unidades">
                                  {#each units_list_p as item, i}
                                    <option
                                      value={typeof $nicks_list[item] !=
                                      "undefined"
                                        ? $nicks_list[item]
                                        : item}
                                    />{/each}
                                </datalist>
                              {/each}
                              {#each extra_add as item_e, t}
                                <input
                                  list="lista_unidades"
                                  placeholder="Unidad"
                                  size="14"
                                  on:change={(event) => {
                                    eco_selected[item[1].length + t] = "";
                                    for (let w in $nicks_list) {
                                      if (
                                        $nicks_list[w] == event.target.value
                                      ) {
                                        eco_selected[item[1].length + t] = w;
                                      }
                                    }
                                  }}
                                />
                                <datalist id="lista_unidades">
                                  {#each units_list_p as item, i}
                                    <option
                                      value={typeof $nicks_list[item] !=
                                      "undefined"
                                        ? $nicks_list[item]
                                        : item}
                                    />{/each}
                                </datalist>
                              {/each}
                              <button
                                id="add_{i}"
                                type="button"
                                class="btn btn-link"
                                on:click={() => {
                                  extra_add.push([]);
                                  extra_add = [...extra_add];
                                }}
                                ><i class="material-icons md-90 green"
                                  >add_circle_outline</i
                                ></button
                              >
                              <button
                                id="save_group"
                                on:click={() => {
                                  save_group(i, item[0]);
                                }}
                                type="button"
                                class="btn btn-success mini">Guardar</button
                              >
                              <button
                                id="cancel_save_group"
                                on:click={() => {
                                  extra_add = [];
                                  selected_edit = -1;
                                }}
                                type="button"
                                class="btn btn-danger mini">Cancelar</button
                              >
                            {/if}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            {#if !new_group_mode}
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() => menu_grupos(i)}
                                class="mdc-icon-button material-icons"
                                >more_vert</button
                              >
                            {/if}
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 1}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Nick name</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Serial</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Tipo</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                          style="text-align: right;"
                        >
                          {#if selected_edit == -1}
                            <button
                              id="add_unidad"
                              on:click={add_unidad}
                              type="button"
                              class="btn btn-info mini"
                            >
                              <i class="material-icons md-90"
                                >add_circle_outline</i
                              >
                              Agregar
                            </button>
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each new_unit as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Nick name"
                              size="14"
                              maxlength="14"
                              on:change={(event) => {
                                new_eco = event.target.value;
                              }}
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Serial"
                              size="10"
                              maxlength="10"
                              on:change={(event) => {
                                new_serial = event.target.value;
                              }}
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <select bind:value={new_type}>
                              {#each $unit_types as item, i}
                                <option value={item}>{item}</option>
                              {/each}
                            </select>
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              id="save_new_unit"
                              on:click={() => {
                                save_new_unit(i);
                              }}
                              type="button"
                              class="btn btn-success mini">Guardar</button
                            >
                            <button
                              id="cancel_save_group"
                              on:click={() => {
                                new_unit = [];
                                new_unit_mode = false;
                              }}
                              type="button"
                              class="btn btn-danger mini">Cancelar</button
                            >
                          </td>
                        </tr>
                      {/each}
                      {#each $units_list as item, i (item)}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            {typeof $nicks_list[item] != "undefined"
                              ? $nicks_list[item]
                              : item}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#if selected_edit != i}
                              {item}
                            {:else}
                              <input
                                type="text"
                                placeholder="Serial"
                                size="10"
                                maxlength="10"
                                on:change={(event) => {
                                  new_serial = event.target.value;
                                }}
                                value={item}
                              />
                            {/if}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#if selected_edit != i}
                              {typeof $unit_config[item] != "undefined"
                                ? $unit_config[item].type
                                : "FS"}
                            {:else}
                              <select bind:value={edit_type}>
                                {#each $unit_types as item, i}
                                  <option value={item}>{item}</option>
                                {/each}
                              </select>

                              <button
                                id="save_unit"
                                on:click={() => {
                                  save_unit(item);
                                }}
                                type="button"
                                class="btn btn-success mini">Guardar</button
                              >
                              <button
                                id="cancel_save_group"
                                on:click={() => {
                                  selected_edit = -1;
                                }}
                                type="button"
                                class="btn btn-danger mini">Cancelar</button
                              >
                            {/if}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            {#if !new_group_mode}
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() =>
                                  menu_unidades(i, item)}
                                class="mdc-icon-button material-icons"
                                >more_vert</button
                              >
                            {/if}
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 2}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        >
                          <i class="material-icons md-90">description</i> Nombre
                          del Reporte
                        </th>
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        >
                          <i class="material-icons md-90">schedule</i> Hora de envío
                        </th>
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        >
                          <i class="material-icons md-90">calendar_today</i> Días
                          de la semana
                        </th>
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        >
                          <i class="material-icons md-90">insert_drive_file</i> Formato
                        </th>
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        >
                          <i class="material-icons md-90">email</i> Emails
                        </th>
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                          style="text-align: right;"
                        >
                          {#if selected_edit == -1}
                            <button
                              id="add_report_cron"
                              on:click={add_report_cron}
                              type="button"
                              class="btn btn-info mini"
                            >
                              <i class="material-icons md-90"
                                >add_circle_outline</i
                              >
                              Agregar
                            </button>
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each new_report_cron as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Nombre"
                              size="20"
                              maxlength="30"
                              value={item.name}
                              on:change={(event) => {
                                item.name = event.target.value;
                              }}
                            />
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: start;"
                          >
                            {#each item.time as time, k}
                              <select
                                on:blur={(event) => {
                                  item.time[k] = event.target.value;
                                }}
                                style="margin: 5px 0px;"
                              >
                                {#each [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5] as time_}
                                  <option
                                    value={time_}
                                    selected={time_ == item.time[k]}
                                  >
                                    {((time_ - 6 + 24) % 24)
                                      .toString()
                                      .padStart(2, "0")}:00 hrs
                                  </option>
                                {/each}
                              </select>
                              {#if k != item.time.length - 1}
                                <br />
                              {/if}
                            {/each}
                            <button
                              id="add_{i}"
                              type="button"
                              class="btn btn-link"
                              on:click={add_time_report}
                              ><i class="material-icons md-90 green"
                                >add_circle_outline</i
                              ></button
                            >
                          </td>
                          <td class="mdc-data-table__cell">
                            {#each item.day as day, k}
                              <select
                                on:blur={(event) => {
                                  item.day[k] = event.target.value;
                                }}
                                style="margin: 5px 0px;"
                              >
                                {#each [0, 1, 2, 3, 4, 5, 6] as day_}
                                  <option
                                    value={day_}
                                    selected={day_ == item.day[k]}
                                  >
                                    {day_es(day_)}
                                  </option>
                                {/each}
                              </select>
                              {#if k != item.day.length - 1}
                                <br />
                              {/if}
                            {/each}
                            <button
                              id="add_{i}"
                              type="button"
                              class="btn btn-link"
                              on:click={add_day_report}
                              ><i class="material-icons md-90 green"
                                >add_circle_outline</i
                              ></button
                            >
                          </td>
                          <td class="mdc-data-table__cell">
                            <select
                              on:blur={(event) => {
                                item.format = event.target.value;
                              }}
                            >
                              <option value="travel">Reporte de viajes</option>
                              <option value="estado">Reporte de estado</option>
                            </select>
                          </td>
                          <td class="mdc-data-table__cell">
                            {#each item.emails as email, k}
                              <input
                                type="text"
                                placeholder="Email"
                                size="20"
                                maxlength="50"
                                value={email}
                                on:change={(event) => {
                                  item.emails[k] = event.target.value;
                                }}
                              />
                              {#if k != item.emails.length - 1}
                                <br />
                              {/if}
                            {/each}
                            <button
                              id="add_{i}"
                              type="button"
                              class="btn btn-link"
                              on:click={add_email_report}
                              ><i class="material-icons md-90 green"
                                >add_circle_outline</i
                              ></button
                            >
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              id="save_new_report_cron"
                              on:click={() => {
                                save_new_report_cron();
                              }}
                              type="button"
                              disabled={item.name == "" ||
                                item.time.length == 0 ||
                                item.day.length == 0 ||
                                item.emails.length == 0 ||
                                item.emails.includes("")}
                              class="btn btn-success mini">Guardar</button
                            >
                            <button
                              id="cancel_save_group"
                              on:click={() => {
                                new_report_cron = [];
                                new_report_cron_mode = false;
                              }}
                              type="button"
                              class="btn btn-danger mini">Cancelar</button
                            >
                          </td>
                        </tr>
                      {/each}
                      {#each $reports_cron as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            {item.name}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: start; padding: 16px;"
                          >
                            {#each item.time as time, i}
                              {((time - 6 + 24) % 24)
                                .toString()
                                .padStart(2, "0") + ":00 hrs"} <br />
                            {/each}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: left; padding: 16px;"
                          >
                            {#each item.day as day, i}
                              {day_es(day)} <br />
                            {/each}
                          </td>
                          <td class="mdc-data-table__cell">
                            {item.format == "travel"
                              ? "Reporte de viajes"
                              : item.format == "estado"
                                ? "Reporte de estado"
                                : "-"}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="padding: 10px;"
                          >
                            {#each item.emails as email, i}
                              {email} <br />
                            {/each}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              style="outline: unset;"
                              on:click|preventDefault={() => menu_reportes(i)}
                              class="mdc-icon-button material-icons"
                              >more_vert</button
                            >
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 8}
                <div class="mdc-data-table d-flex h-100" use:data_table_on>
                  <div
                    class="d-flex py-3"
                    style="border-bottom: 1px solid #e0e0e0;height: 40px;align-items: center;"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      style="margin-left:16px;margin-right:0px">filter_list</i
                    >
                    <input
                      on:click={() => {
                        eco_f_ = "";
                      }}
                      bind:value={eco_f_}
                      list="lista_unidades"
                      placeholder="Unidad"
                      size="12"
                      on:change={(event) => {
                        eco_f = event.target.value;
                      }}
                      disabled={big_seaching ? "disabled" : ""}
                      class="mx-1"
                    />
                    <datalist id="lista_unidades">
                      {#each units_big_query as item, i}
                        <option value={item} />{/each}
                    </datalist>

                    <input
                      list="fecha"
                      placeholder="Desde"
                      size="8"
                      bind:value={fecha_v_}
                      on:click={() => {
                        fecha_v_ = "";
                      }}
                      on:change={(event) => {
                        fecha_v = "";
                        for (let w in fechas) {
                          if (fechas[w][1] == event.target.value) {
                            fecha_v = fechas[w][0];
                          }
                        }
                      }}
                      disabled={big_seaching ? "disabled" : ""}
                      class="mx-1"
                    />
                    <datalist id="fecha">
                      {#each fechas as item, i}
                        <option value={item[1]} />{/each}
                    </datalist>

                    <input
                      list="fecha"
                      placeholder="Hasta"
                      size="8"
                      bind:value={fecha_v_2}
                      on:click={() => {
                        fecha_v_2 = "";
                      }}
                      on:change={(event) => {
                        fecha_v2 = "";
                        for (let w in fechas) {
                          if (fechas[w][1] == event.target.value) {
                            fecha_v2 = fechas[w][0];
                          }
                        }
                      }}
                      disabled={big_seaching ? "disabled" : ""}
                      class="mx-1"
                    />

                    <select
                      name="filtros"
                      bind:value={big_filtro}
                      id="filtros"
                      on:change={(event) => {
                        change_filtro(event.target.value);
                      }}
                    >
                      <option value="0">Posicion full</option>
                      <option value="1">Paquete 2</option>
                      <option value="2">Paquete 2 ACC T1</option>
                      <option value="3">Paquete 4</option>
                      <option value="4">ECM ACC (OBDII)</option>
                      <option value="5">ECM JP1/2 (1708)</option>
                      <option value="6">ECM JP1/2 (1939)</option>
                      <option value="8">ECM EC JP1/2 (1708)</option>
                      <option value="9">ECM EX JP1/2 (1939)</option>
                      <option value="7">Estatus general</option>
                    </select>

                    <!-- check box for run_cron_m -->
                    <div
                      class="form-check
                      d-flex
                      align-items-center
                      justify-content-center"
                    >
                      <input
                        type="checkbox"
                        id="run_cron_m"
                        bind:checked={run_cron_m}
                      />
                      <label class="form-check-label mx-1" for="run_cron_m">
                        Ping automático
                      </label>
                    </div>

                    {#if secciones_l.length > 1 && big_filtro != 7}
                      <div>
                        <span class="ml-2">Seccion</span>
                        <div class="btn-group mx-2" role="group">
                          {#each secciones_l as secc}
                            <button
                              on:click|preventDefault={() => filtro_secc(secc)}
                              type="button"
                              class="btn {f_secc == secc
                                ? 'btn-info'
                                : 'btn-outline-info'}">{secc + 1}</button
                            >
                          {/each}
                        </div>
                      </div>
                    {/if}
                    {#if paginas_l.length > 0 && big_filtro != 7}
                      <div>
                        <span class="ml-2">Páginas</span>
                        <div class="btn-group mx-2" role="group">
                          {#each paginas_l as pag_n}
                            {#if pag_n >= pag_min && pag_n < pag_max}
                              <button
                                on:click|preventDefault={() => filtro(pag_n)}
                                type="button"
                                class="btn {f_pag == pag_n
                                  ? 'btn-info'
                                  : 'btn-outline-info'}">{pag_n + 1}</button
                              >
                            {/if}
                          {/each}
                        </div>
                      </div>
                    {/if}
                  </div>
                  {#if big_seaching}
                    <div
                      class="d-flex flex-column justify-content-center flex-grow-1 align-items-center"
                    >
                      <div
                        class="spinner-border text-danger"
                        style="width: 6rem; height: 6rem;"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  {:else}
                    <div class="overflow-auto h-100">
                      <table class="mdc-data-table__table" id="big_query_table">
                        <thead>
                          {#if big_filtro == 0}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Vel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sat</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fix</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Net</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Hdop</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Input</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Carrier</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Rssi</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 1}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">eCode</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col" style="font-size:0.8rem">eIndex</th>-->
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Vel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fix</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Net</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Hdop</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Input</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Rssi</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 2}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">eCode</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Accs</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">vExt</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">vInt</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Out0</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">In0</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Temp1</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Temp2</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Temp3</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 3}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Vel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fix</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Rssi</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Text Ascii</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Text bin</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 4}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Road speed</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">RPM</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pedal Acel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Odometer</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fuel Lvl</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Hours</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pedal Brake</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Engine Hours</th
                              >
                            </tr>
                          {:else if big_filtro == 5 || big_filtro == 6}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Map ID</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Odometer</th
                              >
                              {#if big_filtro == 6}
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col"
                                  style="font-size:0.8rem">Odometer Hi</th
                                >
                              {/if}
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">RPM</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Road Speed</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Total Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Hours</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Engine Hours</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">VIN</th
                              >
                            </tr>
                          {:else if big_filtro == 8 || big_filtro == 9}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Map ID</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Batt Voltage</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem"
                                >Batt Voltage Switched</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Level</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Level</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Pressure</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Pressure</th
                              >
                            </tr>
                          {/if}
                        </thead>
                        <tbody class="mdc-data-table__content">
                          {#each unit_data as item, i}
                            {#if big_filtro != 7 && ((big_filtro != 5 && big_filtro != 6 && big_filtro != 8 && big_filtro != 9) || item[1].substring(20, 22) == "05")}
                              <tr class="mdc-data-table__row">
                                <td
                                  class="mdc-data-table__cell"
                                  style="font-size:0.8rem"
                                >
                                  <a
                                    href={item[1]}
                                    on:click|preventDefault={() => {
                                      copy_full(item[1]);
                                    }}
                                  >
                                    {moment(new Date(item[0] * 1000)).format(
                                      "DD/MM/YY HH:mm:ss"
                                    )}
                                  </a>
                                </td>
                                <td
                                  class="mdc-data-table__cell"
                                  style="font-size:0.8rem"
                                >
                                  {item[1].substring(20, 22)}
                                </td>
                                {#if big_filtro == 0}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--vel-->
                                      {Math.round(
                                        parseInt(
                                          item[1].substring(66, 74),
                                          16
                                        ) / 27.7778
                                      )}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Sat-->
                                      {parseInt(item[1].substring(78, 80), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Fix-->
                                      <i
                                        tooltip={hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "No"
                                          : "Si"}
                                        class="material-icons md-90 {hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == '1' ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          '1'
                                          ? 'red'
                                          : ''}"
                                        >{hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "gps_off"
                                          : "gps_fixed"}</i
                                      >
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Net-->
                                      {hex2bin(item[1].substring(90, 92))[0] +
                                        hex2bin(item[1].substring(90, 92))[1] ==
                                      "00"
                                        ? "2G"
                                        : hex2bin(
                                              item[1].substring(90, 92)
                                            )[0] +
                                              hex2bin(
                                                item[1].substring(90, 92)
                                              )[1] ==
                                            "01"
                                          ? "3G"
                                          : hex2bin(
                                                item[1].substring(90, 92)
                                              )[0] +
                                                hex2bin(
                                                  item[1].substring(90, 92)
                                                )[1] ==
                                              "10"
                                            ? "4G"
                                            : "?"}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--hdop-->
                                      {parseInt(item[1].substring(92, 94), 16) /
                                        10}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--inputs-->
                                      {hex2bin(item[1].substring(94, 96))}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--carrier-->
                                      {#if parseInt(item[1].substring(82, 86), 16) == "20" || parseInt(item[1].substring(82, 86), 16) == 50 || parseInt(item[1].substring(82, 86), 16) == "90" || parseInt(item[1].substring(82, 86), 16) == "40"}
                                        <span
                                          tooltip={parseInt(
                                            item[1].substring(82, 86),
                                            16
                                          )}
                                          ><img
                                            width="20"
                                            src={parseInt(
                                              item[1].substring(82, 86),
                                              16
                                            ) == "20"
                                              ? "https://www.telcel.com/favicon.ico"
                                              : parseInt(
                                                    item[1].substring(82, 86),
                                                    16
                                                  ) == "50" ||
                                                  parseInt(
                                                    item[1].substring(82, 86),
                                                    16
                                                  ) == "90" ||
                                                  parseInt(
                                                    item[1].substring(82, 86),
                                                    16
                                                  ) == "40"
                                                ? "https://www.att.com.mx/favicon.ico"
                                                : ""}
                                            alt=""
                                          /></span
                                        >
                                      {:else}
                                        {parseInt(
                                          item[1].substring(82, 86),
                                          16
                                        )}
                                      {/if}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--rssi-->
                                      -{65535 -
                                        parseInt(
                                          item[1].substring(86, 90),
                                          16
                                        ) +
                                        1}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                  {/if}
                                {:else if big_filtro == 1}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem;word-break: break-all;"
                                    >
                                      <!--EventC-->
                                      {#if item[1].substring(20, 22) == "02"}
                                        {typeof event_map[
                                          item[1]
                                            .substring(100, 102)
                                            .toUpperCase()
                                        ] == "undefined"
                                          ? item[1].substring(100, 102)
                                          : "(" +
                                            item[1].substring(100, 102) +
                                            ") " +
                                            event_map[
                                              item[1]
                                                .substring(100, 102)
                                                .toUpperCase()
                                            ].substr(0, 15)}
                                      {/if}
                                    </td>
                                    <!--EventI-->
                                    <!--<td class="mdc-data-table__cell" style="font-size:0.8rem"> 
                                                                            {#if item[1].substring(20,22)=="02"}
                                                                                {parseInt(item[1].substring(98,100),16)}
                                                                            {/if}
                                                                        </td> -->
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--vel-->
                                      {Math.round(
                                        parseInt(
                                          item[1].substring(66, 74),
                                          16
                                        ) / 27.7778
                                      )}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Fix-->
                                      <i
                                        tooltip={hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "No"
                                          : "Si"}
                                        class="material-icons md-90 {hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == '1' ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          '1'
                                          ? 'red'
                                          : ''}"
                                        >{hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "gps_off"
                                          : "gps_fixed"}</i
                                      >
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Net-->
                                      {hex2bin(item[1].substring(90, 92))[0] +
                                        hex2bin(item[1].substring(90, 92))[1] ==
                                      "00"
                                        ? "2G"
                                        : hex2bin(
                                              item[1].substring(90, 92)
                                            )[0] +
                                              hex2bin(
                                                item[1].substring(90, 92)
                                              )[1] ==
                                            "01"
                                          ? "3G"
                                          : hex2bin(
                                                item[1].substring(90, 92)
                                              )[0] +
                                                hex2bin(
                                                  item[1].substring(90, 92)
                                                )[1] ==
                                              "10"
                                            ? "4G"
                                            : "?"}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--hdop-->
                                      {parseInt(item[1].substring(92, 94), 16) /
                                        10}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--inputs-->
                                      {hex2bin(item[1].substring(94, 96))}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--rssi-->
                                      -{65535 -
                                        parseInt(
                                          item[1].substring(86, 90),
                                          16
                                        ) +
                                        1}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                  {/if}
                                {:else if big_filtro == 2}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--F -->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--EventC-->
                                      {#if item[1].substring(20, 22) == "02"}
                                        {item[1].substring(100, 102)}
                                      {/if}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Acc count-->
                                      {#if item[1].substring(20, 22) == "02"}
                                        {item[1].substring(102, 104)}
                                      {/if}
                                    </td>
                                    {#if item[1].substring(20, 22) == "02"}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 0 V EXT-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(106, 114),
                                            16
                                          ) / 1000}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(122, 130),
                                            16
                                          ) / 1000}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 1 V Int-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(114, 122),
                                            16
                                          ) / 1000}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(130, 138),
                                            16
                                          ) / 1000}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 2 out0-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {hex2bin(item[1].substring(124, 126))}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {hex2bin(item[1].substring(140, 142))}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 2 in0-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {hex2bin(item[1].substring(128, 130))}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {hex2bin(item[1].substring(144, 146))}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc temp1-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(210, 218),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(210, 218),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        210,
                                                        218
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(210, 218),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(226, 234),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(226, 234),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        226,
                                                        234
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(226, 234),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc temp2-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(218, 226),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(218, 226),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        218,
                                                        226
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(218, 226),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(234, 242),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(234, 242),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        234,
                                                        242
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(234, 242),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc temp3-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(226, 234),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(226, 234),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        226,
                                                        234
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(226, 234),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(242, 250),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(242, 250),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        242,
                                                        250
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(242, 250),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {/if}
                                      </td>
                                    {:else}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 0 V EXT-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 1 V Int-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 2 out0-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 2 in0-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc temp1-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc temp2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc temp3-->
                                    {/if}
                                  {/if}
                                {:else if big_filtro == 3}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--vel-->
                                      {Math.round(
                                        parseInt(
                                          item[1].substring(66, 74),
                                          16
                                        ) / 27.7778
                                      )}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Fix-->
                                      <i
                                        tooltip={hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "No"
                                          : "Si"}
                                        class="material-icons md-90 {hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == '1' ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          '1'
                                          ? 'red'
                                          : ''}"
                                        >{hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "gps_off"
                                          : "gps_fixed"}</i
                                      >
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--rssi-->
                                      -{65535 -
                                        parseInt(
                                          item[1].substring(86, 90),
                                          16
                                        ) +
                                        1}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem;word-break: break-all;"
                                    >
                                      <!--Text Ascii-->
                                      {#if item[1].substring(20, 22) == "04"}
                                        {hex2a(item[1].substring(106))}
                                      {/if}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem;word-break: break-all;"
                                    >
                                      <!--Text bin-->
                                      {#if item[1].substring(20, 22) == "04"}
                                        {item[1].substring(106)}
                                      {/if}
                                    </td>
                                  {/if}
                                {:else if big_filtro == 4}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                    {#if item[1].substring(20, 22) == "02"}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--road_speed_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(274, 282),
                                              16
                                            ) * 0.036
                                          )}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(322, 330),
                                              16
                                            ) * 0.036
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--rpm_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(282, 290),
                                              16
                                            ) / 10
                                          )}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(330, 338),
                                              16
                                            ) / 10
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--pedal_acel_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(290, 298),
                                              16
                                            ) / 100
                                          )}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(338, 346),
                                              16
                                            ) / 100
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--odometer_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(298, 306),
                                              16
                                            ) / 1000
                                          )}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(346, 354),
                                              16
                                            ) / 1000
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--fuel_level_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(306, 314),
                                              16
                                            ) / 100
                                          )}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(354, 362),
                                              16
                                            ) / 100
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--engine_coolant_temp_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(314, 322),
                                              16
                                            ) / 16
                                          )}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(362, 370),
                                              16
                                            ) / 16
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--eng_oil_temp_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(322, 330),
                                              16
                                            ) / 16
                                          )}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(370, 378),
                                              16
                                            ) / 16
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_fuel_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(330, 338), 16) / 1000) != 2147483}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(330, 338),
                                                16
                                              ) / 1000
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_idle_hours_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(338, 346), 16) * 0.05) != 107374182}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(338, 346),
                                                16
                                              ) * 0.05
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_idle_fuel_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(346, 354), 16) * 0.5) != 1073741824}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(346, 354),
                                                16
                                              ) * 0.5
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--pedal_brake_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(362, 370), 16)) < 0x80000000}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(362, 370),
                                                16
                                              )
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_engine_hours_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(370, 378), 16) * 0.05) != 107374182}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(370, 378),
                                                16
                                              ) * 0.05
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                        {#if item[1].substring(102, 104) == "bb"}
                                          {#if parseInt(parseInt(item[1].substring(434, 442), 16) * 0.05) != 107374182}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(370, 378),
                                                16
                                              ) * 0.05
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                    {:else}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--road_speed_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--rpm_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--pedal_acel_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--odometer_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--fuel_level_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--engine_coolant_temp_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--eng_oil_temp_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_fuel_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_idle_hours_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_idle_fuel_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--pedal_brake_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_engine_hours_obd2-->
                                    {/if}
                                  {/if}
                                {:else if big_filtro == 5 || big_filtro == 6 || big_filtro == 8 || big_filtro == 9}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!-- MapID-->
                                      {#if item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8"}
                                        {#if item[1].substring(106, 108) == "5a"}
                                          <a
                                            on:click|preventDefault={() => {
                                              get_matrix_JP1(
                                                item[1].substring(106)
                                              );
                                            }}
                                            href="/"
                                            >{item[1].substring(106, 108)}</a
                                          >
                                        {:else}
                                          {item[1].substring(106, 108)}
                                        {/if}
                                      {/if}
                                    </td>
                                    {#if item[1].substring(20, 22) == "05"}
                                      {#if item[1].substring(98, 102) == "008e" || item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8"}
                                        {#if big_filtro == 5 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[0]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[3]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[4]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[5]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[6]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[7]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[9]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- vin-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[16]}
                                          </td>
                                        {:else if big_filtro == 6 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[0 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer hi-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[1 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[4 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[5 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[6 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[7 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[8 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[10 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem;word-break: break-all;"
                                          >
                                            <!-- vin-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[17 + 17]}
                                          </td>
                                        {:else if big_filtro == 8 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--Batt Voltage-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage_switched-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[2]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[10]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[11]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[12]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[13]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[14]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[15]}
                                          </td>
                                        {:else if big_filtro == 9 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--Batt Voltage-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[1 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage_switched-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[2 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[10 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[11 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[12 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[13 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[14 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[15 + 18]}
                                          </td>
                                        {/if}
                                        {#if big_filtro == 5 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[0]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[2]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[3]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[4]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[5]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[6]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- vin-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[7]}
                                          </td>
                                        {:else if big_filtro == 6 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[0]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer Hi-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[2]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[3]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[4]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[5]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[6]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[7]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- vin-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[8]}
                                          </td>
                                        {:else if big_filtro == 8 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[8]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- batt_voltage_switched-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[9]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- coolant_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[10]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- oil_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[11]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[12]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[13]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[14]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[15]}
                                          </td>
                                        {:else if big_filtro == 9 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[8 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- batt_voltage_switched-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[9 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- coolant_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[10 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- oil_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[11 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[12 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[13 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[14 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[15 + 1]}
                                          </td>
                                        {/if}
                                      {:else}
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        {#if big_filtro == 6}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          />
                                        {/if}
                                      {/if}
                                    {:else}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      {#if big_filtro == 6}
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                      {/if}
                                    {/if}
                                  {/if}
                                {/if}
                              </tr>
                            {/if}
                          {/each}
                        </tbody>
                      </table>
                      {#if big_filtro == 7}
                        <div
                          class="col-md h-100 w-100"
                          style="padding:0"
                          use:initMap
                        >
                          <div class="row h-50 no-gutters w-100">
                            <div class="col-md-6 h-100 w-100">
                              <div class="h-100 w-100" id="map" />
                            </div>
                            <div class="col-md-6 h-100 d-flex overflow-auto">
                              <div
                                class="mdc-data-table w-100 h-100"
                                style="display:flex"
                                use:data_table_on
                              >
                                <table
                                  class="mdc-data-table__table h-100"
                                  id="travels_table"
                                >
                                  <thead>
                                    <tr
                                      class="mdc-data-table__header-row"
                                      style="height: 1px;"
                                    >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Datos PULS</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col"
                                      />
                                    </tr>
                                  </thead>
                                  <tbody class="mdc-data-table__content">
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Reporte
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong
                                          >{puls_info.lastIdtime != ""
                                            ? moment(
                                                puls_info.lastIdtime
                                              ).format("DD-MM-YYYY HH:mm")
                                            : ""}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Script
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong
                                          >{puls_info.scriptVersion}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Configuración
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong
                                          >{puls_info.configVersion}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Firmware
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong>{puls_info.firmware}</strong>
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Hardware
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong>{puls_info.firmware2}</strong>
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        SIM IMSI
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        {#if puls_info.imsi.substring(0, 3) == "310" || puls_info.imsi.substring(0, 3) == "334"}
                                          <img
                                            src={puls_info.imsi.substring(
                                              0,
                                              3
                                            ) == "310"
                                              ? "img/usa-flag.png"
                                              : "img/mex-flag.png"}
                                            alt="flag"
                                            width="20"
                                          />
                                          <img
                                            src={puls_info.imsi.substring(
                                              3,
                                              6
                                            ) == "410"
                                              ? "img/att.ico"
                                              : "img/telcel.ico"}
                                            alt="flag"
                                            width="20"
                                          />
                                        {/if}
                                        <strong>{puls_info.imsi}</strong>
                                        <strong
                                          >{puls_info.imsi.substring(3, 6) ==
                                          "410"
                                            ? "(AT&T)"
                                            : puls_info.imsi.substring(3, 6) ==
                                                "020"
                                              ? "(Telcel)"
                                              : ""}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        ICCID
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong>{puls_info.iccid}</strong>
                                      </td>
                                    </tr>
                                    <tr class="mdc-data-table__row flex-grow-1">
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Descripción
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="word-break: break-all;font-size:0.8rem;"
                                      >
                                        {puls_info.partInfo}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="row h-50 no-gutters overflow-auto">
                            <div
                              class="col-md-12 h-100 d-flex flex-column border border-dark"
                            >
                              <div
                                class="mdc-data-table"
                                style="display:flex"
                                use:data_table_on
                              >
                                <table
                                  class="mdc-data-table__table"
                                  id="travels_table"
                                >
                                  <thead>
                                    <tr class="mdc-data-table__header-row">
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Cliente</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Equipo</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Servicio</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Fecha</th
                                      >
                                      <th
                                        style="display:none;"
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">PARTE</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Instalada</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Retirada</th
                                      >
                                    </tr>
                                  </thead>
                                  {#if !cas_info_runing}
                                    <tbody class="mdc-data-table__content">
                                      {#each cas_info as item, i}
                                        <tr
                                          class="mdc-data-table__row"
                                          style="height:1px"
                                        >
                                          <td class="mdc-data-table__cell">
                                            {#if typeof item[7] != "undefined"}
                                              <a
                                                href="http://www.casomnitracs.mx/contenido/ajax_detalle_servicio_pdf.php?{item[7]}"
                                                target="_blank">{item[0]}</a
                                              >
                                            {:else}
                                              {item[0]}
                                            {/if}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[1]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[2]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {moment(
                                              new Date(item[3] * 1000)
                                            ).format("DD/MM/YYYY")}
                                          </td>
                                          <td
                                            style="display:none;"
                                            class="mdc-data-table__cell"
                                          >
                                            {item[4]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[5]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[6]}
                                          </td>
                                        </tr>
                                      {/each}
                                    </tbody>
                                  {/if}
                                </table>
                              </div>
                              {#if cas_info_runing}
                                <div
                                  class="d-flex flex-column justify-content-center flex-grow-1 align-items-center"
                                >
                                  <div
                                    class="spinner-border text-danger"
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  <div class="m-3">
                                    Descargando datos de CAS...
                                  </div>
                                </div>
                              {/if}
                            </div>
                          </div>
                        </div>
                      {/if}
                    </div>
                  {/if}
                </div>
              {/if}
              {#if selected == 9}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">PC ID</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Status</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">IP</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Pais</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">ISP</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Pantalla</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Version</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Usuario</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Grupo</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Primera conexion</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Ultima conexion</th
                        >
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each sessions as item, i}
                        {#if (item.status == 1 && f_sesion == 1) || f_sesion == 0}
                          <tr class="mdc-data-table__row">
                            <td class="mdc-data-table__cell">
                              {item.finger}
                            </td>
                            <td class="mdc-data-table__cell">
                              <i
                                tooltip={item.status == 0
                                  ? "Offline"
                                  : "Online"}
                                class="material-icons md-90 {item.status == 0
                                  ? 'red'
                                  : 'green'}">brightness_1</i
                              >
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.ip}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.ip_data.pais}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="word-break: break-all;"
                            >
                              {item.ip_data.isp}
                            </td>
                            <td class="mdc-data-table__cell">
                              {typeof item.screen_size != "undefined"
                                ? item.screen_size != null
                                  ? item.screen_size
                                  : ""
                                : ""}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.version}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.user_name}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.group}
                            </td>
                            <td class="mdc-data-table__cell">
                              {moment(
                                new Date(parseInt(item.first * 1000))
                              ).format("DD/MM/YY HH:mm")}
                            </td>
                            <td class="mdc-data-table__cell">
                              {moment(
                                new Date(parseInt(item.last * 1000))
                              ).format("DD/MM/YY HH:mm")}
                            </td>
                          </tr>
                        {/if}
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 5}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Usuario</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Nivel</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Grupo</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Tipo</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Notificación de alertas</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Estado</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                          style="text-align: right;"
                        >
                          <button
                            id="add_user"
                            on:click={add_user}
                            type="button"
                            class="btn btn-info mini"
                          >
                            <i class="material-icons md-90"
                              >add_circle_outline</i
                            >
                            Agregar Usuario
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each new_user as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="User"
                              size="14"
                              maxlength="14"
                              on:change={(event) => {
                                new_user[i].user = event.target.value;
                              }}
                            />@{$users_list[0].group}
                          </td>
                          <td class="mdc-data-table__cell">
                            <select
                              class="form-control"
                              style="font-size: 0.8rem;"
                              on:change={(event) => {
                                new_user[i].level = event.target.value;
                              }}
                            >
                              <option value="4">Super Administrador</option>
                              <option value="3">Administrador</option>
                              <option value="2">Supervisor</option>
                              <option value="1">Operador</option>
                              <option value="0">Invitado</option>
                            </select>
                          </td>
                          <td class="mdc-data-table__cell">
                            {$users_list[0].group}
                          </td>
                          <td class="mdc-data-table__cell">
                            <select
                              class="form-control"
                              style="font-size: 0.8rem;"
                              on:change={(event) => {
                                new_user[i].type = event.target.value;
                              }}
                            >
                              <option value="normal">Normal</option>
                              <option value="app_only">Solo APP</option>
                            </select>
                          </td>
                          <td class="mdc-data-table__cell">
                            <select
                              class="form-control"
                              style="font-size: 0.8rem;"
                              on:change={(event) => {
                                new_user[i].alerts = event.target.value;
                              }}
                            >
                              <option value="true">Activadas</option>
                              <option value="false">Desactivadas</option>
                            </select>
                          </td>
                          <td class="mdc-data-table__cell">
                            <!-- Password-->
                            <input
                              type="password"
                              placeholder="Contraseña"
                              size="14"
                              maxlength="14"
                              on:change={(event) => {
                                new_user[i].password = event.target.value;
                              }}
                            />
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              id="save_new_unit"
                              on:click={() => {
                                save_new_user();
                              }}
                              type="button"
                              class="btn btn-success mini">Guardar</button
                            >
                            <button
                              id="cancel_save_group"
                              on:click={() => {
                                new_user = [];
                                new_user_mode = false;
                              }}
                              type="button"
                              class="btn btn-danger mini">Cancelar</button
                            >
                          </td>
                        </tr>
                      {/each}
                      {#each $users_list as item, i (item)}
                        <tr class="mdc-data-table__row">
                          {#if unit_selected != i}
                            <td class="mdc-data-table__cell"> {item.user} </td>
                            <td class="mdc-data-table__cell">
                              {item.level_text}
                            </td>
                            <td class="mdc-data-table__cell"> {item.group} </td>
                            <td class="mdc-data-table__cell">
                              {item.type
                                ? item.type == "app_only"
                                  ? "Solo App"
                                  : "Normal"
                                : "Normal"}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.alerts == false
                                ? "Desactivadas"
                                : "Activadas"}
                            </td>
                            <td class="mdc-data-table__cell">
                              <i
                                tooltip={item.status == 0
                                  ? "Inactivo"
                                  : "Activo"}
                                class="material-icons md-90 {item.status == 0
                                  ? 'red'
                                  : 'green'}">brightness_1</i
                              >
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="text-align: right;"
                            >
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() =>
                                  menu_user(i, item)}
                                class="mdc-icon-button material-icons"
                                >more_vert</button
                              >
                            </td>
                          {:else}
                            <td class="mdc-data-table__cell">
                              {updated_user.user}
                            </td>
                            <td class="mdc-data-table__cell">
                              <select
                                class="form-control"
                                style="font-size: 0.8rem;"
                                on:change={(event) => {
                                  updated_user.level = event.target.value;
                                }}
                                value={updated_user.level.toString()}
                              >
                                <option value="4">Super Administrador</option>
                                <option value="3">Administrador</option>
                                <option value="2">Supervisor</option>
                                <option value="1">Operador</option>
                                <option value="0">Invitado</option>
                              </select>
                            </td>
                            <td class="mdc-data-table__cell">
                              {$users_list[0].group}
                            </td>
                            <td class="mdc-data-table__cell">
                              <select
                                class="form-control"
                                style="font-size: 0.8rem;"
                                on:change={(event) => {
                                  updated_user.type = event.target.value;
                                }}
                                value={updated_user.type}
                              >
                                <option value="normal">Normal</option>
                                <option value="app_only">Solo APP</option>
                              </select>
                            </td>
                            <td class="mdc-data-table__cell">
                              <select
                                class="form-control"
                                style="font-size: 0.8rem;"
                                on:change={(event) => {
                                  updated_user.alerts = event.target.value;
                                }}
                                value={updated_user.alerts == true
                                  ? "true"
                                  : "false"}
                              >
                                <option value="true">Activadas</option>
                                <option value="false">Desactivadas</option>
                              </select>
                            </td>
                            <td class="mdc-data-table__cell">
                              <input
                                type="password"
                                placeholder="Contraseña"
                                size="14"
                                maxlength="14"
                                value={updated_user.password}
                                on:change={(event) => {
                                  updated_user.password = event.target.value;
                                }}
                              />
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="text-align: right;"
                            >
                              <button
                                id="save_new_unit"
                                on:click={() => {
                                  save_user(i);
                                }}
                                type="button"
                                class="btn btn-success mini"
                              >
                                Guardar
                              </button>
                              <button
                                id="cancel_save_group"
                                on:click={() => {
                                  unit_selected = -1;
                                }}
                                type="button"
                                class="btn btn-danger mini"
                              >
                                Cancelar
                              </button>
                            </td>
                          {/if}
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 6}
                <div
                  class="mdc-data-table"
                  style="display:flex; height: 100%;"
                  use:data_table_on
                >
                  {#if new_protocol}
                    <div class="row" style="margin: 0;">
                      <div
                        class="col-md-12"
                        style="margin-top: 10px; margin-bottom: 10px; display: flex; justify-content: space-between;"
                      >
                        <button
                          id="return_protocol"
                          on:click={() => {
                            protocol_selected = -1;
                            new_protocol = false;
                          }}
                          type="button"
                          class="btn btn-info mini"
                        >
                          <i class="material-icons md-90">arrow_back</i>
                          Regresar a la lista
                        </button>
                        <div style="display: flex; align-items: center;">
                          <div style="margin-right: 20px;">
                            <span>Protocolo</span>
                          </div>
                          <select
                            class="form-control"
                            style="width: auto; margin-right: 30px;"
                            on:change={(e) => {
                              protocol_selected = e.target.value;
                            }}
                          >
                            {#each Array.from({ length: 31 }, (_, i) => i) as item}
                              <option
                                value={item}
                                selected={protocol_selected == item}
                              >
                                {item}
                              </option>
                            {/each}
                          </select>
                          <button
                            id="save_protocol"
                            on:click={() => {
                              save_protocol();
                            }}
                            type="button"
                            class="btn btn-success mini"
                          >
                            <i class="material-icons md-90"> save </i>
                            Guardar protocolo
                          </button>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 20px; overflow: auto;">
                      <div class="row">
                        <div class="col-6">
                          <span>Nombre del protocolo</span>
                          <input
                            type="text"
                            bind:value={protocol_new.name}
                            class="form-control"
                          />
                        </div>
                        <div class="col-6">
                          <span>Paso Inicial</span>
                          <input
                            type="text"
                            bind:value={protocol_new.init}
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row">
                        {#each protocol_list as p, i}
                          <div class="col-12 mt-4">
                            <div class="d-flex">
                              <h4>Paso {p}</h4>
                              <button
                                class="btn btn-sm btn-danger"
                                style="margin-left: 1rem;"
                                on:click={() => {
                                  delete protocol_new[p];
                                  protocol_new = protocol_new;
                                  setTimeout(() => {
                                    autoExpand(
                                      document.querySelector("textarea")
                                    );
                                  }, 100);
                                }}>Eliminar</button
                              >
                            </div>
                          </div>
                          <div class="col-6">
                            <span>Mensaje</span><input
                              type="text"
                              bind:value={protocol_new[p].mensaje}
                              class="form-control"
                            />
                          </div>
                          <div class="col-3">
                            <span>Autoiniciar</span><select
                              class="form-control"
                              on:change={(e) => {
                                protocol_new[p].run =
                                  e.target.value == ""
                                    ? ""
                                    : parseInt(e.target.value);
                              }}
                            >
                              <option
                                value=""
                                selected={protocol_new[p].run === ""}
                              ></option>
                              <option
                                value="0"
                                selected={protocol_new[p].run === 0}
                                >Opcion 1</option
                              >
                              <option
                                value="1"
                                selected={protocol_new[p].run === 1}
                                >Opcion 2</option
                              >
                              <option
                                value="2"
                                selected={protocol_new[p].run === 2}
                                >Opcion 3</option
                              >
                            </select>
                          </div>
                          <div class="col-3">
                            <span>Resumir llamada</span><select
                              class="form-control"
                              on:change={(e) => {
                                protocol_new[p].init = e.target.value;
                              }}
                            >
                              <option
                                value=""
                                selected={protocol_new[p].init == ""}
                              ></option>
                              <option
                                value="resume_call_oper"
                                selected={protocol_new[p].init ==
                                  "resume_call_oper"}>resume_call_oper</option
                              >
                              <option
                                value="resume_call_matriz"
                                selected={protocol_new[p].init ==
                                  "resume_call_matriz"}
                                >resume_call_matriz</option
                              >
                            </select>
                          </div>
                          {#each [1, 2, 3] as s, j}
                            <div class="col-12 mt-3">
                              <h6>Opcion {s}</h6>
                            </div>
                            <div class="col-4">
                              <span>Texto</span><input
                                type="text"
                                bind:value={protocol_new[p].options[j]}
                                class="form-control"
                              />
                            </div>
                            <div class="col-2">
                              <span>Destino</span><select
                                class="form-control"
                                on:change={(e) => {
                                  protocol_new[p].actions[j] = e.target.value;
                                }}
                              >
                                <option value=""></option>
                                {#each protocol_list as pl}
                                  <option
                                    value={pl}
                                    selected={protocol_new[p].actions[j] == pl}
                                    >{pl}</option
                                  >
                                {/each}
                              </select>
                            </div>
                            <div class="col-2">
                              <span>Funcion</span>
                              <select
                                class="form-control"
                                on:change={(e) => {
                                  protocol_new[p].function[j] = e.target.value;
                                }}
                              >
                                <option value=""></option>
                                {#each functions as pl}
                                  <option
                                    value={pl}
                                    selected={protocol_new[p].function[j] == pl}
                                    >{pl}</option
                                  >
                                {/each}
                              </select>
                            </div>
                            <div class="col-2">
                              <span>Color</span><select
                                class="form-control"
                                on:change={(e) => {
                                  protocol_new[p].btn_color[j] = e.target.value;
                                }}
                              >
                                <option
                                  value="success"
                                  selected={protocol_new[p].btn_color[j] ==
                                    "success"}>Verde</option
                                >
                                <option
                                  value="danger"
                                  selected={protocol_new[p].btn_color[j] ==
                                    "danger"}>Rojo</option
                                >
                                <option
                                  value="warning"
                                  selected={protocol_new[p].btn_color[j] ==
                                    "warning"}>Amarillo</option
                                >
                              </select>
                            </div>
                            <div class="col-2">
                              <span>Tamaño</span><select
                                class="form-control"
                                on:change={(e) => {
                                  protocol_new[p].btn_size[j] = e.target.value;
                                }}
                              >
                                <option
                                  value="lg"
                                  selected={protocol_new[p].btn_size[j] == "lg"}
                                  >Grande</option
                                >
                                <option
                                  value=""
                                  selected={protocol_new[p].btn_size[j] == ""}
                                  >Normal</option
                                >
                                <option
                                  value="sm"
                                  selected={protocol_new[p].btn_size[j] == "sm"}
                                  >Chico</option
                                >
                              </select>
                            </div>
                          {/each}
                        {/each}
                      </div>
                      <div class="row">
                        <div class="col-12 mt-4" style="text-align: end;">
                          <button class="btn btn-primary" on:click={add_step}>
                            Agregar paso
                          </button>
                        </div>
                      </div>
                      <div class="row" style="display: none;">
                        <div
                          id="myDiagramDiv"
                          style="border: 0px solid black; width: 100%; height: 400px; position: relative; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); cursor: auto;"
                        >
                          <canvas
                            tabindex="0"
                            width="398"
                            height="398"
                            style="
                          position: absolute;
                          top: 0px;
                          left: 0px;
                          z-index: 2;
                          user-select: none;
                          touch-action: none;
                          width: 100%;
                          height: 398px;
                          cursor: auto;
                        "
                          ></canvas>
                        </div>
                      </div>
                      <div class="row" style="display: none;">
                        <div class="col-12 mt-1">
                          <h3>Protocolo</h3>
                        </div>
                        <div class="col-12">
                          <textarea
                            bind:value={protocol_text}
                            on:change={reverse}
                            class="form-control"
                            bind:this={protocol_t}
                          />
                        </div>
                      </div>
                    </div>
                  {:else if add_protocol_mode}
                    <div
                      style="display: flex; justify-content: flex-end; margin:5px;"
                    >
                      <button
                        type="button"
                        class="btn btn-success mini"
                        on:click={() => {
                          add_protocol_mode = false;
                        }}
                      >
                        <i class="material-icons md-90"> arrow_back </i>
                        Regresar
                      </button>
                    </div>
                    <div class="row h-100 no-gutters overflow-auto">
                      <div
                        class="col-3 h-100 overflow-auto"
                        style="display:flex; flex-direction:column;"
                      >
                        <div
                          class="card-header bg-dark text-white b-0"
                          style="padding:0rem 0rem 0rem 1rem"
                        >
                          <i class="material-icons md-2"> add_alert </i>
                          <span>Protocolo prediseñados</span>
                        </div>
                        <div
                          class="list-group overflow-auto"
                          style="border: 0.5px solid black; flex-grow: 1;"
                        >
                          {#each protocol_templates as item, i}
                            <a
                              href="/"
                              class="list-group-item list-group-item-action {protocol_template_selected ==
                              i
                                ? 'active'
                                : ''}"
                              style="border-bottom-right-radius: 0px; border-top-right-radius: 0px;"
                              on:click|preventDefault={() => {
                                select_protocol(i);
                              }}
                            >
                              <i class="material-icons md-2"> policy </i>
                              {item.def.name}
                            </a>
                          {/each}
                        </div>
                      </div>
                      <div
                        class="col-6 h-100 overflow-auto"
                        style="display:flex; flex-direction:column;"
                      >
                        <div
                          class="card-header bg-dark text-white b-0"
                          style="padding:0rem 0rem 0rem 1rem"
                        >
                          <i class="material-icons md-2"> code </i>
                          <span>Descripción</span>
                        </div>
                        <div
                          class="overflow-auto"
                          style="border: 0.5px solid black; flex-grow: 1; padding: 0px 20px 20px 20px; line-height: 2; display: flex; flex-direction: column;"
                        >
                          <div style="overflow: auto;">
                            {@html protocol_templates[
                              protocol_template_selected
                            ].description}
                          </div>
                          <div
                            style="display:flex;flex-direction: column; justify-content: space-between; align-items:end;"
                          >
                            <button
                              id="add_protocol"
                              on:click={() => {
                                add_new_protocol(
                                  protocol_templates[protocol_template_selected]
                                    .def
                                );
                              }}
                              type="button"
                              class="btn btn-success mini"
                              style="margin-top: 20px;"
                            >
                              <i class="material-icons md-90"> save </i>
                              Agregar protocolo
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-3 h-100 overflow-auto"
                        style="display:flex; flex-direction:column;"
                      >
                        <div
                          class="card-header bg-dark text-white b-0"
                          style="padding:0rem 0rem 0rem 1rem"
                        >
                          <i class="material-icons md-2"> notifications </i>
                          <span>Protocolos configuradas</span>
                        </div>
                        {#if $protocol.length > 0}
                          <div
                            class="list-group overflow-auto"
                            style="border: 0.5px solid black; flex-grow: 1;"
                          >
                            {#each $protocol as item, i}
                              <div
                                class="list-group-item list-group-item-action"
                              >
                                <i class="material-icons md-2">policy</i>
                                {item.name} (ID:{i})
                                <button
                                  class="btn btn-danger btn-sm"
                                  style="float: right;"
                                  on:click={() => {
                                    protocol_to_delete = i;
                                    delete_protocol();
                                  }}
                                >
                                  <i class="material-icons md-90"> delete </i>
                                </button>
                              </div>
                            {/each}
                          </div>
                        {:else}
                          <div
                            style="height: 100%; display: flex; justify-content: center; align-items: center; border: 0.5px solid black;"
                          >
                            <i class="material-icons md-2">info</i>
                            <span>No hay protocolos configurados</span>
                          </div>
                        {/if}
                      </div>
                    </div>
                  {:else if protocol_selected == -1}
                    <table class="mdc-data-table__table" id="travels_table">
                      <thead>
                        <tr class="mdc-data-table__header-row">
                          <th
                            class="mdc-data-table__header-cell"
                            role="columnheader"
                            scope="col">ID</th
                          >
                          <th
                            class="mdc-data-table__header-cell"
                            role="columnheader"
                            scope="col">Nombre</th
                          >
                          <th
                            class="mdc-data-table__header-cell"
                            role="columnheader"
                            scope="col">Inicio</th
                          >
                          <th
                            class="mdc-data-table__header-cell"
                            role="columnheader"
                            scope="col">Pasos</th
                          >
                          <th
                            class="mdc-data-table__header-cell"
                            role="columnheader"
                            scope="col"
                            style="text-align: right;"
                          >
                            <button
                              type="button"
                              class="btn btn-success mini"
                              on:click={() => {
                                add_protocol_mode = !add_protocol_mode;
                              }}
                            >
                              <i class="material-icons md-90">
                                {!add_protocol_mode
                                  ? "add_circle_outline"
                                  : "arrow_back"}
                              </i>
                              {!add_protocol_mode
                                ? "Agregar protocolo"
                                : "Regresar"}
                            </button>
                            <button
                              id="add_protocol"
                              on:click={add_protocol}
                              type="button"
                              class="btn btn-dark mini"
                            >
                              <i class="material-icons md-90"
                                >add_circle_outline</i
                              >
                              Editor Avanzado
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="mdc-data-table__content">
                        {#if $protocol.length > 0}
                          {#each $protocol as item, i (item)}
                            <tr class="mdc-data-table__row">
                              <td class="mdc-data-table__cell"> {i} </td>
                              <td class="mdc-data-table__cell">
                                <a
                                  href="/"
                                  on:click|preventDefault={() => {
                                    unit_to_alert = -1;
                                    alert_selected = -1;
                                    protocol_selected = i;
                                  }}>{item.name}</a
                                >
                              </td>
                              <td class="mdc-data-table__cell">
                                {item.init} ({item[item.init].mensaje.length >
                                20
                                  ? item[item.init].mensaje.slice(0, 20) + "..."
                                  : item[item.init].mensaje})
                              </td>
                              <td class="mdc-data-table__cell">
                                {Object.keys(item).filter(
                                  (key) => !["name", "init"].includes(key)
                                ).length} pasos</td
                              >
                              <td
                                class="mdc-data-table__cell"
                                style="text-align: right;"
                              >
                                <button
                                  style="outline: unset;"
                                  on:click|preventDefault={() =>
                                    menu_protocolo(i)}
                                  class="mdc-icon-button material-icons"
                                  >more_vert</button
                                >
                              </td>
                            </tr>
                          {/each}
                        {:else}
                          <tr class="mdc-data-table__row">
                            <td class="mdc-data-table__cell" colspan="5">
                              No hay protocolos configurados, por favor agregue
                              uno dando click en el botón "Agregar protocolo".
                            </td>
                          </tr>
                        {/if}
                      </tbody>
                    </table>
                  {:else}
                    <!-- bar to return -->
                    <div class="row" style="margin: 0;">
                      <div
                        class="col-md-12"
                        style="margin-top: 10px; margin-bottom: 10px; display: flex; justify-content: space-between; align-items: center;"
                      >
                        <button
                          id="return_protocol"
                          on:click={() => {
                            protocol_selected = -1;
                          }}
                          type="button"
                          class="btn btn-info mini"
                        >
                          <i class="material-icons md-90">arrow_back</i>
                          Regresar a la lista
                        </button>
                        <div style="display: flex; align-items: center;">
                          <!-- list units -->
                          <select
                            class="form-control"
                            style="width: auto; margin-right: 30px;"
                            on:change={(e) => {
                              unit_to_alert = e.target.value;
                            }}
                          >
                            <option value="-1">Unidades</option>
                            {#each $units_list as item, i}
                              <option
                                value={item}
                                selected={unit_to_alert == item}
                              >
                                {$nicks_list[item]}
                              </option>
                            {/each}
                          </select>
                          <select
                            class="form-control"
                            style="width: auto; margin-right: 30px;"
                            on:change={(e) => {
                              alert_selected = e.target.value;
                            }}
                          >
                            <option value="-1">Alertas</option>
                            {#each $alerts_list as item, i}
                              {#if item.protocol == protocol_selected}
                                <option
                                  value={item.alertid}
                                  selected={alert_selected == item.alertid}
                                >
                                  {item.name}
                                </option>
                              {/if}
                            {/each}
                          </select>
                          <!-- Button to simulate alert -->
                          <button
                            id="simulate_alert"
                            on:click={() => {
                              simulate_alert();
                            }}
                            type="button"
                            class="btn btn-danger mini"
                            style="margin-right: 30px;"
                            disabled={unit_to_alert == -1 ||
                              alert_selected == -1}
                          >
                            <i class="material-icons md-90">warning</i>
                            Simular protocolo
                          </button>
                          <span style="font-size: 17px; font-weight: bold;"
                            >{$protocol[protocol_selected].name} (ID:{protocol_selected})</span
                          >
                        </div>
                      </div>
                    </div>
                    <div id="protocol_diag" use:loadGoJS={init_gojs}></div>
                  {/if}
                </div>
              {/if}
              {#if selected == 4}
                <div class="mdc-data-table" style="display:flex; height: 100%;">
                  <div class="row" style="margin: 0;">
                    <div
                      class="col-md-12"
                      style="margin-top: 10px; margin-bottom: 10px; display: flex; justify-content: space-between; align-items: center;"
                    >
                      <div>
                        <span style="font-size: 17px; font-weight: bold;"
                          >{!edit_alert_mode && !add_alert_mode
                            ? "Alertas configuradas"
                            : add_alert_mode
                              ? "Agregar alertas"
                              : "Configurando alertas"}</span
                        >
                      </div>
                      <div>
                        {#if !edit_alert_mode}
                          <button
                            type="button"
                            class="btn btn-success mini"
                            on:click={() => {
                              add_alert_mode = !add_alert_mode;
                            }}
                          >
                            <i class="material-icons md-90">
                              {!add_alert_mode
                                ? "add_circle_outline"
                                : "arrow_back"}
                            </i>
                            {!add_alert_mode ? "Agregar Alerta" : "Regresar"}
                          </button>
                        {/if}
                        {#if !add_alert_mode}
                          <button
                            id="edit_alerts"
                            on:click={() => {
                              edit_alerts();
                            }}
                            type="button"
                            class="btn btn-dark mini"
                          >
                            <!-- edit alerts -->
                            <i class="material-icons md-90">
                              {!edit_alert_mode ? "code" : "arrow_back"}
                            </i>
                            {!edit_alert_mode ? "Editor Avanzado" : "Regresar"}
                          </button>
                        {/if}
                      </div>
                    </div>
                  </div>
                  {#if !edit_alert_mode && !add_alert_mode}
                    <div
                      class="accordion"
                      id="accordion_alerts"
                      style="overflow: auto;"
                    >
                      {#if $alerts_list.length == 0}
                        <div class="alert alert-warning" role="alert">
                          No hay alertas configuradas, por favor agregue una
                          dando click en el botón "Agregar Alerta".
                        </div>
                      {:else}
                        {#each $alerts_list as item, index}
                          <div class="card">
                            <div
                              class="card-header"
                              id="heading{index}"
                              style="background-color: white;"
                            >
                              <button
                                class="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapse{index}"
                                aria-expanded="false"
                                aria-controls="collapse{index}"
                              >
                                <i class="material-icons md-2">{item.icon}</i>
                                {item.name}
                              </button>
                            </div>

                            <div
                              id="collapse{index}"
                              class="collapse"
                              aria-labelledby="heading{index}"
                              data-parent="#accordion_alerts"
                            >
                              <div class="card-body">
                                <div class="row">
                                  {#each Object.entries(item).filter(([key]) => key !== "cron_alert" && key !== "event_alert") as [key, value]}
                                    <div class="col-6">
                                      <table class="table table-bordered">
                                        <tr
                                          ><td><strong>{key}</strong></td><td
                                            >{JSON.stringify(
                                              value,
                                              null,
                                              2
                                            )}</td
                                          ></tr
                                        >
                                      </table>
                                    </div>
                                  {/each}

                                  {#if item.cron_alert.counter.length > 0 || item.cron_alert.discounter.length > 0}
                                    <div
                                      class="col-12 mt-3 text-center text-primary"
                                    >
                                      <h5>Cron Alert</h5>
                                      {#if item.cron_alert.counter.length > 0}
                                        <h6>Counter</h6>
                                        {@html renderTable(
                                          item.cron_alert.counter
                                        )}
                                      {/if}
                                      {#if item.cron_alert.discounter.length > 0}
                                        <h6>Discounter</h6>
                                        {@html renderTable(
                                          item.cron_alert.discounter
                                        )}
                                      {/if}
                                    </div>
                                  {/if}

                                  {#if item.event_alert.counter.length > 0 || item.event_alert.discounter.length > 0}
                                    <div
                                      class="col-12 mt-3 text-center text-primary"
                                    >
                                      <h5>Event Alert</h5>
                                      {#if item.event_alert.counter.length > 0}
                                        <h6>Counter</h6>
                                        {@html renderTable(
                                          item.event_alert.counter
                                        )}
                                      {/if}
                                      {#if item.event_alert.discounter.length > 0}
                                        <h6>Discounter</h6>
                                        {@html renderTable(
                                          item.event_alert.discounter
                                        )}
                                      {/if}
                                    </div>
                                  {/if}
                                </div>
                              </div>
                            </div>
                          </div>
                        {/each}
                      {/if}
                    </div>
                  {:else if add_alert_mode}
                    <div class="row h-100 no-gutters overflow-auto">
                      <div
                        class="col-3 h-100 overflow-auto"
                        style="display:flex; flex-direction:column;"
                      >
                        <div
                          class="card-header bg-dark text-white b-0"
                          style="padding:0rem 0rem 0rem 1rem"
                        >
                          <i class="material-icons md-2"> add_alert </i>
                          <span>Alertas prediseñadas</span>
                        </div>
                        <div
                          class="list-group overflow-auto"
                          style="border: 0.5px solid black; flex-grow: 1;"
                        >
                          {#each alert_templates as item, i}
                            <a
                              href="/"
                              class="list-group-item list-group-item-action {alert_template_selected ==
                              i
                                ? 'active'
                                : ''}"
                              style="border-bottom-right-radius: 0px; border-top-right-radius: 0px;"
                              on:click|preventDefault={() => {
                                select_alert(i);
                              }}
                            >
                              <i class="material-icons md-2">{item.def.icon}</i>
                              {item.def.name}
                            </a>
                          {/each}
                        </div>
                      </div>
                      <div
                        class="col-6 h-100 overflow-auto"
                        style="display:flex; flex-direction:column;"
                      >
                        <div
                          class="card-header bg-dark text-white b-0"
                          style="padding:0rem 0rem 0rem 1rem"
                        >
                          <i class="material-icons md-2"> code </i>
                          <span>Descripción</span>
                        </div>
                        <div
                          class="overflow-auto"
                          style="border: 0.5px solid black; flex-grow: 1; padding: 0px 20px 20px 20px; line-height: 2; display: flex; flex-direction: column;"
                        >
                          <div style="overflow: auto;">
                            {@html alert_templates[alert_template_selected]
                              .description}
                          </div>
                          <div
                            style="display:flex;flex-direction: row; justify-content: space-between; align-items:end;"
                          >
                            <div style="display:flex; flex-direction:column;">
                              <div>
                                <span>Emails reporte</span>
                                <input
                                  type="email"
                                  class="form-control"
                                  placeholder="Emails para recibir esta alerta"
                                  style="width: 100%; margin-bottom: 10px;"
                                  bind:value={emails_template}
                                />
                              </div>
                              <div>
                                <span>Protocolo</span>
                                <select
                                  class="form-control"
                                  style="width: 100%;"
                                  on:change={(e) => {
                                    alert_templates[
                                      alert_template_selected
                                    ].def.protocol = e.target.value;
                                  }}
                                >
                                  {#each $protocol as item, i}
                                    <option
                                      value={i}
                                      selected={alert_templates[
                                        alert_template_selected
                                      ].def.protocol == i}
                                    >
                                      {item.name} (ID:{i})
                                    </option>
                                  {/each}
                                </select>
                              </div>
                            </div>
                            <button
                              id="add_alert"
                              on:click={() => {
                                add_new_alert(
                                  alert_templates[alert_template_selected]
                                );
                              }}
                              type="button"
                              class="btn btn-success mini"
                              style="margin-top: 20px;"
                            >
                              <i class="material-icons md-90"> save </i>
                              Agregar alerta
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-3 h-100 overflow-auto"
                        style="display:flex; flex-direction:column;"
                      >
                        <div
                          class="card-header bg-dark text-white b-0"
                          style="padding:0rem 0rem 0rem 1rem"
                        >
                          <i class="material-icons md-2"> notifications </i>
                          <span>Alertas configuradas</span>
                        </div>
                        {#if $alerts_list.length > 0}
                          <div
                            class="list-group overflow-auto"
                            style="border: 0.5px solid black; flex-grow: 1;"
                          >
                            {#each $alerts_list as item, i}
                              <div
                                class="list-group-item list-group-item-action"
                              >
                                <i class="material-icons md-2">{item.icon}</i>
                                {item.name}
                                <button
                                  class="btn btn-danger btn-sm"
                                  style="float: right;"
                                  on:click={() => {
                                    delete_alert(item);
                                  }}
                                >
                                  <i class="material-icons md-90"> delete </i>
                                </button>
                              </div>
                            {/each}
                          </div>
                        {:else}
                          <div
                            style="height: 100%; display: flex; justify-content: center; align-items: center; border: 0.5px solid black;"
                          >
                            <i class="material-icons md-2">info</i>
                            <span>No hay alertas configuradas</span>
                          </div>
                        {/if}
                      </div>
                    </div>
                  {:else}
                    <iframe
                      title="Alerts"
                      src="{alerts_url}/?url_api={url_api}&key={key_alert}&url_post={window
                        .location.origin +
                        window.location.pathname.replace(/\/$/, '')}"
                      style="width: 100%; height: 100%;"
                      id="iframe_alerts"
                      use:iframe_alerts_on
                    ></iframe>
                    <div class="watermark">External tool</div>
                  {/if}
                </div>
              {/if}
              {#if selected == 3}
                <div class="mdc-data-table" style="display:flex; height: 100%;">
                  <div class="row" style="margin: 0;">
                    <div
                      class="col-md-12"
                      style="margin-top: 10px; margin-bottom: 10px; display: flex; align-items: center;"
                    >
                      <span style="font-size: 17px; font-weight: bold;"
                        >Formato
                      </span>
                      <select
                        style="width: auto; margin-left: 30px;"
                        on:change={(e) => {
                          format_selected = e.target.value;
                          editor.setValue(reports_n[format_selected]);
                        }}
                      >
                        {#each ["alerts", "travel", "estado"] as item, i}
                          <option
                            value={item}
                            selected={format_selected == item}
                          >
                            {item == "alerts"
                              ? "Informe de Alertas"
                              : item == "travel"
                                ? "Reporte de Viajes"
                                : "Reporte de Estado"}
                          </option>
                        {/each}
                      </select>
                      <button
                        id="save_report"
                        on:click={() => {
                          save_report();
                        }}
                        type="button"
                        class="btn btn-success mini"
                        style="margin-left: 20px;display: {reports_changed
                          ? 'inline'
                          : 'none'};"
                      >
                        <i class="material-icons" style="font-size: 1.0rem;">
                          save
                        </i>
                        Guardar reporte
                      </button>
                      <!-- Reset button -->
                      <button
                        id="reset_report"
                        on:click={() => {
                          read_reports();
                          editor.setValue(reports_n[format_selected]);
                          reports_changed = false;
                        }}
                        type="button"
                        class="btn btn-info mini"
                        style="margin-left: 20px;display: {reports_changed
                          ? 'inline'
                          : 'none'};"
                      >
                        <i class="material-icons" style="font-size: 1.0rem;">
                          refresh
                        </i>
                        Reset
                      </button>
                    </div>
                  </div>
                  <div
                    class="row"
                    use:read_reports
                    style="margin: 0; overflow: hidden;"
                    use:loadCodeMirror
                  >
                    <div
                      class="col-md-6"
                      style="padding: 0; height: 100%; overflow: auto;"
                    >
                      <textarea
                        id="code-editor"
                        style="height: 100%; width: 100%;"
                      ></textarea>
                    </div>
                    <div class="col-md-6" style="padding: 0">
                      <iframe
                        title="Preview"
                        id="preview"
                        srcdoc={page_view(reports_n[format_selected])}
                        style="height: 100%; width: 100%;"
                      ></iframe>
                    </div>
                  </div>
                </div>
              {/if}
              {#if selected == 7}
                <iframe
                  title="FormatosViaje"
                  src="{formats_driver_url}/?url_api={url_api}&key={key_alert}&url_post={window
                    .location.origin +
                    window.location.pathname.replace(/\/$/, '')}"
                  style="width: 100%; height: 100%;"
                  id="iframe_reports"
                  use:iframe_reports_on
                ></iframe>
                <div class="watermark">External tool</div>
              {/if}
              {#if selected == 10}
                <div class="content mt-3 col-12">
                  <h4>
                    <i class="material-icons md-2">settings</i>Opciones de
                    Monitor
                  </h4>
                  <!-- line separator -->
                  <hr />
                  <h6>Configurar salidas digitales en Monitor (local)</h6>
                  <p>
                    Seleccione las salidas digitales que desea visualizar en el
                    monitor, puedes colocar un nombre.
                  </p>
                  <div class="row">
                    <div class="col-3">
                      {#each [0, 1, 2, 3] as i}
                        <div class="form-group d-flex align-items-center">
                          <input
                            type="checkbox"
                            class="mr-2"
                            id="entrada{i}"
                            on:change={(e) => {
                              monitor_outputs[i].enabled = e.target.checked;
                            }}
                            checked={monitor_outputs[i].enabled}
                          />
                          <label class="form-check-label mr-3" for="entrada{i}"
                            >Salida {i}</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="nombre{i}"
                            placeholder="Nombre"
                            maxlength="6"
                            style="width: 150px;"
                            on:input={(e) => {
                              monitor_outputs[i].name = e.target.value;
                            }}
                            value={monitor_outputs[i].name}
                          />
                        </div>
                      {/each}
                    </div>
                    <div class="col-3">
                      {#each [4, 5, 6, 7] as i}
                        <div class="form-group d-flex align-items-center">
                          <input
                            type="checkbox"
                            class="mr-2"
                            id="entrada{i}"
                            on:change={(e) => {
                              monitor_outputs[i].enabled = e.target.checked;
                            }}
                            checked={monitor_outputs[i].enabled}
                          />
                          <label class="form-check-label mr-3" for="entrada{i}"
                            >Salida {i}</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="nombre{i}"
                            placeholder="Nombre"
                            maxlength="6"
                            style="width: 150px;"
                            on:input={(e) => {
                              monitor_outputs[i].name = e.target.value;
                            }}
                            value={monitor_outputs[i].name}
                          />
                        </div>
                      {/each}
                    </div>
                  </div>
                  <hr />
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  .mdc-data-table__row {
    height: 45px;
  }
  .mdc-data-table__header-row {
    height: 45px;
  }
  .mdc-chip__text:focus {
    outline: unset;
  }
  .mdc-chip__primary-action:focus {
    outline: unset;
  }
  .mdc-icon-button {
    width: 24px;
    height: 24px;
    padding: 0px;
  }
  .mdc-data-table__table {
    white-space: normal;
  }

  .btn {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }

  .btn.big {
    font-size: 1rem;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }

  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  #protocol_diag {
    width: 100%;
    height: 100%;
    border: 1px solid black;
  }
  .watermark {
    position: fixed;
    bottom: 10px;
    right: 50px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3); /* Color negro con 30% de opacidad */
    z-index: 1000;
    pointer-events: none; /* Para que no interfiera con otros elementos */
  }
</style>
