<script>
  export let login_db;
  export let socket_query;
  export let monitor_worker;
  export let unit_worker;
  export let geos_worker;
  export let travel_worker;
  export let trips_worker;
  export let routes_worker;
  export let routes_db;
  export let chats_db;
  import { onMount, afterUpdate, onDestroy } from "svelte";
  import {
    menu,
    nicks_list,
    recognition,
    portal_version,
    cc_monitor,
    speaking_list,
    driver_name,
    travel_crtl,
    geos_class,
    poli,
    driver_id,
    map_ready,
    live,
    travels,
    geos_full,
    driver_reports,
  } from "./stores.js";
  import { compile } from "svelte/compiler";

  onDestroy(() => {
    socket_query.off("chats_");
    socket_query.off("rtc_answer");
    socket_query.off("rtc_candidate");
    socket_query.off("rtc_requestOffer");
    socket_query.off("rtc_disconnect");
  });

  let show_spinner = true;
  let alerta_off = false;
  let saved_ok = false;
  let saved_text = "";
  let saved_type = "";
  socket_query.on("disconnect", (reason) => {
    alerta_off = true;
  });
  socket_query.on("hello", (reason) => {
    alerta_off = false;
  });
  window.document.body.style.display = "flex";
  window.document.body.style["flex-direction"] = "column";

  var script = document.createElement("script");
  script.src =
    "https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js";
  document.head.appendChild(script);

  var script = document.createElement("script");
  script.src =
    "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js";
  document.head.appendChild(script);

  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.href =
    "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css";
  document.head.appendChild(link);

  var materialIconsLink = document.createElement("link");
  materialIconsLink.rel = "stylesheet";
  materialIconsLink.href =
    "https://fonts.googleapis.com/icon?family=Material+Icons";
  document.head.appendChild(materialIconsLink);

  async function log_out_real() {
    await login_db.setItem("menu_base", 0);
    await login_db.setItem("login_", "");
    unit_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    geos_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    travel_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    monitor_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    trips_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    routes_worker.postMessage({
      type: "emit",
      key: "hello",
      message: "" + ":" + sha1(""),
    });
    socket_query.emit("hello", "" + ":" + sha1(""));
    location.href = "/";
  }

  login_db.getItem("login_").then((data) => {
    if (!data || data == "") {
      location.href = "/";
    } else {
      show_spinner = false;
    }
  });

  let menu_app = 0; // Cambia este valor según el elemento seleccionado
  function change_menu_app(value) {
    menu_app = value;
    // close the offcanvas
    var offcanvasElement = document.getElementById("offcanvasMenu");
    var offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
    offcanvas.hide();
  }

  function handle_add() {
    // Función vacía para manejar el clic en el botón flotante
  }
  function handle_play() {
    if (menu_app == 101) {
      console.log(before_trip);
      for (let x in before_trip) {
        for (let w in before_trip[x].fields) {
          if (
            (before_trip[x].fields[w].value == "" ||
              !before_trip[x].fields[w].value) &&
            before_trip[x].fields[w].required
          ) {
            saved_ok = true;
            saved_text = "Error, campos vacíos";
            saved_type = "alert-danger";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
            return;
          }
        }
      }
      // send before trip form
      socket_query.emit(
        "save_trip_form",
        prog_selected,
        "before_trip",
        before_trip.concat(before_trip_optional),
        $driver_reports.custom ? $driver_reports.custom : [],
        (data) => {
          if (data == "ok") {
            saved_ok = true;
            saved_text = "Formulario enviado";
            saved_type = "alert-success";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
            var myModal = new bootstrap.Modal(
              document.getElementById("confirmPlayModal"),
              {
                keyboard: false,
              }
            );
            myModal.show();
          }
        }
      );
    } else {
      var myModal = new bootstrap.Modal(
        document.getElementById("confirmPlayModal"),
        {
          keyboard: false,
        }
      );
      myModal.show();
    }
  }
  let before_trip = [];
  let before_trip_optional = [];
  let after_trip = [];
  let after_trip_optional = [];
  function confirm_play() {
    if (
      ($driver_reports.before_trip.length > 0 ||
        $driver_reports.before_trip_optional.length > 0) &&
      menu_app != 101
    ) {
      before_trip = JSON.parse(JSON.stringify($driver_reports.before_trip));
      before_trip_optional = JSON.parse(
        JSON.stringify($driver_reports.before_trip_optional)
      );
      for (let x in before_trip) {
        for (let y in before_trip[x].fields) {
          if (before_trip[x].fields[y].type == "select") {
            before_trip[x].fields[y].value = [
              before_trip[x].fields[y].options[0].value,
            ];
          }
          if (before_trip[x].fields[y].type == "checkbox") {
            before_trip[x].fields[y].value = [];
          }
        }
      }
      for (let x in before_trip_optional) {
        for (let y in before_trip_optional[x].fields) {
          if (before_trip_optional[x].fields[y].type == "select") {
            before_trip_optional[x].fields[y].value = [
              before_trip_optional[x].fields[y].options[0].value,
            ];
          }
          if (before_trip_optional[x].fields[y].type == "checkbox") {
            before_trip_optional[x].fields[y].value = [];
          }
        }
      }
      console.log(before_trip);
      menu_app = 101;
      // Cerrar el modal
      var myModalEl = document.getElementById("confirmPlayModal");
      var modal = bootstrap.Modal.getInstance(myModalEl);
      modal.hide();
    } else {
      before_trip = [];
      before_trip_optional = [];
      launch_travel(
        $travel_crtl.find((x) => x.uuid == prog_selected).id,
        $travel_crtl.find((x) => x.uuid == prog_selected).date_ini,
        $travel_crtl.find((x) => x.uuid == prog_selected).dest,
        $travel_crtl.find((x) => x.uuid == prog_selected).oper,
        $travel_crtl.find((x) => x.uuid == prog_selected).route,
        $travel_crtl.find((x) => x.uuid == prog_selected).id_add
      );
      // Cerrar el modal
      var myModalEl = document.getElementById("confirmPlayModal");
      var modal = bootstrap.Modal.getInstance(myModalEl);
      modal.hide();
      menu_app = 0;
    }
  }
  let prog_selected = -1;
  $: {
    if (menu_app === 0) {
      prog_selected = -1;
    }
  }
  function launch_travel(id, date_ini, des, oper, route, id_add) {
    socket_query.emit(
      "travel_launch",
      id,
      date_ini,
      des,
      oper,
      route,
      id_add,
      (data) => {
        if (data == "ok") {
          saved_ok = true;
          saved_text = "Viaje iniciado";
          saved_type = "alert-success";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        } else if (data == "error1") {
          saved_ok = true;
          saved_text = "Error, Unidad con viaje en proceso";
          saved_type = "alert-danger";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );
  }
  let travel_on_going = false;
  let travel_current = {};
  let travel_current_ = {};
  $: {
    let change = false;
    for (let i = 0; i < $travel_crtl.length; i++) {
      if ($travel_crtl[i].step != 0 && $travel_crtl[i].oper == $driver_id) {
        let travel_current_ = $travel_crtl[i];
        if (
          travel_current_.step > 1 &&
          travel_current_.sec_dests.length >= travel_current_.step - 1
        ) {
          travel_current_.dest =
            travel_current_.sec_dests[travel_current_.step - 2];
        }
        if (
          travel_current_.step > 1 &&
          travel_current_.sec_routes.length >= travel_current_.step - 1
        ) {
          travel_current_.route =
            travel_current_.sec_routes[travel_current_.step - 2];
        }
        travel_current = travel_current_;
        travel_on_going = true;
        showLabelStop = true;
        setTimeout(() => {
          showLabelStop = false;
        }, 60 * 1000);
        prog_selected = -1;
        change = true;
        break;
      }
    }
    if (!change) {
      travel_on_going = false;
    }
    console.log("change");
  }
  let toleracia = 25 * 60 * 1000;
  let tiempo_viaje_futuro = 6 * 60 * 60 * 1000;
  let mount_complete = false;
  let on_map = false;
  let map_show = false;
  let map;
  onMount(() => {
    mount_complete = true;
  });
  $: {
    if (
      $map_ready == 1 &&
      mount_complete &&
      !map &&
      document.getElementById("map") &&
      map_show
    ) {
      initMap();
      console.log("mapa cargado");
    }
  }
  function initMap() {
    if ($map_ready != 1 || !mount_complete) {
      map_show = true;
      return;
    }

    maker_unit = null;
    last_live = {};

    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 19.3778, lng: -99.1774 },
      mapId: "ae4572998f650614",
      zoom: 16,
      disableDefaultUI: true, // Deshabilita todos los controles por defecto
      zoomControl: false, // Desactiva el control de zoom
      fullscreenControl: false, // Desactiva el control de pantalla completa
      streetViewControl: false, // Desactiva el control de Street View
      mapTypeControl: false, // Desactiva el control de tipo de mapa
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });

    map.addListener("mouseover", function () {
      on_map = true;
    });

    map.addListener("mouseout", function () {
      on_map = false;
    });
  }
  let maker_unit;
  const style = document.createElement("style");
  style.innerHTML = `
    .custom-label {
      background-color: white; /* Fondo blanco */
      padding: 2px 5px; /* Espaciado interno */
      border-radius: 3px; /* Bordes redondeados */
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); /* Sombra */
      white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    }
  `;
  document.head.appendChild(style);
  let last_live = {};
  let circles = [];
  let poligons = [];
  $: {
    if (
      map &&
      travel_on_going &&
      $live[travel_current.id] &&
      ((last_live.lat != $live[travel_current.id].lat &&
        last_live.lon != $live[travel_current.id].lon) ||
        JSON.stringify(travel_current) != JSON.stringify(travel_current_)) &&
      $travels[travel_current.id] &&
      ($geos_full[$travels[travel_current.id].monitor_crtl.destino] ||
        $poli[$travels[travel_current.id].monitor_crtl.destino])
    ) {
      console.log("update map");
      travel_current_ = JSON.parse(JSON.stringify(travel_current));
      let pos = {
        lat: $live[travel_current.id].lat,
        lng: $live[travel_current.id].lon,
      };
      let heading = $live[travel_current.id].heading;

      if (maker_unit) {
        // Si el marcador ya existe, actualiza la posición y el icono
        maker_unit.setPosition(pos);
        maker_unit.setIcon({
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 6, // Escala del icono (tamaño de la flecha)
          rotation: 0, // Mantén el marcador apuntando hacia adelante (sin rotación)
          fillColor: "#80C080", // Color de la flecha
          fillOpacity: 1,
          strokeWeight: 2,
          labelOrigin: new google.maps.Point(-3, -3),
        });
      } else {
        // Si el marcador no existe, créalo con el icono de la flecha
        maker_unit = new google.maps.Marker({
          position: pos,
          map: map,
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 6, // Escala del icono (tamaño de la flecha)
            rotation: 0, // Mantén el marcador apuntando hacia adelante (sin rotación)
            fillColor: "#80C080", // Color de la flecha
            fillOpacity: 1,
            strokeWeight: 2,
            labelOrigin: new google.maps.Point(-3, -3),
          },
          label: {
            text:
              typeof $nicks_list[travel_current.id] != "undefined"
                ? $nicks_list[travel_current.id]
                : travel_current.id,
            color: "#212121",
            fontSize: "13px",
            fontWeight: "bold",
            className: "custom-label",
          },
        });
      }

      // Rotar el mapa para que siga la dirección del marcador (heading)
      map.setCenter(pos);
      map.setHeading(parseInt(heading));
      map.setTilt(0);
      map.setZoom(15);
      last_live = { lat: pos.lat, lon: pos.lng };

      // Dibujar la ruta estimada
      let mobid = travel_current.id;
      let zIndex = 1;
      if (typeof $travels[mobid].monitor_crtl.destino != "undefined") {
        if ($travels[mobid].monitor_crtl.destino != "") {
          if ($geos_full[$travels[mobid].monitor_crtl.destino] != null) {
            let geo = $geos_full[$travels[mobid].monitor_crtl.destino];
            let anti_f = 10;
            let last_join = "";
            while (typeof geo.join != "undefined" || anti_f == 0) {
              last_join = geo.join;
              geo = $geos_full[geo.join];
              anti_f--;
            }
            for (let x in circles) {
              circles[x].setMap(null);
            }
            for (let y in geo["pos"]) {
              let lat = geo["pos"][y][0];
              let lng = geo["pos"][y][1];
              circles.push(
                new google.maps.Circle({
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                  map: map,
                  center: { lat: lat, lng: lng },
                  radius: 100,
                  zIndex: 3,
                })
              );
            }
            if (
              $travels[mobid].monitor_crtl.follow == 1 &&
              (typeof $travels[mobid].monitor_crtl.ruta == "undefined" ||
                $travels[mobid].monitor_crtl.ruta == "")
            ) {
              if (typeof recorrido_ruta != "undefined") {
                recorrido_ruta.setMap(null);
              }
              if (typeof m_inicio_ruta != "undefined") {
                m_inicio_ruta.setMap(null);
              }
              osrm_destino(geo["pos"][0][0], geo["pos"][0][1], zIndex, mobid);
            } else if (
              $travels[mobid].monitor_crtl.follow == 1 &&
              typeof $travels[mobid].monitor_crtl.ruta != "undefined" &&
              $travels[mobid].monitor_crtl.ruta != ""
            ) {
              route_map(mobid);
              osrm_destino(
                geo["pos"][0][0],
                geo["pos"][0][1],
                zIndex,
                mobid,
                false
              );
            }
          } else if ($poli[$travels[mobid].monitor_crtl.destino] != null) {
            let poli_points = [];
            for (let x in $poli[$travels[mobid].monitor_crtl.destino][0]) {
              poli_points.push({
                lat: $poli[$travels[mobid].monitor_crtl.destino][0][x][0],
                lng: $poli[$travels[mobid].monitor_crtl.destino][0][x][1],
              });
            }
            for (let x in poligons) {
              poligons[x].setMap(null);
            }
            poligons.push(
              new google.maps.Polygon({
                paths: poli_points,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map: map,
                zIndex: 5,
              })
            );
            if (
              $travels[mobid].monitor_crtl.follow == 1 &&
              (typeof $travels[mobid].monitor_crtl.ruta == "undefined" ||
                $travels[mobid].monitor_crtl.ruta == "")
            ) {
              if (typeof recorrido_ruta != "undefined") {
                recorrido_ruta.setMap(null);
              }
              if (typeof m_inicio_ruta != "undefined") {
                m_inicio_ruta.setMap(null);
              }
              osrm_destino(
                poli_points[0].lat,
                poli_points[0].lng,
                zIndex,
                mobid
              );
            } else if (
              $travels[mobid].monitor_crtl.follow == 1 &&
              typeof $travels[mobid].monitor_crtl.ruta != "undefined" &&
              $travels[mobid].monitor_crtl.ruta != ""
            ) {
              route_map(mobid);
              osrm_destino(
                poli_points[0].lat,
                poli_points[0].lng,
                zIndex,
                mobid,
                false
              );
            }
          }
        }
      }
    }
  }
  let showLabelStop = false;
  let l_ruta_estimada, m_inicio_ruta;
  let distance = 0;
  let duration = 0;
  async function osrm_destino(lat, lon, zIndex, mobid, print = true) {
    // OSRM
    let coordenada_osrm = "";
    coordenada_osrm =
      $live[mobid].lon + "," + $live[mobid].lat + ";" + lon + "," + lat;

    let osrm_r = await fetch(
      "https://route.omnitracs.online/route/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full"
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      let coordenada = [];
      for (let t in osrm_r.routes) {
        for (let y in osrm_r.routes[t].geometry.coordinates) {
          coordenada.push({
            lat: osrm_r.routes[t].geometry.coordinates[y][1],
            lng: osrm_r.routes[t].geometry.coordinates[y][0],
          });
        }
      }
      let lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      };
      if (typeof l_ruta_estimada != "undefined") {
        l_ruta_estimada.setMap(null);
      }
      distance = osrm_r.routes[0].distance;
      duration = osrm_r.routes[0].duration;
      if (!print) return;
      l_ruta_estimada = new google.maps.Polyline({
        path: coordenada,
        geodesic: true,
        strokeColor: "#2f21f3",
        strokeOpacity: 0,
        strokeWeight: 2.5,
        map: map,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
      });
    }
  }
  let recorrido_ruta;
  async function route_map(mobid) {
    let coordenada_ruta = [];
    let route;
    try {
      route = await routes_db.getItem($travels[mobid].monitor_crtl.ruta);
    } catch (e) {
      console.log("Error leyendo routa", e);
    }
    if (route != null) {
      let lat_org = route["pos_start"][0];
      let lng_org = route["pos_start"][1];
      for (let x in route["pos"]) {
        let lat = (lat_org - route["pos"][x][0]) / 10000;
        let lng = (lng_org - route["pos"][x][1]) / 10000;
        coordenada_ruta.push({ lat: lat, lng: lng });
      }
    }
    if (typeof recorrido_ruta != "undefined") {
      recorrido_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      recorrido_ruta = new google.maps.Polyline({
        path: coordenada_ruta,
        geodesic: true,
        strokeColor: "#2196f3",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: 3,
      });
    }
    if (typeof m_inicio_ruta != "undefined") {
      m_inicio_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      m_inicio_ruta = new google.maps.Marker({
        position: coordenada_ruta[0],
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerA.png",
        zIndex: 4,
      });
    }
  }
  function handleSliderChange(event) {
    const sliderValue = event.target.value;
    const sosButton = document.getElementById("sosButton");
    const arrowIcon = document.querySelector(".arrow-icon");

    if (sliderValue == 100) {
      sosButton.disabled = false;
      sosButton.classList.add("enabled");
      arrowIcon.style.display = "none"; // Oculta la flecha cuando el deslizador llega al final
    } else {
      sosButton.disabled = true;
      sosButton.classList.remove("enabled");
      arrowIcon.style.display = "inline"; // Muestra la flecha mientras no se ha deslizado completamente
    }
  }

  function openLogoutModal() {
    var myModal = new bootstrap.Modal(
      document.getElementById("confirmLogoutModal"),
      {
        keyboard: false,
      }
    );
    myModal.show();
  }

  // Función para confirmar y ejecutar la acción de cerrar sesión
  function confirm_logout() {
    // Llamar a la función que maneja el cierre de sesión
    log_out_real();

    // Cerrar el modal
    var myModalEl = document.getElementById("confirmLogoutModal");
    var modal = bootstrap.Modal.getInstance(myModalEl);
    modal.hide();
  }

  // Wake lock
  let wakeLock = null;
  async function requestWakeLock() {
    try {
      // Solicitar el wake lock de pantalla
      wakeLock = await navigator.wakeLock.request("screen");
      console.log("Wake Lock activado");

      // Escuchar cualquier evento que lo pueda liberar
      wakeLock.addEventListener("release", () => {
        console.log("Wake Lock liberado");
      });
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  }
  function releaseWakeLock() {
    if (wakeLock !== null) {
      wakeLock.release();
      wakeLock = null;
    }
  }
  // Activar el Wake Lock cuando sea necesario
  document.addEventListener("visibilitychange", async () => {
    if (document.visibilityState === "visible") {
      await requestWakeLock();
    } else {
      releaseWakeLock();
    }
  });
  window.addEventListener("focus", async () => {
    if (wakeLock !== null) {
      await requestWakeLock();
    }
  });
  requestWakeLock();

  // Video with TURN server
  const turnConfig = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      {
        urls: "turn:portainer.sub.omnitracs.online:3478",
        username: "alfa",
        credential: "js1ma",
      },
    ],
  };
  let localStream, localStream2;
  let audioStream1;
  let peerConnection;
  async function requestVideoPermission() {
    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      console.log("Permiso de video concedido");
      stream.getTracks().forEach((track) => track.stop());
      socket_query.emit("video_permission", $driver_id, "ok");
    } catch (error) {
      console.error("Permiso de video denegado:", error);
      socket_query.emit("video_permission", $driver_id, "nok");
    }
  }
  async function requestAudioPermission() {
    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      console.log("Permiso de audio concedido");
      stream.getTracks().forEach((track) => track.stop());
      socket_query.emit("audio_permission", $driver_id, "ok");
    } catch (error) {
      console.error("Permiso de audio denegado:", error);
      socket_query.emit("audio_permission", $driver_id, "nok");
    }
  }
  $: {
    if ($driver_id) {
      requestVideoPermission();
      requestAudioPermission();
    }
  }

  socket_query.on("rtc_answer", async function (data) {
    console.log("Recibida respuesta:", data);
    await peerConnection.setRemoteDescription(new RTCSessionDescription(data));
  });
  socket_query.on("rtc_candidate", async function (data) {
    console.log("Recibido candidato ICE:", data);
    await peerConnection.addIceCandidate(new RTCIceCandidate(data));
  });
  socket_query.on("rtc_requestOffer", async function (data) {
    console.log("Solicitud de nueva oferta recibida", data);
    if (peerConnection) {
      peerConnection.close();
      peerConnection = null;
    }
    await createOffer(data);
  });
  socket_query.on("rtc_disconnect", async function () {
    console.log("Desconectando...");
    if (peerConnection) {
      peerConnection.close();
      peerConnection = null;
    }
    // Detener la transmisión de video
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      localStream = null;
    }
    if (localStream2) {
      localStream2.getTracks().forEach((track) => track.stop());
      localStream2 = null;
    }
    // Detener la transmisión de audio
    if (audioStream1) {
      audioStream1.getTracks().forEach((track) => track.stop());
      audioStream1 = null;
    }
  });

  async function getStream(deviceId) {
    const constraints = {
      video: {
        deviceId: deviceId ? { exact: deviceId } : undefined,
        width: { ideal: 640 },
        height: { ideal: 480 },
        frameRate: { max: 4 },
      },
      audio: false,
    };
    try {
      let stream = await navigator.mediaDevices.getUserMedia(constraints);
      return stream;
    } catch (error) {
      console.error("Error al obtener el stream de video:", error);
      return null;
    }
  }
  async function getStreamAudio(deviceId) {
    const constraints = {
      audio: {
        deviceId: deviceId ? { exact: deviceId } : undefined,
      },
    };
    try {
      let stream = await navigator.mediaDevices.getUserMedia(constraints);
      return stream;
    } catch (error) {
      console.error("Error al obtener el stream de audio:", error);
      return null;
    }
  }
  let last_camera = 3;
  async function createOffer(data) {
    let medias = await navigator.mediaDevices.enumerateDevices();
    let cameras = medias.filter((device) => device.kind === "videoinput");

    let microphones = medias.filter((device) => device.kind === "audioinput");

    console.log("Cámaras disponibles:", cameras.length);

    console.log("Micrófonos disponibles:", microphones.length);

    if (!localStream && last_camera != 1 && data == "video") {
      if (cameras.length > 0) {
        localStream = await getStream(cameras[0].deviceId);
      }
    }
    if (!localStream2 && last_camera != 2 && data == "video") {
      if (cameras.length > 1) {
        localStream2 = await getStream(cameras[1].deviceId);
      }
    }

    if (!audioStream1 && data == "audio") {
      if (microphones.length > 0) {
        console.log("microphones", microphones);
        audioStream1 = await getStreamAudio(microphones[0].deviceId);
      }
    }

    peerConnection = new RTCPeerConnection(turnConfig);

    if (localStream) {
      localStream.getTracks().forEach((track) => {
        peerConnection.addTrack(track, localStream);
      });
    }
    if (localStream2) {
      localStream2.getTracks().forEach((track) => {
        peerConnection.addTrack(track, localStream2);
      });
    }
    if (audioStream1) {
      audioStream1.getTracks().forEach((track) => {
        console.log("audioStream1", track);
        peerConnection.addTrack(track, audioStream1);
      });
    }
    peerConnection.ontrack = (event) => {
      if (event.track.kind == "audio") {
        const audio = document.getElementById("audio");
        audio.srcObject = event.streams[0];
        audio.autoplay = true;
        audio.playsInline = true;
        console.log("Audio Call");
      }
    };

    if (localStream && !localStream2) {
      last_camera = 1;
    }
    if (!localStream && localStream2) {
      last_camera = 2;
    }
    if (localStream && localStream2) {
      last_camera = 3;
    }

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socket_query.emit("rtc_candidate", event.candidate);
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      try {
        console.log(
          "ICE connection state change:",
          peerConnection.iceConnectionState
        );
      } catch (error) {
        console.error("Error al obtener el estado de la conexión ICE:", error);
      }
    };

    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);

    console.log("Enviando oferta:", offer);
    socket_query.emit("rtc_offer", peerConnection.localDescription);
  }

  let current_field;
  let end_trip_camera = false;
  let chat_camera = false;
  async function form_functions(field, change = true) {
    current_field = field;
    if (change) {
      menu_app = 102;
    } else {
      end_trip_camera = true;
    }
  }
  async function startCameraPreview() {
    const cameraDiv = document.querySelector("#camera");
    cameraDiv.classList.add(
      "position-relative",
      "w-100",
      "h-100",
      "d-flex",
      "flex-column"
    );

    // Create elements dynamically within the #camera div
    const video = document.createElement("video");
    video.id = "cameraPreview";
    video.style.width = "100%";
    video.style.height = "100%";
    video.classList.add(
      "position-absolute",
      "top-0",
      "start-0",
      "w-100",
      "h-100",
      "object-fit-cover"
    );
    cameraDiv.appendChild(video);

    const select = document.createElement("select");
    select.id = "cameraSelector";
    select.classList.add(
      "form-select",
      "position-absolute",
      "bottom-0",
      "start-0",
      "m-3",
      "w-auto"
    );
    cameraDiv.appendChild(select);

    const captureButton = document.createElement("button");
    captureButton.id = "captureButton";
    captureButton.innerHTML = '<i class="material-icons">photo_camera</i>';
    captureButton.classList.add(
      "btn",
      "btn-primary",
      "rounded-circle",
      "position-absolute",
      "bottom-0",
      "end-0",
      "m-3",
      "p-3"
    );
    captureButton.style.display = "flex";
    cameraDiv.appendChild(captureButton);

    const canvas = document.createElement("canvas");
    let currentStream;

    // Get the list of available video devices (cameras)
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );

    // Populate the camera selector with available cameras
    videoDevices.forEach((device, index) => {
      const option = document.createElement("option");
      option.value = device.deviceId;
      option.text = device.label || `Camera ${index + 1}`;
      select.appendChild(option);
    });

    // Function to start the video stream from the selected camera
    async function startStream(deviceId) {
      if (currentStream) {
        currentStream.getTracks().forEach((track) => track.stop());
      }
      const constraints = {
        video: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
        },
      };
      currentStream = await navigator.mediaDevices.getUserMedia(constraints);
      video.srcObject = currentStream;
      await video.play();
    }

    // Event listener for camera selection change
    select.addEventListener("change", () => {
      startStream(select.value);
    });

    // Start the default camera initially
    if (videoDevices.length > 0) {
      startStream(videoDevices[0].deviceId);
    }

    // Capture button click event to take a snapshot
    captureButton.addEventListener("click", () => {
      // Capture the image and set it as the background
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL("image/png");
      video.style.display = "none";
      cameraDiv.style.backgroundImage = `url(${imageData})`;
      cameraDiv.style.backgroundSize = "cover";
      cameraDiv.style.backgroundPosition = "center";

      // Remove existing elements (selector and capture button)
      select.remove();
      captureButton.remove();

      // Create confirm and cancel buttons
      const confirmButton = document.createElement("button");
      confirmButton.id = "confirmButton";
      confirmButton.innerHTML = '<i class="material-icons">check_circle</i>';
      confirmButton.classList.add(
        "btn",
        "btn-success",
        "rounded-circle",
        "position-absolute",
        "bottom-0",
        "start-0",
        "m-3",
        "p-3"
      );
      confirmButton.style.display = "flex";
      cameraDiv.appendChild(confirmButton);

      const cancelButton = document.createElement("button");
      cancelButton.id = "cancelButton";
      cancelButton.innerHTML = '<i class="material-icons">cancel</i>';
      cancelButton.classList.add(
        "btn",
        "btn-danger",
        "rounded-circle",
        "position-absolute",
        "bottom-0",
        "end-0",
        "m-3",
        "p-3"
      );
      cancelButton.style.display = "flex";
      cameraDiv.appendChild(cancelButton);

      // Confirm button click event to save the captured image
      confirmButton.addEventListener("click", () => {
        console.log(imageData); // Print the captured image in base64 format
        // You can also store this in a variable if needed
        current_field.value = imageData;
        // stop the video stream
        currentStream.getTracks().forEach((track) => track.stop());
        if (end_trip_camera) {
          end_trip_camera = false;
        } else if (chat_camera) {
          chat_camera = false;
          send_image(imageData);
        } else {
          menu_app = 101;
        }
      });

      // Cancel button click event to reset the view
      cancelButton.addEventListener("click", () => {
        // Remove confirm and cancel buttons
        confirmButton.remove();
        cancelButton.remove();

        // Restore the camera preview elements
        cameraDiv.style.backgroundImage = "";
        video.style.display = "block";
        cameraDiv.appendChild(select);
        cameraDiv.appendChild(captureButton);
      });
    });
  }

  async function travel_finish(travel_current) {
    if (after_trip.length > 0 || after_trip_optional.length > 0) {
      for (let x in after_trip) {
        for (let w in after_trip[x].fields) {
          if (
            (after_trip[x].fields[w].value == "" ||
              !after_trip[x].fields[w].value) &&
            after_trip[x].fields[w].required
          ) {
            saved_ok = true;
            saved_text = "Error, campos vacíos";
            saved_type = "alert-danger";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
            return;
          }
        }
      }
      // send after trip form
      socket_query.emit(
        "save_trip_form",
        travel_current.uuid,
        "after_trip",
        after_trip.concat(after_trip_optional),
        $driver_reports.custom ? $driver_reports.custom : [],
        (data) => {
          if (data == "ok") {
            console.log(after_trip.concat(after_trip_optional));
            after_trip = [];
            after_trip_optional = [];
            saved_ok = true;
            saved_text = "Formulario enviado";
            saved_type = "alert-success";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    }
    socket_query.emit("travel_finish_query", travel_current.id, (data) => {
      var offcanvas = bootstrap.Offcanvas.getInstance(
        document.getElementById("offcanvasFinishTrip")
      );
      offcanvas.hide();
      if (data == "ok") {
        saved_ok = true;
        saved_text = "Solitud de finalización enviada";
        saved_type = "alert-success";
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      } else if (data == "error1") {
        saved_ok = true;
        saved_text = "Error, Unidad sin viaje en proceso";
        saved_type = "alert-danger";
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  $: {
    if ($driver_reports.after_trip && after_trip.length == 0) {
      after_trip = JSON.parse(JSON.stringify($driver_reports.after_trip));
      for (let x in after_trip) {
        for (let w in after_trip[x].fields) {
          if (after_trip[x].fields[w].type == "select") {
            after_trip[x].fields[w].value = [
              after_trip[x].fields[w].options[0].value,
            ];
          }
          if (after_trip[x].fields[w].type == "checkbox") {
            after_trip[x].fields[w].value = [];
          }
        }
      }
    }
    if (
      $driver_reports.after_trip_optional &&
      after_trip_optional.length == 0
    ) {
      after_trip_optional = JSON.parse(
        JSON.stringify($driver_reports.after_trip_optional)
      );
      for (let x in after_trip_optional) {
        for (let w in after_trip_optional[x].fields) {
          if (after_trip_optional[x].fields[w].type == "select") {
            after_trip_optional[x].fields[w].value = [
              after_trip_optional[x].fields[w].options[0].value,
            ];
          }
          if (after_trip_optional[x].fields[w].type == "checkbox") {
            after_trip_optional[x].fields[w].value = [];
          }
        }
      }
    }
  }

  // GPS driver
  let driver_marker, watchId;
  if (navigator.geolocation) {
    // Observa la posición del usuario en tiempo real
    watchId = navigator.geolocation.watchPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        if ($driver_id != "") {
          // send position to server
          socket_query.emit("driver_position", $driver_id, {
            ...pos,
            status: 1,
          });
        }

        if (!map) return;

        // Si el marcador ya existe, actualiza su posición
        if (driver_marker) {
          driver_marker.setPosition(pos);
        } else {
          // Crea un nuevo marcador en la posición actual
          driver_marker = new google.maps.Marker({
            position: pos,
            map: map,
            title: "Mi posición",
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 8,
              fillColor: "#4285F4",
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "#FFFFFF",
              labelOrigin: new google.maps.Point(-3, -3),
            },
            label: {
              text: "GPS",
              color: "#212121",
              fontSize: "13px",
              fontWeight: "bold",
              className: "custom-label",
            },
          });
        }
      },
      (error) => {
        console.log("Error al obtener la geolocalización: ", error);
        switch (error.code) {
          case error.PERMISSION_DENIED:
            msg_queue_gps.push({ lat: 0, lng: 0, status: 0 });
            break;
          case error.POSITION_UNAVAILABLE:
            msg_queue_gps.push({ lat: 0, lng: 0, status: -1 });
            break;
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 0,
      }
    );
  } else {
    console.error("La geolocalización no es soportada por este navegador.");
  }
  let msg_queue_gps = [];
  $: {
    if ($driver_id != "" && msg_queue_gps.length > 0) {
      let msg_queue_gps_ = JSON.parse(JSON.stringify(msg_queue_gps));
      msg_queue_gps = [];
      for (let x in msg_queue_gps_) {
        socket_query.emit("driver_position", $driver_id, msg_queue_gps_[x]);
      }
    }
  }

  function setvar(v) {
    v = [];
  }

  let chat_list = [];
  let chat_setup = false;
  let chat_list_change = false;
  afterUpdate(() => {
    if (chat_list_change) {
      chat_list_change = false;
      setTimeout(() => {
        if (!chats_window) return;
        chats_window.scrollTop = chats_window.scrollHeight;
      }, 100);
    }
  });
  let chats_window;
  let months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  async function chat_control() {
    if (chat_setup) {
      chat_list_change = true;
      return;
    }
    //chats_window = document.getElementById("chat");
    let chat1 = await chats_db.getItem($driver_id);
    chat_list = chat1 != null ? JSON.parse(chat1)["chats"] : [];
    // incert date with type -1 every change of day
    let last_date = "";
    for (let i = 0; i < chat_list.length; i++) {
      let date = new Date(chat_list[i].date * 1000);
      let date_ =
        date.getDate().toString().padStart(2, "0") +
        " de " +
        months[date.getMonth()] +
        " de " +
        date.getFullYear();
      if (date_ != last_date) {
        chat_list.splice(i, 0, { type: -1, date: date_ }); // Insertar nueva entrada
        last_date = date_;
        i++; // Aumentar el índice para evitar re-procesar el elemento recién agregado
      }
    }
    chat_list = [...chat_list];
    chat_list_change = true;
    chat_setup = true;
    //chat.scrollTop = chat.scrollHeight;
  }
  socket_query.on("chats_", async function (data, data2) {
    if (!chat_setup) return;
    data2 = JSON.parse(data2);
    if (data != null) {
      if (data == $driver_id) {
        let last_date = "";
        if (chat_list.length > 0) {
          last_date = chat_list.filter((item) => item.type === -1).pop().date;
        }
        let date = new Date(data2.date * 1000);
        let date_ =
          date.getDate().toString().padStart(2, "0") +
          " de " +
          months[date.getMonth()] +
          " de " +
          date.getFullYear();
        if (date_ != last_date) {
          chat_list.push({ type: -1, date: date_ });
          last_date = date_;
        }

        chat_list.push(data2);
        chat_list = [...chat_list];
        chat_list_change = true;
      }
    }
  });

  let chat_to_send = "";
  async function send_chat() {
    let chat_to_send_ = chat_to_send;
    chat_to_send = "";
    if (chat_to_send_ != "") {
      socket_query.emit(
        "chat_send_driver",
        $driver_id,
        chat_to_send_,
        (data) => {
          if (data == "ok") {
            console.log("chat OK");
          }
        }
      );
    }
  }
  let isRecording = false;
  let mediaRecorder;
  let audioChunks = [];
  let audioStream;
  function run_record() {
    if (!mediaRecorder) {
      // Solicitar permisos para el micrófono al hacer clic
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioStream = stream;
          mediaRecorder = new MediaRecorder(stream);

          // Manejar datos de audio
          mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
          };

          // Cuando la grabación se detenga
          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
            audioChunks = [];

            audioStream.getTracks().forEach((track) => track.stop());

            // Leer el archivo como Base64
            const reader = new FileReader();
            reader.onload = () => {
              const base64Data = reader.result;
              const payload = {
                fileName: `audio-${Date.now()}.webm`, // Nombre único para el archivo
                fileType: "audio/webm",
                base64Data,
              };
              socket_query.emit(
                "chat_send_driver",
                $driver_id,
                base64Data,
                (data) => {
                  //chat_to_send="";
                  if (data == "ok") {
                    console.log("chat OK");
                  }
                }
              );
              console.log("Audio enviado:", payload);
            };
            reader.readAsDataURL(audioBlob);
            mediaRecorder = null;
            audioStream = null;
          };

          // Iniciar grabación al primer clic después de obtener permisos
          toggleRecording();
        })
        .catch((error) => {
          console.error("Error al acceder al micrófono:", error);
          alert(
            "No se pudo acceder al micrófono. Por favor, verifica los permisos."
          );
        });
    } else {
      toggleRecording();
    }
  }
  function toggleRecording() {
    if (!isRecording) {
      mediaRecorder.start();
      isRecording = true;
      console.log("Grabación iniciada");
    } else {
      mediaRecorder.stop();
      isRecording = false;
      console.log("Grabación detenida");
    }
  }
  function send_image(img) {
    socket_query.emit("chat_send_driver", $driver_id, img, (data) => {
      //chat_to_send="";
      if (data == "ok") {
        console.log("chat OK");
      }
    });
  }
</script>

{#if show_spinner}
  <div class="spinner-container" style="width: 100%; height:100%;">
    <div class="spinner"></div>
  </div>
{/if}

{#if alerta_off}
  <div class="alert-top alert alert-danger mb-0 text-center" role="alert">
    <strong>¡ALERTA Portal desconectado!</strong>
  </div>
{/if}

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<!-- Modal de Confirmación -->
<div
  class="modal fade"
  id="confirmPlayModal"
  tabindex="-1"
  aria-labelledby="confirmPlayModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmPlayModalLabel">Confirmar Acción</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        ¿Estás seguro de que deseas iniciar este viaje?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" on:click={confirm_play}>
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal de Confirmación para Cerrar Sesión -->
<div
  class="modal fade"
  id="confirmLogoutModal"
  tabindex="-1"
  aria-labelledby="confirmLogoutModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmLogoutModalLabel">
          Confirmar Cierre de Sesión
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">¿Estás seguro de que deseas cerrar sesión?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" on:click={confirm_logout}>
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Barra de título -->
<nav
  class="navbar navbar-expand-lg"
  style="background-color: #343a40; color: white; display:{show_spinner
    ? 'none'
    : menu_app === 0 && travel_on_going
      ? 'contents'
      : 'block'};"
>
  <div class="container-fluid" style="justify-content:space-between;">
    <div style="display: flex;">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasMenu"
        aria-controls="offcanvasMenu"
        style={menu_app === 0 && travel_on_going
          ? "position: fixed; top: 8px; left: 15px; z-index: 1; background-color: black;"
          : "display: block; color: #343a40; border: none; background-color: transparent;"}
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <a
        class="navbar-brand"
        href="/"
        style="color: white; display:{menu_app === 0 && travel_on_going
          ? 'none'
          : 'block'}"
      >
        <img
          src="img/logo_.png"
          alt="Logo"
          style="height: 25px; margin-right: 10px;"
        />
      </a>
    </div>
    <div></div>
  </div>
</nav>

<!-- Menú lateral -->
<div
  class="offcanvas offcanvas-start"
  tabindex="-1"
  id="offcanvasMenu"
  aria-labelledby="offcanvasMenuLabel"
  style="display:{show_spinner ? 'none' : 'block'};"
>
  <div class="offcanvas-header">
    <div style="display: flex; align-items:center;">
      <img
        src="img/logo2.png"
        alt="Logo"
        style="height: 35px; margin-right: 10px; box-sizing:content-box;vertical-align:baseline"
      />
      <strong>v{$portal_version}</strong>
    </div>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body" style="padding-top: 0;">
    <!-- Nombre del conductor con icono -->
    <div style="text-align:center; padding: 10px 0;">
      <h5 style="margin: 0;">
        <i
          class="material-icons"
          style="vertical-align: middle; color: rgba(0, 0, 0, 0.87);">person</i
        >
        {$driver_name} ({$driver_id.slice(-4)})
      </h5>
      <hr
        style="border-top: 1px solid #343a40; width: 80%; margin: 10px auto;"
      />
    </div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a
          class="nav-link {menu_app === 0 ? 'selected' : ''}"
          href="/"
          on:click|preventDefault={() => {
            change_menu_app(0);
          }}
        >
          <i class="material-icons">home</i> Viajes
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link {menu_app === 2 ? 'selected' : ''}"
          href="/"
          on:click|preventDefault={() => {
            change_menu_app(2);
          }}
        >
          <i class="material-icons"> chat </i> Chat
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link {menu_app === 1 ? 'selected' : ''}"
          href="/"
          on:click|preventDefault={() => {
            change_menu_app(1);
          }}
        >
          <i class="material-icons">description</i> Historial
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/" on:click|preventDefault={openLogoutModal}>
          <i class="material-icons">logout</i> Cerrar sesión
        </a>
      </li>
    </ul>
  </div>
</div>

<!-- Menu para finalizar viaje -->
<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasFinishTrip"
  aria-labelledby="offcanvasFinishTripLabel"
  style="font-size: 1rem;"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasFinishTripLabel">
      <i class="material-icons" style="color: #ff5722;font-size:30px;"
        >warning</i
      >
      Solicitar finalización
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div
    class="offcanvas-body"
    style="display: flex; flex-direction: column; justify-content: {after_trip.length ==
      0 && after_trip_optional.length == 0
      ? 'center'
      : 'start'}; align-items: {after_trip.length == 0 &&
    after_trip_optional.length == 0
      ? 'center'
      : 'normal'};"
  >
    {#if !end_trip_camera}
      {#each after_trip as fo}
        <div style="text-align:center; font-weight:700; margin-bottom:10px;">
          {fo.name}
        </div>
        {#each fo.fields as field}
          {#if field.type == "text" || field.type == "number" || field.type == "password" || field.type == "email" || field.type == "date" || field.type == "time" || field.type == "datetime-local" || field.type == "month" || field.type == "week" || field.type == "url" || field.type == "tel" || field.type == "search" || field.type == "color" || field.type == "signature"}
            <div
              class="form-group"
              style="padding-left:15px; padding-right:15px;"
            >
              <label for={field.id}
                >{field.label}
                <span style="color: red;">{field.required ? "*" : ""}</span
                ></label
              >
              <input
                type={field.type}
                class="form-control"
                id={field.id}
                placeholder={field.placeholder}
                value={field.value ? field.value : ""}
                on:input={(e) => (field.value = e.target.value)}
              />
            </div>
          {/if}
          {#if field.type == "textarea"}
            <div
              class="form-group"
              style="padding-left:10px; padding-right:10px;"
            >
              <label for={field.id}
                >{field.label}
                <span style="color: red;">{field.required ? "*" : ""}</span
                ></label
              >
              <textarea
                class="form-control"
                id={field.id}
                rows="3"
                placeholder={field.placeholder}
                value={field.value ? field.value : ""}
                on:input={(e) => (field.value = e.target.value)}
              ></textarea>
            </div>
          {/if}
          {#if field.type == "file"}
            <div
              class="form-group"
              style="padding-left:10px; padding-right:10px;"
            >
              <label for={field.id}
                >{field.label}
                <span style="color: red;">{field.required ? "*" : ""}</span>
              </label>
              <input
                type="file"
                class="form-control"
                id={field.id}
                placeholder={field.placeholder}
                value={field.value ? field.value : ""}
                on:input={(e) => (field.value = e.target.value)}
              />
            </div>
          {/if}
          {#if field.type == "button"}
            <div
              class="form-group"
              style="padding-left:10px; padding-right:10px;"
            >
              <button
                class="btn btn-primary"
                style="width: 100%;"
                on:click|preventDefault={() => form_functions(field, false)}
              >
                {field.label}
                {#if field.value}
                  <span class="text-success" style="color:rgb(10 243 135);"
                    ><i class="material-icons">check_circle</i></span
                  >
                {/if}
              </button>
            </div>
          {/if}
          {#if field.type == "radio"}
            <div
              class="form-group"
              style="padding-left:10px; padding-right:10px;"
            >
              <label for={field.id}
                >{field.label}
                <span style="color: red;">{field.required ? "*" : ""}</span
                ></label
              >
              <div class="form-check">
                {#each field.options as option}
                  <div style="margin-top: 10px;">
                    <input
                      type="radio"
                      style="transform: scale(1.2); vertical-align: bottom;"
                      value={option.value ? option.value[0] : ""}
                      checked={field.value
                        ? field.value[0] == option.value
                        : false}
                      on:change={(e) => (field.value = [e.target.value])}
                    />
                    <label class="form-check-label" for={option.id}>
                      {option.label}
                    </label>
                  </div>
                {/each}
              </div>
            </div>
          {/if}
          {#if field.type == "select"}
            <div
              class="form-group"
              style="padding-left:10px; padding-right:10px;"
            >
              <label for={field.id}
                >{field.label}
                <span style="color: red;">{field.required ? "*" : ""}</span
                ></label
              >
              <select
                class="form-select"
                id={field.id}
                value={field.value ? field.value[0] : ""}
                on:change={(e) => (field.value = [e.target.value])}
              >
                {#each field.options as option}
                  <option value={option.value}>{option.label}</option>
                {/each}
              </select>
            </div>
          {/if}
          {#if field.type == "checkbox"}
            <div
              class="form-group"
              style="padding-left:10px; padding-right:10px;"
            >
              <label for={field.id}
                >{field.label}
                <span style="color: red;">{field.required ? "*" : ""}</span
                ></label
              >
              <div class="form-check">
                {#each field.options as option}
                  <div style="margin-top: 10px;">
                    <input
                      type="checkbox"
                      style="transform: scale(1.2); vertical-align: bottom;"
                      value={option.value ? option.value : ""}
                      checked={field.value
                        ? field.value.includes(option.value)
                        : false}
                      on:change={(e) => {
                        if (e.target.checked) {
                          if (!field.value.includes(option.value)) {
                            field.value.push(option.value);
                          }
                        } else {
                          field.value = field.value.filter(
                            (v) => v != option.value
                          );
                        }
                      }}
                    />
                    <label class="form-check-label" for={option.id}>
                      {option.label}
                    </label>
                  </div>
                {/each}
              </div>
            </div>
          {/if}
        {/each}
        <!-- horizonal line -->
        <div
          style="width: 100%; display:flex; justify-content:center; margin: 10px auto; padding-left:10px; padding-right:10px;"
        >
          <div
            style="border-top: 1px solid rgb(52, 58, 64); width: 100%;"
          ></div>
        </div>
      {/each}
      {#if after_trip_optional.length > 0}
        <div
          class="accordion"
          id="accordionAfter_trip_optional"
          style="padding-left:15px; padding-right:15px;"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOneAfter_trip_optional">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOneAfter_trip_optional"
                aria-expanded="false"
                aria-controls="collapseOneAfter_trip_optional"
              >
                Formularios opcionales
              </button>
            </h2>
            <div
              id="collapseOneAfter_trip_optional"
              class="accordion-collapse collapse"
              aria-labelledby="headingOneAfter_trip_optional"
              data-bs-parent="#accordionAfter_trip_optional"
            >
              <div class="accordion-body">
                {#each after_trip_optional as fo}
                  <div
                    style="text-align:center; font-weight:700; margin-bottom:10px;"
                  >
                    {fo.name}
                  </div>
                  {#each fo.fields as field}
                    {#if field.type == "text" || field.type == "number" || field.type == "password" || field.type == "email" || field.type == "date" || field.type == "time" || field.type == "datetime-local" || field.type == "month" || field.type == "week" || field.type == "url" || field.type == "tel" || field.type == "search" || field.type == "color" || field.type == "signature"}
                      <div
                        class="form-group"
                        style="padding-left:15px; padding-right:15px;"
                      >
                        <label for={field.id}
                          >{field.label}
                          <span style="color: red;"
                            >{field.required ? "*" : ""}</span
                          ></label
                        >
                        <input
                          type={field.type}
                          class="form-control"
                          id={field.id}
                          placeholder={field.placeholder}
                          value={field.value ? field.value : ""}
                          on:input={(e) => (field.value = e.target.value)}
                        />
                      </div>
                    {/if}
                    {#if field.type == "textarea"}
                      <div
                        class="form-group"
                        style="padding-left:10px; padding-right:10px;"
                      >
                        <label for={field.id}
                          >{field.label}
                          <span style="color: red;"
                            >{field.required ? "*" : ""}</span
                          ></label
                        >
                        <textarea
                          class="form-control"
                          id={field.id}
                          rows="3"
                          placeholder={field.placeholder}
                          value={field.value ? field.value : ""}
                          on:input={(e) => (field.value = e.target.value)}
                        ></textarea>
                      </div>
                    {/if}
                    {#if field.type == "file"}
                      <div
                        class="form-group"
                        style="padding-left:10px; padding-right:10px;"
                      >
                        <label for={field.id}
                          >{field.label}
                          <span style="color: red;"
                            >{field.required ? "*" : ""}</span
                          ></label
                        >
                        <input
                          type="file"
                          class="form-control"
                          id={field.id}
                          placeholder={field.placeholder}
                          value={field.value ? field.value : ""}
                          on:input={(e) => (field.value = e.target.value)}
                        />
                      </div>
                    {/if}
                    {#if field.type == "button"}
                      <div
                        class="form-group"
                        style="padding-left:10px; padding-right:10px;"
                      >
                        <button
                          class="btn btn-primary"
                          style="width: 100%;"
                          on:click|preventDefault={() =>
                            form_functions(field, false)}
                        >
                          {field.label}
                          {#if field.value}
                            <span
                              class="text-success"
                              style="color:rgb(10 243 135);"
                              ><i class="material-icons">check_circle</i></span
                            >
                          {/if}
                        </button>
                      </div>
                    {/if}
                    {#if field.type == "radio"}
                      <div
                        class="form-group"
                        style="padding-left:10px; padding-right:10px;"
                      >
                        <label for={field.id}
                          >{field.label}
                          <span style="color: red;"
                            >{field.required ? "*" : ""}</span
                          ></label
                        >
                        <div class="form-check">
                          {#each field.options as option}
                            <div style="margin-top: 10px;">
                              <input
                                type="radio"
                                style="transform: scale(1.2); vertical-align: bottom;"
                                value={option.value ? option.value[0] : ""}
                                checked={field.value
                                  ? field.value[0] == option.value
                                  : false}
                                on:change={(e) =>
                                  (field.value = [e.target.value])}
                              />
                              <label class="form-check-label" for={option.id}>
                                {option.label}
                              </label>
                            </div>
                          {/each}
                        </div>
                      </div>
                    {/if}
                    {#if field.type == "select"}
                      <div
                        class="form-group"
                        style="padding-left:10px; padding-right:10px;"
                      >
                        <label for={field.id}
                          >{field.label}
                          <span style="color: red;"
                            >{field.required ? "*" : ""}</span
                          ></label
                        >
                        <select
                          class="form-select"
                          id={field.id}
                          value={field.value ? field.value[0] : ""}
                          on:change={(e) => (field.value = [e.target.value])}
                        >
                          {#each field.options as option}
                            <option value={option.value}>{option.label}</option>
                          {/each}
                        </select>
                      </div>
                    {/if}
                    {#if field.type == "checkbox"}
                      <div
                        class="form-group"
                        style="padding-left:10px; padding-right:10px;"
                      >
                        <label for={field.id}
                          >{field.label}
                          <span style="color: red;"
                            >{field.required ? "*" : ""}</span
                          ></label
                        >
                        <div class="form-check">
                          {#each field.options as option}
                            <div style="margin-top: 10px;">
                              <input
                                type="checkbox"
                                style="transform: scale(1.2); vertical-align: bottom;"
                                value={option.value ? option.value : ""}
                                checked={field.value
                                  ? field.value.includes(option.value)
                                  : false}
                                on:change={(e) => {
                                  if (e.target.checked) {
                                    if (!field.value.includes(option.value)) {
                                      field.value.push(option.value);
                                    }
                                  } else {
                                    field.value = field.value.filter(
                                      (v) => v != option.value
                                    );
                                  }
                                }}
                              />
                              <label class="form-check-label" for={option.id}>
                                {option.label}
                              </label>
                            </div>
                          {/each}
                        </div>
                      </div>
                    {/if}
                  {/each}
                  <!-- horizonal line -->
                  <div
                    style="width: 100%; display:flex; justify-content:center; margin: 10px auto; padding-left:10px; padding-right:10px;"
                  ></div>
                {/each}
              </div>
            </div>
          </div>
        </div>
      {/if}
      <p>
        <i class="material-icons" style="color: #f44336;">error_outline</i>
        {#if after_trip.length > 0 || after_trip_optional.length > 0}
          Por favor, complete los campos obligatorios para solicitar la
          finalización del viaje.
        {:else}
          ¿Estás seguro de que deseas solicitar la finalización del viaje
          actual?
        {/if}
      </p>
      <button
        class="btn btn-success w-100"
        style="display: flex; align-items: center; justify-content: center; font-size: 1.2rem;"
        on:click|preventDefault={() => {
          travel_finish(travel_current);
        }}
      >
        <i class="material-icons" style="margin-right: 8px;">check_circle</i>
        Enviar solicitud
      </button>
    {:else}
      <div
        id="camera"
        use:startCameraPreview
        style="width: 100%; height: 100%;"
      ></div>
    {/if}
  </div>
</div>

<!-- Menu para solicitar parada -->
<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasRequestStop"
  aria-labelledby="offcanvasRequestStopLabel"
  style="font-size: 1rem;"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRequestStopLabel">
      <i class="material-icons" style="color: #ff5722;font-size:30px;"
        >warning</i
      >
      Solicitar Parada
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div
    class="offcanvas-body"
    style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
  >
    <p>
      <i class="material-icons" style="color: #f44336;">error_outline</i>
      ¿Estás seguro de que deseas solicitar una parada en el viaje actual?
    </p>
    <button
      class="btn btn-warning w-100"
      style="display: flex; align-items: center; justify-content: center; font-size: 1.2rem;"
      on:click|preventDefault={() => {
        socket_query.emit("travel_stop_query", travel_current.id, (data) => {
          var offcanvas = bootstrap.Offcanvas.getInstance(
            document.getElementById("offcanvasRequestStop")
          );
          offcanvas.hide();
          if (data == "ok") {
            saved_ok = true;
            saved_text = "Solicitud de parada enviada";
            saved_type = "alert-success";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          } else if (data == "error1") {
            saved_ok = true;
            saved_text = "Error, Unidad sin viaje en proceso";
            saved_type = "alert-danger";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        });
      }}
    >
      <i class="material-icons" style="margin-right: 8px;">check_circle</i>
      Enviar solicitud
    </button>
  </div>
</div>

<!-- Audio -->
<audio id="audio" autoplay playsinline kind="audio"></audio>

<!-- Contenido principal -->
<div
  class="container"
  style="display:{show_spinner
    ? 'none'
    : 'flex'};height:100%;flex-direction:column;padding:0;max-width:none;overflow:{menu_app ===
    0 && travel_on_going
    ? 'hidden'
    : 'auto'};"
>
  {#if menu_app === 0 && !travel_on_going}
    <div
      style="text-align: center;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 10px;
      display:{$travel_crtl.length > 0 ? 'block' : 'none'};
    "
    >
      Viajes programados
    </div>
    {#if $travel_crtl.length > 0}
      {#each $travel_crtl as item, i (item.id + item.date_ini)}
        {#if item.oper == $driver_id && item.step == 0}
          <div
            class="card pt-0 mx-2 {prog_selected == item.uuid
              ? 'med-border border-info border-3'
              : ''}"
            style="font-size:1rem; margin-bottom: 10px;"
            id="prog_{item.id + item.date_ini}"
          >
            <div
              on:click={() => {
                if (item.id != "") {
                  if (
                    item.date_ini * 1000 - Date.now() <=
                    tiempo_viaje_futuro
                  ) {
                    prog_selected = item.uuid;
                  } else {
                    prog_selected = -1;
                  }
                }
              }}
              class="card-header b-0 {item.date_ini * 1000 - Date.now() >
              tiempo_viaje_futuro
                ? 'travel-no'
                : ''}"
            >
              <div class="d-flex">
                <div class="d-flex flex-column flex-grow-1">
                  <div>
                    <i
                      tooltip="Viaje"
                      class="material-icons md-90"
                      style="color:{item.date_ini * 1000 - Date.now() >
                      tiempo_viaje_futuro
                        ? '#888'
                        : '#26a69a'}">local_shipping</i
                    >
                    <span
                      >{typeof $nicks_list[item.id] != "undefined"
                        ? $nicks_list[item.id]
                        : item.id}</span
                    >
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <div>
                    <i
                      tooltip="Viaje"
                      class="material-icons md-90"
                      style="color:{item.date_ini * 1000 - Date.now() >
                      tiempo_viaje_futuro
                        ? '#888'
                        : Date.now() - item.date_ini * 1000 > toleracia
                          ? 'rgb(237 5 5)'
                          : '#26a69a'}">alarm</i
                    >
                    <span
                      style="color:{item.date_ini * 1000 - Date.now() >
                      tiempo_viaje_futuro
                        ? '#888'
                        : Date.now() - item.date_ini * 1000 > toleracia
                          ? 'rgb(237 5 5)'
                          : 'rgb(4 173 2)'}"
                      ><strong
                        >{moment(new Date(item.date_ini * 1000)).format(
                          "DD/MM/YY HH:mm"
                        )}</strong
                      >
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="d-flex"
                style="justify-content:flex-end;align-items:center;"
              >
                {#if Date.now() - item.date_ini * 1000 > toleracia}
                  <i
                    class="material-icons md-90"
                    style="color:#f00;margin-right:5px;">warning</i
                  >
                  <span style="color:#f00;"
                    >Retrasado {moment
                      .utc(Date.now() - item.date_ini * 1000)
                      .format(
                        (Date.now() - item.date_ini * 1000) / 1000 / 60 >
                          24 * 60
                          ? "D[d] H[h]"
                          : (Date.now() - item.date_ini * 1000) / 1000 / 60 > 60
                            ? "H[h] mm[m]"
                            : "mm[m]"
                      )}</span
                  >
                {:else if item.date_ini * 1000 - Date.now() > tiempo_viaje_futuro}
                  <i
                    class="material-icons md-90"
                    style="color:#888;margin-right:5px;"
                  >
                    schedule
                  </i>
                  <span style="color:#888;"
                    >Faltan {moment
                      .utc(item.date_ini * 1000 - Date.now())
                      .format(
                        (item.date_ini * 1000 - Date.now()) / 1000 / 60 >
                          24 * 60
                          ? "D[d] H[h]"
                          : (item.date_ini * 1000 - Date.now()) / 1000 / 60 > 60
                            ? "H[h] mm[m]"
                            : "mm[m]"
                      )}</span
                  >
                {/if}
              </div>
              <div class="d-flex">
                <div class="d-flex flex-column flex-grow-1">
                  <div>
                    <i
                      tooltip="Origen"
                      class="material-icons md-90"
                      style="color:{item.date_ini * 1000 - Date.now() >
                      tiempo_viaje_futuro
                        ? '#888'
                        : '#26a69a'}">play_circle_outline</i
                    >
                    <span
                      >{typeof $geos_class[item.org] != "undefined"
                        ? $geos_class[item.org][0]
                        : typeof $poli[item.org] != "undefined"
                          ? $poli[item.org][1]
                          : item.org}</span
                    >
                  </div>
                  <div>
                    <i
                      tooltip="Destino"
                      class="material-icons md-90"
                      style="color:{item.date_ini * 1000 - Date.now() >
                      tiempo_viaje_futuro
                        ? '#888'
                        : '#26a69a'}">stop</i
                    >
                    <span
                      >{typeof $geos_class[item.dest] != "undefined"
                        ? $geos_class[item.dest][0]
                        : typeof $poli[item.dest] != "undefined"
                          ? $poli[item.dest][1]
                          : item.dest}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/if}
      {/each}
    {:else}
      <div
        style="font-weight: bold; flex-grow:1; text-align:center; font-size:1.2rem;display:flex;flex-direction:column;justify-content:center;"
      >
        <div>
          <i class="material-icons md-85" style="color:#888;">
            local_shipping
          </i>
          <span>Sin viajes programados</span>
        </div>
      </div>
    {/if}
  {:else if menu_app === 0 && travel_on_going}
    <div style="flex-grow:1;">
      <div class="h-100" style="" id="map" use:initMap />
    </div>
    <div
      class="card pt-0 mx-2"
      style="font-size:1rem; margin-bottom: 0; margin-top:-14rem;"
    >
      <div class="card-header b-0">
        <div class="d-flex">
          <div class="d-flex flex-column flex-grow-1">
            <div>
              <i class="material-icons md-90" style="color:#26a69a;"
                >local_shipping</i
              >
              <span
                >{typeof $nicks_list[travel_current.id] != "undefined"
                  ? $nicks_list[travel_current.id]
                  : travel_current.id}</span
              >
            </div>
          </div>
          <div class="d-flex flex-column">
            <div>
              <i class="material-icons md-90" style="color:#26a69a;"
                >exit_to_app</i
              >
              <span style="color:#8bc34a"
                ><strong
                  >{moment(new Date(travel_current.date_ini * 1000)).format(
                    "DD/MM/YY HH:mm"
                  )}</strong
                ></span
              >
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex flex-column flex-grow-1">
            <div>
              <i class="material-icons md-90" style="color:#26a69a;">stop</i>
              <span
                >{typeof $geos_class[travel_current.dest] != "undefined"
                  ? $geos_class[travel_current.dest][0]
                  : typeof $poli[travel_current.dest] != "undefined"
                    ? $poli[travel_current.dest][1]
                    : travel_current.dest}</span
              >
            </div>
          </div>
        </div>
        {#if distance > 0 && duration > 0}
          <div class="d-flex">
            <div class="d-flex flex-column flex-grow-1">
              <div>
                <i class="material-icons md-90" style="color:#26a69a;">
                  place
                </i>
                <span>
                  {distance > 0
                    ? "Destino a " + (distance / 1000).toFixed(0) + " km"
                    : ""}
                </span>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div>
                <i class="material-icons md-90" style="color:#26a69a;">
                  hourglass_empty
                </i>
                <span>
                  {duration > 0
                    ? "ETA " +
                      moment
                        .utc(duration * 1000)
                        .format(
                          (duration * 1000) / 1000 / 60 > 24 * 60
                            ? "D[d] H[h]"
                            : (duration * 1000) / 1000 / 60 > 60
                              ? "H[h] mm[m]"
                              : "mm[m]"
                        )
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <!--ETA-->
          <div class="d-flex">
            <div class="d-flex flex-column flex-grow-1">
              <div>
                <i class="material-icons md-90" style="color:#26a69a;">
                  schedule
                </i>
                <!-- hora de llegaa -->
                <span>
                  {duration > 0
                    ? "Llegando " +
                      moment
                        .utc(Date.now() + duration * 1000)
                        .utcOffset("-06:00")
                        .format("DD/MM/YY HH:mm")
                    : ""}
                </span>
              </div>
            </div>
          </div>
        {/if}
      </div>
    </div>
    <!-- Action buttons to Request stop, emergency sos or finish trip -->
    <div class="sos-slider-button-container my-2 mx-2" style="z-index: 1;">
      <div class="sos-slider-button">
        <input
          type="range"
          id="sosSlider"
          class="sos-slider"
          min="0"
          max="100"
          value="0"
          on:input={handleSliderChange}
        />
        <button
          id="sosButton"
          class="btn btn-light btn-sos"
          disabled
          on:click|preventDefault={() => {
            socket_query.emit("travel_sos", travel_current.id, (data) => {
              if (data == "ok") {
                // Restart boton
                document.getElementById("sosSlider").value = 0;
                document.getElementById("sosButton").disabled = true;
                document.querySelector(".arrow-icon").style.display = "inline";
                saved_ok = true;
                saved_text = "Solicitud de emergencia enviada";
                saved_type = "alert-success";
                setTimeout(function () {
                  saved_ok = false;
                }, 3000);
              } else if (data == "error1") {
                saved_ok = true;
                saved_text = "Error, Unidad sin viaje en proceso";
                saved_type = "alert-danger";
                setTimeout(function () {
                  saved_ok = false;
                }, 3000);
              }
            });
          }}
        >
          <i
            class="material-icons arrow-icon"
            style="position: absolute; left:10px;">arrow_forward</i
          >
          <i class="material-icons">warning</i> SOS
        </button>
      </div>
    </div>
  {:else if menu_app === 1}
    <div
      style="text-align: center;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 10px;
    "
    >
      Historial de viajes
    </div>
  {:else if menu_app === 2}
    <div class="d-flex flex-column" style="height: 100%; line-height: 1.3;">
      {#if chat_camera == false}
        <div
          class="chat-container d-flex flex-column"
          style="overflow: auto; flex-grow: 1;"
          id="chat"
          use:chat_control
          bind:this={chats_window}
        >
          {#if false}
            <!-- Day Divider -->
            <div class="day-divider">01 de diciembre de 2024</div>

            <!-- Received Message -->
            <div class="chat-bubble received">
              Hola, ¿cómo estás?
              <div class="chat-time">10:32 AM</div>
            </div>

            <!-- Sent Message -->
            <div class="chat-bubble sent">
              ¡Hola! Estoy bien, gracias. ¿Y tú?
              <div class="chat-time">10:34 AM</div>
            </div>

            <!-- Image Message -->
            <div class="chat-bubble received image-bubble">
              <img
                src="https://via.placeholder.com/300x200"
                alt="Imagen ejemplo"
              />
              <div class="chat-time">10:35 AM</div>
            </div>

            <!-- Day Divider -->
            <div class="day-divider">02 de diciembre de 2024</div>

            <!-- Audio Message -->
            <div class="chat-bubble sent audio-bubble">
              <audio controls style="max-width: 100%;">
                <source
                  src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                  type="audio/mpeg"
                />
                Tu navegador no soporta reproducción de audio.
              </audio>
              <div class="chat-time">3:15 PM</div>
            </div>

            <!-- File Message -->
            <div class="chat-bubble received file-bubble">
              <div>Documento adjunto:</div>
              <a href="https://example.com/document.pdf" target="_blank"
                >documento.pdf</a
              >
              <div class="chat-time">3:20 PM</div>
            </div>

            <!-- Another Sent Message -->
            <div class="chat-bubble sent">
              Aquí tienes una foto, un audio y un archivo como ejemplos.
              <div class="chat-time">3:25 PM</div>
            </div>
          {/if}

          {#if chat_list.length == 0}
            <!-- No messages -->
            <div
              class="no-messages"
              style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;"
            >
              <div
                class="no-messages-text"
                style="color: white; font-size: 15px;"
              >
                No hay mensajes
              </div>
            </div>
          {/if}

          {#each chat_list as item, i}
            {#if item.type == -1}
              <!-- Day Divider -->
              <div class="day-divider">{item.date}</div>
            {/if}
            {#if item.type == 1}
              <!-- Received Message -->
              <div
                class="chat-bubble received {item.message.search(
                  'data:audio/'
                ) == 0
                  ? 'audio-bubble'
                  : item.message.search('data:application/pdf') == 0
                    ? 'file-bubble'
                    : ''}"
              >
                {#if item.message.search("data:image/") == 0}
                  <a href={item.message} download
                    ><img
                      src={item.message}
                      alt=""
                      style="max-width: 100%;"
                    /></a
                  >
                {:else if item.message.search("data:audio/") == 0}
                  <!-- Audio Message -->
                  <audio controls style="max-width: 100%;">
                    <source src={item.message} type="audio/webm" />
                    Tu navegador no soporta reproducción de audio.
                  </audio>
                {:else if item.message.search("data:application/pdf") == 0}
                  <!-- File Message -->
                  <a href={item.message} download>
                    <!-- icon PDF-->
                    <i class="material-icons">picture_as_pdf</i>
                    Documento PDF
                  </a>
                {:else}
                  {item.message}
                {/if}
                <div class="chat-time">
                  {moment(new Date(item.date * 1000)).format("hh:mm A")}
                </div>
              </div>
            {/if}
            {#if item.type == 0}
              <!-- Sent Message -->
              <div class="chat-bubble sent">
                {#if item.message.search("data:image/") == 0}
                  <a href={item.message} download
                    ><img
                      src={item.message}
                      alt=""
                      style="max-width: 100%;"
                    /></a
                  >
                {:else if item.message.search("data:audio/") == 0}
                  <!-- Audio Message -->
                  <audio controls style="max-width: 100%;">
                    <source src={item.message} type="audio/webm" />
                    Tu navegador no soporta reproducción de audio.
                  </audio>
                {:else if item.message.search("data:application/pdf") == 0}
                  <!-- File Message -->
                  <a href={item.message} download>
                    <!-- icon PDF-->
                    <i class="material-icons">picture_as_pdf</i>
                    Documento PDF
                  </a>
                {:else}
                  {item.message}
                {/if}
                <div class="chat-time">
                  {moment(new Date(item.date * 1000)).format("hh:mm A")}
                </div>
              </div>
            {/if}
            {#if item.type == 2}
              <!-- System message -->
              <div class="chat-bubble system">
                {item.message}
                <div class="chat-time">
                  {moment(new Date(item.date * 1000)).format("hh:mm A")}
                </div>
              </div>
            {/if}
          {/each}
        </div>
      {:else}
        <div id="camera" use:startCameraPreview></div>
      {/if}
      <!-- Chat Input -->
      <form on:submit|preventDefault={send_chat}>
        <div
          class="chat-container d-flex align-items-center p-2"
          style="background-color: #343a40;"
        >
          <input
            type="text"
            class="form-control chat-input"
            bind:value={chat_to_send}
            placeholder="Escribe un mensaje..."
          />
          <div class="d-flex">
            {#if chat_to_send.length > 0}
              <button class="btn btn-link" title="Enviar mensaje" type="submit">
                <span class="material-icons">send</span>
              </button>
            {:else}
              <!-- Camera buttom -->
              <button
                on:click|preventDefault={() => {
                  if (!chat_camera) {
                    current_field = {};
                    chat_camera = true;
                  } else {
                    current_field = null;
                    chat_camera = false;
                  }
                }}
                class="btn btn-link"
                type="button"
              >
                <i class="material-icons"
                  >{!chat_camera ? "camera_alt" : "stop"}</i
                >
              </button>
              {#if !chat_camera}
                <button
                  on:click|preventDefault={() => run_record()}
                  class="btn btn-link"
                  type="button"
                >
                  <!-- Audio icon -->
                  <i class="material-icons">{isRecording ? "stop" : "mic"}</i>
                </button>
              {/if}
            {/if}
          </div>
        </div>
      </form>
    </div>
  {:else if menu_app === 101}
    <div style="margin-top: 10px;"></div>
    {#each before_trip as fo}
      <div style="text-align:center; font-weight:700; margin-bottom:10px;">
        {fo.name}
      </div>
      {#each fo.fields as field}
        {#if field.type == "text" || field.type == "number" || field.type == "password" || field.type == "email" || field.type == "date" || field.type == "time" || field.type == "datetime-local" || field.type == "month" || field.type == "week" || field.type == "url" || field.type == "tel" || field.type == "search" || field.type == "color" || field.type == "signature"}
          <div
            class="form-group"
            style="padding-left:15px; padding-right:15px;"
          >
            <label for={field.id}
              >{field.label}
              <span style="color: red;">{field.required ? "*" : ""}</span
              ></label
            >
            <input
              type={field.type}
              class="form-control"
              id={field.id}
              placeholder={field.placeholder}
              value={field.value ? field.value : ""}
              on:input={(e) => (field.value = e.target.value)}
            />
          </div>
        {/if}
        {#if field.type == "textarea"}
          <div
            class="form-group"
            style="padding-left:10px; padding-right:10px;"
          >
            <label for={field.id}
              >{field.label}
              <span style="color: red;">{field.required ? "*" : ""}</span
              ></label
            >
            <textarea
              class="form-control"
              id={field.id}
              rows="3"
              placeholder={field.placeholder}
              value={field.value ? field.value : ""}
              on:input={(e) => (field.value = e.target.value)}
            ></textarea>
          </div>
        {/if}
        {#if field.type == "file"}
          <div
            class="form-group"
            style="padding-left:10px; padding-right:10px;"
          >
            <label for={field.id}
              >{field.label}
              <span style="color: red;">{field.required ? "*" : ""}</span>
            </label>
            <input
              type="file"
              class="form-control"
              id={field.id}
              placeholder={field.placeholder}
              value={field.value ? field.value : ""}
              on:input={(e) => (field.value = e.target.value)}
            />
          </div>
        {/if}
        {#if field.type == "button"}
          <div
            class="form-group"
            style="padding-left:10px; padding-right:10px;"
          >
            <button
              class="btn btn-primary"
              style="width: 100%;"
              on:click|preventDefault={() => form_functions(field)}
            >
              {field.label}
              {#if field.value}
                <span class="text-success" style="color:rgb(10 243 135);"
                  ><i class="material-icons">check_circle</i></span
                >
              {/if}
            </button>
          </div>
        {/if}
        {#if field.type == "radio"}
          <div
            class="form-group"
            style="padding-left:10px; padding-right:10px;"
          >
            <label for={field.id}
              >{field.label}
              <span style="color: red;">{field.required ? "*" : ""}</span
              ></label
            >
            <div class="form-check">
              {#each field.options as option}
                <div style="margin-top: 10px;">
                  <input
                    type="radio"
                    style="transform: scale(1.2); vertical-align: bottom;"
                    value={option.value ? option.value[0] : ""}
                    checked={field.value
                      ? field.value[0] == option.value
                      : false}
                    on:change={(e) => (field.value = [e.target.value])}
                  />
                  <label class="form-check-label" for={option.id}>
                    {option.label}
                  </label>
                </div>
              {/each}
            </div>
          </div>
        {/if}
        {#if field.type == "select"}
          <div
            class="form-group"
            style="padding-left:10px; padding-right:10px;"
          >
            <label for={field.id}
              >{field.label}
              <span style="color: red;">{field.required ? "*" : ""}</span
              ></label
            >
            <select
              class="form-select"
              id={field.id}
              value={field.value ? field.value[0] : ""}
              on:change={(e) => (field.value = [e.target.value])}
            >
              {#each field.options as option}
                <option value={option.value}>{option.label}</option>
              {/each}
            </select>
          </div>
        {/if}
        {#if field.type == "checkbox"}
          <div
            class="form-group"
            style="padding-left:10px; padding-right:10px;"
          >
            <label for={field.id}
              >{field.label}
              <span style="color: red;">{field.required ? "*" : ""}</span
              ></label
            >
            <div class="form-check">
              {#each field.options as option}
                <div style="margin-top: 10px;">
                  <input
                    type="checkbox"
                    style="transform: scale(1.2); vertical-align: bottom;"
                    value={option.value ? option.value : ""}
                    checked={field.value
                      ? field.value.includes(option.value)
                      : false}
                    on:change={(e) => {
                      if (e.target.checked) {
                        if (!field.value.includes(option.value)) {
                          field.value.push(option.value);
                        }
                      } else {
                        field.value = field.value.filter(
                          (v) => v != option.value
                        );
                      }
                    }}
                  />
                  <label class="form-check-label" for={option.id}>
                    {option.label}
                  </label>
                </div>
              {/each}
            </div>
          </div>
        {/if}
      {/each}
      <!-- horizonal line -->
      <div
        style="width: 100%; display:flex; justify-content:center; margin: 10px auto; padding-left:10px; padding-right:10px;"
      >
        <div style="border-top: 1px solid rgb(52, 58, 64); width: 100%;"></div>
      </div>
    {/each}
    {#if before_trip_optional.length > 0}
      <div
        class="accordion"
        id="accordionExample"
        style="padding-left:15px; padding-right:15px;"
      >
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Formularios opcionales
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              {#each before_trip_optional as fo}
                <div
                  style="text-align:center; font-weight:700; margin-bottom:10px;"
                >
                  {fo.name}
                </div>
                {#each fo.fields as field}
                  {#if field.type == "text" || field.type == "number" || field.type == "password" || field.type == "email" || field.type == "date" || field.type == "time" || field.type == "datetime-local" || field.type == "month" || field.type == "week" || field.type == "url" || field.type == "tel" || field.type == "search" || field.type == "color" || field.type == "signature"}
                    <div
                      class="form-group"
                      style="padding-left:15px; padding-right:15px;"
                    >
                      <label for={field.id}
                        >{field.label}
                        <span style="color: red;"
                          >{field.required ? "*" : ""}</span
                        ></label
                      >
                      <input
                        type={field.type}
                        class="form-control"
                        id={field.id}
                        placeholder={field.placeholder}
                        value={field.value ? field.value : ""}
                        on:input={(e) => (field.value = e.target.value)}
                      />
                    </div>
                  {/if}
                  {#if field.type == "textarea"}
                    <div
                      class="form-group"
                      style="padding-left:10px; padding-right:10px;"
                    >
                      <label for={field.id}
                        >{field.label}
                        <span style="color: red;"
                          >{field.required ? "*" : ""}</span
                        ></label
                      >
                      <textarea
                        class="form-control"
                        id={field.id}
                        rows="3"
                        placeholder={field.placeholder}
                        value={field.value ? field.value : ""}
                        on:input={(e) => (field.value = e.target.value)}
                      ></textarea>
                    </div>
                  {/if}
                  {#if field.type == "file"}
                    <div
                      class="form-group"
                      style="padding-left:10px; padding-right:10px;"
                    >
                      <label for={field.id}
                        >{field.label}
                        <span style="color: red;"
                          >{field.required ? "*" : ""}</span
                        ></label
                      >
                      <input
                        type="file"
                        class="form-control"
                        id={field.id}
                        placeholder={field.placeholder}
                        value={field.value ? field.value : ""}
                        on:input={(e) => (field.value = e.target.value)}
                      />
                    </div>
                  {/if}
                  {#if field.type == "button"}
                    <div
                      class="form-group"
                      style="padding-left:10px; padding-right:10px;"
                    >
                      <button
                        class="btn btn-primary"
                        style="width: 100%;"
                        on:click|preventDefault={() => form_functions(field)}
                      >
                        {field.label}
                        {#if field.value}
                          <span
                            class="text-success"
                            style="color:rgb(10 243 135);"
                            ><i class="material-icons">check_circle</i></span
                          >
                        {/if}
                      </button>
                    </div>
                  {/if}
                  {#if field.type == "radio"}
                    <div
                      class="form-group"
                      style="padding-left:10px; padding-right:10px;"
                    >
                      <label for={field.id}
                        >{field.label}
                        <span style="color: red;"
                          >{field.required ? "*" : ""}</span
                        ></label
                      >
                      <div class="form-check">
                        {#each field.options as option}
                          <div style="margin-top: 10px;">
                            <input
                              type="radio"
                              style="transform: scale(1.2); vertical-align: bottom;"
                              value={option.value ? option.value[0] : ""}
                              checked={field.value
                                ? field.value[0] == option.value
                                : false}
                              on:change={(e) =>
                                (field.value = [e.target.value])}
                            />
                            <label class="form-check-label" for={option.id}>
                              {option.label}
                            </label>
                          </div>
                        {/each}
                      </div>
                    </div>
                  {/if}
                  {#if field.type == "select"}
                    <div
                      class="form-group"
                      style="padding-left:10px; padding-right:10px;"
                    >
                      <label for={field.id}
                        >{field.label}
                        <span style="color: red;"
                          >{field.required ? "*" : ""}</span
                        ></label
                      >
                      <select
                        class="form-select"
                        id={field.id}
                        value={field.value ? field.value[0] : ""}
                        on:change={(e) => (field.value = [e.target.value])}
                      >
                        {#each field.options as option}
                          <option value={option.value}>{option.label}</option>
                        {/each}
                      </select>
                    </div>
                  {/if}
                  {#if field.type == "checkbox"}
                    <div
                      class="form-group"
                      style="padding-left:10px; padding-right:10px;"
                    >
                      <label for={field.id}
                        >{field.label}
                        <span style="color: red;"
                          >{field.required ? "*" : ""}</span
                        ></label
                      >
                      <div class="form-check">
                        {#each field.options as option}
                          <div style="margin-top: 10px;">
                            <input
                              type="checkbox"
                              style="transform: scale(1.2); vertical-align: bottom;"
                              value={option.value ? option.value : ""}
                              checked={field.value
                                ? field.value.includes(option.value)
                                : false}
                              on:change={(e) => {
                                if (e.target.checked) {
                                  if (!field.value.includes(option.value)) {
                                    field.value.push(option.value);
                                  }
                                } else {
                                  field.value = field.value.filter(
                                    (v) => v != option.value
                                  );
                                }
                              }}
                            />
                            <label class="form-check-label" for={option.id}>
                              {option.label}
                            </label>
                          </div>
                        {/each}
                      </div>
                    </div>
                  {/if}
                {/each}
                <!-- horizonal line -->
                <div
                  style="width: 100%; display:flex; justify-content:center; margin: 10px auto; padding-left:10px; padding-right:10px;"
                ></div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    {/if}
    <div style="margin-top: 80px;"></div>
  {:else if menu_app == 102}
    <div id="camera" use:startCameraPreview></div>
  {/if}
</div>

<!-- Botón flotante -->
{#if !travel_on_going}
  <button
    class="btn btn-floating"
    on:click|preventDefault={handle_add}
    style="position: fixed; bottom: 20px; right: 20px; border-radius: 50%; width: 56px; height: 56px; display: flex; justify-content: center; align-items: center; font-size: 24px;display:{show_spinner
      ? 'none'
      : menu_app == 0
        ? 'block'
        : 'none'};"
  >
    <i class="material-icons">add</i>
  </button>
{/if}

{#if prog_selected != -1 && !travel_on_going && (menu_app === 0 || menu_app === 101)}
  <button
    class="btn btn-floating"
    on:click|preventDefault={handle_play}
    disabled={!$driver_reports.id}
    style="position: fixed; bottom: 20px; left: 50%; transform: translateX(-50%); border-radius: 50%; width: 56px; height: 56px; display: flex; justify-content: center; align-items: center; font-size: 24px; background-color: red; color: white; z-index: 1000;display:{show_spinner
      ? 'none'
      : 'block'};"
  >
    <i class="material-icons">play_arrow</i>
  </button>
{/if}

{#if travel_on_going && menu_app === 0}
  {#if showLabelStop}
    <div class="floating-label" style="top: 23px; right: 79px;">
      Solicitar Parada
    </div>
    <div class="floating-label" style="top: 96px; right: 79px;">
      Finalizar Viaje
    </div>
  {/if}
  <!-- Botón flotante de Solicitar Parada -->
  <button
    class="btn btn-floating btn-request-stop"
    on:click|preventDefault={() => {
      var offcanvasElement = document.getElementById("offcanvasRequestStop");
      var offcanvas = new bootstrap.Offcanvas(offcanvasElement);
      offcanvas.show();
    }}
    style="position: fixed; top: 10px; right: 20px; border-radius: 50%; width: 56px; height: 56px; display: flex; justify-content: center; align-items: center; font-size: 24px;"
  >
    <i
      class="material-icons"
      style="font-size: 48px; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;"
      >pause_circle_filled</i
    >
  </button>
  <!-- Botón flotante de Finalizar Viaje -->
  <button
    class="btn btn-floating btn-finish-trip"
    on:click|preventDefault={() => {
      var offcanvasElement = document.getElementById("offcanvasFinishTrip");
      var offcanvas = new bootstrap.Offcanvas(offcanvasElement);
      offcanvas.show();
    }}
    style="position: fixed; top: 80px; right: 20px; border-radius: 50%; width: 56px; height: 56px; display: flex; justify-content: center; align-items: center; font-size: 24px;"
  >
    <i
      class="material-icons"
      style="font-size: 48px; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;"
      >check_circle</i
    >
  </button>
{/if}

<style>
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 20%;
    width: 60%;
    z-index: 1050;
  }
  .navbar-toggler {
    display: block !important;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .nav-link {
    font-size: 1.2em; /* Aumenta el tamaño del texto */
    color: rgba(0, 0, 0, 0.87);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center; /* Centra verticalmente el texto con el icono */
  }

  .nav-link i.material-icons {
    margin-right: 10px; /* Espacio entre el icono y el texto */
    font-size: 1.5em; /* Ajusta el tamaño del icono si es necesario */
  }

  .nav-link.selected {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Agrega la sombra */
    background-color: #e0e0e0; /* Fondo diferente para destacar el elemento seleccionado */
  }

  /* Contenedor del spinner */
  .spinner-container {
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Estilos del spinner */
  .spinner {
    width: 100px;
    height: 100px;
    border: 16px solid #8f8d8d;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  /* Animación del spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Estilos del botón flotante */
  .btn-floating {
    background-color: #28a745; /* Cambiado a verde */
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .btn-floating:hover {
    background-color: #218838; /* Verde oscuro al hacer hover */
  }

  /* Estilos de las tarjetas */
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1001;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  .travel-no::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(99, 99, 99, 0.212); /* Cuadro gris transparente */
    z-index: 2; /* Coloca el cuadro por encima del contenido */
    pointer-events: none; /* Permite la interacción con el contenido debajo */
    border-radius: 5px; /* Opcional: redondeo de las esquinas */
  }

  /* Estilos de los botones */
  .btn-light {
    border: 1px solid #ced4da;
    border-radius: 50px;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:
      background-color 0.3s,
      box-shadow 0.3s;
  }

  .btn-light i.material-icons {
    margin-right: 8px;
    font-size: 1.5rem;
  }

  .btn-request-stop {
    background-color: #f8f9fa;
  }

  .btn-request-stop:hover {
    background-color: #e2e6ea;
  }

  .btn-request-stop i {
    color: #ffc107; /* Icono amarillo para solicitar parada */
  }

  /* sos part */
  .sos-slider-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sos-slider-button {
    position: relative;
    width: 100%; /* Ancho total del botón con el slider */
    height: 40px; /* Ajusta según el tamaño del botón */
  }

  .sos-slider {
    position: absolute;
    width: 100%;
    height: 40px; /* Ajusta según el tamaño del botón */
    opacity: 0; /* Oculta el deslizador pero sigue siendo funcional */
    z-index: 1; /* Asegura que esté encima del botón */
    cursor: pointer;
  }

  .btn-sos {
    background-color: #ff0000;
    color: white;
    width: 100%;
    height: 40px;
    border-radius: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
  }

  .btn-sos:disabled {
    background-color: rgb(243 40 40);
  }

  .btn-sos.enabled {
    cursor: pointer;
    z-index: 2;
  }

  .sos-slider-button button {
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 50px;
    position: relative;
  }

  .btn-sos i {
    margin-right: 8px;
  }

  .arrow-icon {
    margin-right: 10px;
    font-size: 20px;
    color: white;
  }

  .sos-slider:hover + .btn-sos {
    background-color: #ff0000;
  }

  /* finish trip part */

  .btn-finish-trip {
    background-color: #e6ffe6;
  }

  .btn-finish-trip:hover {
    background-color: #ccffcc;
  }

  /* Estilos flotantes */
  .btn-floating {
    background-color: #343a40; /* Color oscuro para el fondo */
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .btn-floating:hover {
    background-color: #212529; /* Fondo aún más oscuro en hover */
  }

  .btn-request-stop i {
    color: #ffc107; /* Icono amarillo para solicitar parada */
  }

  .btn-finish-trip i {
    color: #28a745; /* Icono verde para finalizar viaje */
  }

  .floating-label {
    position: fixed;
    background-color: white;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 0.9rem;
    z-index: 1001;
  }

  /* Estilos de chat */
  .chat-container {
    padding: 0rem 1rem 1rem 1rem;
    background-color: #3f474e;
  }
  .chat-bubble {
    border-radius: 15px;
    padding: 10px 15px;
    margin: 5px 0;
    max-width: 80%;
    display: inline-block;
  }
  .chat-bubble.sent {
    background-color: #0d6efd;
    color: #fff;
    align-self: flex-end;
  }
  .chat-bubble.received {
    background-color: #333;
    color: #fff;
    align-self: flex-start;
  }
  .chat-bubble.system {
    background-color: #379543;
    color: #fff;
    align-self: center;
    border: dashed 1px #40ff02;
  }
  .chat-time {
    margin-top: 5px;
    font-size: 0.8rem;
    color: #bbb;
    text-align: right;
  }
  .day-divider {
    text-align: center;
    color: #bbb;
    font-size: 0.9rem;
    margin: 15px 0;
    position: relative;
  }
  .day-divider::before,
  .day-divider::after {
    content: "";
    display: inline-block;
    width: 10%;
    height: 1px;
    background: #a9aaab;
    position: relative;
    vertical-align: middle;
  }
  .day-divider::before {
    margin-right: 10px;
  }
  .day-divider::after {
    margin-left: 10px;
  }
  .chat-input {
    border: none;
    border-radius: 25px;
    padding: 10px 15px;
    color: #000000;
  }
  .chat-input:focus {
    outline: none;
    border-color: #0d6efd;
    box-shadow: 0 0 5px #0d6efd;
  }
  .image-bubble img {
    border-radius: 10px;
    max-width: 100%;
  }
  .audio-bubble {
    background-color: #444;
    padding: 10px;
    border-radius: 15px;
  }
  .file-bubble {
    background-color: #444;
    padding: 10px;
    border-radius: 15px;
    color: #0d6efd;
  }
  .file-bubble a {
    color: inherit;
    text-decoration: none;
  }
  .file-bubble a:hover {
    text-decoration: underline;
  }
</style>
