<script>
  export let routes_db, socket_query, units_travel_db;
  import { onMount, onDestroy, tick, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    geos_class_list,
    route_list,
    route_list_d,
    travel_crtl,
    geos_joined,
    geos_class_join,
    internal_com,
    map_ready,
    monitor_extra_map,
    recognition,
    unit_groups,
    geos_full,
    poli_list,
    poli,
    poli_full,
    geos_map,
    cc_monitor,
    speaking_list,
  } from "./stores.js";
  import { dataset_dev } from "svelte/internal";

  const bc = new BroadcastChannel("map_external");
  const bc_route = new BroadcastChannel("map_external_route");
  const bc_coordenadas = new BroadcastChannel("map_external_coordenadas");

  let mod_on = {};
  var observer = new IntersectionObserver(function (entry) {
    for (let x in entry) {
      if (entry[x].isIntersecting)
        mod_on[entry[x].target.id.split("_")[1]] = true;
      else delete mod_on[entry[x].target.id.split("_")[1]];
    }
    //monitor2();
  });
  function monitor_v(e) {
    update_filters = true;
    observer.observe(e);
  }

  let map_cover = false;
  function CenterControl(controlDiv, map) {
    var controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click para ver mapa de cobertura";
    controlDiv.appendChild(controlUI);

    var controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "12px";
    controlText.style.lineHeight = "20px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.style["min-width"] = "104px";
    controlText.innerHTML = map_cover ? "Ocultar cobertura" : "Ver cobetura";
    controlUI.appendChild(controlText);

    var controlUI2 = document.createElement("div");
    controlUI2.style.backgroundColor = "#fff";
    controlUI2.style.border = "2px solid #fff";
    controlUI2.style.borderRadius = "3px";
    controlUI2.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI2.style.cursor = "pointer";
    controlUI2.style.marginBottom = "22px";
    controlUI2.style.textAlign = "center";
    controlUI2.title = "Click para ver mapa de trafico";
    controlDiv.appendChild(controlUI2);

    var controlText2 = document.createElement("div");
    controlText2.style.color = "rgb(25,25,25)";
    controlText2.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText2.style.fontSize = "12px";
    controlText2.style.lineHeight = "20px";
    controlText2.style.paddingLeft = "5px";
    controlText2.style.paddingRight = "5px";
    controlText2.style["min-width"] = "104px";
    controlText2.innerHTML = map_traffic ? "Ocultar trafico" : "Ver trafico";
    controlUI2.appendChild(controlText2);

    controlUI.addEventListener("click", function () {
      map_cover = !map_cover;
      mapa_cobertura(map_cover);
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].pop();
      var centerControlDiv = document.createElement("div");
      var centerControl = new CenterControl(centerControlDiv, map);
      centerControlDiv.index = 1;
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
        centerControlDiv
      );
    });

    controlUI2.addEventListener("click", function () {
      map_traffic = !map_traffic;

      if (map_traffic) trafficLayer.setMap(map);
      else trafficLayer.setMap(null);
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].pop();
      var centerControlDiv = document.createElement("div");
      var centerControl = new CenterControl(centerControlDiv, map);
      centerControlDiv.index = 1;
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
        centerControlDiv
      );
    });
  }

  let trafficLayer;
  let map_traffic = false;
  let map;
  let on_map = false;
  let mount_complete = false;
  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.addListener("mouseover", function () {
      on_map = true;
    });
    map.addListener("mouseout", function () {
      on_map = false;
    });
    map.fitBounds(bounds);

    trafficLayer = new google.maps.TrafficLayer();
    var centerControlDiv = document.createElement("div");
    var centerControl = new CenterControl(centerControlDiv, map);
    centerControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      centerControlDiv
    );

    geocoder = new google.maps.Geocoder();
  }

  function mapa_cobertura(type) {
    if (type) {
      var tech = "umts";
      var layers = [tech + "_garantizada"]; //tech + '_no_garantizada',
      for (let x in layers) {
        var layer = new google.maps.ImageMapType({
          getTileUrl: function getTileUrl(coord, zoom) {
            var url = "https://lbsva.telcel.com/tiles/gwc/service/wmts?";
            url += "layer=telcel:" + layers[x];
            url += "&style=";
            url += "&tilematrixset=EPSG:900913";
            url += "&Service=WMTS";
            url += "&Request=GetTile";
            url += "&Version=1.0.0";
            url += "&Format=image/png";
            url += "&TileMatrix=EPSG:900913:" + zoom;
            url += "&TileCol=" + coord.x;
            url += "&TileRow=" + coord.y;
            return url;
          },
          tileSize: new google.maps.Size(256, 256),
          isPng: true,
        });
        map.overlayMapTypes.push(layer);
      }
    } else {
      map.overlayMapTypes.clear();
    }
  }

  onMount(() => {
    mount_complete = true;
  });
  $: {
    if ($map_ready == 1 && mount_complete) {
      initMap();
      console.log("mapa cargado");
    }
  }

  let monitor_;
  onDestroy(() => {
    clearInterval(monitor_);
    socket_query.off("rtc_offer");
    socket_query.off("rtc_candidate");
    socket_query.off("driver_position");
  });

  let units_fw = [
    { type: "geo", name: "Agencia Corona La Alborada", id: "4864071059" },
    { type: "unit", id: "4864071059" },
    { type: "unit", id: "4864071059" },
    { type: "geo", name: "Agencia Corona Tamaulipas" },
    { type: "unit", id: "4864071059" },
    { type: "unit", id: "4864071059" },
    { type: "geo", name: "Agencia Corona La Alborada" },
    { type: "unit", id: "4864071059" },
    { type: "unit", id: "4864071059" },
    { type: "geo", name: "Agencia Corona Tamaulipas" },
    { type: "unit", id: "4864071059" },
    { type: "unit", id: "4864071059" },
  ];

  let units_ufw = [
    { type: "unit", nick: "TZ-419", id: "4864071059" },
    { type: "unit", nick: "TZ-210", id: "4864071059" },
    { type: "unit", nick: "TZ-200", id: "4864071059" },
    { type: "unit", nick: "TZ-100", id: "4864071059" },
    { type: "unit", nick: "TZ-419", id: "4864071059" },
    { type: "unit", nick: "TZ-210", id: "4864071059" },
    { type: "unit", nick: "TZ-200", id: "4864071059" },
    { type: "unit", nick: "TZ-100", id: "4864071059" },
  ];

  let travels_ufw = [
    {
      nick: "TZ-111",
      state: "OK",
      ign: 1,
      ruta: {
        name: "Ruta Corona La Alborada a Corona Tamaulipas",
        id: "4923043",
      },
      org: {
        name: "Agencia Corona La Alborada",
        id: "385434",
        time: "19/02/20 7:22",
      },
      dest: { name: "Agencia Corona Tamaulipas", id: "385434" },
      gps: { state: "OK", level: 0.8 },
      gsm: { state: "OK", level: 0.8, last: 38 },
      mov: { speed: 54, time_stop: 0 },
    },
  ];

  function time_code_s_(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("m[min]");
    } else if (time < 60 * 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("m[min]");
    } else if (time < 60 * 60 * 24) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("H[h] m[min]");
    } else if (time < 60 * 60 * 24 * 90) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("D[d] H[h] m[min]");
    } else {
      return "+3 meses";
    }
  }
  function time_code_s(time) {
    //if(time<60){return moment.utc().subtract(1, 'years').startOf('years').add({ seconds: time }).format('s[s]')} // 's[s]'
    if (time < 60) {
      return "0m";
    } else if (time < 3600) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("m[min]");
    } else if (time < 86400) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("H[h] m[min]");
    } else if (time < 7776000) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("D[d] H[h] m[min]");
    } else {
      return "+3 meses";
    }
  }
  function time_code(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("m[min]");
    } else if (time < 60 * 24) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("H[h] m[min]");
    } else {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("D[d] H[h] m[min]");
    }
  }

  units_ufw = [];
  travels_ufw = [];
  let travels_fw = [];
  let travels_fz = {};
  let travels_fh = [];
  units_fw = [];
  let units_fw_p = {};
  let geo_dic = {};
  let travels_fw_master = {};
  let paginas_l = [];
  monitor_ = setInterval(monitor2, 1000);
  $: {
    $nicks_list;
    let travel_keys = $units_list;
    let add_new = false;
    for (let x in travel_keys) {
      let t_found = false;
      let mobid = travel_keys[x];
      for (let y in travels_fh) {
        if (travels_fh[y]["id"] == mobid) {
          t_found = true;
        }
      }
      if (!t_found) {
        travels_fh.push({ id: mobid });
        travels_fz[mobid] = {
          mov: {},
          dest: {},
          ruta: {},
          gsm: { last: 0 },
          gps: {},
          pos: {},
          org: { time: "", name: "Desconocido" },
          geo_stay: [],
          alerts_list: [],
          state: "OK",
          monitor_crtl: { follow: 0 },
          oper: "Desconocido",
          alerts_now: 0,
          outputs: null,
        };
        add_new = true;
      }
    }
    if (add_new) {
      for (let w in travels_fh) {
        travels_fw_master[travels_fh[w].id] = w;
      }
    }
    travels_fh.sort(travels_order);
    let travels_fh_c = 0;
    let paginas_c = 0;
    paginas_l = [];
    for (let x in travels_fh) {
      if (travels_fh_c < 100) {
        travels_fh[x]["page"] = 0;
      } else if (travels_fh_c < 200) {
        travels_fh[x]["page"] = 1;
      } else if (travels_fh_c < 300) {
        travels_fh[x]["page"] = 2;
      } else if (travels_fh_c < 400) {
        travels_fh[x]["page"] = 3;
      } else if (travels_fh_c < 500) {
        travels_fh[x]["page"] = 4;
      } else if (travels_fh_c < 600) {
        travels_fh[x]["page"] = 5;
      } else if (travels_fh_c < 700) {
        travels_fh[x]["page"] = 6;
      } else if (travels_fh_c < 800) {
        travels_fh[x]["page"] = 7;
      } else if (travels_fh_c < 900) {
        travels_fh[x]["page"] = 8;
      } else if (travels_fh_c < 1000) {
        travels_fh[x]["page"] = 9;
      }
      travels_fh_c++;
    }
    paginas_c = Math.ceil(travels_fh_c / 100);
    if (paginas_c > 10) paginas_c = 10;
    for (let x = 0; x < paginas_c; x++) {
      paginas_l.push(x);
    }
  }

  function monitor2() {
    let live_ = $live;
    let travels_ = $travels;
    let travel_keys = Object.keys(travels_);
    let now_ = Math.round(new Date().getTime() / 1000);
    for (let x in travel_keys) {
      let mobid = travel_keys[x];
      let time_stop = 0;
      if (
        typeof travels_[mobid] != "undefined" &&
        typeof travels_fz[mobid] != "undefined"
      ) {
        if (true) {
          //(mod_on[mobid]||typeof travels_fz[mobid]["ok"]=="undefined")
          if (
            travels_[mobid]["travel"][travels_[mobid]["travel"].length - 1][
              "ls"
            ] == 1 ||
            travels_[mobid]["travel"].length > 1
          ) {
            if (
              travels_[mobid]["travel"][travels_[mobid]["travel"].length - 1][
                "ls"
              ] == 1
            ) {
              time_stop =
                travels_[mobid]["travel"][travels_[mobid]["travel"].length - 1][
                  "p"
                ][
                  travels_[mobid]["travel"][
                    travels_[mobid]["travel"].length - 1
                  ]["p"].length - 1
                ];
              time_stop =
                time_stop.length > 4 ? time_stop[4] - time_stop[3] : 0;
            } else {
              let now = Math.round(new Date().getTime() / 1000);
              time_stop =
                travels_[mobid]["travel"][travels_[mobid]["travel"].length - 2][
                  "p"
                ][
                  travels_[mobid]["travel"][
                    travels_[mobid]["travel"].length - 2
                  ]["p"].length - 1
                ];
              time_stop =
                time_stop.length > 4
                  ? time_stop[4] * 60 +
                    travels_[mobid]["travel"][
                      travels_[mobid]["travel"].length - 2
                    ]["ds"]
                  : time_stop[3] * 60 +
                    travels_[mobid]["travel"][
                      travels_[mobid]["travel"].length - 2
                    ]["ds"];
              time_stop = Math.round((now - time_stop) / 60);
            }

            if (travels_fz[mobid].org.name == "Desconocido") {
              let lat_org_ = travels_[mobid]["travel"][0]["ps"][0] / 10000;
              let lng_org_ = travels_[mobid]["travel"][0]["ps"][1] / 10000;
              let geo_org_ = geo_search(lat_org_, lng_org_);
              travels_fz[mobid].org.name = geo_org_[0];
              let org_time_ = moment(
                new Date(travels_[mobid]["travel"][0]["ds"] * 1000)
              ).format("DD/MM/YY HH:mm");
              travels_fz[mobid].org.time = org_time_;
            }
          }
          travels_fz[mobid]["alerts_now"] =
            typeof travels_[mobid]["alerts_now"] != "undefined"
              ? travels_[mobid]["alerts_now"]
              : travels_fz[mobid]["alerts_now"];
          travels_fz[mobid]["proto_status"] =
            typeof travels_[mobid]["proto_status"] != "undefined"
              ? travels_[mobid]["proto_status"]
              : "";
          travels_fz[mobid].mov.time_stop = time_stop;
          travels_fz[mobid]["monitor_crtl"] = travels_[mobid]["monitor_crtl"];
          travels_fz[mobid]["oper"] =
            typeof travels_[mobid]["monitor_crtl"]["driver"] == "undefined"
              ? "Desconocido"
              : travels_[mobid]["monitor_crtl"]["driver"] == ""
                ? "Desconocido"
                : travels_[mobid]["monitor_crtl"]["driver"];
          travels_fz[mobid]["dest"]["name"] =
            typeof travels_[mobid]["monitor_crtl"]["destino"] == "undefined"
              ? "Desconocido"
              : travels_[mobid]["monitor_crtl"]["destino"] == ""
                ? "Desconocido"
                : travels_[mobid]["monitor_crtl"]["destino"];
          travels_fz[mobid]["ruta"]["name"] =
            typeof travels_[mobid]["monitor_crtl"]["ruta"] == "undefined"
              ? "Desconocida"
              : travels_[mobid]["monitor_crtl"]["ruta"] == ""
                ? "Desconocida"
                : travels_[mobid]["monitor_crtl"]["ruta"];
          travels_fz[mobid]["alerts_list"] =
            typeof travels_[mobid]["alerts_list"] == "undefined"
              ? []
              : travels_[mobid]["alerts_list"];

          let live_mob = live_[mobid];
          if (
            typeof live_mob != "undefined" &&
            (typeof mod_on[mobid] != "undefined" ||
              typeof travels_fz[mobid]["ok"] == "undefined")
          ) {
            //()
            let time_last = now_ - live_mob["last_comm"];
            travels_fz[mobid].gsm.last = time_last < 300 ? true : false;
            travels_fz[mobid].gsm.text = time_code_s(time_last); //time_code_s
            travels_fz[mobid].mov.speed = live_mob["vel"];
            travels_fz[mobid].gsm.level = live_mob["rssi"];
            travels_fz[mobid].gps.level = live_mob["hdop"];
            travels_fz[mobid].gsm.net = live_mob["network"];
            travels_fz[mobid].ign = live_mob["ingnition"];
            travels_fz[mobid].outputs = live_mob["outputs_0"]
              ? live_mob["outputs_0"]
              : null;

            if (
              travels_fz[mobid].pos.lat != live_mob["lat"] &&
              travels_fz[mobid].pos.lon != live_mob["lon"]
            ) {
              travels_fz[mobid].pos.lat = live_mob["lat"];
              travels_fz[mobid].pos.lon = live_mob["lon"];
              let geo_stay_ = geo_search(live_mob["lat"], live_mob["lon"]);
              travels_fz[mobid].geo_stay =
                geo_stay_[0] != "Geocerca_automatica_"
                  ? geo_stay_[1] == 1 && last_geocode == mobid
                    ? travels_fz[mobid].geo_stay
                    : geo_stay_
                  : last_geocode != mobid
                    ? ["", 1, ""]
                    : travels_fz[mobid].geo_stay;
            }
            travels_fz[mobid]["ok"] = "ok";
          }
        }
      }
    }
  }
  let total_alerts = 0;
  let total_follow = 0;
  let total_wating = 0;
  $: {
    let total_alerts_ = 0,
      total_follow_ = 0,
      total_wating_ = 0;
    for (let x in travels_fz) {
      if (travels_fz[x].alerts_now > 0) total_alerts_++;
      if (travels_fz[x].monitor_crtl.follow == 1) total_follow_++;
      if (
        travels_fz[x].proto_status != "" &&
        typeof travels_fz[x].proto_status != "undefined"
      ) {
        total_wating_++;
      }
    }
    total_alerts = total_alerts_;
    total_follow = total_follow_;
    total_wating = total_wating_;
  }

  function dentro(point, vs) {
    let x = point[0],
      y = point[1];
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1];
      let xj = vs[j][0],
        yj = vs[j][1];
      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }

  function geo_search(lat, lng) {
    let box_size = 0.0001797 * 10; // 200 metros por celula
    let p = Math.floor((lat - 14.5408) / box_size);
    let q = Math.floor((lng + 118.2689) / box_size);
    let key_close_list = [
      p + "_" + q,
      p + "_" + (q + 1),
      p + "_" + (q - 1),
      p + 1 + "_" + q,
      p - 1 + "_" + q,
      p + 1 + "_" + (q + 1),
      p - 1 + "_" + (q - 1),
      p + 1 + "_" + (q - 1),
      p - 1 + "_" + (q + 1),
    ];
    let geos_arround = [];
    for (let w in key_close_list) {
      if ($geos_map[key_close_list[w]]) {
        for (let s in $geos_map[key_close_list[w]]) {
          if (!geos_arround.includes($geos_map[key_close_list[w]][s])) {
            geos_arround.push($geos_map[key_close_list[w]][s]);
          }
        }
      }
    }
    let found = false;
    for (let x in $poli) {
      if (geos_arround.includes(x)) {
        if (dentro([lat, lng], $poli[x][0])) {
          return [$poli[x][1], 2, x];
        }
      }
    }
    for (let x in $geos) {
      // filtro
      if (geos_arround.includes($geos[x][0])) {
        for (let y in $geos[x][1]) {
          let lat_ = $geos[x][1][y][0];
          let lng_ = $geos[x][1][y][1];
          if (get_km(lat, lng, lat_, lng_) <= 0.13) {
            if (typeof $geos_class[$geos[x][0]] != "undefined") {
              return [
                $geos_class[$geos[x][0]][0],
                $geos_class[$geos[x][0]][1],
                $geos[x][0],
              ];
            } else {
              if (typeof $geos_joined[$geos[x][0]] == "undefined") {
                return [
                  "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                  1,
                  $geos[x][0],
                ];
              } else {
                for (let h in $geos_joined[$geos[x][0]]) {
                  if (
                    typeof $geos_class[$geos_joined[$geos[x][0]][h]] !=
                    "undefined"
                  ) {
                    return [
                      $geos_class[$geos_joined[$geos[x][0]][h]][0],
                      $geos_class[$geos_joined[$geos[x][0]][h]][1],
                      $geos_joined[$geos[x][0]][h],
                    ];
                  }
                }
                return [
                  "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                  1,
                  $geos[x][0],
                ];
              }
            }
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function travels_order(a, b) {
    if ($nicks_list[b.id] < $nicks_list[a.id]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  let show_drivers = -1;
  let show_destinos = -1;
  let show_rutas = -1;
  let driver_selected;
  let destino_selected;
  let ruta_selected;
  function drivers_edit(i) {
    show_drivers = i;
  }
  function destino_edit(i) {
    show_destinos = i;
  }
  function ruta_edit(i) {
    show_rutas = i;
  }
  function save_driver(unit, driver_id) {
    console.log(unit, driver_id);
    socket_query.emit("driver_save", unit, driver_id, (data) => {
      if (data == "ok") {
        show_drivers = -1;
      }
    });
  }
  function save_destino(unit, destino_id) {
    console.log(unit, destino_id);
    socket_query.emit("destino_save", unit, destino_id, (data) => {
      if (data == "ok") {
        show_destinos = -1;
      }
    });
  }
  function save_ruta(unit, ruta_id) {
    console.log(unit, ruta_id);
    socket_query.emit("ruta_save", unit, ruta_id, (data) => {
      if (data == "ok") {
        show_rutas = -1;
      }
    });
  }
  let m_inicio,
    m_fin,
    recorrido = [],
    recorrido_ruta,
    m_inicio_ruta,
    m_fin_ruta;
  let l_ruta_estimada;
  let selected = -1;
  let selected_ = -1;
  let live_ = {};
  $: {
    if (selected != -1) {
      if (JSON.stringify($live[selected]) != JSON.stringify(live_)) {
        selected_ = selected;
        maping(selected, {});
        geo_loc();
      }
    }
  }
  var circles = [];
  let poligons = [];
  let task_move;
  let task_move_p = 0;
  function move_mark(m_fin, ini, end) {
    task_move_p = 0;
    try {
      clearInterval(task_move);
    } catch (e) {}
    task_move = setInterval(() => {
      if (typeof m_fin != "undefined") {
        m_fin.setPosition({
          lat: ini.lat + (end.lat - ini.lat) * task_move_p,
          lng: ini.lng + (end.lng - ini.lng) * task_move_p,
        });
        /*
        map.panTo({
          lat: ini.lat + (end.lat - ini.lat) * task_move_p,
          lng: ini.lng + (end.lng - ini.lng) * task_move_p,
        });
        */
      }
      task_move_p = task_move_p + 0.0016;
      if (task_move_p > 1.0016) {
        map.panTo({
          lat: end.lat,
          lng: end.lng,
        });
        try {
          clearInterval(task_move);
        } catch (e) {}
      }
    }, 100);
  }
  const style = document.createElement("style");
  style.innerHTML = `
    .custom-label {
      background-color: white; /* Fondo blanco */
      padding: 2px 5px; /* Espaciado interno */
      border-radius: 3px; /* Bordes redondeados */
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); /* Sombra */
      white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    }
  `;
  document.head.appendChild(style);
  async function maping(mobid, source) {
    if (typeof source.target != "undefined") {
      if (source.target.id != "") {
        if (source.target.id == "alerts_box") {
          console.log("send alerts", source.target.attributes.mobid.value);
          socket_query.emit("alerts_now", source.target.attributes.mobid.value);
        }
        return 0;
      }
      //geocode($live[mobid].lat,$live[mobid].lon,mobid);
    }
    if (typeof $live[mobid] != "undefined")
      geocode($live[mobid].lat, $live[mobid].lon, mobid);
    if (typeof $travels[mobid] != "undefined") {
      if (
        $travels[mobid].monitor_crtl.follow == 0 &&
        (typeof $travels[mobid].monitor_crtl.ruta == "undefined" ||
          $travels[mobid].monitor_crtl.ruta == "")
      )
        ruta_estimada(mobid);
    }
    let bounds = new google.maps.LatLngBounds();
    let zIndex = 0;
    //console.log($travels[mobid],$live[mobid]);
    if (
      typeof $travels[mobid] == "undefined" ||
      typeof $live[mobid] == "undefined"
    ) {
      console.log("$travels||$live not ready");
      return 0;
    }
    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (typeof $travels[mobid].travel[0].p != "undefined") {
      m_inicio = new google.maps.Marker({
        position: {
          lat: $travels[mobid].travel[0]["ps"][0] / 10000,
          lng: $travels[mobid].travel[0]["ps"][1] / 10000,
        },
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });
    }

    let icon_status =
      $live[mobid].ingnition == 0
        ? "wht-blank"
        : $live[mobid].vel == 0
          ? "purple-blank"
          : "grn-blank";
    var image = {
      url: "img/" + icon_status + ".png",
      scaledSize: new google.maps.Size(40, 40),
      labelOrigin: new google.maps.Point(20, -10),
    };

    zIndex++;
    if (typeof m_fin != "undefined") {
      m_fin.setMap(null);
    }
    if (typeof $live[mobid] != "undefined") {
      m_fin = new google.maps.Marker({
        position: { lat: $live[mobid].lat, lng: $live[mobid].lon },
        map: map,
        animation: google.maps.Animation.DROP,
        //icon: image,
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 6, // Escala del icono (tamaño de la flecha)
          rotation: $live[mobid].heading, // Aplica el 'heading' como rotación
          fillColor:
            $live[mobid].ingnition == 0
              ? "#FAFBFA"
              : $live[mobid].vel == 0
                ? "#D580D5"
                : "#80C080",
          fillOpacity: 1,
          strokeWeight: 2,
          labelOrigin: new google.maps.Point(-3, -3),
        },
        zIndex: zIndex,
        label: {
          text:
            typeof $nicks_list[mobid] != "undefined"
              ? $nicks_list[mobid]
              : mobid,
          color: "#212121",
          fontSize: "13px",
          fontWeight: "bold",
          className: "custom-label",
        },
      });
    }

    let coordenada_ruta = [];
    zIndex++;
    zIndex++;
    zIndex++;
    post_mapping(mobid);

    let coordenada = [];
    for (let x in $travels[mobid].travel) {
      if (typeof $travels[mobid].travel[x].p != "undefined") {
        for (let y in $travels[mobid].travel[x].p) {
          let lat =
            ($travels[mobid].travel[x]["ps"][0] -
              $travels[mobid].travel[x]["p"][y][0]) /
            10000;
          let lng =
            ($travels[mobid].travel[x]["ps"][1] -
              $travels[mobid].travel[x]["p"][y][1]) /
            10000;
          coordenada.push({ lat: lat, lng: lng });
          bounds.extend({ lat: lat, lng: lng });
        }
      }
    }
    if (coordenada.length > 3) osrm(coordenada, zIndex, mobid);

    zIndex++;
    coordenada.push({ lat: $live[mobid].lat, lng: $live[mobid].lon });
    for (let x in recorrido) {
      if (typeof recorrido[x] != "undefined") {
        recorrido[x].setMap(null);
      }
    }
    if (coordenada.length > 0) {
      recorrido.push(
        new google.maps.Polyline({
          path: coordenada,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#b71c1c",
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        })
      );
    }
    if (
      coordenada.length > 3 &&
      $live[mobid].vel > 9 &&
      window.localStorage.getItem("real_time_icon") == 1
    ) {
      move_mark(
        m_fin,
        coordenada[coordenada.length - 3],
        coordenada[coordenada.length - 1]
      );
    } else {
      try {
        clearInterval(task_move);
      } catch (e) {}
    }

    let geo_end = [];
    for (let x in circles) {
      circles[x].setMap(null);
    }
    for (let x in poligons) {
      poligons[x].setMap(null);
    }
    if (typeof $travels[mobid].monitor_crtl.destino != "undefined") {
      if ($travels[mobid].monitor_crtl.destino != "") {
        if ($geos_full[$travels[mobid].monitor_crtl.destino] != null) {
          var geo = $geos_full[$travels[mobid].monitor_crtl.destino];
          let anti_f = 10;
          let last_join = "";
          while (typeof geo.join != "undefined" || anti_f == 0) {
            last_join = geo.join;
            geo = $geos_full[geo.join];
            anti_f--;
          }
          for (let y in geo["pos"]) {
            let lat = geo["pos"][y][0];
            let lng = geo["pos"][y][1];
            circles.push(
              new google.maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map: map,
                center: { lat: lat, lng: lng },
                radius: 100,
                zIndex: 3,
              })
            );
          }
          if (
            $travels[mobid].monitor_crtl.follow == 1 &&
            (typeof $travels[mobid].monitor_crtl.ruta == "undefined" ||
              $travels[mobid].monitor_crtl.ruta == "")
          ) {
            osrm_destino(geo["pos"][0][0], geo["pos"][0][1], zIndex, mobid);
            if (typeof m_fin_ruta != "undefined") {
              m_fin_ruta.setMap(null);
            }
            m_fin_ruta = new google.maps.Marker({
              position: { lat: geo["pos"][0][0], lng: geo["pos"][0][1] },
              map: map,
              animation: google.maps.Animation.DROP,
              icon: "img/markerB.png",
              zIndex: 0,
            });
          }
        } else if ($poli[$travels[mobid].monitor_crtl.destino] != null) {
          let poli_points = [];
          for (let x in $poli[$travels[mobid].monitor_crtl.destino][0]) {
            poli_points.push({
              lat: $poli[$travels[mobid].monitor_crtl.destino][0][x][0],
              lng: $poli[$travels[mobid].monitor_crtl.destino][0][x][1],
            });
          }
          poligons.push(
            new google.maps.Polygon({
              paths: poli_points,
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
              map: map,
              zIndex: 5,
            })
          );
          if (
            $travels[mobid].monitor_crtl.follow == 1 &&
            (typeof $travels[mobid].monitor_crtl.ruta == "undefined" ||
              $travels[mobid].monitor_crtl.ruta == "")
          ) {
            osrm_destino(poli_points[0].lat, poli_points[0].lng, zIndex, mobid);
          }
        }
      }
    }

    if (!on_map && typeof map != "undefined") {
      if (
        coordenada.length > 3 &&
        $live[mobid].vel > 9 &&
        window.localStorage.getItem("real_time_icon") == 1
      ) {
        bounds = new google.maps.LatLngBounds();
        bounds.extend(coordenada[coordenada.length - 3]);
        bounds.extend(coordenada[coordenada.length - 1]);
        map.fitBounds(bounds);
      } else {
        map.setZoom(14);
        map.panTo({ lat: $live[mobid].lat, lng: $live[mobid].lon });
      }
    }

    if (selected == mobid && selected_ != mobid) {
      if (coordenada.length > 1 && show_drivers != mobid) {
        map.fitBounds(bounds);
      }
      selected_ = mobid;
    } else if (selected == mobid && selected_ == mobid) {
      selected_ = -1;
    }

    selected = mobid;
    live_ = $live[mobid];

    bc.postMessage({
      selected_: selected_,
      panTo: { lat: $live[mobid].lat, lng: $live[mobid].lon },
      recorrido: coordenada,
      m_fin_ruta:
        coordenada_ruta.length > 0
          ? coordenada_ruta[coordenada_ruta.length - 1]
          : "",
      m_inicio_ruta: coordenada_ruta.length > 0 ? coordenada_ruta[0] : "",
      recorrido_ruta: coordenada_ruta,
      m_inicio:
        typeof $travels[mobid].travel[0].p != "undefined"
          ? {
              lat: $travels[mobid].travel[0]["ps"][0] / 10000,
              lng: $travels[mobid].travel[0]["ps"][1] / 10000,
            }
          : "",
      m_fin: { lat: $live[mobid].lat, lng: $live[mobid].lon },
      image_end: image,
      eco:
        typeof $nicks_list[mobid] != "undefined" ? $nicks_list[mobid] : mobid,
      target: typeof source.target,
    });
  }
  async function post_mapping(mobid) {
    let zIndex = 1;
    let coordenada_ruta = [];
    if (typeof $travels[mobid].monitor_crtl.ruta != "undefined") {
      if ($travels[mobid].monitor_crtl.ruta != "") {
        let route;
        try {
          route = await routes_db.getItem($travels[mobid].monitor_crtl.ruta);
        } catch (e) {
          console.log("Error leyendo routa", e);
        }
        if (route != null) {
          let lat_org = route["pos_start"][0];
          let lng_org = route["pos_start"][1];
          for (let x in route["pos"]) {
            let lat = (lat_org - route["pos"][x][0]) / 10000;
            let lng = (lng_org - route["pos"][x][1]) / 10000;
            coordenada_ruta.push({ lat: lat, lng: lng });
          }
        }
      }
    }

    zIndex++;
    if (typeof recorrido_ruta != "undefined") {
      recorrido_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      recorrido_ruta = new google.maps.Polyline({
        path: coordenada_ruta,
        geodesic: true,
        strokeColor: "#2196f3",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_inicio_ruta != "undefined") {
      m_inicio_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      m_inicio_ruta = new google.maps.Marker({
        position: coordenada_ruta[0],
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerA.png",
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_fin_ruta != "undefined") {
      m_fin_ruta.setMap(null);
    } //http://www.google.com/mapfiles/
    if (coordenada_ruta.length > 0) {
      m_fin_ruta = new google.maps.Marker({
        position: coordenada_ruta[coordenada_ruta.length - 1],
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerB.png",
        zIndex: zIndex,
      });
    }
    bc_route.postMessage({
      m_fin_ruta:
        coordenada_ruta.length > 0
          ? coordenada_ruta[coordenada_ruta.length - 1]
          : "",
      m_inicio_ruta: coordenada_ruta.length > 0 ? coordenada_ruta[0] : "",
      recorrido_ruta: coordenada_ruta,
    });

    driver_on_map = $travels[mobid].monitor_crtl.driver;
    // create a driver marker
    if (driver_markers) {
      driver_markers.setMap(null);
    }

    if (!drivers_[$travels[mobid].monitor_crtl.driver]) return;
    if (drivers_[$travels[mobid].monitor_crtl.driver].pos.status != 1) return;

    driver_markers = new google.maps.Marker({
      position: drivers_[$travels[mobid].monitor_crtl.driver].pos,
      map: map,
      title: "Posición del conductor",
      zIndex: 1,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor:
          new Date() / 1000 -
            drivers_[$travels[mobid].monitor_crtl.driver].pos.date <
          10 * 60
            ? "#4285F4"
            : "#FF0000",
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#FFFFFF",
        labelOrigin: new google.maps.Point(-3, -3),
      },
      label: {
        text: $drivers[$travels[mobid].monitor_crtl.driver].name,
        color: "#212121",
        fontSize: "13px",
        fontWeight: "bold",
        className: "custom-label",
      },
    });
  }
  $: {
    selected;
    alert_reload();
    geo_loc();
  }
  function alert_reload() {
    if (selected != -1) {
      socket_query.emit("alert_reload", selected, 3, (data) => {});
    }
  }
  setInterval(alert_reload, 60 * 1000);

  let geo_lc;
  function geo_loc() {
    if (
      selected != -1 &&
      $live[selected].lac != null &&
      $live[selected].cellid != 0
    ) {
      socket_query.emit(
        "geo_loc",
        $live[selected].lac,
        $live[selected].cellid,
        (data) => {
          //console.log("geo_loc",data);
          if (typeof geo_lc != "undefined") {
            geo_lc.setMap(null);
          }
          var antenna_ico = {
            url: "img/antenna.png",
            scaledSize: { width: 32, height: 32 },
          };
          geo_lc = new google.maps.Marker({
            position: { lat: data.lat / 10000, lng: data.lon / 10000 },
            map: map,
            animation: google.maps.Animation.DROP,
            icon: antenna_ico,
            zIndex: 0,
          });
        }
      );
    }
  }

  function ecm_reload() {
    if (selected != -1) {
      socket_query.emit("ecm_reload", selected, 3, (data) => {});
    }
  }

  let f_monitor = localStorage.getItem("f_monitor") == 0 ? 0 : 1,
    f_alertas = localStorage.getItem("f_alertas") == 0 ? 0 : 1,
    f_todas = localStorage.getItem("f_todas") == 0 ? 0 : 1,
    f_viaje = localStorage.getItem("f_viaje") == 0 ? 0 : 1,
    f_sinviaje = localStorage.getItem("f_sinviaje") == 0 ? 0 : 1,
    f_dispositivos = localStorage.getItem("f_dispositivos") == 0 ? 0 : 1,
    f_unidades = localStorage.getItem("f_unidades") == 1 ? 1 : 0,
    f_pag = 0;
  function filtro(i) {
    update_filters = true;
    if (i == 0) {
      f_monitor = f_monitor == 1 ? 0 : 1;
      localStorage.setItem("f_monitor", f_monitor);
    }
    if (i == 1) {
      f_alertas = f_alertas == 1 ? 0 : 1;
      localStorage.setItem("f_alertas", f_alertas);
    }
    if (i == 2) {
      f_todas = f_todas == 1 ? 0 : 1;
      localStorage.setItem("f_todas", f_todas);
    }
    if (i == 3) {
      f_viaje = f_viaje == 1 ? 0 : 1;
      f_sinviaje = f_viaje == 0 && f_sinviaje == 0 ? 1 : f_sinviaje;
      localStorage.setItem("f_viaje", f_viaje);
      localStorage.setItem("f_sinviaje", f_sinviaje);
    }
    if (i == 4) {
      f_sinviaje = f_sinviaje == 1 ? 0 : 1;
      f_viaje = f_sinviaje == 0 && f_viaje == 0 ? 1 : f_viaje;
      localStorage.setItem("f_sinviaje", f_sinviaje);
      localStorage.setItem("f_viaje", f_viaje);
    }
    if (i == 5) {
      f_dispositivos = 1;
      f_unidades = 0;
      localStorage.setItem("f_dispositivos", f_dispositivos);
      localStorage.setItem("f_unidades", f_unidades);
    }
    if (i == 6) {
      f_dispositivos = 0;
      f_unidades = 1;
      localStorage.setItem("f_dispositivos", f_dispositivos);
      localStorage.setItem("f_unidades", f_unidades);
    }

    if (i == 7) {
      f_pag = 0;
    }
    if (i == 8) {
      f_pag = 1;
    }
    if (i == 9) {
      f_pag = 2;
    }
    if (i == 10) {
      f_pag = 3;
    }
    if (i == 11) {
      f_pag = 4;
    }
    if (i == 12) {
      f_pag = 5;
    }
    if (i == 13) {
      f_pag = 6;
    }
    if (i == 14) {
      f_pag = 7;
    }
    if (i == 15) {
      f_pag = 8;
    }
    if (i == 16) {
      f_pag = 9;
    }
  }
  let future_travel = [];
  let future_travel_r = [{ route_m: [] }];
  let eco_selected = "",
    org_selected = "",
    dest_selected = "",
    oper_selected = "",
    route_f_selected = "",
    fechas = [],
    fecha_v,
    hora_v;
  let eco_add_selected = [];
  let horas = [];
  let new_travel_s = false;
  function new_travel() {
    if (!new_travel_s) {
      expand_map = false;
      localStorage.setItem("expand_map", expand_map);
      eco_add_selected = [];
      let lista_viajes_prog = document.getElementById("lista_viajes_prog");
      lista_viajes_prog.scrollTop = 0;
      new_travel_s = true;
      fechas = [];
      horas = [];
      for (let x = 0; x < 3; x++) {
        fechas.push([
          moment().startOf("day").add(x, "days").valueOf() / 1000,
          moment().startOf("day").add(x, "days").format("DD-MM-YY"),
        ]);
      }
      for (let x = 0; x < 48; x++) {
        horas.push([
          x * 30 * 60,
          moment()
            .startOf("day")
            .add(x * 30, "minutes")
            .format("HH:mm"),
        ]);
      }
      future_travel.unshift({
        id: "",
        date_ini: "",
        org: { name: "" },
        dest: { name: "" },
        dest_m: [],
        route: { name: "" },
        route_m: [],
        oper: "",
        sec_dests: [],
        sec_routes: [],
      });
      future_travel_r = [{ route_m: [] }];
      //future_travel = [...future_travel];
    } else {
      new_travel_s = false;
      future_travel.splice(0, 1);
      //future_travel = [...future_travel];
      eco_selected = "";
      org_selected = "";
      dest_selected = "";
      oper_selected = "";
      route_f_selected = "";
      fecha_v = "";
      hora_v = "";
      eco_add_selected = [];
    }
  }
  let saved_ok = false;
  let saved_type, saved_text;
  function save_travel(id, date_ini) {
    if (new_travel_s) {
      // Guardar Viaje
      let future_travel_ex = true;

      let sec_dests = [];
      let sec_routes = [];
      for (let z in future_travel_r[0].route_m) {
        sec_routes.push(future_travel_r[0].route_m[z].name);
      }
      for (let z in future_travel[0].dest_m) {
        if (future_travel[0].dest_m[z].name == "") {
          future_travel_ex = false;
        }
        sec_dests.push(future_travel[0].dest_m[z].name);
      }

      if (
        eco_selected != "" &&
        org_selected != "" &&
        dest_selected != "" &&
        oper_selected != "" &&
        fecha_v != "" &&
        hora_v !== "" &&
        future_travel_ex &&
        eco_add_selected[eco_add_selected.length - 1] != ""
      ) {
        for (let f in $unit_groups) {
          if ($unit_groups[f][0] == eco_selected) {
            console.log("found group");
            for (let p in $unit_groups[f][1]) {
              let eco_add_selected_found = false;
              for (let g in eco_add_selected) {
                if (eco_add_selected[g] == $unit_groups[f][1][p]) {
                  eco_add_selected_found = true;
                }
              }
              if (!eco_add_selected_found) {
                eco_add_selected.push($unit_groups[f][1][p]);
              }
            }
          }
        }

        let fecha_total = fecha_v + hora_v;
        socket_query.emit(
          "travel_save",
          eco_selected,
          org_selected,
          dest_selected,
          oper_selected,
          route_f_selected,
          fecha_total,
          sec_dests,
          sec_routes,
          eco_add_selected,
          (data) => {
            if (data == "ok") {
              new_travel();
              saved_ok = true;
              saved_text = "Viaje guardado";
              saved_type = "alert-success";
              setTimeout(function () {
                saved_ok = false;
              }, 3000);
            } else {
              saved_ok = true;
              saved_text = "Error en cargar viaje";
              saved_type = "alert-danger";
              setTimeout(function () {
                saved_ok = false;
              }, 3000);
            }
          }
        );
      } else {
        saved_ok = true;
        saved_text = "Completa todos los datos";
        saved_type = "alert-danger";
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    } // Borrar Viaje
    else {
      e_selected = -1;
      socket_query.emit("travel_delete", id, date_ini, (data) => {
        if (data == "ok") {
          saved_ok = true;
          saved_text = "Viaje borrado";
          saved_type = "alert-success";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      });
    }
  }
  let edit_mode = -1;
  let travel_toe = {};
  let travel_toe_ = {};
  let future_travel_backup = {};
  let con_init_travel_modal = false;
  let con_init_travel_modal_backup = [];
  function edit_travel(i, w) {
    if (edit_mode == -1) {
      edit_mode = i;
      travel_toe.sec_dests = [...future_travel[w].sec_dests];
      travel_toe_.sec_dests = [...future_travel[w].sec_dests];
      travel_toe.sec_routes = [...future_travel[w].sec_routes];
      travel_toe_.sec_routes = [...future_travel[w].sec_routes];
      future_travel_backup.sec_dests = [...future_travel[w].sec_dests];
      future_travel_backup.sec_routes = [...future_travel[w].sec_routes];
      org_selected = future_travel[w].org;
      dest_selected = future_travel[w].dest;
      route_f_selected = future_travel[w].route;
      extra_selected(i);
    } else {
      edit_mode = -1;
      future_travel[w].sec_dests = [...future_travel_backup.sec_dests];
      future_travel[w].sec_routes = [...future_travel_backup.sec_routes];
      org_selected = "";
      dest_selected = "";
      route_f_selected = "";
    }
  }
  function save_edit_travel(id, date_ini) {
    console.log(travel_toe, org_selected, dest_selected, route_f_selected);
    let dest_c = travel_toe.sec_dests.length;
    for (let x in travel_toe.sec_dests) {
      if (travel_toe.sec_dests[x] != "") {
        dest_c--;
      }
    }
    if (org_selected != "" && dest_selected != "" && dest_c == 0) {
      socket_query.emit(
        "travel_update",
        id,
        date_ini,
        org_selected,
        dest_selected,
        route_f_selected,
        travel_toe.sec_dests,
        travel_toe.sec_routes,
        (data) => {
          if (data == "ok") {
            org_selected = "";
            dest_selected = "";
            route_f_selected = "";
            edit_mode = -1;
            saved_ok = true;
            saved_text = "Viaje actualizado";
            saved_type = "alert-success";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          } else {
            saved_ok = true;
            saved_text = "Error en actualizar viaje";
            saved_type = "alert-danger";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    } else {
      saved_ok = true;
      saved_text = "Completa todos los datos";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    }
  }
  function add_destino(id, date_ini) {
    future_travel[0].dest_m.push({ name: "" });
    future_travel[0].route_m.push({ name: "" });
    future_travel_r[0].route_m.push({ name: "" });
    future_travel = [...future_travel];
    //console.log(future_travel[0]);
  }
  function launch_travel(
    id,
    date_ini,
    des,
    oper,
    route,
    id_add,
    org = "",
    disable_pos = false
  ) {
    // Check if unit is on origin
    let org_pos = [0, 0];
    if ($geos_full[org] != null) {
      var geo = $geos_full[org];
      let anti_f = 10;
      let last_join = "";
      while (typeof geo.join != "undefined" || anti_f == 0) {
        last_join = geo.join;
        geo = $geos_full[geo.join];
        anti_f--;
      }
      org_pos = [geo["pos"][0][0], geo["pos"][0][1]];
    } else if ($poli[org] != null) {
      let poli_points = [];
      for (let x in $poli[org][0]) {
        poli_points.push({
          lat: $poli[org][0][x][0],
          lng: $poli[org][0][x][1],
        });
      }
      org_pos = [poli_points[0].lat, poli_points[0].lng];
    }
    console.log("org_pos", org_pos);
    let actual_geo = geo_search($live[id].lat, $live[id].lon);
    if (
      org != actual_geo[2] &&
      get_km(org_pos[0], org_pos[1], $live[id].lat, $live[id].lon) > 1 &&
      !disable_pos
    ) {
      con_init_travel_modal_backup = [
        id,
        date_ini,
        des,
        oper,
        route,
        id_add,
        org,
      ];
      con_init_travel_modal = true;
      return;
    }
    con_init_travel_modal_backup = [];

    socket_query.emit(
      "travel_launch",
      id,
      date_ini,
      des,
      oper,
      route,
      id_add,
      (data) => {
        if (data == "ok") {
          saved_ok = true;
          saved_text = "Viaje iniciado";
          saved_type = "alert-success";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        } else if (data == "error1") {
          saved_ok = true;
          saved_text = "Error, Unidad con viaje en proceso";
          saved_type = "alert-danger";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );
  }
  let units_list_p;
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  let future_travel_p = [];
  $: {
    future_travel_p = [...$travel_crtl];
    if (new_travel_s) {
      future_travel_p.unshift(future_travel[0]);
    }
    future_travel = [...future_travel_p];
  }
  let route_list_ = [];
  $: {
    let route_list_p = [];
    $geos_class;
    $geos;
    $poli;
    for (let x in $route_list) {
      route_list_p.push($route_list[x]);
      let geo = geo_search(
        $route_list[x][2][0] / 10000,
        $route_list[x][2][1] / 10000
      );
      route_list_p[x][2][2] = geo[2];
      geo = geo_search(
        $route_list[x][3][0] / 10000,
        $route_list[x][3][1] / 10000
      );
      route_list_p[x][3][2] = geo[2];
    }
    route_list_ = [...route_list_p];
  }
  let e_selected = -1;
  function extra_selected(i) {
    e_selected = i;
  }
  function end_travel(id, date_ini, id_add) {
    socket_query.emit("end_travel", id, date_ini, id_add, (data) => {
      if (data == "ok") {
        saved_ok = true;
        saved_text = "Viaje Cerrado";
        saved_type = "alert-success";
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        close_travel_modal = true;
      } else if (data == "error1") {
        saved_ok = true;
        saved_text =
          "Error, el viaje fue cerrado manualmente, asigne de nuevo al operador para cerrar correctamente.";
        saved_type = "alert-danger";
        setTimeout(function () {
          saved_ok = false;
        }, 5000);
      }
    });
  }
  let route_modal = false;
  let route_name;
  let route_modal_org = 0;
  let route_modal_edit = false;
  function route_options(i, edit) {
    console.log("route_modal_org", route_modal_org);
    route_modal_org = i;
    route_modal_edit = edit;
    let con_s = false;

    if (route_modal_org == 0) {
      if (org_selected != "" && dest_selected != "") con_s = true;
    } else if (route_modal_org == 1) {
      if (edit) {
        if (
          dest_selected != "" &&
          travel_toe.sec_dests[route_modal_org - 1] != ""
        )
          con_s = true;
        console.log(travel_toe.sec_dests[route_modal_org - 1]);
      } else {
        if (
          dest_selected != "" &&
          future_travel[0].dest_m[route_modal_org - 1].name != ""
        )
          con_s = true;
      }
    } else {
      if (edit) {
        if (
          travel_toe.sec_dests[route_modal_org - 2] != "" &&
          travel_toe.sec_dests[route_modal_org - 1] != ""
        )
          con_s = true;
      } else {
        if (
          future_travel[0].dest_m[route_modal_org - 2].name != "" &&
          future_travel[0].dest_m[route_modal_org - 1].name != ""
        )
          con_s = true;
      }
    }

    if (con_s) {
      new Contextual({
        isSticky: false,
        items: [
          {
            label: "Generar nueva ruta",
            onClick: () => {
              route_modal = true;
            },
          },
          { label: "Cancelar", onClick: () => {} },
        ],
      });
    }
  }
  let map_routes;
  let all_steps;
  var directionsService;
  var directionsRenderer;
  let geocoder;
  function show_route_map() {
    let org_pos = "",
      dest_pos = "";
    let org_selected_, dest_selected_;
    for (let x in $geos_class_list) {
      if (route_modal_org == 0) {
        if ($geos_class_list[x][0] == org_selected) {
          org_selected_ = $geos_class_list[x][3];
        }
        if ($geos_class_list[x][0] == dest_selected) {
          dest_selected_ = $geos_class_list[x][3];
        }
      } else if (route_modal_org == 1) {
        if ($geos_class_list[x][0] == dest_selected) {
          org_selected_ = $geos_class_list[x][3];
        }
        if (route_modal_edit) {
          if (
            $geos_class_list[x][0] == travel_toe.sec_dests[route_modal_org - 1]
          ) {
            dest_selected_ = $geos_class_list[x][3];
          }
        } else {
          if (
            $geos_class_list[x][0] ==
            future_travel[0].dest_m[route_modal_org - 1].name
          ) {
            dest_selected_ = $geos_class_list[x][3];
          }
        }
      } else {
        if (route_modal_edit) {
          if (
            $geos_class_list[x][0] == travel_toe.sec_dests[route_modal_org - 2]
          ) {
            org_selected_ = $geos_class_list[x][3];
          }
          if (
            $geos_class_list[x][0] == travel_toe.sec_dests[route_modal_org - 1]
          ) {
            dest_selected_ = $geos_class_list[x][3];
          }
        } else {
          if (
            $geos_class_list[x][0] ==
            future_travel[0].dest_m[route_modal_org - 2].name
          ) {
            org_selected_ = $geos_class_list[x][3];
          }
          if (
            $geos_class_list[x][0] ==
            future_travel[0].dest_m[route_modal_org - 1].name
          ) {
            dest_selected_ = $geos_class_list[x][3];
          }
        }
      }
    }
    for (let x in $geos) {
      if ($geos[x][0] == org_selected_) {
        org_pos = { lat: $geos[x][1][0][0], lng: $geos[x][1][0][1] };
      }
      if ($geos[x][0] == dest_selected_) {
        dest_pos = { lat: $geos[x][1][0][0], lng: $geos[x][1][0][1] };
      }
      if (org_pos != "" && dest_pos != "") {
        break;
      }
    }
    if (org_pos == "") {
      if (route_modal_org == 0) {
        let c_ = centro($poli[org_selected][0]);
        org_pos = { lat: c_[0], lng: c_[1] };
      } else if (route_modal_org == 1) {
        let c_ = centro($poli[dest_selected][0]);
        org_pos = { lat: c_[0], lng: c_[1] };
      } else {
        if (route_modal_edit) {
          let c_ = centro($poli[travel_toe.sec_dests[route_modal_org - 2]][0]);
          org_pos = { lat: c_[0], lng: c_[1] };
        } else {
          let c_ = centro(
            $poli[future_travel[0].dest_m[route_modal_org - 2].name][0]
          );
          org_pos = { lat: c_[0], lng: c_[1] };
        }
      }
    }
    if (dest_pos == "") {
      if (route_modal_org == 0) {
        let c_ = centro($poli[dest_selected][0]);
        dest_pos = { lat: c_[0], lng: c_[1] };
      } else if (route_modal_org == 1) {
        if (route_modal_edit) {
          let c_ = centro($poli[travel_toe.sec_dests[route_modal_org - 1]][0]);
          dest_pos = { lat: c_[0], lng: c_[1] };
        } else {
          let c_ = centro(
            $poli[future_travel[0].dest_m[route_modal_org - 1].name][0]
          );
          dest_pos = { lat: c_[0], lng: c_[1] };
        }
      } else {
        if (route_modal_edit) {
          let c_ = centro($poli[travel_toe.sec_dests[route_modal_org - 1]][0]);
          dest_pos = { lat: c_[0], lng: c_[1] };
        } else {
          let c_ = centro(
            $poli[future_travel[0].dest_m[route_modal_org - 1].name][0]
          );
          dest_pos = { lat: c_[0], lng: c_[1] };
        }
      }
    }

    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    function initMap() {
      map_routes = new google.maps.Map(document.getElementById("map_routes"), {
        center: { lat: 24.458489, lng: -102.217231 },
        zoom: 5,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
        ],
      });

      directionsService = new google.maps.DirectionsService();
      directionsRenderer = new google.maps.DirectionsRenderer({
        draggable: true,
        map: map_routes,
      });

      directionsRenderer.addListener("directions_changed", function () {
        directionsRenderer.setMap(map_routes);
        console.log(
          directionsRenderer.getDirections().routes[0].legs[0].distance.text
        );
        console.log(
          directionsRenderer.getDirections().routes[0].legs[0].duration.text
        );
        let steps = directionsRenderer.getDirections().routes[0].legs[0].steps;
        all_steps = [];
        for (let z in steps) {
          for (let w in steps[z].lat_lngs) {
            all_steps.push([
              steps[z].lat_lngs[w].lat(),
              steps[z].lat_lngs[w].lng(),
            ]);
          }
        }
        if (all_steps.length > 0) {
          all_steps.unshift([org_pos.lat, org_pos.lng]);
          all_steps.push([dest_pos.lat, dest_pos.lng]);
        }
        //console.log(all_steps);
      });

      if (org_pos != "" && dest_pos != "") {
        displayRoute(org_pos, dest_pos, directionsService, directionsRenderer);
      }
    }
    initMap();
    //map_routes.fitBounds(bounds);
  }
  function displayRoute(origin, destination, service, display) {
    console.log("displayRoute", "origin", origin, "destination", destination);
    service.route(
      {
        origin: origin,
        destination: destination,
        waypoints: [],
        travelMode: "DRIVING",
        avoidTolls: false,
      },
      function (response, status) {
        if (status === "OK") {
          //console.log("Servicio ok ",response);
          display.setDirections(response);
        } else {
          saved_ok = true;
          saved_text = "Ruta no disponible";
          saved_type = "alert-danger";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );
  }
  function save_new_route() {
    route_modal = false;
    let out_vector = [];
    if (all_steps.length > 0) {
      let lat_start = Math.round(all_steps[0][0] * 10000);
      let lng_start = Math.round(all_steps[0][1] * 10000);
      out_vector.push({
        pos_start: [lat_start, lng_start],
        pos: [[0, 0]],
        stops: [],
      });
      for (let x in all_steps) {
        let lat_ = Math.round(all_steps[x][0] * 10000);
        let lng_ = Math.round(all_steps[x][1] * 10000);
        if (
          lat_start != lat_ &&
          lng_start != lng_ &&
          (out_vector[0].pos[out_vector[0].pos.length - 1][0] !=
            lat_start - lat_ ||
            out_vector[0].pos[out_vector[0].pos.length - 1][1] !=
              lng_start - lng_)
        ) {
          out_vector[0].pos.push([lat_start - lat_, lng_start - lng_]);
        }
      }
      console.log(out_vector[0]);

      socket_query.emit(
        "route_save",
        route_name.value,
        out_vector[0],
        1,
        (data) => {
          if (data == "ok") {
            saved_type = "alert-success";
            saved_text = "Ruta Guardada";
            saved_ok = true;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    }
  }
  function cancel_new_route() {
    route_modal = false;
  }
  $: {
    if ($internal_com != null) {
      if (typeof $internal_com["monitor_set"] != "undefined")
        auto_select($internal_com["monitor_set"]);
    }
  }
  function auto_select(id) {
    if (selected != id) {
      maping(id, {});
      select_prog(id);
    }
    let unit_seg = document.getElementById("seg_" + id);
    if (unit_seg != null) {
      unit_seg.scrollIntoView();
    }
  }
  let total_terminado = 0;
  let total_en_proceso = 0;
  let future_travel_master = {};
  $: {
    let total_terminado_ = 0,
      total_en_proceso_ = 0;
    let future_travel_master_ = {};
    for (let x in future_travel) {
      if (future_travel[x].step - 2 == future_travel[x].sec_dests.length) {
        total_terminado_++;
      } else if (future_travel[x].step > 0) {
        total_en_proceso_++;
      }
      if (future_travel[x].step > 0) {
        future_travel_master_[future_travel[x].id] = [future_travel[x].id_add];
      }
    }
    total_terminado = total_terminado_;
    total_en_proceso = total_en_proceso_;
    future_travel_master = future_travel_master_;
  }
  let mapa_ex_mode = false;
  let external_map;
  function mapa_ex() {
    mapa_ex_mode = mapa_ex_mode ? false : true;
    if (mapa_ex_mode) {
      external_map = window.open("/mapa.html", "_blank");
      window.focus();
      monitor_extra_map.set(1);
    } else {
      monitor_extra_map.set(0);
      try {
        external_map.close();
      } catch (e) {}
    }
  }
  $: {
    mapa_ex_mode = $monitor_extra_map;
  }

  let observer_ops = {
    root: document.getElementById("followed_travel_list"),
    rootMargin: "0px",
    threshold: 0,
  };
  /*
    let observer_ctl = {};
    var observer = new IntersectionObserver(observer_func, observer_ops);
    async function observer_func(event)
    {
        for(let x in event)
        {
            observer_ctl[event[x].target.id.split("seg_")[1]]=event[x].isIntersecting;
            //console.log(observer_ctl);
        }
    }
  */
  function obs_travel(i) {
    //console.log(i.getAttribute("id"));
    //observer.observe(i);
  }

  let prog_selected = -1;
  function select_prog(id) {
    let found = false;
    for (let x in future_travel) {
      if (future_travel[x].step > 0 && future_travel[x].id == id) {
        prog_selected = id;
        found = true;
        let unit_prog = document.getElementById(
          "prog_" + id + future_travel[x].date_ini
        );
        if (unit_prog != null) {
          unit_prog.scrollIntoView();
        }
      }
    }
    if (!found) prog_selected = -1;
  }
  let close_travel_modal = false;
  function send_travel_modal() {
    socket_query.emit(
      "travel_comment",
      c_travel_uuid,
      travel_end_comment,
      parseInt(travel_end_reason),
      (data) => {
        if (data == "ok") {
          close_travel_modal = false;
          c_travel_uuid = "";
          travel_end_comment = "";
        }
      }
    );
  }
  let c_travel_uuid = "";
  let travel_end_reason = 0;
  let travel_end_comment = "";
  let travel_modal_id, travel_modal_date_ini, travel_modal_id_add;
  function end_travel_modal(id, date_ini, uuid, id_add) {
    c_travel_uuid = uuid;
    travel_end_reason = 0;
    con_close_travel_modal = true;
    travel_modal_id = id;
    travel_modal_date_ini = date_ini;
    travel_modal_id_add = id_add; //end_travel(id,date_ini,id_add);
  }

  function edit_del_destino(org, i) {
    if (i == 0 && future_travel[org].sec_dests.length > 0) {
      future_travel[org].dest = future_travel[org].sec_dests[0];
      future_travel[org].sec_dests.splice(0, 1);
      dest_selected = future_travel[org].dest;
      travel_toe.sec_dests.splice(0, 1);
      travel_toe_.sec_dests.splice(0, 1);

      future_travel[org].route = future_travel[org].sec_routes[0];
      future_travel[org].sec_routes.splice(0, 1);
      route_f_selected = future_travel[org].route;
      travel_toe.sec_routes.splice(0, 1);
      travel_toe_.sec_routes.splice(0, 1);
    } else {
      future_travel[org].sec_dests.splice(i - 1, 1);
      travel_toe.sec_dests.splice(i - 1, 1);
      travel_toe_.sec_dests.splice(i - 1, 1);

      future_travel[org].sec_routes.splice(i - 1, 1);
      travel_toe.sec_routes.splice(i - 1, 1);
      travel_toe_.sec_routes.splice(i - 1, 1);
    }
    future_travel[org] = future_travel[org];
  }
  function edit_add_destino(org, i) {
    future_travel[org].sec_dests.push("");
    travel_toe.sec_dests.push("");
    travel_toe_.sec_dests.push("");

    future_travel[org].sec_routes.push("");
    travel_toe.sec_routes.push("");
    travel_toe_.sec_routes.push("");

    future_travel[org] = future_travel[org];
  }
  let id_add_selected = -1;
  $: {
    if ($recognition == "monitoreadas") {
      filtro(0);
    }
    if ($recognition == "con alertas") {
      filtro(1);
    }
    if ($recognition == "sin monitoreo") {
      filtro(2);
    }
  }
  let eco_f = "",
    eco_f_;
  $: {
    if (eco_f != "") {
      let change_page = false;
      for (let x in travels_fh) {
        if (travels_fh[x].id == eco_f) {
          if (f_pag != travels_fh[x].page) {
            f_pag = travels_fh[x].page;
            change_page = true;
          }
          break;
        }
      }
      buscar_unidad(eco_f, change_page);
      //let unit_seg = document.getElementById("seg_"+eco_f);
      //if(unit_seg!=null){unit_seg.scrollIntoView();maping(eco_f,{});select_prog(eco_f)}
    }
  }
  async function buscar_unidad(eco, change_page) {
    if (change_page) await tick();
    let unit_seg = document.getElementById("seg_" + eco);
    if (unit_seg != null) {
      unit_seg.scrollIntoView();
      maping(eco, {});
      select_prog(eco);
    }
    eco_f = "";
    eco_f_ = "";
  }

  async function busca_future_travel(eco) {
    let change_page = false;
    for (let x in travels_fh) {
      if (travels_fh[x].id == eco) {
        if (f_pag != travels_fh[x].page) {
          f_pag = travels_fh[x].page;
          change_page = true;
        }
        break;
      }
    }
    if (change_page) await tick();
    if (document.getElementById("seg_" + eco) != null) {
      document.getElementById("seg_" + eco).scrollIntoView();
    }
  }

  let con_close_travel_modal = false;
  let con_delete_travel_modal = false;
  function save_travel_modal(id, date_ini) {
    travel_modal_id = id;
    travel_modal_date_ini = date_ini;
    con_delete_travel_modal = true;
  }
  let caravan_mode = false;
  let caravan_group = [];
  function caravan() {
    console.log(caravan_group);
    if (caravan_mode) {
      if (caravan_group.length > 0) {
        socket_query.emit("travel_caravan", caravan_group, (data) => {
          if (data == "ok") {
            saved_type = "alert-success";
            saved_text = "Caravana creada";
            saved_ok = true;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
            caravan_mode = false;
            caravan_group = [];
          } else {
            saved_type = "alert-danger";
            saved_text = "Error en crear Caravana";
            saved_ok = true;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        });
      }
    } else {
      caravan_group = [];
      caravan_mode = true;
    }
  }
  let actual_name = "";
  let last_geocode = "";
  let last_geocode_pos = "";
  async function geocode(lat, lon, mobid) {
    var latlng = { lat: lat, lng: lon };
    last_geocode = mobid;

    if (last_geocode_pos != JSON.stringify({ lat: lat, lon: lon })) {
      last_geocode_pos = JSON.stringify({ lat: lat, lon: lon });
      // Modo Nominatim
      let dir = await fetch(
        "https://geoc.omnitracs.online/reverse?lat=" +
          lat +
          "&lon=" +
          lon +
          "&format=json&polygon_text=1"
      );
      let dir_ = await dir.json();
      //console.log(dir_);
      if (
        typeof dir_.display_name != "undefined" &&
        typeof travels_fz[mobid].geo_stay[0] != "undefined"
      ) {
        //console.log(dir_.display_name);
        actual_name = dir_.display_name;
        if (
          travels_fz[mobid].geo_stay[0].split("-")[0] ==
            "Geocerca_automatica" ||
          travels_fz[mobid].geo_stay[0] == ""
        ) {
          travels_fz[mobid].geo_stay[0] = actual_name;
        }
      } else {
        console.log("error en geocode");
        actual_name = "";
      }
    }
  }
  // Prototipo
  let coordenada_key = [];
  let coordenada_ruta_key = [];
  let last_estimado = "";
  async function ruta_estimada(mobid) {
    if (typeof l_ruta_estimada != "undefined") {
      l_ruta_estimada.setMap(null);
    }
    if (true) {
      //(last_estimado!=mobid)
      coordenada_key = [];
      let rec_p = JSON.parse(await units_travel_db.getItem(mobid));
      for (var x in rec_p) {
        if (typeof rec_p[x].p != "undefined") {
          let lat, lng;
          for (let y in rec_p[x].p) {
            lat = (rec_p[x]["ps"][0] - rec_p[x]["p"][y][0]) / 10000;
            lng = (rec_p[x]["ps"][1] - rec_p[x]["p"][y][1]) / 10000;
            coordenada_key.push([lat, lng]);
          }
          coordenada_key[coordenada_key.length - 1] = [lat, lng, 0];
        }
      }
      coordenada_ruta_key = [];
      if (typeof $travels[mobid] != "undefined") {
        for (let x in $travels[mobid].travel) {
          if (typeof $travels[mobid].travel[x].p != "undefined") {
            for (let y in $travels[mobid].travel[x].p) {
              let lat =
                ($travels[mobid].travel[x]["ps"][0] -
                  $travels[mobid].travel[x]["p"][y][0]) /
                10000;
              let lng =
                ($travels[mobid].travel[x]["ps"][1] -
                  $travels[mobid].travel[x]["p"][y][1]) /
                10000;
              coordenada_ruta_key.push([lat, lng]);
            }
          }
        }
      }
    }
    last_estimado = mobid;
    if (coordenada_ruta_key.length > 4) {
      let env = [];
      for (
        let x = coordenada_ruta_key.length - 4;
        x < coordenada_ruta_key.length;
        x++
      ) {
        env.push(coordenada_ruta_key[x]);
      }
      //console.log(env)
      ruta_comp3(env, coordenada_key, mobid);
    }
  }
  var error = 0.01; //1000 metros
  function ruta_comp3(ruta_ev, ruta_org, mobid) {
    let org_pos = 0;
    let x1, y1, x2, y2, x, y, m, b, p_rec, x_rec, y_rec, dentro_x, dentro_y;

    let change = 0;

    let z = 0; // Punto acutual
    //console.log("ruta total",ruta_ev.length);
    let ruta_rest = [];
    for (var w = org_pos; w < ruta_org.length - 1; w++) {
      // Carga de puntos
      x = ruta_ev[z][0];
      y = ruta_ev[z][1];

      // Carga de la recta de la ruta madre
      x1 = ruta_org[w][0];
      y1 = ruta_org[w][1];
      x2 = ruta_org[w + 1][0];
      y2 = ruta_org[w + 1][1];
      m = (y2 - y1) / (x2 - x1);

      if (isFinite(m)) {
        b = y1 - m * x1;
        p_rec = Math.abs(m * x - y + b) / Math.sqrt(Math.pow(m, 2) + 1);
        x_rec = (x + m * y - b * m) / (Math.pow(m, 2) + 1);
        y_rec = (Math.pow(m, 2) * y + m * x + b) / (Math.pow(m, 2) + 1);
      } else {
        p_rec = Math.abs(x1 - x);
        x_rec = x1;
        y_rec = y;
      }
      dentro_x =
        x1 > x2
          ? x_rec <= x1 + error && x_rec >= x2 - error
            ? true
            : false
          : x1 < x2
            ? x_rec >= x1 - error && x_rec <= x2 + error
              ? true
              : false
            : true;
      dentro_y =
        y1 > y2
          ? y_rec <= y1 + error && y_rec >= y2 - error
            ? true
            : false
          : y1 < y2
            ? y_rec >= y1 - error && y_rec <= y2 + error
              ? true
              : false
            : true;
      p_rec = ((p_rec * Math.PI) / 180) * 6371;

      let d1 = Math.sqrt(Math.pow(y2 - y, 2) + Math.pow(x2 - x, 2)); // Distancia a puntos.
      d1 = ((d1 * Math.PI) / 180) * 6371;

      let d2 = Math.sqrt(Math.pow(y1 - y, 2) + Math.pow(x1 - x, 2));
      d2 = ((d2 * Math.PI) / 180) * 6371;

      if ((dentro_x && dentro_y && p_rec < 1) || d1 < 1 || d2 < 1) {
        // Distancia con punto central
        //console.log("distancia central",p_rec,w,z);
        z++;
      } else if (d1 > 10 && d2 > 10) {
        // Supera distancia de apego
        //if(z>0) console.log("ruta se aleja",z,w,d1,d2,p_rec);
        z = 0;
      }

      if (z >= ruta_ev.length - 1) {
        //console.log("flujo terminado");
        ruta_rest.push([{ lat: $live[mobid].lat, lng: $live[mobid].lon }]);
        for (let f = w + 3; f < ruta_org.length - 1; f++) {
          if (typeof ruta_org[f][2] == "undefined") {
            ruta_rest[ruta_rest.length - 1].push({
              lat: ruta_org[f][0],
              lng: ruta_org[f][1],
            });
          } else {
            break;
          }
        }
        z = 0;
      }
    }
    //console.log(ruta_rest)
    if (ruta_rest.length > 0) {
      let lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      };
      if (typeof l_ruta_estimada != "undefined") {
        l_ruta_estimada.setMap(null);
      }
      if (ruta_rest.length > 0) {
        l_ruta_estimada = new google.maps.Polyline({
          path: ruta_rest[0],
          geodesic: true,
          strokeColor: "#2f21f3",
          strokeOpacity: 0,
          strokeWeight: 2.5,
          map: map,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "20px",
            },
          ],
        });
        if (ruta_rest[0].length > 3) osrm_future(ruta_rest[0]);
      }
    }
  }
  async function osrm_destino(lat, lon, zIndex, mobid) {
    // OSRM
    let coordenada_osrm = "";
    coordenada_osrm =
      $live[mobid].lon + "," + $live[mobid].lat + ";" + lon + "," + lat;

    let osrm_r = await fetch(
      "https://route.omnitracs.online/route/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full"
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      let coordenada = [];
      for (let t in osrm_r.routes) {
        for (let y in osrm_r.routes[t].geometry.coordinates) {
          coordenada.push({
            lat: osrm_r.routes[t].geometry.coordinates[y][1],
            lng: osrm_r.routes[t].geometry.coordinates[y][0],
          });
        }
      }
      let lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      };
      if (typeof l_ruta_estimada != "undefined") {
        l_ruta_estimada.setMap(null);
      }
      l_ruta_estimada = new google.maps.Polyline({
        path: coordenada,
        geodesic: true,
        strokeColor: "#2f21f3",
        strokeOpacity: 0,
        strokeWeight: 2.5,
        map: map,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
      });
    }
  }
  let colors_line = [
    "#2196f3",
    "#e91e63",
    "#03a9f4",
    "#9c27b0",
    "#00bcd4",
    "#673ab7",
    "#009688",
    "#3f51b5",
    "#4caf50",
    "#ff5722",
    "#8bc34a",
    "#ff9800",
    "#cddc39",
    "#ffc107",
    "#607d8b",
    "#ffeb3b",
    "#9e9e9e",
    "#212121",
    "#f44336",
  ];
  async function osrm(coordenada, zIndex, mobid) {
    // OSRM
    let coordenada_osrm = "";
    let radius_osrm = "";
    for (let t in coordenada) {
      coordenada_osrm =
        coordenada_osrm + coordenada[t].lng + "," + coordenada[t].lat + ";";
    }
    coordenada_osrm =
      coordenada_osrm + $live[mobid].lon + "," + $live[mobid].lat + ";";
    coordenada_osrm =
      coordenada_osrm + $live[mobid].lon + "," + $live[mobid].lat + ";";
    radius_osrm = radius_osrm + "20%3B";
    radius_osrm = radius_osrm + "20%3B";
    for (let t in coordenada) {
      radius_osrm = radius_osrm + "20%3B";
    }

    coordenada_osrm = coordenada_osrm.slice(0, -1);
    radius_osrm = radius_osrm.slice(0, -3);
    let osrm_r = await fetch(
      "https://route.omnitracs.online/match/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full&radiuses=" +
        radius_osrm
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      if (osrm_r.matchings[0].confidence > 0.2) {
        coordenada = [];
        for (let t in osrm_r.matchings) {
          for (let y in osrm_r.matchings[t].geometry.coordinates) {
            coordenada.push({
              lat: osrm_r.matchings[t].geometry.coordinates[y][1],
              lng: osrm_r.matchings[t].geometry.coordinates[y][0],
            });
          }
        }
      }
      //console.log("confidence",osrm_r.matchings[0].confidence);
    }
    //---------

    zIndex++;
    coordenada.push({ lat: $live[mobid].lat, lng: $live[mobid].lon });

    let color_change = linesNeedColorChange(coordenada);
    let color_change_c = 0;
    console.log("color_change", color_change, coordenada.length);
    // delete color change for last three points
    color_change = color_change.filter((x) => x < coordenada.length - 3);

    for (let x in recorrido) {
      if (typeof recorrido[x] != "undefined") {
        recorrido[x].setMap(null);
      }
    }
    if (coordenada.length > 0) {
      recorrido.push(
        new google.maps.Polyline({
          path: [coordenada[0]],
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#b71c1c",
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        })
      );
      for (let x in coordenada) {
        if (color_change.includes(parseInt(x))) {
          recorrido.push(
            new google.maps.Polyline({
              path: [
                new google.maps.LatLng(
                  coordenada[x - 1].lat,
                  coordenada[x - 1].lng
                ),
                new google.maps.LatLng(coordenada[x].lat, coordenada[x].lng),
              ],
              geodesic: true,
              strokeColor:
                color_change_c < colors_line.length
                  ? colors_line[color_change_c]
                  : colors_line[color_change_c % colors_line.length],
              strokeOpacity: 1.0,
              strokeWeight: 2.5,
              map: map,
              zIndex: zIndex,
              icons: [
                {
                  icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor:
                      color_change_c < colors_line.length
                        ? colors_line[color_change_c]
                        : colors_line[color_change_c % colors_line.length],
                  },
                  offset: "100px",
                  repeat: "100px",
                },
              ],
            })
          );
          color_change_c++;
          zIndex++;
        } else {
          recorrido[recorrido.length - 1]
            .getPath()
            .push(new google.maps.LatLng(coordenada[x].lat, coordenada[x].lng));
        }
      }
      bc_coordenadas.postMessage({ recorrido: coordenada });
    }
  }
  async function osrm_future(coordenada) {
    // OSRM
    let coordenada_osrm = "";
    let radius_osrm = "";
    for (let t in coordenada) {
      coordenada_osrm =
        coordenada_osrm + coordenada[t].lng + "," + coordenada[t].lat + ";";
    }
    for (let t in coordenada) {
      radius_osrm = radius_osrm + "20%3B";
    }

    coordenada_osrm = coordenada_osrm.slice(0, -1);
    radius_osrm = radius_osrm.slice(0, -3);
    let osrm_r = await fetch(
      "https://route.omnitracs.online/match/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full&radiuses=" +
        radius_osrm
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      if (osrm_r.matchings[0].confidence > 0.2) {
        coordenada = [];
        for (let t in osrm_r.matchings) {
          for (let y in osrm_r.matchings[t].geometry.coordinates) {
            coordenada.push({
              lat: osrm_r.matchings[t].geometry.coordinates[y][1],
              lng: osrm_r.matchings[t].geometry.coordinates[y][0],
            });
          }
        }
      }
      //console.log("confidence future",osrm_r.matchings[0].confidence);
    }
    //---------
    let lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      scale: 4,
    };
    if (typeof l_ruta_estimada != "undefined") {
      l_ruta_estimada.setMap(null);
    }
    l_ruta_estimada = new google.maps.Polyline({
      path: coordenada,
      geodesic: true,
      strokeColor: "#2f21f3",
      strokeOpacity: 0,
      strokeWeight: 2.5,
      map: map,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "20px",
        },
      ],
    });
  }
  function travel_link(id) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Copiar link de viaje",
          onClick: () => {
            req_travel_link(id);
          },
        },
      ],
    });
  }
  function req_travel_link(id) {
    socket_query.emit("travel_link", id, (data) => {
      if (data != "error") {
        var el = document.createElement("textarea");
        el.value = "https://" + window.location.hostname + data;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        saved_type = "alert-success";
        saved_text = "Link de viaje copiado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  function options_units(id, follow) {
    if (follow == 1) {
      new Contextual({
        isSticky: false,
        items: [
          {
            label: "Generar alerta manual",
            onClick: () => {
              manual_alert(id);
            },
          },
          {
            label: "Crear Geocerca",
            onClick: () => {
              geo_new(id);
            },
          },
        ],
      });
    } else {
      new Contextual({
        isSticky: false,
        items: [
          {
            label: "Crear Geocerca",
            onClick: () => {
              geo_new(id);
            },
          },
        ],
      });
    }
  }
  function manual_alert(id) {
    socket_query.emit("alert_service", id, (data) => {
      if (data != "error") {
        saved_type = "alert-success";
        saved_text = "Alerta manual OK";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 1000);
      }
    });
  }
  let geo_modal = false;
  let map_geo = 0;
  let geo_new_pos = {};
  let circles_new = [];
  let type_geo_new = -1;
  let actual_geo = "";
  let actual_geo_name = "";
  let geo_data;
  function geo_new(id) {
    if (typeof $live[id] != "undefined") {
      geo_new_pos = { lat: $live[id].lat, lng: $live[id].lon };
      let geo_s = geo_search(geo_new_pos.lat, geo_new_pos.lng);
      console.log(geo_s);
      if (geo_s[1] == 1) {
        if (geo_s[2] == "") {
          // Geo completamente nueva
          type_geo_new = 0;

          type_geo_new = -1;
          saved_type = "alert-danger";
          saved_text = "La unidad debe estar detenida.";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        } // Geo ya existente sin nombre
        else {
          actual_geo = geo_s[2];
          actual_geo_name = geo_s[0];
          geo_c_sel = 2;
          type_geo_new = 1;

          geo_modal = true;
        }
      } else {
        type_geo_new = -1;
        saved_type = "alert-success";
        saved_text = "La geocerca ya existe, se llama " + geo_s[0];
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    }
  }
  let geo_c_sel = 2;
  function show_geo_fence() {
    function initMap() {
      map_geo = new google.maps.Map(document.getElementById("map_geo"), {
        center: geo_new_pos,
        zoom: 17,
      });
    }
    initMap();
    if (type_geo_new == 0) {
      new_geo();
    } else if (type_geo_new == 1) {
      autom_geo(actual_geo);
    }
  }
  function autom_geo(geo_s) {
    let bounds = new google.maps.LatLngBounds();
    var geo = $geos_full[geo_s];
    geo_data = geo;
    for (let x in circles_new) {
      circles_new[x].setMap(null);
    }
    circles_new = [];
    if (geo != null) {
      let anti_f = 10;
      let last_join = "";
      while (typeof geo.join != "undefined" || anti_f == 0) {
        last_join = geo.join;
        geo = $geos_full[geo.join];
        anti_f--;
      }
      for (let y in geo["pos"]) {
        let lat = geo["pos"][y][0];
        let lng = geo["pos"][y][1];

        bounds.extend({ lat: lat + 0.000898, lng: lng });
        bounds.extend({ lat: lat, lng: lng + 0.000898 });
        bounds.extend({ lat: lat - 0.000898, lng: lng });
        bounds.extend({ lat: lat, lng: lng - 0.000898 });

        circles_new.push(
          new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map_geo,
            center: { lat: lat, lng: lng },
            radius: 100,
            zIndex: 3,
          })
        );
      }
      map_geo.fitBounds(bounds);
    }
  }
  function new_geo() {
    for (let x in circles_new) {
      circles_new[x].setMap(null);
    }
    circles_new = [];
    circles_new.push(
      new google.maps.Circle({
        strokeColor: "#3f51b5",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#3f51b5",
        fillOpacity: 0.35,
        map: map_geo,
        center: map.getCenter(),
        radius: 100,
        draggable: true,
        zIndex: 4,
      })
    );
  }
  function save_geo() {
    socket_query.emit(
      "geo_save",
      actual_geo,
      actual_geo_name,
      geo_c_sel,
      geo_data,
      (data) => {
        if (data == "ok") {
          map_geo = 0;
          geo_modal = false;
          saved_ok = true;
          saved_text = "Geocerca guardada";
          saved_type = "alert-success";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );
  }
  // Centroide
  function centro(pts) {
    let first = pts[0],
      last = pts[pts.length - 1];
    if (first[0] != last[0] || first[1] != last[1]) pts.push(first);
    let twicearea = 0,
      x = 0,
      y = 0,
      nPts = pts.length,
      p1,
      p2,
      f;
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
      p1 = pts[i];
      p2 = pts[j];
      f = p1[0] * p2[1] - p2[0] * p1[1];
      twicearea += f;
      x += (p1[0] + p2[0]) * f;
      y += (p1[1] + p2[1]) * f;
    }
    f = twicearea * 3;
    return [x / f, y / f];
  }

  // Remote functions
  $: {
    $cc_monitor;
    if ($cc_monitor.length > 0 && $map_ready == 1 && mount_complete) {
      if ($cc_monitor[0].type == "select_unit") {
        for (let x in $nicks_list) {
          if (
            sanitizeText($nicks_list[x].toLowerCase()) == $cc_monitor[0].name
          ) {
            auto_select(x);
            let estado =
              travels_fz[x].org.time != "" ||
              travels_fz[x].oper != "Desconocido"
                ? "en viaje"
                : "estacionada, sin viaje asignado";
            let last = travels_fz[x].gsm.text.includes("min")
              ? travels_fz[x].gsm.text.replace("min", " minutos")
              : travels_fz[x].gsm.text.replace("m", " minutos");
            speaking_list.set([
              "La unidad se encuentra " +
                (travels_fz[x].ign == 1 ? "encendida" : "apagada") +
                " y su última posición fue hace " +
                last +
                ", se encuentra " +
                estado,
            ]);
            break;
          }
        }
      }
      cc_monitor.set([]);
    }
  }
  function sanitizeText(text) {
    const accentsMap = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
      ñ: "n",
      Ñ: "N",
      ü: "u",
      Ü: "U",
    };
    const removeAccents = (str) => {
      return str
        .split("")
        .map((char) => accentsMap[char] || char)
        .join("");
    };
    const removeSpecialChars = (str) => {
      return str.replace(/[^a-zA-Z0-9]/g, "");
    };
    const lowerCaseText = text.toLowerCase();
    const textWithoutAccents = removeAccents(lowerCaseText);
    const sanitizedText = removeSpecialChars(textWithoutAccents);

    return sanitizedText;
  }

  // WebRTC
  const turnConfig = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      {
        urls: "turn:portainer.sub.omnitracs.online:3478",
        username: "alfa",
        credential: "js1ma",
      },
    ],
  };
  let peerConnection;
  let driver_video = null;
  let last_video = 2;
  let audioStream1;
  socket_query.on("rtc_offer", async function (data) {
    console.log("Recibida oferta:", data);
    video.srcObject = null;
    video2.srcObject = null;

    if (peerConnection) {
      peerConnection.close();
    }
    peerConnection = new RTCPeerConnection(turnConfig);

    peerConnection.ontrack = (event) => {
      if (event.track.kind == "video") {
        const video = document.getElementById("video");
        const video2 = document.getElementById("video2");
        if (last_video == 2) {
          last_video = 1;
          console.log("Video 1");
          video.srcObject = event.streams[0];
          video.autoplay = true;
          video.playsInline = true;
        } else {
          last_video = 2;
          console.log("Video 2");
          video2.srcObject = event.streams[0];
          video2.autoplay = true;
          video2.playsInline = true;
        }
        // Auto record
        if (video.srcObject && !mediaRecorder) {
          const videoStream = video.captureStream();
          mediaRecorder = new MediaRecorder(videoStream);
          recordedChunks = [];
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data);
            }
          };
          mediaRecorder.start();
          console.log("Recording started 1");
        }
        if (video2.srcObject && !mediaRecorder2) {
          const videoStream2 = video2.captureStream();
          mediaRecorder2 = new MediaRecorder(videoStream2);
          recordedChunks2 = [];
          mediaRecorder2.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunks2.push(event.data);
            }
          };
          mediaRecorder2.start();
          console.log("Recording started 2");
        }
      }
      if (event.track.kind == "audio") {
        const audio = document.getElementById("audio");
        audio.srcObject = event.streams[0];
        audio.autoplay = true;
        audio.playsInline = true;
        console.log("Audio Call");
      }
    };

    async function getStreamAudio(deviceId) {
      const constraints = {
        audio: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
        },
      };
      try {
        let stream = await navigator.mediaDevices.getUserMedia(constraints);
        return stream;
      } catch (error) {
        console.error("Error al obtener el stream de audio:", error);
        return null;
      }
    }
    let medias = await navigator.mediaDevices.enumerateDevices();
    let microphones = medias.filter((device) => device.kind === "audioinput");
    if (microphones.length > 0 && show_audio_status) {
      console.log("microphones", microphones);
      audioStream1 = await getStreamAudio(microphones[0].deviceId);
    }

    if (audioStream1) {
      audioStream1.getTracks().forEach((track) => {
        console.log("audioStream1", track);
        peerConnection.addTrack(track, audioStream1);
      });
    }

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        console.log("Enviando candidato ICE:", event.candidate);
        socket_query.emit("rtc_candidate_admin", driver_video, event.candidate);
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      console.log(
        "ICE connection state change:",
        peerConnection.iceConnectionState
      );
      if (peerConnection.iceConnectionState === "connected") {
      }
    };

    await peerConnection.setRemoteDescription(new RTCSessionDescription(data));
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);

    console.log("Enviando respuesta:", answer);
    socket_query.emit(
      "rtc_answer",
      driver_video,
      peerConnection.localDescription
    );
  });
  socket_query.on("rtc_candidate", async function (data) {
    console.log("Recibido candidato ICE:", data);
    await peerConnection.addIceCandidate(new RTCIceCandidate(data));
  });
  let close_video_modal = false;
  let cargando_video = true;
  let cargando_video2 = true;
  function show_video(id) {
    const video = document.getElementById("video");
    const video2 = document.getElementById("video2");
    video.srcObject = null;
    video2.srcObject = null;
    driver_video = id;
    socket_query.emit("rtc_requestOffer", id, "video");
    close_video_modal = true;
    video.onloadedmetadata = () => {
      cargando_video = false;
    };
    video2.onloadedmetadata = () => {
      cargando_video2 = false;
    };
  }
  let drivers_ = {};
  let driver_markers;
  let driver_on_map;
  $: {
    $drivers;
    drivers_ = $drivers;
    if (drivers_[driver_on_map]) {
      if (drivers_[driver_on_map].status != 1) {
        if (driver_markers) {
          driver_markers.setMap(null);
        }
      }
    }
  }
  socket_query.on("driver_position", async function (data) {
    if (typeof drivers_[data.driver] != "undefined") {
      drivers_[data.driver].pos = data.pos;

      if (driver_on_map != data.driver) return;

      // create a driver marker
      if (driver_markers) {
        driver_markers.setMap(null);
      }
      if (drivers_[data.driver].pos.status != 1) return;

      driver_markers = new google.maps.Marker({
        position: data.pos,
        map: map,
        title: "Mi posición",
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: "#4285F4",
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#FFFFFF",
          labelOrigin: new google.maps.Point(-3, -3),
        },
        label: {
          text: $drivers[data.driver].name,
          color: "#212121",
          fontSize: "13px",
          fontWeight: "bold",
          className: "custom-label",
        },
      });
    }
  });
  let mediaRecorder, mediaRecorder2;
  let recordedChunks = [],
    recordedChunks2 = [];

  let monitor_outputs = localStorage.getItem("monitor_outputs")
    ? JSON.parse(localStorage.getItem("monitor_outputs"))
    : [];

  let expand_map = localStorage.getItem("expand_map") == "true" ? true : false;
  let total_filters = 0;
  let update_filters = false;
  afterUpdate(() => {
    if (update_filters) {
      update_filters = false;
      total_filters = document.querySelectorAll(
        "#followed_travel_list .card"
      ).length;
    }
  });
  let show_audio_status = false;
  function show_audio(id) {
    show_audio_status = true;
    driver_video = id;
    socket_query.emit("rtc_requestOffer", id, "audio");
  }
  function linesNeedColorChange(points, threshold = 0.01) {
    let last_point = 0;
    let result = [];
    for (let x = 0; x < points.length - 1; x++) {
      if (x > 1) {
        for (let y = last_point; y < x - 1; y++) {
          let distance = puntoLinea(points[y], points[y + 1], points[x]);
          if (distance < threshold && distance != null) {
            result.push(x);
            last_point = x;
          }
        }
      }
    }
    return result;
  }
  function puntoLinea(a, b, p) {
    const l = { lat: b.lat - a.lat, lng: b.lng - a.lng };
    const t =
      ((p.lat - a.lat) * l.lat + (p.lng - a.lng) * l.lng) /
      (l.lat ** 2 + l.lng ** 2);
    if (t < 0 || t > 1) return null;
    let p2 = { lat: a.lat + t * l.lat, lng: a.lng + t * l.lng };
    return get_km(p.lat, p.lng, p2.lat, p2.lng);
  }
</script>

<audio id="audio" autoplay playsinline kind="audio"> </audio>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

{#if geo_modal}
  <div
    class="modal fade {geo_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {geo_modal
      ? 'block'
      : 'none'}; z-index:1039;"
    use:show_geo_fence
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Nueva geocerca
            <strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-0">
          <div class="h-100" id="map_geo" />
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <label for="geo_name" class="col col-form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                id="geo_name"
                placeholder=""
                bind:value={actual_geo_name}
                size="30"
              />
            </div>
            <div class="d-flex">
              <label for="geo_class" class="col col-form-label"
                >Clasificación</label
              >
              <div class="btn-group btn-group-toggle ml-2" id="geo_class">
                <label class="btn btn-secondary" class:active={geo_c_sel == 2}>
                  <input
                    type="radio"
                    bind:group={geo_c_sel}
                    value={2}
                    name="geo_c_sel"
                    autocomplete="off"
                  /> Inicio/Fin de Viaje
                </label>
                <label class="btn btn-secondary" class:active={geo_c_sel == 3}>
                  <input
                    type="radio"
                    bind:group={geo_c_sel}
                    value={3}
                    name="geo_c_sel"
                    autocomplete="off"
                  /> Parada Autorizada
                </label>
                <label class="btn btn-secondary" class:active={geo_c_sel == 4}>
                  <input
                    type="radio"
                    bind:group={geo_c_sel}
                    value={4}
                    name="geo_c_sel"
                    autocomplete="off"
                  /> Parada No Autorizada
                </label>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <button
                  on:click={() => {
                    save_geo();
                  }}
                  class="btn btn-success big"
                  type="button"
                >
                  <i class="material-icons md-85">save</i>
                  Guardar geocerca
                </button>
                <button
                  on:click={() => {
                    map_geo = 0;
                    geo_modal = false;
                  }}
                  class="btn btn-danger big"
                  type="button"
                >
                  <i class="material-icons md-85">clear</i>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if route_modal}
  <div
    class="modal fade {route_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {route_modal
      ? 'block'
      : 'none'}; z-index:1039;"
    use:show_route_map
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Ruta nueva
            <strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-0">
          <div class="h-100" id="map_routes" />
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-end">
            <div>
              {#if route_modal_edit}
                <input
                  class="form-con"
                  type="text"
                  value="{route_modal_org == 0
                    ? typeof $geos_class[org_selected] != 'undefined'
                      ? $geos_class[org_selected][0]
                      : $poli[org_selected][0]
                    : route_modal_org == 1
                      ? typeof $geos_class[dest_selected] != 'undefined'
                        ? $geos_class[dest_selected][0]
                        : $poli[dest_selected][0]
                      : typeof $geos_class[
                            travel_toe.sec_dests[route_modal_org - 2]
                          ] != 'undefined'
                        ? $geos_class[
                            travel_toe.sec_dests[route_modal_org - 2]
                          ][0]
                        : $poli[
                            travel_toe.sec_dests[route_modal_org - 2]
                          ][0]} a {route_modal_org == 0
                    ? typeof $geos_class[dest_selected] != 'undefined'
                      ? $geos_class[dest_selected][0]
                      : $poli[dest_selected][0]
                    : typeof $geos_class[
                          travel_toe.sec_dests[route_modal_org - 1]
                        ] != 'undefined'
                      ? $geos_class[
                          travel_toe.sec_dests[route_modal_org - 1]
                        ][0]
                      : $poli[travel_toe.sec_dests[route_modal_org - 1]][0]}"
                  bind:this={route_name}
                  size="60"
                />
              {:else}
                <input
                  class="form-con"
                  type="text"
                  value="{route_modal_org == 0
                    ? typeof $geos_class[org_selected] != 'undefined'
                      ? $geos_class[org_selected][0]
                      : $poli[org_selected][1]
                    : route_modal_org == 1
                      ? typeof $geos_class[dest_selected] != 'undefined'
                        ? $geos_class[dest_selected][0]
                        : $poli[dest_selected][1]
                      : typeof $geos_class[
                            future_travel[0].dest_m[route_modal_org - 2].name
                          ] != 'undefined'
                        ? $geos_class[
                            future_travel[0].dest_m[route_modal_org - 2].name
                          ][0]
                        : $poli[
                            future_travel[0].dest_m[route_modal_org - 2].name
                          ][1]} a {route_modal_org == 0
                    ? typeof $geos_class[dest_selected] != 'undefined'
                      ? $geos_class[dest_selected][0]
                      : $poli[dest_selected][1]
                    : typeof $geos_class[
                          future_travel[0].dest_m[route_modal_org - 1].name
                        ][0] != 'undefined'
                      ? $geos_class[
                          future_travel[0].dest_m[route_modal_org - 1].name
                        ]
                      : $poli[
                          future_travel[0].dest_m[route_modal_org - 1].name
                        ][1]}"
                  bind:this={route_name}
                  size="60"
                />
              {/if}

              <button
                on:click={save_new_route}
                class="btn btn-success big"
                type="button"
              >
                <i class="material-icons md-85">save</i>
                Guardar ruta
              </button>
              <button
                on:click={cancel_new_route}
                class="btn btn-danger big"
                type="button"
              >
                <i class="material-icons md-85">clear</i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if con_delete_travel_modal}
  <div
    class="modal fade {con_delete_travel_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {con_delete_travel_modal
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 20%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons red md-2">warning</i> Confirmar borrado de
            viaje de la unidad {typeof $nicks_list[travel_modal_id] !=
            "undefined"
              ? $nicks_list[travel_modal_id]
              : travel_modal_id}<strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="d-flex flex-column">
            Esta accion borrará el viaje de la unidad.
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-between">
            <div>
              <button
                on:click={() => {
                  con_delete_travel_modal = false;
                  save_travel(travel_modal_id, travel_modal_date_ini);
                }}
                class="btn btn-success big"
                type="button"
              >
                <i class="material-icons md-85">done_outline</i>
                Confirmar
              </button>
            </div>
            <div>
              <button
                on:click={() => {
                  con_delete_travel_modal = false;
                }}
                class="btn btn-danger big"
                type="button"
              >
                <i class="material-icons md-85">not_interested</i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if con_init_travel_modal}
  <!--modal to confirm run route outside or origin-->
  <div
    class="modal fade {con_init_travel_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {con_init_travel_modal
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 20%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons red md-2">warning</i>
            Unidad fuera de origen
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="d-flex flex-column">
            Confirma inicio de viaje con la unidad fuera de origen.
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-between">
            <div>
              <button
                on:click={() => {
                  launch_travel(...con_init_travel_modal_backup, true);
                  con_init_travel_modal = false;
                }}
                class="btn btn-success big"
                type="button"
              >
                <i class="material-icons md-85">done_outline</i>
                Confirmar
              </button>
            </div>
            <div>
              <button
                on:click={() => {
                  con_init_travel_modal = false;
                }}
                class="btn btn-danger big"
                type="button"
              >
                <i class="material-icons md-85">not_interested</i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if con_close_travel_modal}
  <div
    class="modal fade {con_close_travel_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {con_close_travel_modal
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 20%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons red md-2">warning</i> Confirmar cierre de
            viaje de la unidad {typeof $nicks_list[travel_modal_id] !=
            "undefined"
              ? $nicks_list[travel_modal_id]
              : travel_modal_id}<strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="d-flex flex-column">
            Esta accion terminara con el viaje de la unidad.
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-between">
            <div>
              <button
                on:click={() => {
                  con_close_travel_modal = false;
                  end_travel(
                    travel_modal_id,
                    travel_modal_date_ini,
                    travel_modal_id_add
                  );
                }}
                class="btn btn-success big"
                type="button"
              >
                <i class="material-icons md-85">done_outline</i>
                Confirmar
              </button>
            </div>
            <div>
              <button
                on:click={() => {
                  con_close_travel_modal = false;
                }}
                class="btn btn-danger big"
                type="button"
              >
                <i class="material-icons md-85">not_interested</i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if close_travel_modal}
  <div
    class="modal fade {close_travel_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {close_travel_modal
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 20%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Comentario
            <strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="d-flex flex-column">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Razon</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                bind:value={travel_end_reason}
              >
                <option value="0">Unidad terminó viaje</option>
                <option value="1">Unidad canceló viaje</option>
                <option value="2">Unidad no inició viaje</option>
              </select>
            </div>
            <textarea
              class="form-control"
              placeholder="Escribe un comentario..."
              style="width:100%;"
              bind:value={travel_end_comment}
            />
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-end">
            <div>
              <button
                on:click={send_travel_modal}
                class="btn btn-success big"
                type="button"
              >
                <i class="material-icons md-85">save</i>
                Enviar Comentario
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

<!-- Modal Video -->
<div
  class="modal fade {!close_video_modal ? '' : 'show'}"
  id="videoModal"
  tabindex="-1"
  style="display: {!close_video_modal
    ? 'none'
    : 'block'}; background-color: rgba(0, 0, 0, 0.8);"
  aria-labelledby="videoModalLabel"
>
  <div
    class="modal-dialog"
    role="document"
    style="max-width: 90%; height: 90%;"
  >
    <div class="modal-content" style="height: 100%;">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="videoModalLabel"
          style="font-weight: bold; text-align: center; width: 100%;"
        >
          Video en vivo de {driver_video && $drivers
            ? $drivers[driver_video].name
            : ""}
          {mediaRecorder || mediaRecorder2 ? " - Grabando" : ""}
          {#if mediaRecorder || mediaRecorder2}
            <i class="material-icons md-2" style="color: red;"
              >fiber_manual_record</i
            >
          {/if}
        </h5>
        <button
          type="button"
          class="close"
          on:click={() => {
            const video = document.getElementById("video");
            const video2 = document.getElementById("video2");
            video.style.display = "none";
            video2.style.display = "none";
            video.srcObject = null;
            video2.srcObject = null;
            close_video_modal = false;
            socket_query.emit("rtc_disconnect", driver_video);
            cargando_video = true;
            cargando_video2 = true;
            mediaRecorder = null;
            mediaRecorder2 = null;
            // Detener la transmisión de audio
            if (audioStream1) {
              audioStream1.getTracks().forEach((track) => track.stop());
              audioStream1 = null;
            }
          }}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        style="position: relative; display:flex; justify-content: center; overflow: auto;"
      >
        <div
          id="loading-message"
          style="text-align: center; margin-top: 20px; display: {cargando_video &&
          cargando_video2
            ? 'block'
            : 'none'}; font-size: 18px; color: #555;"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Cargando...</span>
          </div>
          <p>Cargando video...</p>
        </div>
        <video
          id="video"
          autoplay
          playsinline
          muted
          style="width: 50%; display: {!cargando_video
            ? 'block'
            : 'none'}; border-radius: 8px;"
        >
        </video>
        <video
          id="video2"
          autoplay
          playsinline
          muted
          style="width: 50%; display: {!cargando_video2
            ? 'block'
            : 'none'}; border-radius: 8px;"
        >
        </video>
      </div>
      <div
        class="modal-footer"
        style="justify-content: {cargando_video || cargando_video2
          ? 'space-between'
          : 'flex-end'}"
      >
        {#if cargando_video || cargando_video2}
          <button
            type="button"
            class="btn btn-primary"
            on:click={() => {
              const video = document.getElementById("video");
              const video2 = document.getElementById("video2");
              video.style.display = "none";
              video2.style.display = "none";
              video.srcObject = null;
              video2.srcObject = null;
              socket_query.emit("rtc_disconnect", driver_video);
              cargando_video = true;
              cargando_video2 = true;
              mediaRecorder = null;
              mediaRecorder2 = null;
              setTimeout(() => {
                show_video(driver_video);
              }, 1000);
            }}
          >
            <i class="material-icons md-2">refresh</i>
            Cambiar cámara
          </button>
        {/if}
        {#if !cargando_video || !cargando_video2}
          <button
            type="button"
            class="btn btn-success"
            on:click={() => {
              const video = document.getElementById("video");
              const video2 = document.getElementById("video2");
              if (video.srcObject) {
                video.requestPictureInPicture();
                const modal = document.getElementById("videoModal");
                modal.style["z-index"] = 0;
                video.onleavepictureinpicture = () => {
                  modal.style["z-index"] = 1050;
                };
              } else if (video2.srcObject) {
                video2.requestPictureInPicture();
                const modal = document.getElementById("videoModal");
                modal.style["z-index"] = 0;
                video2.onleavepictureinpicture = () => {
                  modal.style["z-index"] = 1050;
                };
              }
            }}
          >
            <i class="material-icons md-2">picture_in_picture_alt</i>
            Extraer video
          </button>
        {/if}
        {#if mediaRecorder || mediaRecorder2}
          <button
            type="button"
            class="btn btn-warning"
            on:click={() => {
              if (mediaRecorder) {
                mediaRecorder.stop();
                mediaRecorder.onstop = () => {
                  const blob = new Blob(recordedChunks, { type: "video/webm" });
                  const url = URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download =
                    (driver_video && $drivers
                      ? $drivers[driver_video].name
                      : "") +
                    " " +
                    new Date().toLocaleDateString().replace(/\//g, "-") +
                    " " +
                    new Date().toLocaleTimeString() +
                    "_1.webm";
                  a.click();
                  console.log("Recording stopped and downloaded");
                  mediaRecorder = null;
                };
              }
              if (mediaRecorder2) {
                mediaRecorder2.stop();
                mediaRecorder2.onstop = () => {
                  const blob = new Blob(recordedChunks2, {
                    type: "video/webm",
                  });
                  const url = URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download =
                    (driver_video && $drivers
                      ? $drivers[driver_video].name
                      : "") +
                    " " +
                    new Date().toLocaleDateString().replace(/\//g, "-") +
                    " " +
                    new Date().toLocaleTimeString() +
                    "_2.webm";
                  a.click();
                  console.log("Recording stopped and downloaded");
                  mediaRecorder2 = null;
                };
              }
            }}
          >
            <i class="material-icons md-2">stop</i>
            Guardar Grabación
          </button>
        {/if}
        <button
          type="button"
          class="btn btn-secondary"
          on:click={() => {
            const video = document.getElementById("video");
            const video2 = document.getElementById("video2");
            video.style.display = "none";
            video2.style.display = "none";
            video.srcObject = null;
            video2.srcObject = null;
            close_video_modal = false;
            socket_query.emit("rtc_disconnect", driver_video);
            cargando_video = true;
            cargando_video2 = true;
            mediaRecorder = null;
            mediaRecorder2 = null;
            // Detener la transmisión de audio
            if (audioStream1) {
              audioStream1.getTracks().forEach((track) => track.stop());
              audioStream1 = null;
            }
          }}
        >
          <i class="material-icons md-2">close</i>
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-md-6 h-100">
      <div class="row h-100 no-gutters">
        <div class="col-12 h-100">
          <div class="card h-100 bg-light card-b">
            <div class="d-flex flex-column h-100">
              <div
                class="card-header b-0 bg-dark text-white"
                style="line-height: 1.5"
              >
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <i class="material-icons md-85">verified_user</i> Unidades
                    <span
                      tooltip="Unidades Totales"
                      class="badge badge-pill badge-primary"
                      >{Object.keys(travels_fz).length}</span
                    >
                    <span
                      tooltip="Unidades con alertas"
                      class="badge badge-pill badge-danger">{total_alerts}</span
                    >
                    <span
                      tooltip="Unidades con monitor"
                      class="badge badge-pill badge-success"
                      >{total_follow}</span
                    >
                    <span
                      tooltip="Alertas en espera"
                      class="badge badge-pill badge-warning"
                      >{total_wating}</span
                    >
                  </div>
                  {#if f_todas == 1 && paginas_l.length > 1}
                    <div>
                      Páginas
                      <div class="btn-group mx-2" role="group">
                        {#each paginas_l as pag_n}
                          <button
                            on:click|preventDefault={() => filtro(7 + pag_n)}
                            type="button"
                            class="btn {f_pag == pag_n
                              ? 'btn-info'
                              : 'btn-outline-info'}">{pag_n + 1}</button
                          >
                        {/each}
                      </div>
                    </div>
                  {/if}
                  <div class="d-flex justify-content-end">
                    <input
                      on:click={() => {
                        eco_f_ = "";
                      }}
                      bind:value={eco_f_}
                      list="lista_unidades"
                      placeholder="Buscar Unidad"
                      size="20"
                      on:change={(event) => {
                        eco_f = "";
                        for (let w in $nicks_list) {
                          if ($nicks_list[w] == event.target.value) {
                            eco_f = w;
                          }
                        }
                      }}
                    />
                    <datalist id="lista_unidades">
                      {#each units_list_p as item, i}
                        <option
                          value={typeof $nicks_list[item] != "undefined"
                            ? $nicks_list[item]
                            : item}
                        />{/each}
                    </datalist>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row-reverse align-items-center my-1">
                <div class="btn-group mx-2" role="group">
                  <button
                    on:click|preventDefault={() => filtro(0)}
                    type="button"
                    class="btn {f_monitor == 1
                      ? 'btn-info'
                      : 'btn-outline-info'}">Monitoreadas</button
                  >
                  <button
                    on:click|preventDefault={() => filtro(1)}
                    type="button"
                    class="btn {f_alertas == 1
                      ? 'btn-info'
                      : 'btn-outline-info'}">Con alertas</button
                  >
                  <button
                    on:click|preventDefault={() => filtro(2)}
                    type="button"
                    class="btn {f_todas == 1 ? 'btn-info' : 'btn-outline-info'}"
                    >Sin monitoreo</button
                  >
                </div>
                <div class="btn-group mx-2" role="group">
                  <button
                    on:click|preventDefault={() => filtro(3)}
                    type="button"
                    class="btn {f_viaje == 1 ? 'btn-info' : 'btn-outline-info'}"
                    >En movimiento</button
                  >
                  <button
                    on:click|preventDefault={() => filtro(4)}
                    type="button"
                    class="btn {f_sinviaje == 1
                      ? 'btn-info'
                      : 'btn-outline-info'}">Estacionadas</button
                  >
                </div>
                <div class="btn-group mx-2" role="group">
                  <button
                    on:click|preventDefault={() => filtro(5)}
                    type="button"
                    class="btn {f_dispositivos == 1
                      ? 'btn-info'
                      : 'btn-outline-info'}">Dispositivos</button
                  >
                  <button
                    on:click|preventDefault={() => filtro(6)}
                    type="button"
                    class="btn {f_unidades == 1
                      ? 'btn-info'
                      : 'btn-outline-info'}">Unidades</button
                  >
                </div>
              </div>
              <div
                class="card-body flex-grow-1 overflow-auto"
                style="overflow-x: hidden;"
                id="followed_travel_list"
              >
                {#if total_filters == 0}
                  <div
                    class="d-flex justify-content-center"
                    style="height: 100%; flex-direction: column; align-items: center;"
                  >
                    <div class="alert" role="alert" style="width: 40%;">
                      <i class="material-icons md-2">info</i>
                      No hay unidades con el filtro seleccionado
                    </div>
                  </div>
                {/if}
                {#each travels_fh as item, i (item.id)}
                  {#if ((f_pag == item.page && f_todas == 1) || f_todas == 0) && typeof travels_fz[item.id].ok != "undefined" && ((f_monitor == 1 && travels_fz[item.id].oper != "Desconocido") || (f_todas == 1 && travels_fz[item.id].oper == "Desconocido") || (f_alertas == 1 && ((travels_fz[item.id].alerts_list.length > 0 && travels_fz[item.id].monitor_crtl.follow == 1) || travels_fz[item.id].alerts_now > 0))) && ((f_viaje == 1 && travels_fz[item.id].org.time != "") || (f_sinviaje == 1 && travels_fz[item.id].org.time == "")) && ((f_unidades == 1 && typeof future_travel_master[item.id] != "undefined") || f_dispositivos == 1)}
                    <div
                      id="seg_{item.id}"
                      class="card mb-1 mx-2 {travels_fz[item.id].state == 'OK'
                        ? ''
                        : 'big-border border-danger'} {selected == item.id
                        ? 'med-border border-info'
                        : ''}"
                      style="font-size:0.78rem"
                      use:monitor_v
                      on:contextmenu|preventDefault={() =>
                        options_units(
                          item.id,
                          travels_fz[item.id].monitor_crtl.follow
                        )}
                    >
                      <div
                        on:click={(s) => {
                          maping(item.id, s);
                          select_prog(item.id);
                        }}
                        class="card-header b-0"
                      >
                        <div class="d-flex">
                          <div class="d-flex flex-column flex-grow-1 mb-0">
                            <div>
                              <i
                                tooltip={item.id}
                                class="material-icons"
                                style="color:{travels_fz[item.id].monitor_crtl
                                  .follow == 0
                                  ? '#78909c'
                                  : travels_fz[item.id].alerts_now > 0
                                    ? '#df1616'
                                    : travels_fz[item.id].alerts_list.length > 0
                                      ? '#df1616'
                                      : 'rgb(24, 179, 45)'}">verified_user</i
                              >
                              <strong>
                                {typeof $nicks_list[item.id] != "undefined"
                                  ? $nicks_list[item.id]
                                  : item.id}
                                {#if f_unidades == 1 && typeof future_travel_master[item.id] != "undefined"}
                                  {#each future_travel_master[item.id][0] as id_ex, i}
                                    <span style="color:#607D8B;"
                                      >,{typeof $nicks_list[id_ex] !=
                                      "undefined"
                                        ? $nicks_list[id_ex]
                                        : id_ex}</span
                                    >
                                  {/each}
                                {/if}
                              </strong>
                              <!-- Salidas digitales -->
                              {#each monitor_outputs as out, i}
                                {#if out.enabled && travels_fz[item.id].outputs}
                                  <span tooltip={out.name}>
                                    <i
                                      class="material-icons md-85"
                                      style="color:{travels_fz[item.id].outputs[
                                        7 - i
                                      ] == '1'
                                        ? 'green'
                                        : 'red'}">radio_button_checked</i
                                    >
                                  </span>
                                {/if}
                              {/each}
                              <span tooltip={travels_fz[item.id].geo_stay[0]}
                                >{typeof travels_fz[item.id].geo_stay[0] !=
                                "undefined"
                                  ? travels_fz[item.id].geo_stay[0].substr(
                                      0,
                                      45
                                    )
                                  : ""}</span
                              >
                              {#if travels_fz[item.id].alerts_list.length > 0}
                                {#each travels_fz[item.id].alerts_list as alerts (alerts[3])}
                                  <i
                                    tooltip="{alerts[0]} {moment(
                                      new Date(alerts[2] * 1000)
                                    ).format('DD/MM/YY HH:mm')}"
                                    class="material-icons md-90"
                                    style="color:#a30000">{alerts[1]}</i
                                  >
                                {/each}
                              {/if}
                            </div>
                            <div>
                              {#if travels_fz[item.id].org.time != "" || travels_fz[item.id].oper != "Desconocido"}
                                <i
                                  tooltip="Origen"
                                  class="material-icons md-90"
                                  style="color:#26a69a"
                                  >{travels_fz[item.id].org.time != ""
                                    ? "play_circle_outline"
                                    : "change_history"}</i
                                >
                                <strong
                                  >{travels_fz[item.id].org.time} |
                                </strong><span
                                  style={false ? "color:#2196f3" : ""}
                                  ><strong
                                    >{travels_fz[item.id].org.name !=
                                    "Desconocido"
                                      ? travels_fz[item.id].org.name
                                      : travels_fz[item.id].geo_stay[0]}</strong
                                  ></span
                                >
                              {:else}
                                <i
                                  class="material-icons md-90"
                                  style="color:#26a69a">change_history</i
                                >
                                <strong>
                                  Estacionada, sin viaje asignado</strong
                                >
                              {/if}
                            </div>
                            <div>
                              {#if travels_fz[item.id].org.time != "" || travels_fz[item.id].oper != "Desconocido"}
                                <i
                                  tooltip="Destino"
                                  class="material-icons md-90"
                                  style="color:#26a69a">stop</i
                                >
                                {#if show_destinos != item.id}
                                  <span
                                    on:click={() => {
                                      destino_edit(item.id);
                                    }}
                                    style={travels_fz[item.id].dest.name ==
                                    "Desconocido"
                                      ? "color:#2196f3"
                                      : ""}
                                    ><strong id="change_destino"
                                      >{typeof $geos_class[
                                        travels_fz[item.id].dest.name
                                      ] != "undefined"
                                        ? $geos_class[
                                            travels_fz[item.id].dest.name
                                          ][0]
                                        : typeof $poli[
                                              travels_fz[item.id].dest.name
                                            ] != "undefined"
                                          ? $poli[
                                              travels_fz[item.id].dest.name
                                            ][1]
                                          : travels_fz[item.id].dest
                                              .name}</strong
                                    ></span
                                  >
                                {:else}
                                  <select
                                    id="destino_list"
                                    bind:value={destino_selected}
                                  >
                                    <option value="">Desconocido</option>
                                    {#each $geos_class_list as item, i}
                                      <option value={item[0]}>{item[1]}</option>
                                    {/each}
                                    {#each $poli_list as item, i}
                                      <option value={item[0]}>{item[2]}</option>
                                    {/each}
                                  </select>
                                  <button
                                    id="save_destino"
                                    on:click={() => {
                                      save_destino(item.id, destino_selected);
                                    }}
                                    type="button"
                                    class="btn btn-success">Guardar</button
                                  >
                                  <button
                                    id="cancel_save_destino"
                                    on:click={() => {
                                      show_destinos = -1;
                                    }}
                                    type="button"
                                    class="btn btn-danger">Cancelar</button
                                  >
                                {/if}
                              {/if}
                            </div>
                            <div>
                              {#if travels_fz[item.id].org.time != "" || travels_fz[item.id].oper != "Desconocido"}
                                {#if show_rutas != item.id}
                                  <i
                                    tooltip="Ruta"
                                    class="material-icons md-90"
                                    style="color:#26a69a">directions</i
                                  >
                                  <span
                                    tooltip={typeof $route_list_d[
                                      travels_fz[item.id].ruta.name
                                    ] != "undefined"
                                      ? $route_list_d[
                                          travels_fz[item.id].ruta.name
                                        ]
                                      : "Nombre no disponible"}
                                    on:click={() => {
                                      ruta_edit(item.id);
                                    }}
                                    style={travels_fz[item.id].ruta.name ==
                                    "Desconocida"
                                      ? "color:#2196f3"
                                      : ""}
                                    ><strong id="change_ruta"
                                      >{typeof $route_list_d[
                                        travels_fz[item.id].ruta.name
                                      ] != "undefined"
                                        ? $route_list_d[
                                            travels_fz[item.id].ruta.name
                                          ].length > 35
                                          ? $route_list_d[
                                              travels_fz[item.id].ruta.name
                                            ].slice(0, 35) + "..."
                                          : $route_list_d[
                                              travels_fz[item.id].ruta.name
                                            ]
                                        : "Nombre no disponible"}</strong
                                    ></span
                                  >
                                {:else}
                                  <select
                                    id="ruta_list"
                                    bind:value={ruta_selected}
                                  >
                                    <option value="">Desconocida</option>
                                    {#each route_list_ as item_, i}
                                      {#if travels_fz[item.id].dest.name == "Desconocido" || travels_fz[item.id].dest.name == item_[3][2] || $geos_class_join[travels_fz[item.id].dest.name][2] == item_[3][2]}
                                        <option value={item_[0]}
                                          >{item_[1]}</option
                                        >
                                      {/if}
                                    {/each}
                                  </select>
                                  <button
                                    id="save_ruta"
                                    on:click={() => {
                                      save_ruta(item.id, ruta_selected);
                                    }}
                                    type="button"
                                    class="btn btn-success">Guardar</button
                                  >
                                  <button
                                    id="cancel_save_ruta"
                                    on:click={() => {
                                      show_rutas = -1;
                                    }}
                                    type="button"
                                    class="btn btn-danger">Cancelar</button
                                  >
                                {/if}
                              {/if}
                            </div>
                          </div>
                          <div class="d-flex flex-column">
                            <div class="d-flex justify-content-end mb-1">
                              <div class="p-1">
                                <strong>GPS</strong>
                                <i
                                  tooltip={travels_fz[item.id].gps.level}
                                  class="material-icons {travels_fz[item.id].gps
                                    .level < 1.5
                                    ? 'green'
                                    : 'red'}"
                                  >{travels_fz[item.id].gps.level < 1.5
                                    ? "location_on"
                                    : "location_off"}</i
                                >
                              </div>

                              <div class="p-1">
                                <strong
                                  >{typeof travels_fz[item.id].gsm.net !=
                                  "undefined"
                                    ? travels_fz[item.id].gsm.net
                                    : "GSM"}</strong
                                ><i
                                  tooltip="-{travels_fz[item.id].gsm.level}"
                                  class="material-icons {travels_fz[item.id].gsm
                                    .level < 90
                                    ? 'green'
                                    : 'red'}"
                                  >{travels_fz[item.id].gsm.level < 90
                                    ? "signal_cellular_alt"
                                    : "signal_cellular_connected_no_internet_4_bar"}</i
                                >
                              </div>

                              <div class="p-1">
                                <i
                                  tooltip="Última comunicación"
                                  class="material-icons {travels_fz[item.id].gsm
                                    .last
                                    ? 'green'
                                    : 'red'}"
                                  >{travels_fz[item.id].gsm.last
                                    ? "sync"
                                    : "sync_problem"}</i
                                >
                                <strong
                                  >{travels_fz[item.id].gsm.text}<strong
                                  /></strong
                                >
                              </div>

                              {#if travels_fz[item.id].alerts_now > 0}
                                <div class="p-1">
                                  <a href="/" on:click|preventDefault
                                    ><i
                                      mobid={item.id}
                                      id="alerts_box"
                                      tooltip="Alertas"
                                      class="material-icons {travels_fz[item.id]
                                        .proto_status == ''
                                        ? 'red'
                                        : 'yei'}">warning</i
                                    >
                                  </a>
                                  <strong
                                    >{travels_fz[item.id].alerts_now}</strong
                                  >
                                </div>
                              {/if}
                              {#if f_unidades == 1 && typeof future_travel_master[item.id] != "undefined"}
                                {#each future_travel_master[item.id] as id_ex, i}
                                  {#if typeof travels_fz[id_ex] != "undefined"}
                                    {#if travels_fz[id_ex].alerts_now > 0}
                                      <div class="p-1">
                                        <a href="/" on:click|preventDefault
                                          ><i
                                            mobid={id_ex}
                                            id="alerts_box"
                                            tooltip="Alertas"
                                            class="material-icons {travels_fz[
                                              id_ex
                                            ].proto_status == ''
                                              ? 'red'
                                              : 'yei'}">warning</i
                                          >
                                        </a>
                                        <strong
                                          >{travels_fz[id_ex]
                                            .alerts_now}</strong
                                        >
                                      </div>
                                    {/if}
                                  {/if}
                                {/each}
                              {/if}
                            </div>
                            <div class="d-flex justify-content-end mb-1">
                              <div class="p-1">
                                <i
                                  tooltip="Velocidad"
                                  class="material-icons green">speed</i
                                >
                                <strong
                                  >{travels_fz[item.id].mov.speed} Km/hr</strong
                                >
                              </div>
                              <div class="p-1">
                                <i
                                  tooltip="Tiempo detenida {travels_fz[item.id]
                                    .mov.time_stop}min"
                                  class="material-icons {travels_fz[item.id]
                                    .ign == 0
                                    ? 'gray'
                                    : travels_fz[item.id].mov.time_stop > 3
                                      ? 'yei'
                                      : 'green'}">pause_presentation</i
                                >
                                <strong
                                  >{time_code(
                                    travels_fz[item.id].mov.time_stop
                                  )}</strong
                                >
                              </div>
                              <div class="p-1">
                                <i
                                  tooltip="Ignición {travels_fz[item.id].ign ==
                                  1
                                    ? 'On'
                                    : 'Off'}"
                                  class="material-icons {travels_fz[item.id]
                                    .ign == 1
                                    ? 'green'
                                    : 'gray'}">vpn_key</i
                                >
                              </div>
                            </div>
                            <div
                              class="d-flex justify-content-end align-items-center"
                            >
                              <i
                                tooltip="Operador"
                                class="material-icons md-90"
                                style="color:#26a69a">person</i
                              >
                              {#if show_drivers != item.id}
                                <span
                                  on:click={() => {
                                    drivers_edit(item.id);
                                  }}
                                  style={travels_fz[item.id].oper ==
                                    "Desconocido" ||
                                  travels_fz[item.id].oper == ""
                                    ? "color:#2196f3"
                                    : ""}
                                  ><strong id="change_driver"
                                    >{typeof $drivers[
                                      travels_fz[item.id].oper
                                    ] != "undefined"
                                      ? $drivers[travels_fz[item.id].oper].name
                                      : travels_fz[item.id].oper}</strong
                                  ></span
                                >
                              {:else}
                                <select
                                  id="driver_list"
                                  bind:value={driver_selected}
                                >
                                  <option value="">Desconocido</option>
                                  {#each $drivers_list as item, i}
                                    <option value={item[0]}
                                      >{item[1]["name"]}</option
                                    >
                                  {/each}
                                </select>
                                <button
                                  id="save_driver"
                                  on:click={() => {
                                    save_driver(item.id, driver_selected);
                                  }}
                                  type="button"
                                  class="btn btn-success mx-1">Guardar</button
                                >
                                <button
                                  id="cancel_save_driver"
                                  on:click={() => {
                                    show_drivers = -1;
                                  }}
                                  type="button"
                                  class="btn btn-danger mx-1">Cancelar</button
                                >
                              {/if}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 h-100" style="display: flex; flex-direction: column;">
      <div
        class="row no-gutters"
        style="height:{expand_map
          ? 'unset'
          : mapa_ex_mode
            ? '0%'
            : 'unset'}; {expand_map ? 'flex-grow: 1;' : 'flex-grow: 1;'}"
      >
        <div class="col-12 h-100">
          <div class="h-100" id="map" />
        </div>
      </div>
      <div
        class="row no-gutters"
        style="max-height: 50%; height:{expand_map
          ? 'unset'
          : mapa_ex_mode
            ? '100%'
            : 'unset'};"
      >
        <div class="col-12 h-100">
          <div class="card h-100 bg-light card-b">
            <div class="d-flex flex-column h-100">
              <div
                class="card-header b-0 bg-dark text-white"
                style="line-height: 1.5; padding-right: 10px;"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column flex-grow-1">
                    <div>
                      <i class="material-icons md-85">alarm</i> Viajes
                      programados
                      <span
                        tooltip="Viajes programados"
                        class="badge badge-pill badge-primary"
                        >{future_travel.length}</span
                      >
                      <span
                        tooltip="Viajes en proceso"
                        class="badge badge-pill badge-warning"
                        >{total_en_proceso}</span
                      >
                      <span
                        tooltip="Viajes terminados"
                        class="badge badge-pill badge-success"
                        >{total_terminado}</span
                      >
                      {#if !caravan_mode}
                        <button
                          on:click={mapa_ex}
                          id="mapa_ex"
                          type="button"
                          class="btn btn-info"
                          style="margin-left: 0.75rem;"
                          >{mapa_ex_mode
                            ? "Mostrar mapa"
                            : "Extraer Mapa"}</button
                        >
                      {/if}
                      {#if !new_travel_s && edit_mode == -1}
                        <button
                          on:click={caravan}
                          id="mapa_ex"
                          type="button"
                          class="btn btn-{caravan_mode ? 'warning' : 'success'}"
                          style="margin-left: 0.75rem;"
                          >{!caravan_mode
                            ? "Crear caravana"
                            : "Crear caravana"}</button
                        >
                        {#if caravan_mode}
                          <button
                            on:click={() => {
                              caravan_mode = false;
                            }}
                            id="mapa_ex"
                            type="button"
                            class="btn btn-danger">Cancelar</button
                          >
                        {/if}
                      {/if}
                    </div>
                  </div>
                  {#if edit_mode == -1}
                    <div class="d-flex flex-column">
                      <div class="d-flex justify-content-end">
                        <button
                          on:click={new_travel}
                          id="edit_prog"
                          type="button"
                          class="btn btn-{new_travel_s ? 'warning' : 'success'}"
                          >{new_travel_s ? "Cancelar Viaje" : "Nuevo Viaje"}
                        </button>
                      </div>
                    </div>
                  {/if}
                  <i
                    on:click={() => {
                      expand_map = !expand_map;
                      localStorage.setItem("expand_map", expand_map);
                    }}
                    class="material-icons md-85"
                    style="margin-left: 0.75rem; cursor: pointer;"
                  >
                    {!expand_map ? "expand_more" : "expand_less"}
                  </i>
                </div>
              </div>
              <div
                class="card-body flex-grow-1 overflow-auto pt-1"
                style="z-index:4; {expand_map
                  ? 'display: none;'
                  : ''} {!expand_map && future_travel.length == 0
                  ? 'display: flex; flex-direction: column; justify-content: center; align-items: center;'
                  : ''}"
                id="lista_viajes_prog"
              >
                {#if future_travel.length == 0}
                  <div class="alert" role="alert">
                    <i class="material-icons md-85">info</i>
                    No hay viajes programados
                  </div>
                {/if}
                {#each future_travel as item, i (item.id + item.date_ini)}
                  <div
                    class="card pt-0 mx-2 {prog_selected == item.id
                      ? 'med-border border-info'
                      : ''}"
                    style="font-size:0.78rem; margin-bottom: 5px;"
                    id="prog_{item.id + item.date_ini}"
                    on:contextmenu|preventDefault={() => travel_link(item.uuid)}
                  >
                    <div
                      on:click={(event) => {
                        if (item.id != "") {
                          console.log("click", event);
                          maping(item.id, {});
                          prog_selected = item.id;
                          busca_future_travel(item.id);
                        }
                      }}
                      class="card-header b-0"
                    >
                      <div class="d-flex">
                        <div class="d-flex flex-column flex-grow-1">
                          {#if item.id == ""}
                            <div>
                              <i
                                tooltip="Unidad"
                                class="material-icons md-90"
                                style="color:#26a69a">directions_car</i
                              >
                              <input
                                list="lista_unidades"
                                placeholder="Unidad"
                                size="8"
                                on:change={(event) => {
                                  eco_selected = "";
                                  for (let w in $nicks_list) {
                                    if ($nicks_list[w] == event.target.value) {
                                      eco_selected = w;
                                    }
                                  }
                                }}
                              />
                              <datalist id="lista_unidades">
                                {#each units_list_p as item, i}
                                  <option
                                    value={typeof $nicks_list[item] !=
                                    "undefined"
                                      ? $nicks_list[item]
                                      : item}
                                  />{/each}
                              </datalist>
                              {#each eco_add_selected as item, i}
                                <input
                                  list="lista_unidades"
                                  placeholder="Unidad"
                                  size="8"
                                  on:change={(event) => {
                                    eco_add_selected[i] = "";
                                    for (let w in $nicks_list) {
                                      if (
                                        $nicks_list[w] == event.target.value
                                      ) {
                                        eco_add_selected[i] = w;
                                      }
                                    }
                                  }}
                                />
                                <datalist id="lista_unidades">
                                  {#each units_list_p as item, i}
                                    <option
                                      value={typeof $nicks_list[item] !=
                                      "undefined"
                                        ? $nicks_list[item]
                                        : item}
                                    />{/each}
                                </datalist>
                              {/each}
                              {#if eco_selected != "" && (eco_add_selected.length == 0 || eco_add_selected[eco_add_selected.length - 1] != "")}
                                <button
                                  tooltip="Agregar"
                                  type="button"
                                  class="btn btn-link p-0"
                                  on:click={() => {
                                    eco_add_selected.push("");
                                    eco_add_selected = [...eco_add_selected];
                                  }}
                                >
                                  <i
                                    class="material-icons md-85"
                                    style="color:#43a047">add_box</i
                                  >
                                </button>
                              {/if}
                            </div>
                          {/if}
                          <div>
                            {#if caravan_mode && item.step - 2 != item.sec_dests.length && item.step > 0}
                              <input
                                type="checkbox"
                                bind:group={caravan_group}
                                value={item.uuid}
                              />
                            {/if}
                            {#if typeof item.caravan_id != "undefined"}
                              <i
                                tooltip="Caravana {item.caravan_id}"
                                class="material-icons md-90"
                                style="color:{item.caravan_color}"
                                >brightness_1</i
                              >
                            {:else}
                              <i
                                tooltip="Viaje"
                                class="material-icons md-90"
                                style="color:#26a69a">alarm</i
                              >
                            {/if}
                            {#if item.id != ""}
                              <strong>
                                {typeof $nicks_list[item.id] != "undefined"
                                  ? $nicks_list[item.id]
                                  : item.id}
                                {#if id_add_selected == item.id + item.date_ini}
                                  {#each item.id_add as item, i}
                                    , <span style="color:#607D8B;"
                                      >{typeof $nicks_list[item] != "undefined"
                                        ? $nicks_list[item]
                                        : item}</span
                                    >
                                  {/each}
                                {/if}
                              </strong>
                              {#if typeof item.id_add != "undefined"}
                                {#if item.id_add.length > 0}
                                  <button
                                    style="padding: 1px 1px;"
                                    type="button"
                                    class="btn btn-link"
                                    on:click={() => {
                                      id_add_selected =
                                        id_add_selected ==
                                        item.id + item.date_ini
                                          ? -1
                                          : item.id + item.date_ini;
                                    }}
                                    ><i class="material-icons md-90 green"
                                      >{id_add_selected ==
                                      item.id + item.date_ini
                                        ? "remove_circle_outline"
                                        : "add_circle_outline"}</i
                                    ></button
                                  >
                                {/if}
                              {/if}
                              |
                              <span
                                style="color:{item.step == 0
                                  ? '#ff8f00'
                                  : '#8bc34a'}"
                                ><strong
                                  >{moment(
                                    new Date(item.date_ini * 1000)
                                  ).format("DD/MM/YY HH:mm")}</strong
                                ></span
                              >
                              {#if item.step == 1}
                                | <span style="color:#546e7a"
                                  ><strong>Iniciado</strong></span
                                >
                              {:else if item.step - 2 == item.sec_dests.length}
                                | <span style="color:#d84315"
                                  ><strong>Terminado</strong></span
                                >
                              {:else if item.step > 1}
                                | <span style="color:#546e7a"
                                  ><strong
                                    >{item.step - 1} de {item.sec_dests.length +
                                      1}</strong
                                  ></span
                                >
                              {/if}
                              {#if item.step > 0}
                                |
                                <button
                                  on:click={() => {
                                    end_travel_modal(
                                      item.id,
                                      item.date_ini,
                                      item.uuid,
                                      item.id_add
                                    );
                                  }}
                                  id="end_travel_{$nicks_list[item.id]}"
                                  type="button"
                                  class="btn btn-info"
                                >
                                  Cerrar viaje
                                </button>
                              {/if}
                              {#if item.step == 0 && edit_mode != item.id + item.date_ini}
                                |
                                <button
                                  on:click={() => {
                                    launch_travel(
                                      item.id,
                                      item.date_ini,
                                      item.dest,
                                      item.oper,
                                      item.route,
                                      item.id_add,
                                      item.org
                                    );
                                  }}
                                  id="launch_prog_{$nicks_list[item.id]}"
                                  type="button"
                                  class="btn btn-warning"
                                >
                                  Iniciar
                                </button>
                              {/if}
                            {:else}
                              <input
                                list="fecha"
                                placeholder="Fecha"
                                size="8"
                                on:change={(event) => {
                                  fecha_v = "";
                                  for (let w in fechas) {
                                    if (fechas[w][1] == event.target.value) {
                                      fecha_v = fechas[w][0];
                                    }
                                  }
                                }}
                              />
                              <datalist id="fecha">
                                {#each fechas as item, i}
                                  <option value={item[1]} />{/each}
                              </datalist>
                              <input
                                list="hora"
                                placeholder="Hora"
                                size="5"
                                on:change={(event) => {
                                  hora_v = "";
                                  for (let w in horas) {
                                    if (horas[w][1] == event.target.value) {
                                      hora_v = horas[w][0];
                                    }
                                  }
                                }}
                              />
                              <datalist id="hora">
                                {#each horas as item, i}
                                  <option value={item[1]} />{/each}
                              </datalist>
                            {/if}
                          </div>
                          <div>
                            <i
                              tooltip="Origen"
                              class="material-icons md-90"
                              style="color:#26a69a">play_circle_outline</i
                            >
                            {#if (item.id != "" && edit_mode != item.id + item.date_ini) || (edit_mode == item.id + item.date_ini && item.step != 0)}
                              <strong
                                >{typeof $geos_class[item.org] != "undefined"
                                  ? $geos_class[item.org][0]
                                  : typeof $poli[item.org] != "undefined"
                                    ? $poli[item.org][1]
                                    : item.org}</strong
                              >
                              {#if item.step > 0}
                                <i
                                  class="material-icons md-85"
                                  style="color:#aed581">check_circle</i
                                >
                              {/if}
                            {:else if edit_mode == item.id + item.date_ini}
                              <input
                                list="org_list"
                                placeholder="Origen"
                                size="30"
                                on:change={(event) => {
                                  org_selected = "";
                                  for (let x in $geos_class_list) {
                                    if (
                                      $geos_class_list[x][1] ==
                                      event.target.value
                                    ) {
                                      org_selected = $geos_class_list[x][0];
                                    }
                                  }
                                  for (let x in $poli_list) {
                                    if (
                                      $poli_list[x][2] == event.target.value
                                    ) {
                                      org_selected = $poli_list[x][0];
                                    }
                                  }
                                }}
                                value={typeof $geos_class[item.org] !=
                                "undefined"
                                  ? $geos_class[item.org][0]
                                  : typeof $poli[item.org] != "undefined"
                                    ? $poli[item.org][1]
                                    : item.org}
                              />
                              <datalist id="org_list">
                                {#each $geos_class_list as item, i}
                                  <option value={item[1]} />{/each}
                                {#each $poli_list as item, i}
                                  <option value={item[2]} />{/each}
                              </datalist>
                            {:else}
                              <input
                                list="org_list"
                                placeholder="Origen"
                                size="30"
                                on:change={(event) => {
                                  org_selected = "";
                                  for (let x in $geos_class_list) {
                                    if (
                                      $geos_class_list[x][1] ==
                                      event.target.value
                                    ) {
                                      org_selected = $geos_class_list[x][0];
                                    }
                                  }
                                  for (let x in $poli_list) {
                                    if (
                                      $poli_list[x][2] == event.target.value
                                    ) {
                                      org_selected = $poli_list[x][0];
                                    }
                                  }
                                }}
                              />
                              <datalist id="org_list">
                                {#each $geos_class_list as item, i}
                                  <option value={item[1]} />{/each}
                                {#each $poli_list as item, i}
                                  <option value={item[2]} />{/each}
                              </datalist>
                            {/if}
                          </div>
                          <div>
                            <i
                              tooltip="Destino"
                              class="material-icons md-90"
                              style="color:#26a69a">stop</i
                            >
                            {#if item.id != ""}
                              {#if item.sec_dests.length == 0 || e_selected == item.id + item.date_ini}
                                {#if edit_mode != item.id + item.date_ini || (edit_mode == item.id + item.date_ini && item.step > 1)}
                                  <strong
                                    >{typeof $geos_class[item.dest] !=
                                    "undefined"
                                      ? $geos_class[item.dest][0]
                                      : typeof $poli[item.dest] != "undefined"
                                        ? $poli[item.dest][1]
                                        : item.dest}</strong
                                  >
                                  {#if item.step > 1}
                                    <i
                                      class="material-icons md-85"
                                      style="color:#aed581">check_circle</i
                                    >
                                  {/if}
                                {:else}
                                  <input
                                    list="dest_list"
                                    placeholder="Destino"
                                    size="30"
                                    on:change={(event) => {
                                      dest_selected = "";
                                      for (let x in $geos_class_list) {
                                        if (
                                          $geos_class_list[x][1] ==
                                          event.target.value
                                        ) {
                                          dest_selected =
                                            $geos_class_list[x][0];
                                        }
                                      }
                                      for (let x in $poli_list) {
                                        if (
                                          $poli_list[x][2] == event.target.value
                                        ) {
                                          dest_selected = $poli_list[x][0];
                                        }
                                      }
                                    }}
                                    value={typeof $geos_class[item.dest] !=
                                    "undefined"
                                      ? $geos_class[item.dest][0]
                                      : typeof $poli[item.dest] != "undefined"
                                        ? $poli[item.dest][1]
                                        : item.dest}
                                  />
                                  <button
                                    tooltip="Borrar"
                                    type="button"
                                    class="btn btn-link p-0"
                                    on:click={() => edit_del_destino(i, 0)}
                                  >
                                    <i
                                      class="material-icons md-85"
                                      style="color:#dd2c00">delete</i
                                    >
                                  </button>
                                  {#if 0 == item.sec_dests.length && edit_mode == item.id + item.date_ini}
                                    <button
                                      tooltip="Agregar"
                                      type="button"
                                      class="btn btn-link p-0"
                                      on:click={() => edit_add_destino(i, 0)}
                                    >
                                      <i
                                        class="material-icons md-85"
                                        style="color:#43a047">add_box</i
                                      >
                                    </button>
                                  {/if}
                                  <datalist id="dest_list">
                                    {#each $geos_class_list as item, i}
                                      <option value={item[1]} />{/each}
                                    {#each $poli_list as item, i}
                                      <option value={item[2]} />{/each}
                                  </datalist>
                                {/if}
                              {:else}
                                <span style="color:#ff5722"
                                  ><strong
                                    >{typeof $geos_class[
                                      item.sec_dests[item.sec_dests.length - 1]
                                    ] != "undefined"
                                      ? $geos_class[
                                          item.sec_dests[
                                            item.sec_dests.length - 1
                                          ]
                                        ][0]
                                      : typeof $poli[
                                            item.sec_dests[
                                              item.sec_dests.length - 1
                                            ]
                                          ] != "undefined"
                                        ? $poli[
                                            item.sec_dests[
                                              item.sec_dests.length - 1
                                            ]
                                          ][1]
                                        : item.sec_dests[
                                            item.sec_dests.length - 1
                                          ]}</strong
                                  ></span
                                >
                                {#if item.step == item.sec_dests.length + 2}
                                  <i
                                    class="material-icons md-85"
                                    style="color:#aed581">check_circle</i
                                  >
                                {/if}
                              {/if}
                              {#if item.sec_dests.length > 0 && e_selected != item.id + item.date_ini}
                                <button
                                  id={item.id + item.date_ini}
                                  type="button"
                                  class="btn btn-link"
                                  on:click={() => {
                                    extra_selected(item.id + item.date_ini);
                                  }}
                                  ><i class="material-icons md-90 green"
                                    >add_circle_outline</i
                                  ></button
                                >
                              {/if}
                            {:else}
                              <input
                                list="dest_list"
                                placeholder="Destino"
                                size="30"
                                on:change={(event) => {
                                  dest_selected = "";
                                  for (let x in $geos_class_list) {
                                    if (
                                      $geos_class_list[x][1] ==
                                      event.target.value
                                    ) {
                                      dest_selected = $geos_class_list[x][0];
                                    }
                                  }
                                  for (let x in $poli_list) {
                                    if (
                                      $poli_list[x][2] == event.target.value
                                    ) {
                                      dest_selected = $poli_list[x][0];
                                    }
                                  }
                                }}
                              />
                              <datalist id="dest_list">
                                {#each $geos_class_list as item, i}
                                  <option value={item[1]} />{/each}
                                {#each $poli_list as item, i}
                                  <option value={item[2]} />{/each}
                              </datalist>
                            {/if}
                          </div>
                          {#if e_selected == item.id + item.date_ini}
                            {#each item.sec_dests as sec_dests, j}
                              <div>
                                <i
                                  class="material-icons md-90"
                                  style="color:#26a69a">stop</i
                                >
                                {#if edit_mode != item.id + item.date_ini || (edit_mode == item.id + item.date_ini && item.step > j + 2)}
                                  <strong
                                    >{typeof $geos_class[sec_dests] !=
                                    "undefined"
                                      ? $geos_class[sec_dests][0]
                                      : typeof $poli[sec_dests] != "undefined"
                                        ? $poli[sec_dests][1]
                                        : sec_dests}</strong
                                  >
                                  {#if item.step > j + 2}
                                    <i
                                      class="material-icons md-85"
                                      style="color:#aed581">check_circle</i
                                    >
                                  {/if}
                                {:else}
                                  <input
                                    list="dest_list"
                                    on:change={(event) => {
                                      travel_toe.sec_dests[j] = "";
                                      for (let x in $geos_class_list) {
                                        if (
                                          $geos_class_list[x][1] ==
                                          event.target.value
                                        ) {
                                          travel_toe.sec_dests[j] =
                                            $geos_class_list[x][0];
                                          travel_toe_.sec_dests[j] =
                                            $geos_class_list[x][0];
                                          console.log(travel_toe);
                                        }
                                      }
                                      for (let x in $poli_list) {
                                        if (
                                          $poli_list[x][2] == event.target.value
                                        ) {
                                          travel_toe.sec_dests[j] =
                                            $poli_list[x][0];
                                          travel_toe_.sec_dests[j] =
                                            $poli_list[x][0];
                                          console.log(travel_toe);
                                        }
                                      }
                                    }}
                                    placeholder="Destino"
                                    size="30"
                                    value={typeof $geos_class[sec_dests] !=
                                    "undefined"
                                      ? $geos_class[sec_dests][0]
                                      : typeof $poli[sec_dests] != "undefined"
                                        ? $poli[sec_dests][1]
                                        : sec_dests}
                                  />
                                  <button
                                    tooltip="Borrar"
                                    type="button"
                                    class="btn btn-link p-0"
                                    on:click={() => edit_del_destino(i, j + 1)}
                                  >
                                    <i
                                      class="material-icons md-85"
                                      style="color:#dd2c00">delete</i
                                    >
                                  </button>
                                  <datalist id="dest_list">
                                    {#each $geos_class_list as item, i}
                                      <option value={item[1]} />{/each}
                                    {#each $poli_list as item, i}
                                      <option value={item[2]} />{/each}
                                  </datalist>
                                {/if}
                                {#if j + 1 == item.sec_dests.length && edit_mode != item.id + item.date_ini}
                                  <button
                                    id="{item.id + item.date_ini}_remove"
                                    type="button"
                                    class="btn btn-link"
                                    on:click={() => {
                                      e_selected = -1;
                                    }}
                                    ><i class="material-icons md-90 green"
                                      >remove_circle_outline</i
                                    ></button
                                  >
                                {/if}
                                {#if j + 1 == item.sec_dests.length && edit_mode == item.id + item.date_ini}
                                  <button
                                    tooltip="Agregar"
                                    type="button"
                                    class="btn btn-link p-0"
                                    on:click={() => edit_add_destino(i, j + 1)}
                                  >
                                    <i
                                      class="material-icons md-85"
                                      style="color:#43a047">add_box</i
                                    >
                                  </button>
                                {/if}
                              </div>
                            {/each}
                          {/if}
                          {#if typeof item.dest_m != "undefined"}
                            {#each item.dest_m as dest, j}
                              <div>
                                <i
                                  class="material-icons md-90"
                                  style="color:#26a69a">stop</i
                                >
                                <input
                                  list="dest_list"
                                  on:change={(event) => {
                                    for (let x in $geos_class_list) {
                                      if (
                                        $geos_class_list[x][1] ==
                                        event.target.value
                                      ) {
                                        future_travel[0].dest_m[j] = {
                                          name: $geos_class_list[x][0],
                                        };
                                        console.log(future_travel[0]);
                                      }
                                    }
                                    for (let x in $poli_list) {
                                      if (
                                        $poli_list[x][2] == event.target.value
                                      ) {
                                        future_travel[0].dest_m[j] = {
                                          name: $poli_list[x][0],
                                        };
                                        console.log(future_travel[0]);
                                      }
                                    }
                                  }}
                                  placeholder="Destino"
                                  size="30"
                                />
                                <datalist id="dest_list">
                                  {#each $geos_class_list as item, i}
                                    <option value={item[1]} />{/each}
                                  {#each $poli_list as item, i}
                                    <option value={item[2]} />{/each}
                                </datalist>
                              </div>
                            {/each}
                          {/if}
                        </div>
                        <div class="d-flex flex-column">
                          <div class="d-flex justify-content-end">
                            <div>
                              {#if item.id != "" && $drivers[item.oper]}
                                {#if $drivers[item.oper].status == 1}
                                  <!--GPS Driver-->
                                  <i
                                    on:click={() => {
                                      // Center map on driver
                                      if (
                                        typeof drivers_[item.oper] !=
                                          "undefined" &&
                                        map
                                      ) {
                                        if (
                                          drivers_[item.oper].pos.status == 1
                                        ) {
                                          setTimeout(() => {
                                            map.setCenter({
                                              lat: drivers_[item.oper].pos.lat,
                                              lng: drivers_[item.oper].pos.lng,
                                            });
                                          }, 1000);
                                          console.log("map center");
                                        }
                                      }
                                    }}
                                    tooltip={drivers_[item.oper].pos.status == 1
                                      ? "GPS disponible"
                                      : drivers_[item.oper].pos.status == -1
                                        ? "GPS no disponible"
                                        : "Conductor no acepta GPS"}
                                    class="material-icons md-90"
                                    style="color:{drivers_[item.oper].pos
                                      .status == 1
                                      ? '#26a69a'
                                      : drivers_[item.oper].pos.status == -1
                                        ? '#ff8f00'
                                        : 'rgb(166 38 96)'};
                                      cursor:pointer"
                                    >{drivers_[item.oper].pos.status == 1
                                      ? "gps_fixed"
                                      : "gps_off"}</i
                                  >
                                  <!--Show Video live -->
                                  <i
                                    on:click={() => {
                                      if (
                                        $drivers[item.oper].video_permission ==
                                        "ok"
                                      )
                                        show_video(item.oper);
                                      else {
                                        saved_ok = true;
                                        saved_text =
                                          "El conductor no dió permisos para ver video en vivo";
                                        saved_type = "alert-danger";
                                        setTimeout(function () {
                                          saved_ok = false;
                                        }, 3000);
                                      }
                                    }}
                                    tooltip="Video"
                                    class="material-icons md-90"
                                    style="color:{$drivers[item.oper]
                                      .video_permission == 'ok'
                                      ? '#26a69a'
                                      : 'rgb(166 38 96)'};
                                      cursor:pointer"
                                    >{$drivers[item.oper].video_permission ==
                                    "ok"
                                      ? "videocam"
                                      : "videocam_off"}</i
                                  >
                                  <!-- Show Audio Live-->
                                  <i
                                    on:click={() => {
                                      if (
                                        $drivers[item.oper].audio_permission ==
                                        "ok"
                                      ) {
                                        if (!show_audio_status) {
                                          show_audio(item.oper);
                                        } else {
                                          show_audio_status = false;
                                          socket_query.emit(
                                            "rtc_disconnect",
                                            driver_video
                                          );
                                          // Detener la transmisión de audio
                                          if (audioStream1) {
                                            audioStream1
                                              .getTracks()
                                              .forEach((track) => track.stop());
                                            audioStream1 = null;
                                          }
                                        }
                                      } else {
                                        saved_ok = true;
                                        saved_text =
                                          "El conductor no dió permisos para escuchar audio en vivo";
                                        saved_type = "alert-danger";
                                        setTimeout(function () {
                                          saved_ok = false;
                                        }, 3000);
                                      }
                                    }}
                                    tooltip="Audio"
                                    class="material-icons md-90"
                                    style="color:{$drivers[item.oper]
                                      .audio_permission == 'ok'
                                      ? !show_audio_status
                                        ? '#26a69a'
                                        : !audioStream1
                                          ? 'rgb(166 38 96)'
                                          : 'rgb(83 231 102)'
                                      : 'rgb(166 38 96)'};
                                      cursor:pointer"
                                    >{$drivers[item.oper].audio_permission ==
                                    "ok"
                                      ? "mic"
                                      : "mic_off"}</i
                                  >
                                  <i
                                    class="material-icons md-90"
                                    tooltip="Conductor en línea"
                                    style="color:rgb(74 205 34)"
                                    >fiber_manual_record
                                  </i>
                                {/if}
                              {/if}
                              <i
                                class="material-icons md-90"
                                style="color:{$drivers[item.oper]
                                  ? $drivers[item.oper].status == 1
                                    ? 'rgb(74 205 34)'
                                    : '#26a69a'
                                  : '#26a69a'}">person</i
                              >
                              {#if item.id != ""}
                                <strong
                                  >{typeof $drivers[item.oper] != "undefined"
                                    ? $drivers[item.oper].name
                                    : item.oper}</strong
                                >
                              {:else}
                                <input
                                  list="driver_list"
                                  placeholder="Operador"
                                  size="30"
                                  on:change={(event) => {
                                    oper_selected = "";
                                    for (let x in $drivers_list) {
                                      if (
                                        $drivers_list[x][1]["name"] ==
                                        event.target.value
                                      ) {
                                        oper_selected = $drivers_list[x][0];
                                      }
                                    }
                                  }}
                                />
                                <datalist id="driver_list">
                                  {#each $drivers_list as item, i}
                                    <option value={item[1]["name"]} />{/each}
                                </datalist>
                              {/if}
                            </div>
                          </div>
                          <div class="d-flex justify-content-end">
                            <div>
                              <i
                                tooltip="Ruta"
                                class="material-icons md-90"
                                style="color:#26a69a">directions</i
                              >
                              {#if item.id != ""}
                                {#if item.sec_dests.length == 0 || e_selected == item.id + item.date_ini}
                                  {#if edit_mode != item.id + item.date_ini || (edit_mode == item.id + item.date_ini && item.step > 1)}
                                    <strong
                                      tooltip={typeof $route_list_d[
                                        item.route
                                      ] != "undefined"
                                        ? $route_list_d[item.route]
                                        : "Nombre no disponible"}
                                      >{typeof $route_list_d[item.route] !=
                                      "undefined"
                                        ? $route_list_d[item.route].length > 35
                                          ? $route_list_d[item.route].slice(
                                              0,
                                              35
                                            ) + "..."
                                          : $route_list_d[item.route]
                                        : "Nombre no disponible"}</strong
                                    >
                                  {:else}
                                    <input
                                      list="ruta_list"
                                      placeholder="Ruta"
                                      size="30"
                                      on:change={(event) => {
                                        route_f_selected = "";
                                        for (let x in route_list_) {
                                          if (
                                            route_list_[x][1] ==
                                            event.target.value
                                          ) {
                                            route_f_selected =
                                              route_list_[x][0];
                                          }
                                        }
                                      }}
                                      value={typeof $route_list_d[item.route] !=
                                      "undefined"
                                        ? $route_list_d[item.route]
                                        : ""}
                                      on:contextmenu|preventDefault={() =>
                                        route_options(0, true)}
                                    />
                                    <datalist id="ruta_list">
                                      {#each route_list_ as item, i}
                                        {#if typeof geos_class_join[org_selected] != "undefined"}
                                          {#if (org_selected == "" || org_selected == item[2][2] || $geos_class_join[org_selected][2] == item[2][2]) && (dest_selected == "" || dest_selected == item[3][2] || $geos_class_join[dest_selected][2] == item[3][2])}
                                            <option value={item[1]} />
                                          {/if}
                                        {:else if (org_selected == "" || org_selected == item[2][2]) && (dest_selected == "" || dest_selected == item[3][2])}
                                          <option value={item[1]} />
                                        {/if}
                                      {/each}
                                    </datalist>
                                  {/if}
                                {:else}
                                  <span style="color:#ff5722"
                                    ><strong>{"Multiples rutas"}</strong></span
                                  >
                                {/if}
                              {:else}
                                <input
                                  list="ruta_list_1"
                                  placeholder="Ruta"
                                  size="30"
                                  on:change={(event) => {
                                    route_f_selected = "";
                                    for (let x in route_list_) {
                                      if (
                                        route_list_[x][1] == event.target.value
                                      ) {
                                        route_f_selected = route_list_[x][0];
                                      }
                                    }
                                  }}
                                  on:contextmenu|preventDefault={() =>
                                    route_options(0, false)}
                                />
                                <datalist id="ruta_list_1">
                                  {#each route_list_ as item, i}
                                    {#if typeof geos_class_join[org_selected] != "undefined"}
                                      {#if (org_selected == "" || org_selected == item[2][2] || $geos_class_join[org_selected][2] == item[2][2]) && (dest_selected == "" || dest_selected == item[3][2] || $geos_class_join[dest_selected][2] == item[3][2])}
                                        <option value={item[1]} />
                                      {/if}
                                    {:else if (org_selected == "" || org_selected == item[2][2]) && (dest_selected == "" || dest_selected == item[3][2])}
                                      <option value={item[1]} />
                                    {/if}
                                  {/each}
                                </datalist>
                              {/if}
                            </div>
                          </div>
                          {#if e_selected == item.id + item.date_ini}
                            {#each item.sec_routes as sec_routes, j}
                              <div
                                tooltip="Ruta"
                                class="d-flex justify-content-end align-items-center"
                              >
                                <i
                                  class="material-icons md-90"
                                  style="color:#26a69a">directions</i
                                >
                                {#if edit_mode != item.id + item.date_ini || (edit_mode == item.id + item.date_ini && item.step > j + 2)}
                                  <strong
                                    tooltip={typeof $route_list_d[sec_routes] !=
                                    "undefined"
                                      ? $route_list_d[sec_routes]
                                      : "Nombre no disponible"}
                                    >{typeof $route_list_d[sec_routes] !=
                                    "undefined"
                                      ? $route_list_d[sec_routes].length > 35
                                        ? $route_list_d[sec_routes].slice(
                                            0,
                                            35
                                          ) + "..."
                                        : $route_list_d[sec_routes]
                                      : "Nombre no disponible"}</strong
                                  >
                                {:else}
                                  <input
                                    list="ruta_list_{j}a"
                                    placeholder="Ruta"
                                    size="30"
                                    on:change={(event) => {
                                      for (let x in route_list_) {
                                        if (
                                          route_list_[x][1] ==
                                          event.target.value
                                        ) {
                                          travel_toe.sec_routes[j] =
                                            route_list_[x][0];
                                        }
                                      }
                                      console.log(item.sec_dests);
                                    }}
                                    value={typeof $route_list_d[sec_routes] !=
                                    "undefined"
                                      ? $route_list_d[sec_routes]
                                      : ""}
                                    on:contextmenu|preventDefault={() =>
                                      route_options(j + 1, true)}
                                  />
                                  <datalist id="ruta_list_{j}a">
                                    {#each route_list_ as item_, i}
                                      {#if typeof $geos_class_join[dest_selected] != "undefined"}
                                        {#if (j == 0 && (dest_selected == "" || dest_selected == item_[2][2] || $geos_class_join[dest_selected][2] == item_[2][2]) && (travel_toe_.sec_dests[j] == "" || travel_toe_.sec_dests[j] == item_[3][2] || $geos_class_join[travel_toe_.sec_dests[j]][2] == item_[3][2])) || (j != 0 && (travel_toe_.sec_dests[j - 1] == "" || travel_toe_.sec_dests[j - 1] == item_[2][2] || $geos_class_join[travel_toe_.sec_dests[j - 1]][2] == item_[2][2]) && (travel_toe_.sec_dests[j] == "" || travel_toe_.sec_dests[j] == item_[3][2] || $geos_class_join[travel_toe_.sec_dests[j]][2] == item_[3][2]))}
                                          <option value={item_[1]} />{/if}
                                      {:else if (j == 0 && (dest_selected == "" || dest_selected == item_[2][2]) && (travel_toe_.sec_dests[j] == "" || travel_toe_.sec_dests[j] == item_[3][2])) || (j != 0 && (travel_toe_.sec_dests[j - 1] == "" || travel_toe_.sec_dests[j - 1] == item_[2][2]) && (travel_toe_.sec_dests[j] == "" || travel_toe_.sec_dests[j] == item_[3][2]))}
                                        <option value={item_[1]} />
                                      {/if}
                                    {/each}
                                  </datalist>
                                {/if}
                              </div>
                            {/each}
                          {/if}
                          {#if typeof item.route_m != "undefined"}
                            {#each future_travel[0].route_m as route, j}
                              <div class="d-flex justify-content-end">
                                <div>
                                  <i
                                    class="material-icons md-90"
                                    style="color:#26a69a">directions</i
                                  >
                                  <input
                                    list="ruta_list_{j}b"
                                    placeholder="Ruta"
                                    size="30"
                                    on:change={(event) => {
                                      console.log("blur");
                                      for (let x in route_list_) {
                                        if (
                                          route_list_[x][1] ==
                                          event.target.value
                                        ) {
                                          future_travel_r[0].route_m[j] = {
                                            name: route_list_[x][0],
                                          };
                                        }
                                      }
                                    }}
                                    on:contextmenu|preventDefault={() =>
                                      route_options(1 + j, false)}
                                  />
                                  <datalist id="ruta_list_{j}b">
                                    {#each route_list_ as item, i (item[0])}
                                      {#if typeof $geos_class_join[dest_selected] != "undefined"}
                                        {#if (j == 0 && (dest_selected == "" || dest_selected == item[2][2] || $geos_class_join[dest_selected][2] == item[2][2]) && (future_travel[0].dest_m[j].name == "" || future_travel[0].dest_m[j].name == item[3][2] || $geos_class_join[future_travel[0].dest_m[j].name][2] == item[3][2])) || (j != 0 && (future_travel[0].dest_m[j - 1].name == "" || future_travel[0].dest_m[j - 1].name == item[2][2] || $geos_class_join[future_travel[0].dest_m[j - 1].name][2] == item[2][2]) && (future_travel[0].dest_m[j].name == "" || future_travel[0].dest_m[j].name == item[3][2] || $geos_class_join[future_travel[0].dest_m[j].name][2] == item[3][2]))}
                                          <option value={item[1]} />{/if}
                                      {:else if (j == 0 && (dest_selected == "" || dest_selected == item[2][2]) && (future_travel[0].dest_m[j].name == "" || future_travel[0].dest_m[j].name == item[3][2])) || (j != 0 && (future_travel[0].dest_m[j - 1].name == "" || future_travel[0].dest_m[j - 1].name == item[2][2]) && (future_travel[0].dest_m[j].name == "" || future_travel[0].dest_m[j].name == item[3][2]))}
                                        <option value={item[1]} />
                                      {/if}
                                    {/each}
                                  </datalist>
                                </div>
                              </div>
                            {/each}
                          {/if}
                          <div class="d-flex justify-content-end">
                            <div>
                              {#if item.id == "" && dest_selected != "" && org_selected != ""}
                                <button
                                  on:click={() => {
                                    add_destino(item.id, item.date_ini);
                                  }}
                                  id="add_dest_{$nicks_list[item.id]}"
                                  type="button"
                                  class="btn btn-info"
                                >
                                  Agregar destino
                                </button>
                              {/if}
                              {#if item.id != "" && !new_travel_s && item.step - 2 < item.sec_dests.length && (edit_mode == item.id + item.date_ini || edit_mode == -1)}
                                {#if edit_mode == item.id + item.date_ini}
                                  <button
                                    on:click={() => {
                                      save_edit_travel(item.id, item.date_ini);
                                    }}
                                    id="save_edit_travel_{$nicks_list[item.id]}"
                                    type="button"
                                    class="btn btn-success"
                                  >
                                    Guardar edicion
                                  </button>
                                {/if}
                                <button
                                  on:click={() => {
                                    edit_travel(item.id + item.date_ini, i);
                                  }}
                                  id="pos_edit_travel_{$nicks_list[item.id]}"
                                  type="button"
                                  class="btn btn-{edit_mode ==
                                  item.id + item.date_ini
                                    ? 'danger'
                                    : 'info'}"
                                >
                                  {edit_mode == item.id + item.date_ini
                                    ? "Cancelar edicion"
                                    : "Editar"}
                                </button>
                              {/if}
                              {#if edit_mode != item.id + item.date_ini && (!new_travel_s || i == 0) && (item.step == 0 || item.id == "")}
                                <button
                                  on:click={() => {
                                    item.id != ""
                                      ? save_travel_modal(
                                          item.id,
                                          item.date_ini
                                        )
                                      : save_travel(item.id, item.date_ini);
                                  }}
                                  id="edit_prog_{$nicks_list[item.id]}"
                                  type="button"
                                  class="btn btn-{item.id != ''
                                    ? 'danger'
                                    : 'success'}"
                                >
                                  {item.id != "" ? "Borrar" : "Guardar"}
                                </button>
                              {/if}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                {/each}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .btn {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .btn.big {
    vertical-align: initial;
    padding: 5px 6px;
    font-size: 0.9rem;
  }

  .card.big-border {
    border: 3px solid;
  }
  .card.med-border {
    border: 3px solid;
  }
  .card-b {
    border: 0px;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .h-15 {
    height: 15%;
  }
  .h-85 {
    height: 85%;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1001;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  .card-header:first-child {
    border-radius: 0;
  }

  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  .form-con {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
    background-color: white;
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-95 {
    font-size: 1.8rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
