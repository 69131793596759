<script>
  export let chats_db;
  export let socket_query;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    last_message_date,
  } from "./stores.js";

  onDestroy(() => {
    socket_query.off("chats_", chat_in);
    msg_observer.disconnect();
    chats_window.removeEventListener("scroll", scroll_listen);
  });

  let chat_list_change = false;
  let chats_window;
  let delay_scroll = false;
  let scroll_listen_set = false;
  $: {
    if (chats_window && !scroll_listen_set) {
      scroll_listen_set = true;
      chats_window.addEventListener("scroll", scroll_listen);
    }
  }
  function scroll_listen() {
    if (
      chats_window.scrollTop + chats_window.clientHeight >=
      chats_window.scrollHeight * 0.9
    ) {
      document.getElementById("fab").classList.remove("visible");
    } else {
      document.getElementById("fab").classList.add("visible");
    }
    // Detectar si el scroll está en la parte superior para cargar más mensajes
    if (chats_window.scrollTop == 0 && !delay_scroll) {
      // Cargar más mensajes
      chat_list = chat_list_.slice(-30 - chat_list.length);
      delay_scroll = true;
    }
  }
  afterUpdate(() => {
    if (chat_list_change) {
      chat_list_change = false;
      //chats_window.scrollTop = chats_window.scrollHeight;
      let last_read = document.getElementById("last_read");
      if (last_read) {
        last_read.scrollIntoView();
      } else {
        chats_window.scrollTop = chats_window.scrollHeight;
      }
    }
    if (delay_scroll) {
      delay_scroll = false;
      chats_window.scrollTop = 20;
    }
  });

  let selected = !localStorage.getItem("selected_chat")
    ? -1
    : parseInt(localStorage.getItem("selected_chat"));
  let setup = false;
  $: {
    if (selected != -1 && !setup && $drivers_list[selected] != null) {
      setup = true;
      maping(
        selected,
        $drivers[$drivers_list[selected][0]].chatId,
        $drivers_list[selected][0]
      );
    }
  }
  $: {
    localStorage.setItem("selected_chat", selected);
  }
  let chat_list = [];
  let chat_list_ = [];
  let chatId_selected = 0;
  let driver_id_selected = 0;
  async function maping(i, chatId, driver) {
    driver_id_selected = driver;
    selected = i;
    chatId_selected = chatId == "" ? driver : chatId;
    let chat1 = await chats_db.getItem(chatId);
    chat_list_ = chat1 != null ? JSON.parse(chat1)["chats"] : [];
    if (last_read[chatId_selected] != null) {
      for (let x = 0; x < chat_list_.length; x++) {
        if (chat_list_[x].date > last_read[chatId_selected]) {
          chat_list_.splice(x, 0, {
            type: -1,
            date: chat_list_[x].date,
            message: "Último mensaje leído",
          });
          break;
        }
      }
    }
    chat_list = chat_list_.slice(-30);
    chats_pendientes[driver] = 0;
    chat_list_change = true;
  }

  socket_query.on("chats_", chat_in);
  function chat_in(data, data2) {
    if (data != null) {
      if (data == chatId_selected) {
        chat_list.push(JSON.parse(data2));
        chat_list = [...chat_list];
        chat_list_change = true;
      } else {
        if (chats_pendientes[data] == null) chats_pendientes[data] = 0;
        chats_pendientes[data]++;
      }
    }
  }
  let chat_to_send = "";
  function send_chat() {
    let chat_to_send_ = chat_to_send;
    chat_to_send = "";
    if (chatId_selected != 0 || driver_id_selected != 0) {
      if (chat_to_send_ != "") {
        socket_query.emit(
          "chat_send",
          chatId_selected != 0 ? chatId_selected : driver_id_selected,
          chat_to_send_,
          (data) => {
            //chat_to_send="";
            if (data == "ok") {
              console.log("chat OK");
            }
          }
        );
      }
    }
  }
  let saved_ok = false,
    saved_type,
    saved_text;
  function fielSender(e) {
    let file = e.target.files[0];
    document.getElementById("fileInput").value = "";
    if (file) {
      const reader = new FileReader();

      // Cuando el archivo se haya leído
      reader.onload = () => {
        const base64Data = reader.result; // Obtener solo la parte Base64
        const fileType = file.type; // Tipo de archivo
        const fileName = file.name; // Nombre del archivo

        // Crear un objeto con la información
        const payload = {
          fileName,
          fileType,
          base64Data,
        };
        if (
          fileType.search("image/") == -1 &&
          fileType.search("application/pdf") == -1
        ) {
          saved_type = "alert-danger";
          saved_text = "Solo se permiten imágenes o PDF";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
          return;
        }
        socket_query.emit(
          "chat_send",
          chatId_selected != 0 ? chatId_selected : driver_id_selected,
          base64Data,
          (data) => {
            //chat_to_send="";
            if (data == "ok") {
              console.log("chat OK");
            }
          }
        );
        console.log("Archivo enviado:", payload);
      };

      // Leer el archivo como Data URL (Base64)
      reader.readAsDataURL(file);
    } else {
      alert("Por favor selecciona un archivo primero");
    }
  }
  let isRecording = false;
  let mediaRecorder;
  let audioChunks = [];
  let audioStream;
  function run_record() {
    if (!mediaRecorder) {
      // Solicitar permisos para el micrófono al hacer clic
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioStream = stream;
          mediaRecorder = new MediaRecorder(stream);

          // Manejar datos de audio
          mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
          };

          // Cuando la grabación se detenga
          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
            audioChunks = [];

            audioStream.getTracks().forEach((track) => track.stop());

            // Leer el archivo como Base64
            const reader = new FileReader();
            reader.onload = () => {
              const base64Data = reader.result;
              const payload = {
                fileName: `audio-${Date.now()}.webm`, // Nombre único para el archivo
                fileType: "audio/webm",
                base64Data,
              };
              socket_query.emit(
                "chat_send",
                chatId_selected != 0 ? chatId_selected : driver_id_selected,
                base64Data,
                (data) => {
                  //chat_to_send="";
                  if (data == "ok") {
                    console.log("chat OK");
                  }
                }
              );
              console.log("Audio enviado:", payload);
            };
            reader.readAsDataURL(audioBlob);
            mediaRecorder = null;
            audioStream = null;
          };

          // Iniciar grabación al primer clic después de obtener permisos
          toggleRecording();
        })
        .catch((error) => {
          console.error("Error al acceder al micrófono:", error);
          alert(
            "No se pudo acceder al micrófono. Por favor, verifica los permisos."
          );
        });
    } else {
      toggleRecording();
    }
  }
  function toggleRecording() {
    if (!isRecording) {
      mediaRecorder.start();
      isRecording = true;
      console.log("Grabación iniciada");
    } else {
      mediaRecorder.stop();
      isRecording = false;
      console.log("Grabación detenida");
    }
  }

  // Observador de scroll de mensajes
  let last_read = localStorage.getItem("last_read")
    ? JSON.parse(localStorage.getItem("last_read"))
    : {};
  let msg_observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let chatId = entry.target.getAttribute("chatId");
          let chat_date = parseInt(entry.target.getAttribute("chat_date"));
          if (last_read[chatId] == null || last_read[chatId] < chat_date) {
            last_read[chatId] = chat_date;
            localStorage.setItem("last_read", JSON.stringify(last_read));
            last_message_date.set($last_message_date);
          }
        }
      });
    },
    {
      root: document.querySelector(".scroll-messages"),
      threshold: 0.5,
    }
  );
  function run_observer(node) {
    msg_observer.observe(node);
  }
  // Experimental count chats  Mover a WebWorker de Trips
  let chats_pendientes = {};
  let chat_count_ready = false;
  async function chat_counter() {
    for (let x in $drivers_list) {
      let driver = $drivers_list[x][0];
      let chat1 = await chats_db.getItem(driver);
      if (chat1) {
        chat1 = JSON.parse(chat1)["chats"];
        if (!last_read[driver]) {
          last_read[driver] = chat1[chat1.length - 1].date;
        }
        for (let y in chat1) {
          if (chat1[y].date > last_read[driver]) {
            if (chats_pendientes[driver]) {
              chats_pendientes[driver]++;
            } else {
              chats_pendientes[driver] = 1;
            }
          }
        }
      } else {
        last_read[driver] = 0;
      }
    }
    localStorage.setItem("last_read", JSON.stringify(last_read));
    last_message_date.set($last_message_date);
    if (selected != -1) chats_pendientes[driver_id_selected] = 0;
    console.log(chats_pendientes);
  }
  $: {
    if ($drivers_list && !chat_count_ready) {
      if ($drivers_list.length > 0) {
        chat_count_ready = true;
        chat_counter();
      }
    }
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<div
  id="fab"
  class="fab"
  title="Ir abajo"
  on:click={() => {
    if (!chats_window) return;
    chats_window.scrollTop = chats_window.scrollHeight;
    document.getElementById("fab").classList.remove("visible");
  }}
>
  <span class="material-icons">keyboard_arrow_down</span>
</div>

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div class="card-header bg-dark text-white b-0">
            <i tooltip="Operador" class="material-icons md-90">person</i> Choferes
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each $drivers_list as item, i}
                <a
                  href="/{item[0]}"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    maping(i, $drivers[item[0]].chatId, item[0]);
                  }}
                  class="list-group-item list-group-item-action"
                  >{typeof $drivers[item[0]].name != "undefined"
                    ? $drivers[item[0]].name
                    : item}
                  <!--Bage message not read-->
                  {#if chats_pendientes[item[0]]}
                    {#if chats_pendientes[item[0]] > 0}
                      <span
                        class="badge badge-pill badge-success"
                        style="position: absolute; right: 10px; font-size: 90%;"
                        >{chats_pendientes[item[0]]}</span
                      >
                    {/if}
                  {/if}
                </a>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="d-flex flex-column h-100">
        <div class="card-header b-0">
          <i class="material-icons md-90">chat</i> Chat
        </div>
        <div
          id="scroll-messages"
          class="card-body flex-grow-1 overflow-auto p-4"
          style="background-color: #e7e4e4;{chat_list.length == 0
            ? 'display: flex; flex-direction: column; justify-content: center;'
            : ''}"
          bind:this={chats_window}
        >
          {#if chat_list.length > 0}
            {#each chat_list as item, i}
              {#if item.message.search("data:audio/") != -1}
                <div
                  class="mt1 d-flex my-1 {item.type == 0
                    ? 'flex-row'
                    : 'flex-row-reverse'}"
                  use:run_observer
                  chatId={chatId_selected}
                  chat_date={item.date}
                >
                  <div
                    class=" card {item.type == 0
                      ? 'text-white bg-dark2'
                      : ' bg-secondary2'}"
                    style="border-radius:1rem;border:2px solid #009184"
                  >
                    <div class="card-header b-0">
                      <i
                        class="material-icons md-12"
                        style="color:{item.type == 0 ? '#fff' : '#212121'}"
                        >{item.type == 1 ? "home" : "person_pin"}</i
                      > <audio src={item.message} controls="controls" />
                    </div>
                  </div>
                  <div style="font-size:0.7rem" class="d-flex align-items-end">
                    <strong
                      >{moment(new Date(item.date * 1000)).format(
                        "DD/MM/YY HH:mm"
                      )}</strong
                    >
                  </div>
                </div>
              {:else if item.message.search("data:image/") != -1}
                <div
                  class="mt1 d-flex my-1 {item.type == 0
                    ? 'flex-row'
                    : 'flex-row-reverse'}"
                  use:run_observer
                  chatId={chatId_selected}
                  chat_date={item.date}
                >
                  <div
                    class=" card {item.type == 0
                      ? 'text-white bg-dark2'
                      : ' bg-secondary2'}"
                    style="border-radius:1rem;border:2px solid #009184"
                  >
                    <div class="card-header b-0">
                      <i
                        class="material-icons md-12"
                        style="color:{item.type == 0 ? '#fff' : '#212121'}"
                        >{item.type == 1 ? "home" : "person_pin"}</i
                      >
                      <a href={item.message} download
                        ><img src={item.message} alt="" height="300" /></a
                      >
                    </div>
                  </div>
                  <div style="font-size:0.7rem" class="d-flex align-items-end">
                    <strong
                      >{moment(new Date(item.date * 1000)).format(
                        "DD/MM/YY HH:mm"
                      )}</strong
                    >
                  </div>
                </div>
              {:else if item.message.search("data:application/pdf") != -1}
                <div
                  class="mt1 d-flex my-1 {item.type == 0
                    ? 'flex-row'
                    : 'flex-row-reverse'}"
                  use:run_observer
                  chatId={chatId_selected}
                  chat_date={item.date}
                >
                  <div
                    class=" card {item.type == 0
                      ? 'text-white bg-dark2'
                      : ' bg-secondary2'}"
                    style="border-radius:1rem;border:2px solid #009184"
                  >
                    <div class="card-header b-0">
                      <i
                        class="material-icons md-12"
                        style="color:{item.type == 0 ? '#fff' : '#212121'}"
                        >{item.type == 1 ? "home" : "person_pin"}</i
                      >
                      <a href={item.message} download>
                        <!-- icon PDF-->
                        <i class="material-icons">picture_as_pdf</i>
                        Documento PDF
                      </a>
                    </div>
                  </div>
                  <div style="font-size:0.7rem" class="d-flex align-items-end">
                    <strong
                      >{moment(new Date(item.date * 1000)).format(
                        "DD/MM/YY HH:mm"
                      )}</strong
                    >
                  </div>
                </div>
              {:else if item.type != -1}
                <div
                  class="mt1 d-flex my-1 {item.type == 0
                    ? 'flex-row'
                    : item.type == 1
                      ? 'flex-row-reverse'
                      : 'flex-column justify-content-center align-items-center'}"
                  use:run_observer
                  chatId={chatId_selected}
                  chat_date={item.date}
                >
                  <div
                    class=" card {item.type == 0
                      ? 'text-white bg-dark2'
                      : item.type == 1
                        ? 'bg-secondary2'
                        : 'bg-secondary3'}"
                    style="border-radius:1rem;border:2px solid #009184"
                  >
                    <div class="card-header b-0" style="border-bottom:0px;">
                      <i
                        class="material-icons md-12"
                        style="color:{item.type == 0 ? '#fff' : '#212121'}"
                        >{item.type == 1
                          ? "home"
                          : item.type == 0
                            ? "person_pin"
                            : "info"}</i
                      >
                      {item.message}
                    </div>
                  </div>
                  <div
                    style="font-size:0.7rem; margin: {item.type == 2?"5px":"0px"} 5px 0px 5px;"
                    class="d-flex align-items-end"
                  >
                    <strong
                      >{moment(new Date(item.date * 1000)).format(
                        "DD/MM/YY HH:mm"
                      )}</strong
                    >
                  </div>
                </div>
              {:else}
                <div
                  class="d-flex justify-content-center"
                  style="text-align: center; margin: 10px;"
                  id="last_read"
                >
                  <div class="text-white" style="width: 100%;">
                    <div
                      class=""
                      style="display: flex;color: black;flex-direction: row;justify-content: center;align-items: center;"
                    >
                      <div
                        style="display: inline-block;background:black; height:2px; width:10%;"
                      ></div>
                      <span style="margin-left: 10px;margin-right: 10px;"
                        ><i class="material-icons md-12">info</i>
                        <strong>{item.message}</strong></span
                      >
                      <div
                        style="display: inline-block;background:black; height:2px; width:10%;"
                      ></div>
                    </div>
                  </div>
                </div>
              {/if}
            {/each}
          {:else}
            <div class="d-flex justify-content-center">
              <div class="card bg-secondary2 text-white">
                <div class="card-header b-0" style="color: black;">
                  <i class="material-icons md-12">info</i> No hay mensajes
                </div>
              </div>
            </div>
          {/if}
        </div>
        <div class="d-flex mt-1">
          <form on:submit|preventDefault={send_chat} class="d-flex w-100">
            <input
              name="chat"
              class="w-100 mr-2"
              autocomplete="off"
              bind:value={chat_to_send}
              style="font-size:1.1rem; margin: 0.5rem; border-radius: 15px; padding: 5px;"
              placeholder="Escribe un mensaje"
            />
            <input
              type="file"
              id="fileInput"
              accept="image/*,application/pdf"
              style="display: none;"
              on:change={(e) => fielSender(e)}
            />
            <button
              on:click|preventDefault={() => {
                document.getElementById("fileInput").click();
              }}
              style="padding: 0.1rem 0.5rem; margin: 0.1rem"
              class="btn btn-light"
              type="button"
            >
              <i class="material-icons">attach_file</i>
            </button>
            <button
              on:click|preventDefault={() => run_record()}
              style="padding: 0.1rem 0.5rem; margin: 0.1rem"
              class="btn btn-light"
              type="button"
            >
              <!-- Audio icon -->
              <i class="material-icons">{isRecording ? "stop" : "mic"}</i>
            </button>
            <button
              type="submit"
              style="padding: 0.1rem 2rem; margin: 0.5rem"
              class="btn btn-success"><i class="material-icons">send</i></button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .bg-secondary3 {
    background-color: #8afd9e;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  .fab {
    z-index: 1000;
    position: fixed;
    bottom: 60px;
    right: 25px;
    width: 48px;
    height: 48px;
    background-color: #6200ee; /* Color estilo Material Design */
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s ease,
      visibility 0.3s ease;
  }

  .fab.visible {
    opacity: 1;
    visibility: visible;
  }

  .fab:hover {
    background-color: #3700b3;
  }
</style>
